import classNames from 'classnames'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { getObjectFromJsonString } from 'utils/actions'
import { compressAddress } from 'utils/stakingAssistant/utils'
import {
    AVS_KEY,
    BITCOIN_AND_OTHERS_KEY,
    LIQUID_STAKING_KEY,
    STABLECOIN_KEY,
} from 'utils/constants'
import { Card } from 'components/tables'
import {
    ComponentWithLink,
    DETAILS_TYPE_TEXT,
    ItemWithLogo,
    GroupSize,
    HorizontalSeparator,
    Skeleton,
    SKELETON_BG_CONTRAST1,
} from 'components/ui'
import { AssetMetricsCard } from './assetMetricsCard'
import { AssetExpandedMetricsCard } from './assetExpandedMetricsCard'
import { LstProviderLabel } from '../profile/heroSection'

import styles from './assetCard.module.scss'

export const AssetCard = ({
    index = '',
    item = null,
    isLoading = false,
    netStakingFlowItem = null,
    sortByKey = null,
    minimal = false,
    stablecoinData,
}) => {
    const { queryParams } = useTableArchiveContext()

    const categoryKey = queryParams?.category?.key

    const isLst = categoryKey === LIQUID_STAKING_KEY
    const isAVS = categoryKey === AVS_KEY
    const isStablecoins = categoryKey === STABLECOIN_KEY
    const isBtc = categoryKey === BITCOIN_AND_OTHERS_KEY

    const links = getObjectFromJsonString(item?.links)
    const address = (links?.address || links?.contract) ?? ''
    const truncatedAddress = compressAddress(address, 5, 5)

    return (
        <Card
            withExpansion={
                !minimal && !isLst && !isAVS && !isStablecoins && !isBtc
            }
            renderMetricsCard={() => (
                <>
                    <ComponentWithLink
                        link={`/asset/${item?.slug}`}
                        blank={false}
                    >
                        <div className={styles.header}>
                            <div className={styles.itemGroup}>
                                <div className={styles.index}>{index}</div>
                                <ItemWithLogo
                                    iconUrl={item?.logoUrl}
                                    name={item?.symbol}
                                    detailsType={DETAILS_TYPE_TEXT}
                                    isVerified={false}
                                    alt={item?.name}
                                    slug={item?.slug}
                                    iconSize={36}
                                    sizeType={GroupSize.Small}
                                    isLoading={isLoading}
                                    withDetails={false}
                                />
                            </div>
                            <div className={styles.link}>
                                <span>View Profile</span>
                                <span
                                    className={classNames(
                                        'icon icon-arrow-right',
                                        styles.arrow
                                    )}
                                />
                            </div>
                        </div>
                    </ComponentWithLink>
                    <HorizontalSeparator />
                    {isLst && (
                        <>
                            {isLoading ? (
                                <div className='py-2'>
                                    <Skeleton
                                        width={'160px'}
                                        height={'40px'}
                                        variant={SKELETON_BG_CONTRAST1}
                                        borderRadius={'8px'}
                                    />
                                </div>
                            ) : (
                                <LstProviderLabel
                                    className='!bg-transparent hover:!shadow-none'
                                    asset={item}
                                />
                            )}
                            <HorizontalSeparator />
                        </>
                    )}
                    <div
                        className={classNames(
                            styles.mainMetrics,
                            isLst ? '!pt-5 !pb-3' : '!pb-7'
                        )}
                    >
                        <AssetMetricsCard
                            item={item}
                            index={index}
                            isLoading={isLoading}
                            sortBy={sortByKey}
                            minimal={minimal}
                            stablecoinData={stablecoinData}
                        />
                    </div>
                </>
            )}
            asset={item}
            renderExpandedCard={() => {
                if (!isLst) {
                    return (
                        <AssetExpandedMetricsCard
                            netStakingFlowItem={netStakingFlowItem}
                            item={item}
                            index={index}
                            isLoading={isLoading}
                        />
                    )
                }
            }}
        />
    )
}
