import { STAKING_LINKS } from 'components/ui/StakeWithButton'

// TODO add provider logic as well
export const hasStakeWithButton = (profileItem, validators) => {
    // Check if asset is in the list of assets that have a stake button
    const hasStakeButton =
        Object.keys(STAKING_LINKS).includes(profileItem?.slug) &&
        validators?.length === 1
    return hasStakeButton
}
