import Image from 'next/image'
import { trimUnescapedHtml } from 'utils/actions'
import { useAnnouncementsContext } from 'components/announcements'
import { Link, Skeleton } from 'components/ui'
import { SocialLinks } from './socialLinks'

import styles from './viewWithLinks.module.scss'

export const FooterLink = ({
    link = {
        title: '',
        href: '',
        blank: false,
    },
}) => {
    return (
        <Link href={link?.href} blank={Boolean(link?.blank)} external={true}>
            <p className={styles.link}>{link?.title ?? ''}</p>
        </Link>
    )
}

const AnnouncementTeaser = () => {
    const { isLoading, lastAnnouncement } = useAnnouncementsContext()

    const readMoreLink = !isLoading
        ? trimUnescapedHtml(lastAnnouncement?.content ?? '')
        : null

    return (
        <div className={styles.announcementTeaser}>
            <div className={styles.announcement}>
                <div className={styles.image}>
                    {isLoading ? (
                        <Skeleton width={'100%'} height={'100%'} borderRadius={'4px'} />
                    ) : (
                        <Image
                            src={
                                lastAnnouncement?.post_options?.featuredImage
                                    ?.sourceUrl
                            }
                            alt='Announcement'
                            fill
                            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    )}
                </div>
                <div className={styles.content}>
                    {isLoading ? (
                        <Skeleton width={'100%'} height={'20px'} borderRadius={'4px'} />
                    ) : (
                        <p className={styles.title}>
                            {trimUnescapedHtml(
                                lastAnnouncement?.title || 'Announcement'
                            )}
                        </p>
                    )}

                    {isLoading ? (
                        <Skeleton width={'100%'} height={'100%'} borderRadius={'4px'} />
                    ) : (
                        <>
                            <p className={styles.text}>
                                {trimUnescapedHtml(lastAnnouncement?.excerpt ?? '')}
                            </p>
                            {Boolean(readMoreLink) && (
                                <Link
                                    href={readMoreLink}
                                    blank={true}
                                    className={styles.readMore}
                                >
                                    Read more
                                </Link>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export const ViewWithLinks = ({
    links = [],
    description = '',
    withSocialLinks = true,
    withAnnouncement = false,
}) => {
    return (
        <div className={styles.viewWithLinks}>
            <div className={styles.linksWithText}>
                <div className={styles.links}>
                    {links?.map((link, idx) => (
                        <FooterLink
                            key={`link-${link?.title}-${idx}`}
                            link={link}
                        />
                    ))}
                </div>
                <div className={styles.description}>{description}</div>
            </div>
            {withAnnouncement && (
                <div className={styles.announcementWrap}>
                    <AnnouncementTeaser />
                </div>
            )}
            {withSocialLinks && (
                <div className={styles.social}>
                    <SocialLinks />
                </div>
            )}
        </div>
    )
}
