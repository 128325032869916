import { START_TIME_SECONDS, VIDEO_ID } from 'components/calculator/constants'
import { HeaderWithLink } from 'components/text/headerWithLink'
import { Calculator } from 'components/calculator'
import { YoutubeLink } from 'components/ui'

import styles from './calculatorSection.module.scss'

export const CalculatorSection = ({
    asset = null,
    scenariosExpandable = true,
}) => {
    if (!asset?.includeInCalculator || asset?.underMaintenance) return null

    return (
        <div id='section-calculate-rewards' className={styles.calculatorWrap}>
            <div className={styles.topWrap}>
                <div className={styles.headerWrap}>
                    <HeaderWithLink
                        title={`Calculate Your ${asset?.symbol} Staking Rewards`}
                        href={`/asset/${asset?.slug}#section-calculate-rewards`}
                    />
                    <p className='text-description'>
                        Examine the long-term compounding effect of staking -
                        per asset, provider, staking amount and price scenario.
                    </p>
                </div>
                <div className={styles.linkWrap}>
                    <YoutubeLink
                        href={`https://youtube.com/watch?v=${VIDEO_ID}&t=${START_TIME_SECONDS}s`}
                        label={'Learn how to use the calculator'}
                    />
                </div>
            </div>
            <Calculator
                scenariosExpandable={scenariosExpandable}
                noRedirect
                assetSlug={asset?.slug}
            />
        </div>
    )
}
