import classNames from 'classnames'
import { useAssetRewardCalculatorContext } from 'contexts/assetRewardCalculatorContext'
import {
    NO_DATA_INDICATOR,
    formatOutputNumber,
    formatSeconds,
    getPrecisionBySignificantFigures,
    isInvalidNumber,
} from 'utils/formatter'
import {
    calculateIfValid,
    getMetricValueByKey,
    getObjectFromJsonString,
} from 'utils/actions'
import { Switch } from 'components/forms'
import { DEFAULT_STAKING_AMOUNT_USD } from 'components/calculator/constants'
import { getStakingAmountTokens } from '../info/assetRewardCalculator'
import { AssetInput } from './assetInput'
import { ReceiveBlock } from './receiveBlock'
import {
    getNumberOrDefault,
    getStakingAmountUsd,
} from 'components/calculator/utils'
import {
    BUTTON_VARIANT_BLUE,
    BUTTON_VARIANT_FG,
    ButtonWithBg,
    Link,
} from 'components/ui'
import { GTMEvent, logEvent } from 'utils/GTM'
import { HeaderWithLink } from 'components/text/headerWithLink'

export const getAmountFromLstInput = (
    lstAmountInTokens = '',
    usd = false,
    tokenPrice = null,
    isStake = false,
    exchangeRatio = null,
    marketRatio = null
) => {
    const result = calculateIfValid(
        ({ ratio, lstAmountInTokens }) => lstAmountInTokens * ratio,
        {
            ratio: isStake ? exchangeRatio : marketRatio,
            lstAmountInTokens,
        }
    )
    const amountInTokens = getNumberOrDefault(result, '')

    return usd
        ? getStakingAmountUsd(amountInTokens, false, tokenPrice, null)
        : amountInTokens
}

export const ExitCalculator = () => {
    const {
        asset,
        outputAsset,
        amount: lstAmount,
        setAmount: setLstAmount,
        isLoading,
        usd,
        setUsd,
    } = useAssetRewardCalculatorContext()

    const outputAssetPrice = getMetricValueByKey(outputAsset, 'price')
    const tokenPrice = getMetricValueByKey(asset, 'price')
    const exchangeRatio = getMetricValueByKey(asset, 'exchange_ratio')
    const marketRatio = getMetricValueByKey(asset, 'market_ratio')
    const unstakingTimeInSeconds = getMetricValueByKey(asset, 'unstaking_time')

    let amountInLstTokens = 0
    if (lstAmount?.actual) {
        amountInLstTokens = getStakingAmountTokens(
            lstAmount?.actual,
            usd,
            tokenPrice
        )
    } else {
        amountInLstTokens = getStakingAmountTokens(
            DEFAULT_STAKING_AMOUNT_USD,
            true,
            tokenPrice
        )
    }

    const sellRewardInEth = getAmountFromLstInput(
        amountInLstTokens,
        false,
        outputAssetPrice,
        false,
        exchangeRatio,
        marketRatio
    )
    const unstakeRewardInEth = getAmountFromLstInput(
        amountInLstTokens,
        false,
        outputAssetPrice,
        true,
        exchangeRatio,
        marketRatio
    )
    const diffPercentage = calculateIfValid(
        ({ sellRewardInEth, unstakeRewardInEth }) => {
            return (
                (Math.abs(sellRewardInEth - unstakeRewardInEth) /
                    Math.min(sellRewardInEth, unstakeRewardInEth)) *
                100
            )
        },
        {
            sellRewardInEth,
            unstakeRewardInEth,
        }
    )
    const isSellBest =
        sellRewardInEth >= unstakeRewardInEth &&
        !isInvalidNumber(unstakeRewardInEth) &&
        !isInvalidNumber(sellRewardInEth)
    const isUnstakeBest =
        !isSellBest &&
        !isInvalidNumber(unstakeRewardInEth) &&
        !isInvalidNumber(sellRewardInEth)

    const provider = asset?.rewardOptionsWithAssetAsOutput?.[0]?.providers?.[0]

    const externalStakeLink = getObjectFromJsonString(asset?.links)?.[
        'stakelink'
    ]

    const externalSwapLink =
        getObjectFromJsonString(asset?.links)?.['swapoutLink'] ||
        getObjectFromJsonString(asset?.links)?.['swaplink']

    const precision = isInvalidNumber(diffPercentage)
        ? 2
        : getPrecisionBySignificantFigures(diffPercentage / 100, 2)

    const onUnstake = (type, url) => {
        logEvent(GTMEvent.ExternalLstUnstake, {
            token: outputAsset?.symbol,
            provider: provider?.name,
            type,
            url,
        })
    }

    return (
        <div id='section-exit-calculator' className='flex flex-col w-full'>
            <HeaderWithLink
                title={`Exit Calculator`}
                href={`/asset/${asset?.slug}#section-exit-calculator`}
            />
            <p className='text-description mb-6'>
                Swap your {asset?.symbol} back to {outputAsset?.symbol}, or
                unstake from {provider?.name} directly.
            </p>
            <div className='flex flex-col xl:flex-row gap-y-12 xl:bg-contrast-1 w-full rounded-lg justify-between'>
                <div className='flex flex-col justify-center w-full xl:w-fit border-r border-solid border-contrast-2/20 p-8 py-6 pl-8 pr-8 xl:pr-12 bg-contrast-1 rounded-lg'>
                    <div className='flex flex-col w-full'>
                        <Switch
                            className='flex justify-end mb-3'
                            textClassName='text-[10px] text-contrast-4 top-[1px]'
                            labelLeft='USD'
                            labelRight={asset?.symbol ?? NO_DATA_INDICATOR}
                            isLeft={usd}
                            onClick={() => {
                                setUsd(!usd)
                            }}
                        />
                        <AssetInput
                            asset={asset}
                            amount={lstAmount}
                            onChange={newValue => {
                                setLstAmount(newValue, false)
                            }}
                            usd={usd}
                            isLoading={isLoading}
                            placeholder={formatOutputNumber(
                                usd
                                    ? DEFAULT_STAKING_AMOUNT_USD
                                    : getStakingAmountTokens(
                                          DEFAULT_STAKING_AMOUNT_USD,
                                          true,
                                          tokenPrice
                                      ),
                                {
                                    allowEmpty: false,
                                    withAbbreviation: false,
                                    forcePrecision: false,
                                    showApproximation: true,
                                }
                            )}
                            darker
                        />
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-5 w-full bg-contrast-1 rounded-lg'>
                    <div
                        className={classNames(
                            'flex flex-col p-8 md:px-12 md:py-8',
                            {
                                'border border-solid border-blue rounded-lg bg-contrast-0':
                                    isSellBest,
                            }
                        )}
                    >
                        {isSellBest && (
                            <div className='flex justify-center'>
                                <div className='-mt-[56px] py-1 px-5 h-fit w-fit bg-blue text-[var(--c-lightest)] text-xs font-bold rounded-tr-lg rounded-tl-lg whitespace-nowrap'>
                                    Best Rate
                                </div>
                            </div>
                        )}
                        <p className='font-bold text-xl mb-3'>Swap</p>
                        <div
                            className={classNames('flex flex-row mb-6', {
                                ['opacity-0']: !isSellBest,
                            })}
                        >
                            {!isInvalidNumber(diffPercentage) && (
                                <div className='flex flex-col gap-y-2'>
                                    <p className='text-contrast-3 font-normal text-sm italic md:whitespace-nowrap'>
                                        {`Save ${formatOutputNumber(
                                            diffPercentage,
                                            {
                                                postfix: '%',
                                                spaceAfterNumber: true,
                                                allowEmpty: false,
                                                withAbbreviation: false,
                                                precision: 2,
                                                forcePrecision: false,
                                                showApproximation: false,
                                            }
                                        )} currently when swapping!`}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='grid grid-cols-2 gap-x-14 gap-y-5 mb-5'>
                            <ReceiveBlock
                                reward={sellRewardInEth}
                                unit={outputAsset?.symbol}
                                tokenPrice={outputAssetPrice}
                                precision={precision}
                            />
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm text-contrast-3 whitespace-nowrap'>
                                    Wait Time
                                </p>
                                <p className='font-bold text-xl text-contrast-4 whitespace-nowrap'>
                                    Instant
                                </p>
                            </div>
                        </div>
                        {externalSwapLink ? (
                            <Link
                                href={externalSwapLink}
                                blank={true}
                                onClick={() =>
                                    onUnstake('swap', externalSwapLink)
                                }
                            >
                                <ButtonWithBg
                                    className='h-8 px-10 !w-fit'
                                    variant={BUTTON_VARIANT_FG}
                                >
                                    Swap ↗
                                </ButtonWithBg>
                            </Link>
                        ) : (
                            <div className='flex justify-center items-center h-8 px-8 !w-fit bg-contrast-3 text-contrast-0 font-bold text-xs rounded-sm'>
                                Coming Soon
                            </div>
                        )}
                    </div>
                    <div
                        className={classNames(
                            'flex flex-col p-8 md:px-12 md:py-8',
                            {
                                'border border-solid border-blue rounded-lg bg-contrast-0':
                                    isUnstakeBest,
                            }
                        )}
                    >
                        {isUnstakeBest && (
                            <div className='flex justify-center'>
                                <div className='-mt-[56px] py-1 px-5 h-fit w-fit bg-blue text-[var(--c-lightest)] text-xs font-bold rounded-tr-lg rounded-tl-lg whitespace-nowrap'>
                                    Best Rate
                                </div>
                            </div>
                        )}
                        <p className='font-bold text-xl mb-3'>Unstake</p>
                        <div
                            className={classNames('flex flex-row mb-6', {
                                ['opacity-0']: !isUnstakeBest,
                            })}
                        >
                            {!isInvalidNumber(diffPercentage) && (
                                <div className='flex flex-col gap-y-2'>
                                    <p className='text-contrast-3 font-normal text-sm italic md:whitespace-nowrap'>
                                        {`Save ${formatOutputNumber(
                                            diffPercentage,
                                            {
                                                postfix: '%',
                                                spaceAfterNumber: true,
                                                allowEmpty: false,
                                                withAbbreviation: false,
                                                precision: 2,
                                                forcePrecision: false,
                                                showApproximation: false,
                                            }
                                        )} currently when unstaking!`}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='grid grid-cols-2 gap-x-14 gap-y-5 mb-5'>
                            <ReceiveBlock
                                reward={unstakeRewardInEth}
                                unit={outputAsset?.symbol}
                                tokenPrice={outputAssetPrice}
                                precision={precision}
                            />
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm text-contrast-3 whitespace-nowrap'>
                                    Wait Time
                                </p>
                                <p className='font-bold text-xl text-contrast-4 whitespace-nowrap'>
                                    {unstakingTimeInSeconds
                                        ? formatSeconds(
                                              unstakingTimeInSeconds,
                                              true
                                          )
                                        : 'N/A'}
                                </p>
                            </div>
                        </div>
                        {externalStakeLink ? (
                            <Link
                                href={externalStakeLink}
                                blank={true}
                                onClick={() =>
                                    onUnstake('unstake', externalStakeLink)
                                }
                            >
                                <ButtonWithBg
                                    className='h-8 px-10 !w-fit'
                                    variant={BUTTON_VARIANT_BLUE}
                                >
                                    Unstake ↗
                                </ButtonWithBg>
                            </Link>
                        ) : (
                            <div className='flex justify-center items-center h-8 px-8 !w-fit bg-contrast-3 text-contrast-0 font-bold text-xs rounded-sm'>
                                Coming Soon
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
