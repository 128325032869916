import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { getObjectFromJsonString } from 'utils/actions'
import { fetchAVSLogosForAVSProfile, fetchLSTLogosForAVSProfile } from 'data'

const AVSProfilePageContext = createContext({})

export const AVSProfilePageContextProvider = ({ asset, children }) => {
    const [logos, setLogos] = useState([])
    const [AVSData, setAVSData] = useState([])
    const restakedTokensMetric = useMemo(
        () => asset?.metrics?.find(m => m.metricKey === 'restaked_tokens'),
        [asset]
    )

    const distribution = useMemo(
        () => getObjectFromJsonString(restakedTokensMetric?.variation),
        [restakedTokensMetric]
    )

    const data = Object.keys(distribution || {}).map(key => ({
        name: key,
        value: distribution[key],
    }))

    useEffect(() => {
        if (distribution) {
            const getLogos = async () => {
                const result = await fetchLSTLogosForAVSProfile(
                    Object.keys(distribution)
                )
                setLogos(result?.assets)
            }

            getLogos()
        }
    }, [distribution])

    useEffect(() => {
        const fetchAVSLogos = async () => {
            const result = await fetchAVSLogosForAVSProfile()
            setAVSData(result?.assets)
        }
        fetchAVSLogos()
    }, [])

    return (
        <AVSProfilePageContext.Provider
            value={{
                data,
                logos,
                AVSData,
            }}
        >
            {children}
        </AVSProfilePageContext.Provider>
    )
}

export const useAVSProfilePageContext = () => {
    return useContext(AVSProfilePageContext)
}
