import { configureStore } from '@reduxjs/toolkit'

import { ui } from './ui'
import { storage } from './storage'
import { tableReducer } from './table'

export const store = configureStore({
    reducer: {
        ui,
        storage,
        table: tableReducer,
    },
})
