import classNames from 'classnames'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { CopyAddress } from 'components/ui'
import { StakeWithButton } from 'components/ui/StakeWithButton'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import {
    getMetricChangePercentage,
    getMetricDefaultValueByKey,
} from 'utils/actions'
import { formatOutputNumber } from 'utils/formatter'
import { compressAddress } from 'utils/stakingAssistant/utils'

export const ValidatorRow = ({
    validator,
    tokenPrice,
    className,
    profileItem,
    rewardOption,
    sortByKey,
}) => {
    const { queryParams } = useTableArchiveContext()

    const timeframe = queryParams?.timeframe?.key
    const address = validator?.address

    const formattedData = getFormattedData(validator, timeframe, tokenPrice)

    return (
        <div
            className={classNames(
                'flex items-center justify-between px-4 py-3 border-t border-solid border-shade-light/20 bg-contrast-0',
                className
            )}
        >
            <MetricBox
                title={
                    <div className='w-[90px]'>
                        <CopyAddress
                            address={address}
                            textClassName='!text-[10px] whitespace-nowrap'
                        />
                    </div>
                }
                value={compressAddress(address, 4, 4)}
                isSmall
            />
            <div className='flex w-2/12 min-w-[89px] justify-center'>
                <MetricBox
                    title='Reward Rate'
                    value={formattedData.rewardRate}
                    className='rounded-lg bg-gradient-sr-light/10 px-3 py-2'
                    isSmall
                />
            </div>
            <div className='flex w-1/12'>
                <MetricBox
                    title='Fee'
                    value={formattedData.fee}
                    isSmall
                    className={classNames('', {
                        ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                            sortByKey === 'commission',
                    })}
                />
            </div>
            {formattedData.performance && (
                <div className='flex w-2/12'>
                    <MetricBox
                        title='Performance'
                        value={formattedData.performance}
                        isSmall
                        className={classNames('', {
                            ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                                sortByKey === 'performance',
                        })}
                    />
                </div>
            )}
            <div className='flex w-2/12'>
                <MetricBox
                    title='Stakers'
                    value={formattedData.stakers}
                    change={formattedData.stakersChangePercent}
                    isSmall
                    className={classNames('', {
                        ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                            sortByKey === 'staking_wallets',
                    })}
                />
            </div>
            <div className='flex w-2/12'>
                <MetricBox
                    title='Staked Tokens'
                    value={formattedData.stakedTokens}
                    change={formattedData.stakedTokensChangePercent}
                    isSmall
                    className={classNames('', {
                        ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                            sortByKey === 'staked_tokens',
                    })}
                />
            </div>
            <MetricBox
                title='Self Staked'
                value={formattedData.selfStakedUsd}
                change={formattedData.selfStakedTokensChangePercent}
                isSmall
                className={'w-2/12'}
            />
            <div className='px-4'>
                <StakeWithButton
                    item={rewardOption}
                    profileItem={profileItem}
                    validator={validator}
                    isNewDesign
                />
            </div>
        </div>
    )
}

const getFormattedData = (item, timeframeKey, tokenPrice) => {
    const rewardRate = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'reward_rate'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )
    const fee = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'commission'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )
    const performance = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'performance'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )
    const stakers = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'staking_wallets'),
        {
            precision: 2,
            forcePrecision: false,
        }
    )
    const stakersChangePercent = formatOutputNumber(
        getMetricChangePercentage(
            item?.metrics,
            'staking_wallets',
            timeframeKey
        ),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const stakedTokens = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'staked_tokens'),
        {
            precision: 2,
            forcePrecision: false,
        }
    )
    const stakedTokensChangePercent = formatOutputNumber(
        getMetricChangePercentage(item?.metrics, 'staked_tokens', timeframeKey),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )
    const selfStakedTokens = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'self_staked_tokens'),
        {
            precision: 2,
            forcePrecision: false,
        }
    )
    const selfStakedTokensChangePercent = formatOutputNumber(
        getMetricChangePercentage(
            item?.metrics,
            'self_staked_tokens',
            timeframeKey
        ),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )
    const selfStakedUsd = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'self_staked_tokens') * tokenPrice,
        {
            precision: 2,
            forcePrecision: false,
            prefix: '$',
        }
    )
    return {
        rewardRate,
        fee,
        performance,
        stakers,
        stakersChangePercent,
        stakedTokens,
        stakedTokensChangePercent,
        selfStakedTokens,
        selfStakedTokensChangePercent,
        selfStakedUsd,
    }
}
