import { DEFAULT_REWARD_OPTIONS_SORT_BY } from 'components/rewardOptions/constants'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import {
    usePaginatedCustodialRewardOptionsForProfile,
    usePaginatedRewardOptionsForProfile,
} from 'data'
import { useEffect } from 'react'
import { DEFAULT_ORDER, PageSizes } from 'utils/constants'

// use to fetch pages with appending
export const useRewardOptionsData = (profileItem = null, category) => {
    const { paginationParams, queryParams } = useTableArchiveContext()

    const categoryKey = queryParams?.category?.key
    const sortByKey = queryParams?.sortBy?.key
    const orderKey = queryParams?.order?.key
    const timeframeKey = queryParams?.timeframe?.key
    const byChange = queryParams?.byChange
    const searchTerms = queryParams?.search
    const showVerifiedFirst = queryParams?.verifiedFirst

    const page = Number(paginationParams?.page ?? 0)
    const pageSizeNumber = Number(
        paginationParams?.pageSize?.key ?? PageSizes.Twenty
    )

    const { data, size, setSize, isValidating } =
        usePaginatedRewardOptionsForProfile(
            category,
            {
                search: searchTerms,
                category: categoryKey,
                sortBy: sortByKey ?? DEFAULT_REWARD_OPTIONS_SORT_BY.key,
                order: orderKey ?? DEFAULT_ORDER.key,
                timeframe: timeframeKey,
                byChange,
                profileSlug: profileItem?.slug,
                showVerifiedFirst,
            },
            pageSizeNumber + 1,
            true
        )

    useEffect(() => {
        // NB: SWR's size (page number) starts from 1
        if (page + 1 !== size) {
            setSize(page + 1)
        }
    }, [page, setSize, size])

    const lastBatch = data?.[(data?.length ?? 0) - 1]
    const hasMore = (lastBatch?.rewardOptions?.length ?? 0) > pageSizeNumber
    const noDataFound = !Boolean(
        data?.map(batch => batch?.rewardOptions ?? [])?.flat()?.length
    )

    // Since we fetched pageSizeNumber + 1 items (for hasMore check), remove the last item
    const batches = data?.map(
        batch => batch?.rewardOptions?.slice(0, pageSizeNumber) ?? []
    )

    return {
        data: batches,
        isLoading: isValidating,
        noDataFound,
        isEnd: !hasMore,
    }
}

export const useCustodialRewardOptionsData = (profileItem = null) => {
    const { paginationParams } = useTableArchiveContext()

    const page = Number(paginationParams?.page ?? 0)
    const pageSizeNumber = Number(
        paginationParams?.pageSize?.key ?? PageSizes.Twenty
    )

    const { data, size, setSize, isValidating } =
        usePaginatedCustodialRewardOptionsForProfile(
            profileItem?.slug,
            pageSizeNumber + 1,
            true
        )

    useEffect(() => {
        // NB: SWR's size (page number) starts from 1
        if (page + 1 !== size) {
            setSize(page + 1)
        }
    }, [page, setSize, size])

    const lastBatch = data?.[(data?.length ?? 0) - 1]
    const hasMore = (lastBatch?.providers?.length ?? 0) > pageSizeNumber
    const noDataFound = !Boolean(
        data?.map(batch => batch?.providers ?? [])?.flat()?.length
    )

    // Since we fetched pageSizeNumber + 1 items (for hasMore check), remove the last item
    const batches = data?.map(
        batch => batch?.providers?.slice(0, pageSizeNumber) ?? []
    )

    return {
        data: batches,
        isLoading: isValidating,
        noDataFound,
        isEnd: !hasMore,
    }
}
