import classNames from 'classnames'
import { GTMEvent, logEvent } from 'utils/GTM'
import { BUTTON_VARIANT_FG, ButtonWithBg, Link } from 'components/ui'
import { LIQUID_STAKING_KEY } from 'utils/constants'
import { useCombinedWalletsContext } from 'contexts/combinedWalletsContext'

export const LstStakeNowButton = ({
    className = '',
    asset = null,
    provider = null,
    label = 'Stake Now',
    variant = BUTTON_VARIANT_FG,
    inputAssetSlug = '',
}) => {
    const { primaryWallet } = useCombinedWalletsContext()
    return (
        <Link
            href={`/stake-app?input=${inputAssetSlug}&output=${asset?.slug}&type=${LIQUID_STAKING_KEY}`}
            blank={true}
            onClick={() => {
                const address = primaryWallet?.address
                logEvent(GTMEvent.StakeButtonClicked, {
                    // Force the address to be a string
                    // to prevent GTM converting it to a number
                    address: `_${address}`,
                    provider: provider?.slug,
                    output_token: asset?.symbol,
                })
            }}
        >
            <ButtonWithBg
                className={classNames('w-[100px]', className)}
                variant={variant}
            >
                {label}
            </ButtonWithBg>
        </Link>
    )
}
