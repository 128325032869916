import classNames from 'classnames'
import Image from 'next/image'
import { useClickWithTimeout, useTimeElapsed } from 'hooks'
import {
    getMetricValueByKey,
    getMetricChangePercentage,
    getMetricAbsoluteChange,
    calculateIfValid,
    getMetricPrecision,
    getObjectFromJsonString,
} from 'utils/actions'
import {
    CUSTOM_AUM_CHANGE_METRIC_KEY,
    DEFAULT_ASSET_TIMEFRAME,
    DEFAULT_PROVIDER_TIMEFRAME,
} from 'utils/constants'
import {
    formatOutputNumber,
    isInvalidNumber,
    ellipseString,
    NO_DATA_INDICATOR,
} from 'utils/formatter'
import {
    ComponentWithLink,
    Flag,
    SKELETON_BG_CONTRAST2,
    Skeleton,
    TooltipOnHover,
    withTooltip,
} from 'components/ui'
import { STAKING_LINKS, StakeWithButton } from 'components/ui/StakeWithButton'
import { AssetStack } from 'components/providers/archive/assetStack'
import { COLUMN_WIDTH, ALIGNMENT_LEFT, ALIGNMENT_RIGHT } from './constants'
import {
    getPercentageTextColorClassNames,
    getValueTextColorClassNames,
} from './utils'
import { LabelWithTooltip } from './labelWithTooltip'

import styles from './metricGroup.module.scss'

const VALUE_HEIGHT = 24
const LARGE_VALUE_HEIGHT = 32

export const MetricGroup = ({
    className = '',
    metricGroup = null,
    item = null,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    highlighted = false,
    isLoading = false,
    large = false,
    minimal = false,
    hasExpansion = false,
    isSortable = false,
}) => {
    const value = getMetricValueByKey(
        item,
        metricGroup?.key,
        metricGroup?.key === CUSTOM_AUM_CHANGE_METRIC_KEY
            ? timeframeKey
            : metricGroup?.timeframeKey
    )

    const percentage =
        metricGroup?.withPercentage && !isInvalidNumber(value)
            ? metricGroup?.postfix === '%'
                ? getMetricAbsoluteChange(
                      item?.metrics,
                      metricGroup?.key,
                      timeframeKey
                  )
                : getMetricChangePercentage(
                      item?.metrics,
                      metricGroup?.key,
                      timeframeKey
                  )
            : undefined

    const formattedValue = formatOutputNumber(value, {
        precision:
            metricGroup?.key === 'price' && metricGroup?.timeframeKey
                ? Math.max(2, getMetricPrecision(metricGroup?.key, value))
                : getMetricPrecision(metricGroup?.key, value),
        forcePrecision: false,
        allowEmpty: false,
        withAbbreviation: metricGroup?.key !== 'price',
        showApproximation: true,
        prefix: metricGroup?.prefix ?? '',
        postfix: metricGroup?.postfix ?? '',
        showPlus: Boolean(metricGroup?.showPlus),
    })

    const valueHeight = large ? LARGE_VALUE_HEIGHT : VALUE_HEIGHT

    const valueColorClassName = getValueTextColorClassNames(
        value,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )
    const percentageColorClassName = getPercentageTextColorClassNames(
        percentage,
        metricGroup?.withColor
    )

    const staticWidth = minimal ? '100px' : metricGroup?.width ?? COLUMN_WIDTH

    return (
        <div
            className={classNames(
                styles.metricGroup,
                {
                    [styles.highlighted]: highlighted,
                    [styles.large]: large,
                    '!w-4': minimal,
                },
                className
            )}
            style={{
                minWidth: staticWidth,
            }}
        >
            <LabelWithTooltip
                item={item}
                metricGroup={metricGroup}
                hasExpansion={hasExpansion && !isInvalidNumber(value)}
                timeframeKey={timeframeKey}
                isSortableInArchive={isSortable}
            />
            <div className={styles.valueWrap}>
                {isLoading ? (
                    <Skeleton
                        width={
                            large ? `calc(${staticWidth} - 16px)` : staticWidth
                        }
                        height={`${valueHeight}px`}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={'8px'}
                    />
                ) : (
                    <div className='flex flex-row items-center gap-x-2'>
                        <p
                            className={classNames(
                                'font-bold',
                                valueColorClassName,
                                large ? 'text-xl' : 'text-sm'
                            )}
                        >
                            {formattedValue}
                        </p>
                        {!isInvalidNumber(percentage) && (
                            <TooltipOnHover
                                text={`${timeframeKey} ${
                                    metricGroup?.postfix === '%'
                                        ? 'Absolute Change'
                                        : 'Change Percentage'
                                }`}
                                textClassName={
                                    '!text-[10px] !whitespace-normal !text-center !w-[80px] !px-2 !py-1'
                                }
                            >
                                <p
                                    className={classNames(
                                        ' text-[14px] font-bold',
                                        percentageColorClassName
                                    )}
                                >
                                    {formatOutputNumber(percentage, {
                                        precision: 2,
                                        forcePrecision: false,
                                        allowEmpty: false,
                                        withAbbreviation: true,
                                        showApproximation: true,
                                        postfix: '%',
                                        spaceAfterNumber: false,
                                        showPlus: true,
                                    })}
                                </p>
                            </TooltipOnHover>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export const SortingMetricGroup = ({
    className = '',
    metricGroup = null,
    item = null,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    isLoading = false,
    isSortable = false,
    hasExpansion = false,
}) => {
    const value = getMetricValueByKey(
        item,
        metricGroup?.key,
        metricGroup?.timeframeKey
    )
    const percentage =
        metricGroup?.withPercentage && !isInvalidNumber(value)
            ? metricGroup?.postfix === '%'
                ? getMetricAbsoluteChange(
                      item?.metrics,
                      metricGroup?.key,
                      timeframeKey
                  )
                : getMetricChangePercentage(
                      item?.metrics,
                      metricGroup?.key,
                      timeframeKey
                  )
            : undefined

    const formattedValue = formatOutputNumber(value, {
        precision: getMetricPrecision(metricGroup?.key, value),
        forcePrecision: false,
        allowEmpty: false,
        withAbbreviation: metricGroup?.key !== 'price',
        showApproximation: true,
        prefix: metricGroup?.prefix ?? '',
        postfix: metricGroup?.postfix ?? '',
        showPlus: Boolean(metricGroup?.showPlus),
    })

    const valueColorClassName = getValueTextColorClassNames(
        value,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )
    const percentageColorClassName = getPercentageTextColorClassNames(
        percentage,
        metricGroup?.withColor
    )

    return (
        <div className={classNames(styles.sortingMetricGroup, className)}>
            <LabelWithTooltip
                item={item}
                metricGroup={metricGroup}
                hasExpansion={hasExpansion && !isInvalidNumber(value)}
                timeframeKey={timeframeKey}
                isSortableInArchive={isSortable}
            />
            <div className={styles.valueWrap}>
                {isLoading ? (
                    <Skeleton
                        width={'80px'}
                        height={'20px'}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={'8px'}
                    />
                ) : (
                    <div className='flex flex-row items-center gap-x-2'>
                        <p
                            className={classNames(
                                'text-[14px] font-bold',
                                valueColorClassName
                            )}
                        >
                            {formattedValue}
                        </p>
                        {!isInvalidNumber(percentage) && (
                            <TooltipOnHover
                                text={`${timeframeKey} ${
                                    metricGroup?.postfix === '%'
                                        ? 'Absolute Change'
                                        : 'Change Percentage'
                                }`}
                                textClassName={
                                    '!text-[10px] !whitespace-normal !text-center !w-[80px] !px-2 !py-1'
                                }
                            >
                                <p
                                    className={classNames(
                                        ' text-[14px] font-bold',
                                        percentageColorClassName
                                    )}
                                >
                                    {formatOutputNumber(percentage, {
                                        precision: 2,
                                        forcePrecision: false,
                                        allowEmpty: false,
                                        withAbbreviation: true,
                                        showApproximation: true,
                                        postfix: '%',
                                        spaceAfterNumber: false,
                                        showPlus: true,
                                    })}
                                </p>
                            </TooltipOnHover>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export const PegDeviationMetricGroup = ({
    className = '',
    metricGroup = null,
    item = null,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    highlighted = false,
    isLoading = false,
    large = false,
    minimal = false,
}) => {
    const value = getMetricValueByKey(item, metricGroup?.key)

    const formattedValue = formatOutputNumber(value, {
        precision: getMetricPrecision(metricGroup?.key, value),
        forcePrecision: false,
        allowEmpty: false,
        withAbbreviation: true,
        showApproximation: true,
        prefix: metricGroup?.prefix ?? '',
        postfix: metricGroup?.postfix ?? '',
        showPlus: Boolean(metricGroup?.showPlus),
    })

    const updatedTimeStatus = useTimeElapsed(
        item?.metrics?.find(metric => metric?.metricKey === metricGroup?.key)
            ?.createdAt
    )

    const valueHeight = large ? LARGE_VALUE_HEIGHT : VALUE_HEIGHT

    const textColorClassName = getValueTextColorClassNames(
        value,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )

    const staticWidth = minimal ? '100px' : metricGroup?.width ?? COLUMN_WIDTH

    const exchangeRatio = getMetricValueByKey(item, 'exchange_ratio')
    const marketRatio = getMetricValueByKey(item, 'market_ratio')

    return (
        <div
            className={classNames(
                styles.metricGroup,
                {
                    [styles.highlighted]: highlighted,
                    [styles.large]: large,
                    '!w-4': minimal,
                },
                className
            )}
            style={{
                minWidth: staticWidth,
            }}
        >
            <TooltipOnHover
                textClassName='!w-[160px]'
                text={
                    <div className='flex flex-col'>
                        <div className='flex flex-row justify-between items-center'>
                            <p className='font-normal'>Exchange ratio:</p>
                            <p className='font-bold text-contrast-4'>
                                {formatOutputNumber(exchangeRatio, {
                                    precision: getMetricPrecision(
                                        'exchange_ratio',
                                        exchangeRatio
                                    ),
                                    allowEmpty: false,
                                })}
                            </p>
                        </div>
                        <div className='flex flex-row justify-between items-center'>
                            <p className='font-normal'>Market ratio:</p>
                            <p className='font-bold text-contrast-4'>
                                {formatOutputNumber(marketRatio, {
                                    precision: getMetricPrecision(
                                        'market_ratio',
                                        marketRatio
                                    ),
                                    allowEmpty: false,
                                })}
                            </p>
                        </div>
                    </div>
                }
                updatedTimeStatus={updatedTimeStatus}
            >
                <div className={styles.labelWrap}>
                    <span
                        className={classNames(styles.label, {
                            [styles.long]:
                                String(metricGroup?.label).length > 20,
                        })}
                    >
                        {metricGroup?.label}
                    </span>
                </div>
            </TooltipOnHover>
            <div className={styles.valueWrap}>
                {isLoading ? (
                    <Skeleton
                        width={
                            large ? `calc(${staticWidth} - 16px)` : staticWidth
                        }
                        height={`${valueHeight}px`}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={'8px'}
                    />
                ) : (
                    <div
                        className={classNames(
                            styles.value,
                            textColorClassName,
                            large ? 'text-xl' : 'text-sm'
                        )}
                    >
                        {formattedValue}
                    </div>
                )}
            </div>
        </div>
    )
}

export const SupportedItemsMetricGroup = ({
    items = [],
    label = '',
    tooltipTextObj = {
        title: '',
        text: '',
    },
    isLoading = false,
}) => {
    const ItemsLabelWithTooltip = withTooltip(() => {
        return <div className={styles.label}>{label}</div>
    })
    return (
        <div className={styles.supportedItemsMetricGroup}>
            <ItemsLabelWithTooltip
                tooltipObject={tooltipTextObj}
                tooltipClassName={styles.tooltip}
            />
            {isLoading ? (
                <Skeleton
                    width={'100px'}
                    height={`${VALUE_HEIGHT}px`}
                    variant={SKELETON_BG_CONTRAST2}
                    borderRadius={'4px'}
                />
            ) : items?.length ? (
                <AssetStack
                    items={items ?? []}
                    iconSize={VALUE_HEIGHT}
                    overlap={6}
                />
            ) : (
                <p>{NO_DATA_INDICATOR}</p>
            )}
        </div>
    )
}

const copyAddressMessage = 'Copy Address'

export const ValidatorMetricGroup = ({
    validator = null,
    withAddress = true,
    alignment = ALIGNMENT_LEFT,
    profileItem = null,
    item = null,
    showStakeButton = false,
}) => {
    const { copyingMessage, handleClickCopy } = useClickWithTimeout(
        validator?.address ?? '',
        copyAddressMessage
    )

    if (withAddress && !validator?.address) {
        return null
    }

    // If the profile item is provider and it is verified, then we show stake button to all RO's
    const isVerifiedProvider = profileItem?.isVerified

    // For aptos we need to check if item is delegation node to show stake button
    const isDelegationNode =
        profileItem?.slug !== 'aptos' ||
        validator?.metrics?.find(m => m.metricKey === 'is_delegation_node')
            ?.defaultValue === 1

    const asset = item?.inputAssets?.[0]
    const stakeButtonValidator = isVerifiedProvider
        ? item?.validators?.[0]
        : validator

    // Check if provider is in the list of providers that have stake button
    const hasStakeButton =
        Object.keys(STAKING_LINKS).includes(
            isVerifiedProvider ? asset?.slug : profileItem?.slug
        ) && isDelegationNode

    // Check if RO provider is verified or, for some providers, show stake button for all RO's
    const isVerified =
        item?.providers?.[0]?.isVerified ||
        ['dydx', 'cudos', 'zetachain', 'vara-network'].includes(
            profileItem?.slug
        )

    return (
        <div className={styles.validatorMetricGroup} onClick={handleClickCopy}>
            {(isVerified || showStakeButton) && hasStakeButton ? (
                <StakeWithButton
                    item={item}
                    profileItem={isVerifiedProvider ? asset : profileItem}
                    validator={stakeButtonValidator}
                />
            ) : (
                <>
                    <div
                        className={classNames(styles.labelWrap, {
                            [styles.left]: alignment === ALIGNMENT_LEFT,
                            [styles.right]: alignment === ALIGNMENT_RIGHT,
                        })}
                    >
                        {copyingMessage === copyAddressMessage && (
                            <span
                                className={classNames(
                                    'icon icon-copy',
                                    styles.icon
                                )}
                            />
                        )}
                        <p className={styles.label}>{copyingMessage}</p>
                    </div>
                    {withAddress && (
                        <div className={styles.address}>
                            {ellipseString(validator?.address)}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

// Used only for Hedera, to show node id and link to hashscan
export const ValidatorMetricGroupWithNodeId = ({ validator = null }) => {
    const nodeId = getMetricValueByKey(validator, 'node_id')
    return (
        <div className={styles.validatorMetricGroup}>
            <ComponentWithLink
                link={
                    nodeId ? `https://hashscan.io/mainnet/node/${nodeId}` : null
                }
                external
                blank
            >
                <div className={styles.label} title='Node ID'>
                    Node ID
                </div>
                <div className={styles.address}>
                    {nodeId ?? NO_DATA_INDICATOR}
                </div>
            </ComponentWithLink>
        </div>
    )
}

export const StakedTokensAndValueMetricGroup = ({
    metricGroup = null,
    item = null,
    timeframeKey = DEFAULT_PROVIDER_TIMEFRAME.key,
    highlighted = false,
    isLoading = false,
}) => {
    const stakedTokens = getMetricValueByKey(item, 'staked_tokens')
    const price = getMetricValueByKey(item, 'price')
    const aum = calculateIfValid(
        ({ price, stakedTokens }) => price * stakedTokens,
        {
            price,
            stakedTokens,
        }
    )
    const percentage = !isInvalidNumber(stakedTokens)
        ? getMetricChangePercentage(
              item?.metrics,
              metricGroup?.key,
              timeframeKey
          )
        : undefined

    const valueColorClassName = getValueTextColorClassNames(
        stakedTokens,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )
    const percentageColorClassName = getPercentageTextColorClassNames(
        percentage,
        metricGroup?.withColor
    )

    const aumString = `(${formatOutputNumber(aum, {
        precision: getMetricPrecision('aum', aum),
        forcePrecision: false,
        allowEmpty: false,
        withAbbreviation: true,
        showApproximation: true,
        prefix: '$',
        showPlus: false,
    })})`

    return (
        <div
            className={classNames(styles.metricGroup, {
                [styles.highlighted]: highlighted,
            })}
            style={{ minWidth: metricGroup?.width ?? COLUMN_WIDTH }}
        >
            <LabelWithTooltip
                item={item}
                metricGroup={metricGroup}
                hasExpansion={false}
                timeframeKey={timeframeKey}
            />
            <div className={styles.valueWrap}>
                {isLoading ? (
                    <Skeleton
                        width={metricGroup?.width ?? COLUMN_WIDTH}
                        height={`${VALUE_HEIGHT}px`}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={'4px'}
                    />
                ) : (
                    <div className='flex flex-row items-center gap-x-2'>
                        <p
                            className={classNames(
                                'text-[14px] font-bold',
                                valueColorClassName
                            )}
                        >
                            {`${formatOutputNumber(stakedTokens, {
                                precision: getMetricPrecision(
                                    metricGroup?.key,
                                    stakedTokens
                                ),
                                forcePrecision: false,
                                allowEmpty: false,
                                withAbbreviation: true,
                                showApproximation: true,
                                prefix: metricGroup?.prefix ?? '',
                                postfix: metricGroup?.postfix ?? '',
                                showPlus: Boolean(metricGroup?.showPlus),
                            })} ${metricGroup?.withoutAuM ? '' : aumString}`}
                        </p>
                        {!isInvalidNumber(percentage) && (
                            <TooltipOnHover
                                text={`${timeframeKey} ${
                                    metricGroup?.postfix === '%'
                                        ? 'Absolute Change'
                                        : 'Change Percentage'
                                }`}
                                textClassName={
                                    '!text-[10px] !whitespace-normal !text-center !w-[80px] !px-2 !py-1'
                                }
                            >
                                <p
                                    className={classNames(
                                        ' text-[14px] font-bold',
                                        percentageColorClassName
                                    )}
                                >
                                    {formatOutputNumber(percentage, {
                                        precision: 2,
                                        forcePrecision: false,
                                        allowEmpty: false,
                                        withAbbreviation: true,
                                        showApproximation: true,
                                        postfix: '%',
                                        spaceAfterNumber: false,
                                        showPlus: true,
                                    })}
                                </p>
                            </TooltipOnHover>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export const FlagMetricGroup = ({ country = '', isLoading = false }) => {
    return (
        <div className={classNames(styles.metricGroup, styles.flagMetricGroup)}>
            <div className={styles.labelWrap}>
                <span className={styles.label}>Country</span>
            </div>
            <div className={styles.flagWrap}>
                {isLoading ? (
                    <Skeleton
                        width='30px'
                        height={`${VALUE_HEIGHT}px`}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={'4px'}
                    />
                ) : country ? (
                    <Flag country={country} width={30} height={VALUE_HEIGHT} />
                ) : (
                    <Image
                        className={styles.earth}
                        src={`/static/flags/earth.svg`}
                        alt={`Global`}
                        height={VALUE_HEIGHT}
                        width={30}
                    />
                    // NO_DATA_INDICATOR
                )}
            </div>
        </div>
    )
}

export const SRRatingMetricGroup = ({
    item = null,
    highlighted = false,
    metricGroup = null,
    isLoading = false,
}) => {
    const vsp = item?.metrics?.find(m => m?.metricKey === 'vsp')
    const value = vsp?.defaultValue

    const verificationScores = getObjectFromJsonString(
        vsp?.variation
    )?.verificationScore

    const posVerified = verificationScores?.pos?.verified
    const lsVerified = verificationScores?.ls?.verified

    let rating = NO_DATA_INDICATOR
    if (!isInvalidNumber(value)) {
        if (value >= 70) {
            if (value >= 94) {
                rating = 'AAA'
            } else if (value >= 83) {
                rating = 'AA'
            } else {
                rating = 'A'
            }
        } else if (value >= 50) {
            rating = 'B'
        } else if (value >= 25) {
            rating = 'C'
        } else {
            rating = 'D'
        }
    }

    return (
        <div
            className={classNames(styles.metricGroup, {
                [styles.highlighted]: highlighted,
            })}
            style={{ minWidth: COLUMN_WIDTH }}
        >
            <LabelWithTooltip
                item={item}
                metricGroup={metricGroup}
                hasExpansion={false}
            />
            <div className={styles.valueWrap}>
                {isLoading ? (
                    <Skeleton
                        width={metricGroup?.width ?? COLUMN_WIDTH}
                        height={VALUE_HEIGHT}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={'4px'}
                    />
                ) : (
                    <>
                        <div className={styles.value}>{rating}</div>
                        <div className={styles.percentage}>
                            {[
                                ...(posVerified ? ['PoS'] : []),
                                ...(lsVerified ? ['Liq. Staking'] : []),
                            ].join(', ')}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
