import axios from 'axios'

const ETHERSCAN_KEY = process.env.ETHERSCAN_KEY
const etherscanQueue = []

// Actual fetch function for etherscan
async function actualFetchEtherscan(params) {
    const response = await axios.get('https://api.etherscan.io/api', {
        params: {
            ...params,
            apikey: ETHERSCAN_KEY,
        },
    })

    if (!Array.isArray(response.data.result)) {
        throw new Error(JSON.stringify(response.data))
    }
    return response
}

/**
 * Enqueue fetch request and return a promise that will resolve/reject upon request completion
 * We need to queue etherscan requests, because they have a limit of 5 requests per second
 */
export function fetchEtherscan(params) {
    const resultPromise = new Promise((resolve, reject) => {
        etherscanQueue.push({ params, resolve, reject })
        console.log('Etherscan Queue size', etherscanQueue.length)
    })

    // Ensure runner is processing or will process upcoming items
    ensureRunnerIsRunning()

    return resultPromise
}

let isRunnerActive = false

// Runner that processes items from the queue at a controlled rate
function runner() {
    if (etherscanQueue.length === 0) {
        // Mark runner as inactive if queue is empty
        isRunnerActive = false
        return
    }

    const { resolve, reject, params } = etherscanQueue.shift()
    console.log('Etherscan Queue size', etherscanQueue.length)

    // Utilizing the actual fetch function
    actualFetchEtherscan(params)
        .then(resolve)
        .catch(reject)
        .finally(() => {
            setTimeout(runner, 300)
        })
}

// Ensure the runner is active, if not, start it
function ensureRunnerIsRunning() {
    if (!isRunnerActive) {
        isRunnerActive = true
        runner()
    }
}
