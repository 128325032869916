import { createContext, useContext, useEffect, useState } from 'react'
import { COSMOS_STAKING_TYPE_OPTIONS } from 'components/staking/cosmos/constants'
import { useStakingModalContext } from './stakingModalContext'
import { useCosmosAssetWalletBalance } from 'components/stakingAssistant/hooks'
import { useCosmosTransaction } from 'components/staking/cosmos/hooks'
import { WalletState } from 'state/stakingPortfolio/walletState'
import { useCombinedWalletsContext } from './combinedWalletsContext'
import { useRouter } from 'next/router'
import { POS_PROVIDER_KEY } from 'utils/constants'

export const CosmosStakingContext = createContext({})

export const CosmosStakingContextProvider = ({ children }) => {
    const router = useRouter()

    const { inputAsset, rewardOption } = useStakingModalContext()

    const [stakingType, setStakingType] = useState(
        COSMOS_STAKING_TYPE_OPTIONS.find(
            option => option.key === rewardOption?.type?.key
        )
    )
    const [selectedRewardOptions, setSelectedRewardOptions] = useState(
        rewardOption ? [{ ...rewardOption, amount: 0 }] : []
    )

    // Close the wallet modal as soon as the user is logged in
    const { primaryWallet, isLoggedIn } = useCombinedWalletsContext()
    useEffect(() => {
        if (isLoggedIn) {
            WalletState.walletModalIsOpen.value = false
        }
    }, [isLoggedIn])

    useEffect(() => {
        setStakingType(
            COSMOS_STAKING_TYPE_OPTIONS.find(
                option => option.key === rewardOption?.type?.key
            )
        )
        setSelectedRewardOptions(
            rewardOption ? [{ ...rewardOption, amount: 0 }] : []
        )
    }, [rewardOption])

    // If the provider from the URL is no longer selected, update the URL to show the first selected provider.
    useEffect(() => {
        if (
            !selectedRewardOptions?.find(
                option => option.id === rewardOption.id
            )
        ) {
            const provider = selectedRewardOptions?.[0]?.providers?.[0]
            const setDefaultProviderForPos =
                provider && stakingType?.key === POS_PROVIDER_KEY

            if (setDefaultProviderForPos) {
                router.push(
                    `/stake-app?input=${inputAsset?.slug}&provider=${provider?.slug}&type=${stakingType?.key}`
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRewardOptions])

    const { data: inputData, isLoading: isLoadingInputAssetBalance } =
        useCosmosAssetWalletBalance(primaryWallet)

    const availableBalance = inputData?.available ?? 0

    const transaction = useCosmosTransaction(selectedRewardOptions)

    const value = {
        stakingType,
        setStakingType,
        selectedRewardOptions,
        setSelectedRewardOptions,
        inputAssetBalance: availableBalance,
        isLoadingInputAssetBalance,
        transaction,
    }

    return (
        <CosmosStakingContext.Provider value={value}>
            {children}
        </CosmosStakingContext.Provider>
    )
}

export const useCosmosStakingContext = () => {
    return useContext(CosmosStakingContext)
}
