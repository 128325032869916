import { useState } from 'react'
import {
    BarChart as BarChartRecharts,
    Bar,
    XAxis,
    LabelList,
    ResponsiveContainer,
} from 'recharts'
import {
    NO_DATA_INDICATOR,
    formatOutputNumber,
    getPrecisionBySignificantFigures,
} from 'utils/formatter'

import styles from './barChart.module.scss'

const toChartData = metrics => {
    const metricsNumber = metrics?.length ?? 0
    return metrics
        ?.sort((a, b) => b?.daysDiff - a?.daysDiff)
        ?.map((m, idx) => {
            const metric = m?.metric
            const value = metric?.defaultValue ?? 0
            const precision = Math.min(
                getPrecisionBySignificantFigures(value, 3),
                3
            )
            return {
                daysDiff: metricsNumber - idx - 1,
                label: formatOutputNumber(value, {
                    allowEmpty: false,
                    withAbbreviation: true,
                    precision,
                    forcePrecision: false,
                    showApproximation: false,
                    prefix: '$',
                    spaceBeforeNumber: false,
                    showPlus: true,
                }),
                value: Math.abs(value),
                fill: value > 0 ? 'var(--c-green)' : 'var(--c-red)',
            }
        })
}

const renderCustomizedLabel = props => {
    const { x, y, width, value } = props
    return (
        props?.hoveredBarIndex === value && (
            <g>
                <text
                    x={x + width / 2}
                    y={y - 10}
                    fill='var(--c-contrast-3)'
                    textAnchor='middle'
                    dominantBaseline='middle'
                    fontSize={10}
                >
                    {value === 0 ? `last 24h` : `${value}d ago`}
                </text>
            </g>
        )
    )
}

export const BarChart = ({
    maxBarSize = 10,
    withLabels = false,
    metrics = [],
}) => {
    const [hoveredBarIndex, setHoveredBarIndex] = useState(null)
    const metricsNumber = metrics?.length ?? 0
    const data = toChartData(metrics)
    return (
        <div className={styles.barChart}>
            {Boolean(data?.length) && data?.some(el => el?.value !== 0) ? (
                <ResponsiveContainer>
                    <BarChartRecharts
                        data={toChartData(metrics)}
                        margin={{
                            top: withLabels ? 15 : 5,
                            right: 0,
                            bottom: 5,
                            left: 0,
                        }}
                        onMouseMove={state => {
                            setHoveredBarIndex(
                                metricsNumber - 1 - state?.activeTooltipIndex
                            )
                        }}
                        onMouseLeave={() => {
                            setHoveredBarIndex(null)
                        }}
                    >
                        <Bar
                            dataKey='value'
                            isAnimationActive={false}
                            maxBarSize={maxBarSize}
                            tick={false}
                        >
                            {withLabels && (
                                <LabelList
                                    dataKey='daysDiff'
                                    content={props =>
                                        renderCustomizedLabel({
                                            ...props,
                                            hoveredBarIndex,
                                        })
                                    }
                                />
                            )}
                        </Bar>
                        {withLabels && (
                            <XAxis
                                dataKey='label'
                                axisLine={false}
                                tick={{ fill: 'var(--c-contrast-3)' }}
                                tickLine={false}
                                style={{ fontSize: 10, fontWeight: 'bold' }}
                                interval={0}
                            />
                        )}
                    </BarChartRecharts>
                </ResponsiveContainer>
            ) : (
                <div className={styles.centered}>{NO_DATA_INDICATOR}</div>
            )}
        </div>
    )
}
