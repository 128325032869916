import { useState } from 'react'
import classNames from 'classnames'
import { BUTTON_VARIANT_FG, ButtonWithBg } from 'components/ui'

import styles from './collapse.module.scss'

export const Collapse = ({ label = 'Expand', children }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div
            className={classNames(styles.collapse, {
                [styles.expanded]: isExpanded,
            })}
        >
            <div className={styles.childrenWrap}>{children}</div>
            <div className={styles.buttonWrap}>
                <ButtonWithBg
                    variant={BUTTON_VARIANT_FG}
                    onClick={() => {
                        setIsExpanded(!isExpanded)
                    }}
                >
                    <span className={styles.labelWrap}>{label}</span>
                    <span
                        className={classNames(styles.toggle, {
                            [styles.expanded]: isExpanded,
                        })}
                    >
                        <span className={styles.bar1} />
                        <span className={styles.bar2} />
                    </span>
                </ButtonWithBg>
            </div>
        </div>
    )
}
