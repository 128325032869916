import { createSteadyState } from 'steadystate'

const initialState = {
    firstLoad: true,
    touch: false,
    mouseX: null,
    mouseY: null,
    mouseState: 'default',
    scrollDir: 1,
    scrolled: false,
    hamburgerActive: false,
    overlayActive: false,
    overlayScreen: 'menu',
    hasAd: false,
    closeMenu: false,
    menuActive: false,
    isPageTop: true,
    isPageBottom: false,
}

const uiState = createSteadyState({
    key: 'ui',
    initialState,
    actions: {
        openMenu: state => {
            state.closeMenu = false
            state.hamburgerActive = true
            state.menuActive = true
        },
        toggleMenu: state => {
            if (
                ['login', 'signup'].includes(state.overlayScreen) &&
                state.overlayActive
            ) {
                state.overlayScreen = 'menu'
            } else {
                state.hamburgerActive = !state.hamburgerActive
                state.overlayActive = !state.overlayActive
                state.overlayScreen = !state.overlayScreen
                    ? 'menu'
                    : state.overlayScreen
            }
        },
        closeAll: state => {
            state.overlayActive = false
            state.hamburgerActive = false
            state.closeMenu = true
        },
        unmountMenu: state => {
            state.menuActive = false
            state.closeMenu = false
            state.hamburgerActive = false
        },
        setCursor: (state, { payload }) => {
            state.mouseX = payload.x
            state.mouseY = payload.y
        },
        setIsPageTop: (state, { payload }) => {
            state.isPageTop = payload
        },
        setIsPageBottom: (state, { payload }) => {
            state.isPageBottom = payload
        },
    },
})

export const { ui, useUiState, setUiState } = uiState

export function useFirstLoad() {
    const firstLoad = useUiState('firstLoad')

    return firstLoad
}

export function useCursor() {
    const x = useUiState('mouseX')
    const y = useUiState('mouseY')

    return {
        x,
        y,
    }
}
