import classNames from 'classnames'
import { baseUrl } from 'utils/actions'
import { CopyLinkButton } from 'components/ui'

export const HeaderWithLink = ({ className = '', title = '', href = '' }) => {
    return (
        <div className='flex flex-row items-center gap-x-3'>
            <h2 className={classNames(className)}>{title}</h2>
            <CopyLinkButton
                className='!p-0 mb-3 top-[2px]'
                href={`${baseUrl}${href}`}
                copyLinkText=''
            />
        </div>
    )
}
