import { events } from '@stakingrewards/staking-sdk'

export function subscribeToSdkTransactionEvents({
    handlePending = () => {},
    handleWaitingForSignature = () => {},
    handleBroadcasting = () => {},
    handleSuccess = () => {},
    handleFailed = () => {},
}) {
    const { eventEmitter, TransactionEvents } = events

    eventEmitter.on(TransactionEvents.PENDING, handlePending)
    eventEmitter.on(
        TransactionEvents.WAITING_FOR_SIGNATURE,
        handleWaitingForSignature
    )
    eventEmitter.on(TransactionEvents.BROADCASTING, handleBroadcasting)
    eventEmitter.on(TransactionEvents.SUCCESS, handleSuccess)
    eventEmitter.on(TransactionEvents.FAILED, handleFailed)

    return () => {
        eventEmitter.off(TransactionEvents.PENDING, handlePending)
        eventEmitter.off(
            TransactionEvents.WAITING_FOR_SIGNATURE,
            handleWaitingForSignature
        )
        eventEmitter.off(TransactionEvents.BROADCASTING, handleBroadcasting)
        eventEmitter.off(TransactionEvents.SUCCESS, handleSuccess)
        eventEmitter.off(TransactionEvents.FAILED, handleFailed)
    }
}
