import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { logEvent } from 'utils/GTM'

import styles from './containerWithTabs.module.scss'
import Image from 'next/image'

const getRandomMessage = (current, messages = []) => {
    let randomNumber = Math.floor(Math.random() * messages.length)
    while (randomNumber === current && messages.length > 1) {
        randomNumber = Math.floor(Math.random() * messages.length)
    }
    return messages[randomNumber]
}

export const TabLoading = ({ small = false, messages = [] }) => {
    const [message, setMessage] = useState(getRandomMessage(null, messages))
    useEffect(() => {
        const interval = setInterval(() => {
            setMessage(currentMessage =>
                getRandomMessage(currentMessage, messages)
            )
        }, 2000)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div
            className={classNames(styles.page, {
                [styles.isSmall]: small,
            })}
        >
            <div className={styles.loading}>
                <div className={styles.loading1}></div>
                <div className={styles.loading2}></div>
                <div className={styles.loading3}></div>
                <div className={styles.loading4}></div>
            </div>
            <div className={styles.message}>{message}</div>
        </div>
    )
}

/**
 * A container component that displays a set of tabs and their corresponding content.
 *
 * @param {Object} props - The properties that define the component.
 * @param {Array<{ key: string, name: string, event?: Object, content: ReactNode}>} props.tabs - The tabs to display.
 *   @param {string} props.tabs[].key - A unique identifier for the tab.
 *   @param {string} props.tabs[].name - The name of the tab to display.
 *   @param {Object} [props.tabs[].event] - An optional GTM event to log when the tab is clicked.
 *   @param {ReactNode} props.tabs[].content - The content to display for the tab.
 * @param {Function} [props.onChange] - An optional function to call when the selected tab changes.
 * @param {number} [props.defaultSelectionIndex] - The index of the tab to select by default.
 * @param {boolean} [props.isLoading] - Whether to show loading bar.
 * @param {ReactNode} props.children - The common content across all tabs to display on the top of container.
 * @returns {ReactElement} The rendered component.
 */
export function ContainerWithTabs({
    className = '',
    tabs,
    onChange = () => {},
    defaultSelectionIndex,
    isLoading,
    loadingMessages = [],
    large = false,
    darker = false,
    minWidth = '640px',
    height = '564px',
    children,
    isTextPerformanceVisible,
}) {
    const defaultTab = defaultSelectionIndex || 0
    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTab)
    const selectedTab = tabs[selectedTabIndex]

    return (
        <div className={classNames('overflow-auto w-full', className)}>
            <div
                className={classNames(
                    'border border-solid border-contrast-2/40 overflow-x-hidden overflow-y-hidden',
                    {
                        'rounded-t-lg': isTextPerformanceVisible,
                        'rounded-lg': !isTextPerformanceVisible,
                    }
                )}
                style={{ minWidth, height }}
            >
                <div className='flex w-full h-16 bg-contrast-1/50 rounded-t-lg overflow-hidden md:overflow-visible border-b-contrast-2/20 border-solid border-b'>
                    {tabs.map((choice, i) => (
                        <div
                            key={choice.key}
                            className={classNames(
                                'flex items-center justify-center h-full px-8 font-bold text-xs text-contrast-3 cursor-pointer hover:bg-contrast-1 transition-all ease-in-out select-none border-r border-solid border-contrast-2/20',
                                {
                                    'bg-contrast-1 text-primary':
                                        selectedTab?.key === choice.key,
                                }
                            )}
                            onClick={() => {
                                if (choice.event) {
                                    logEvent(choice.event)
                                }
                                setSelectedTabIndex(i)
                                onChange(choice)
                            }}
                        >
                            {choice.name}
                        </div>
                    ))}
                </div>
                <div
                    className={classNames(
                        'w-full bg-contrast-1 p-3 pb-[34px]',
                        {
                            'bg-opacity-50': !darker,
                            'rounded-t-lg': isTextPerformanceVisible,
                            'rounded-lg': !isTextPerformanceVisible,
                        }
                    )}
                >
                    <div className='overflow-hidden'>{children}</div>
                    {isLoading ? (
                        <TabLoading messages={loadingMessages} small={!large} />
                    ) : (
                        selectedTab?.content
                    )}
                </div>
            </div>
            <div className='flex flex-wrap items-center justify-end absolute right-[16px] bottom-[16px] gap-[16px] text-[12px] text-[#7A7A7A]'>
                <p>Data Trusted by</p>
                <Image
                    src={'/static/svg/grayscale-logo.svg'}
                    alt='Trusted by Industry Leaders'
                    priority
                    width={67}
                    height={11}
                />
                <Image
                    src={'/static/svg/kraken-logo.svg'}
                    alt='Trusted by Industry Leaders'
                    priority
                    width={58}
                    height={11}
                />
                <Image
                    src={'/static/svg/coinbase.svg'}
                    alt='Trusted by Industry Leaders'
                    priority
                    width={62}
                    height={11}
                />
                <p>& more</p>
            </div>
        </div>
    )
}
