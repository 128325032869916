/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useWatchStorage, setUiState } from 'state'

export function Initialize() {
    const { setTouch, toggleMenu, closeAll } = setUiState()

    useWatchStorage()

    useEffect(() => {
        setVh()

        const isTouchScreen = 'ontouchstart' in document.documentElement

        if (isTouchScreen) {
            setTouch(true)
        } else {
            setTouch(false)
        }

        window.addEventListener('resize', setVh)
        window.addEventListener('keydown', handleKeyDown)

        if ('onorientationchange' in window) {
            window.addEventListener('orientationchange', setVh, false)
        }

        return () => {
            window.removeEventListener('resize', setVh)
            window.removeEventListener('keydown', handleKeyDown)

            if ('onorientationchange' in window) {
                window.removeEventListener('orientationchange', setVh)
            }
        }
    }, [])

    const setVh = () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--v-height', `${vh}px`)
    }

    const handleMenuToggle = e => {
        if (e.key === '/') {
            toggleMenu()
        } else if (e.key === 'Escape') {
            closeAll()
        }
    }

    const handleKeyDown = e => {
        switch (e.target.tagName.toLowerCase()) {
            case 'input':
            case 'textarea':
                if (e.key === 'Escape') {
                    document.activeElement.blur()
                    // "Escape" always closes the menu
                    closeAll()
                }
                break
            default:
                handleMenuToggle(e)
                break
        }
    }

    return null
}
