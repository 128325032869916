import classNames from 'classnames'
import { Search } from 'components/forms'
import { LogoImage } from 'components/ui'
import { fetchAssetsBySearchQuery } from 'data'
import { useClickOutside } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { targetAsset } from 'state/assetProfile'
import { getMetricValueByKey } from 'utils/actions'

export const AssetSelectDropdown = ({
    baseAsset,
    defaultAsset,
    assetShortcuts,
    onChange,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [searchTerms, setSearchTerms] = useState('')
    const [assetResults, setAssetResults] = useState([])

    useEffect(() => {
        fetchAssetsBySearchQuery(searchTerms, true)
            .then(data => {
                const baseAssetPrice = getMetricValueByKey(baseAsset, 'price')
                const fetchedAssets =
                    data?.assets
                        ?.filter(
                            asset =>
                                !['ethereum-2-0', 'bitcoin'].includes(
                                    asset.slug
                                )
                        )
                        .map(asset => {
                            const assetPrice = getMetricValueByKey(
                                baseAsset,
                                'price'
                            )
                            return {
                                ...asset,
                                rate: (1 / assetPrice) * baseAssetPrice,
                            }
                        }) ?? []
                const filteredShortcuts = assetShortcuts.filter(
                    shortcut =>
                        shortcut.name
                            .toLowerCase()
                            .includes(searchTerms?.toLowerCase()) ||
                        shortcut.symbol
                            .toLowerCase()
                            .includes(searchTerms?.toLowerCase()) ||
                        shortcut.slug
                            .toLowerCase()
                            .includes(searchTerms?.toLowerCase())
                )
                const assets = [...filteredShortcuts, ...fetchedAssets]
                setAssetResults(assets.slice(0, 4))
            })
            .catch(() => {
                setAssetResults([])
            })
    }, [searchTerms, assetShortcuts]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        targetAsset.value = defaultAsset
    }, [defaultAsset])

    const handleChange = asset => {
        setIsOpen(false)
        onChange(asset)
        targetAsset.value = asset
    }

    const ref = useRef()
    useClickOutside(ref, () => {
        setIsOpen(false)
    })

    return (
        <>
            {isOpen && <div className='min-w-[180px] max-w-[180px]'></div>}
            <div
                className={classNames(
                    'min-w-[180px] max-w-[180px] border-r border-solid border-contrast-2/40 transition-all duration-200 select-none bg-contrast-1 rounded-l-lg',
                    {
                        'absolute z-10 rounded-lg shadow-[0_0_10px_rgba(0,0,0,0.2)]':
                            isOpen,
                    }
                )}
                ref={ref}
            >
                <div
                    className={classNames(
                        'flex items-center justify-between p-4 cursor-pointer transition-all duration-200',
                        {
                            'rounded-l-lg hover:shadow-[0_0_10px_rgba(0,0,0,0.1)]':
                                !isOpen,
                        }
                    )}
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                >
                    <div className='flex gap-2'>
                        <LogoImage
                            src={targetAsset?.value?.logoUrl}
                            alt={targetAsset?.value?.name}
                            slug={targetAsset?.value?.slug}
                            size={28}
                        />
                        <div className='text-xs font-bold'>
                            <div className='text-contrast-4 flex items-center'>
                                {targetAsset?.value?.name}
                            </div>
                            <div className='text-contrast-3'>
                                {targetAsset?.value?.symbol}
                            </div>
                        </div>
                    </div>
                    <span className='icon icon-chevron-arrow-down !w-2 mt-3 !text-contrast-3' />
                </div>
                {isOpen && (
                    <div className='border-t border-solid border-contrast-2/40 p-4'>
                        <Search
                            searchTerms={searchTerms}
                            setSearchTerms={setSearchTerms}
                        />
                        <div className='grid grid-cols-2 mt-4 gap-1'>
                            {(assetResults.length > 0
                                ? assetResults
                                : assetShortcuts
                            )?.map((asset, idx) => {
                                return (
                                    <div
                                        key={asset?.slug}
                                        className={classNames(
                                            'flex items-center cursor-pointer p-1 gap-1 hover:bg-contrast-3/10 rounded-md',
                                            {
                                                selected:
                                                    targetAsset?.value?.slug ===
                                                    asset?.slug,
                                            }
                                        )}
                                        title={asset?.name}
                                        onClick={() => {
                                            handleChange(asset)
                                        }}
                                    >
                                        <LogoImage
                                            src={asset?.logoUrl}
                                            alt={asset?.name}
                                            slug={asset?.slug}
                                            size={24}
                                        />
                                        <div className='text-xs font-bold'>
                                            <div className='text-contrast-4'>
                                                {asset?.symbol}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
