import { createContext, useState, useContext, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { v4 as uuid } from 'uuid'
import { WalletState } from 'state/stakingPortfolio/walletState'
import {
    SwingState,
    executeQuote,
    getIntegration,
} from 'state/stakingPortfolio/swing'
import { GTMEvent, logEvent } from 'utils/GTM'
import { getEthFromWei } from 'utils/stakingAssistant'
import { useExchangeQuote } from 'components/staking/ethereum/hooks'
import { ETHEREUM_STAKING_TYPE_OPTIONS } from 'components/staking/ethereum/constants'
import { useStakingModalContext } from './stakingModalContext'
import { useEthereumAssetWalletBalance } from 'components/stakingAssistant/hooks'
import { useCombinedWalletsContext } from './combinedWalletsContext'

export const EthereumStakingContext = createContext({})

export const EthereumStakingContextProvider = ({ children }) => {
    const { rewardOption, setRewardOption, inputAsset } =
        useStakingModalContext()

    const [stakingType, setStakingType] = useState(
        ETHEREUM_STAKING_TYPE_OPTIONS.find(
            option => option.key === rewardOption?.type?.key
        )
    )

    const [isQuoteUpdated, setIsQuoteUpdated] = useState(false)

    useEffect(() => {
        setStakingType(
            ETHEREUM_STAKING_TYPE_OPTIONS.find(
                option => option.key === rewardOption?.type?.key
            )
        )
        setIsQuoteUpdated(false)
    }, [rewardOption])

    const [selectedQuote, setSelectedQuote] = useState(null)

    const { primaryWallet, user, isLoggedIn, updateUser } =
        useCombinedWalletsContext()

    const walletAddress = primaryWallet?.address

    // Close the wallet modal as soon as the user is logged in
    useEffect(() => {
        if (isLoggedIn) {
            setIsQuoteUpdated(false)
            WalletState.walletModalIsOpen.value = false
        }
    }, [isLoggedIn])

    const { data: inputAssetBalance, isLoading: isLoadingInputAssetBalance } =
        useEthereumAssetWalletBalance(
            inputAsset,
            isLoggedIn ? walletAddress : null
        )

    const outputAsset = rewardOption?.outputAssets?.[0] ?? null

    const router = useRouter()

    const latestValueRef = useRef()
    const [inputValue, setInputValue] = useState(router?.query?.amount ?? '')

    const {
        quotes,
        bestQuote,
        isLoading: isLoadingQuote,
    } = useExchangeQuote(
        inputValue,
        inputAsset?.symbol,
        outputAsset?.symbol,
        () => {
            // A new quote for the changed input is received
            setIsQuoteUpdated(true)
        },
        isQuoteUpdated
    )

    const currentQuote = selectedQuote ?? bestQuote

    const currentUrl = router.asPath

    const value = {
        stakingType,
        setStakingType,

        onSetEthereumRewardOption: newOption => {
            setRewardOption(newOption)
            setIsQuoteUpdated(false)
            setSelectedQuote(null)
        },
        onSetEthereumInputValue: value => {
            setInputValue(value)
            latestValueRef.current = value
            setSelectedQuote(null)
            // Input changed - it needs a new quote,
            // a new quote is not received yet
            setIsQuoteUpdated(false)
        },

        isQuoteUpdated,
        outputAsset,
        quotes,
        bestQuote,
        selectedQuote: isLoggedIn ? currentQuote : null,
        setSelectedQuote,
        isLoadingQuote,
        inputAssetBalance,
        isLoadingInputAssetBalance,
        inputValue,
        latestValueWithQuote: latestValueRef?.current ?? null,
        onStake: async () => {
            try {
                const gtmEvent = {
                    transaction_id: uuid(),
                    wallet: primaryWallet?.connector?.name,
                    address: '_' + walletAddress,
                    session_id: user?.sessionId,
                    input_token: inputAsset?.symbol,
                    output_token: outputAsset?.symbol,
                    provider: rewardOption?.providers?.[0]?.slug,
                    amount: Number(inputValue),
                    amount_received: getEthFromWei(currentQuote?.quote?.amount),
                    type:
                        currentQuote?.quote?.type === 'deposit'
                            ? 'stake'
                            : 'swap',
                    route: getIntegration(currentQuote)?.name,
                    current_url: currentUrl,
                }
                logEvent(GTMEvent.StakingClicked, gtmEvent)
                SwingState.GTMStakingEvent.value = gtmEvent

                await executeQuote(
                    currentQuote?.route,
                    inputAsset?.symbol,
                    outputAsset?.symbol,
                    Number(inputValue)
                )

                if (user) {
                    const oldMetadata = user?.metadata ?? {}
                    await updateUser({
                        metadata: { ...oldMetadata, has_staked_in_app: true },
                    })
                    window.localStorage.setItem('has_staked_in_app', 'true')
                }
            } catch (e) {
                console.error('Failed to execute quote', e)
            }
        },
    }

    return (
        <EthereumStakingContext.Provider value={value}>
            {children}
        </EthereumStakingContext.Provider>
    )
}

export const useEthereumStakingContext = () => {
    return useContext(EthereumStakingContext)
}
