import { fetchAdManagerTopPosition, fetchDataAdPosition } from 'data'
import { RewardOptionRow } from './rewardOptionRow'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { RewardOptionCard } from './rewardOptionCard'
import { useEffect, useState } from 'react'
import { TableView } from 'components/ui'
import { LIQUID_STAKING_KEY } from 'utils/constants'

export const RewardOptionAds = ({ profileItem, profileType, operatorData }) => {
    const { selectedTableView, queryParams } = useTableArchiveContext()

    const sortByKey = queryParams?.sortBy?.key
    const timeframe = queryParams?.timeframe?.key
    const rewardOptionType = queryParams?.category?.key
    const isGridView = selectedTableView === TableView.Grid

    const [adRewardOption, setAdRewardOption] = useState([])

    useEffect(() => {
        const fetchRewardTopPosition = async id => {
            try {
                const result = await fetchAdManagerTopPosition(id)
                const adData = await fetchDataAdPosition(
                    {
                        category: rewardOptionType,
                        timeframe: timeframe,
                        profileSlug: null,
                        profileType: profileType,
                        rewardOptionId: result?.rewardOptions?.[0]?.id,
                    },
                    profileType
                )
                const joinedAdData = {
                    ...result,
                    ...adData,
                }
                setAdRewardOption(joinedAdData)
            } catch (err) {
                console.error(err)
            }
        }

        if (profileItem.id) {
            fetchRewardTopPosition(profileItem.id)
        }
    }, [profileItem.id, rewardOptionType])

    return (
        <div className='flex flex-col gap-4 mb-4'>
            {adRewardOption?.rewardOptions?.map((item, index) => {
                item.operators = []
                operatorData?.forEach(operator => {
                    const parentProvider = operator?.providers?.find(
                        p => p?.type?.label === LIQUID_STAKING_KEY
                    )
                    if (parentProvider?.id === item?.providers?.[0]?.id) {
                        item.operators.push(operator)
                    }
                })

                return (
                    <div key={`page-${index}`} className='flex flex-col gap-4'>
                        {isGridView ? (
                            <RewardOptionCard
                                sponsoredROTrackingId={
                                    adRewardOption?.trackingId
                                }
                                key={index}
                                index={null}
                                item={item}
                                type={rewardOptionType}
                                profileItem={profileItem}
                                profileType={profileType}
                                timeframe={timeframe}
                                sortByKey={sortByKey}
                            />
                        ) : (
                            <RewardOptionRow
                                sponsoredROTrackingId={
                                    adRewardOption?.trackingId
                                }
                                key={index}
                                index={null}
                                item={item}
                                type={rewardOptionType}
                                profileItem={profileItem}
                                profileType={profileType}
                                timeframe={timeframe}
                                sortByKey={sortByKey}
                            />
                        )}
                    </div>
                )
            })}
        </div>
    )
}
