import { useState } from 'react'
import dynamic from 'next/dynamic'
import { useAssetsWithMetrics, useNetStakingFlowData } from 'data'
import { TYPE_ASSET } from 'utils/constants'
import { getLatestNetFlowMetricPerDays } from 'utils/converter'
import { HeaderWithLink } from 'components/text/headerWithLink'
import { ErrorBoundary } from 'components/utils'
import { ContainerWithTabs, TabLoading } from 'components/ui/containerWithTabs'
import { useApprovedHistoricalMetricKeys } from './hooks'
import { PerformanceOverTime } from './performanceOverTime'
import { toNetStakingFlowChartData } from './utils'
import { PegDeviationOverTime } from './pegDeviationOverTime'
import { TextPerformanceChart } from './textPerformanceChart'
import { getMetricObject } from 'utils/getMetricObject'
import { useRouter } from 'next/router'
import { getObjectFromJsonString } from 'utils/actions'
import classNames from 'classnames'

const BarChart = dynamic(
    () => import('./netStakingFlowChart').then(module => module.BarChart),
    {
        ssr: false,
    }
)

const INITIAL_DAY_COUNT = 30
const PEG_INITIAL_DAY_COUNT = 90

const ChartTab = ({
    id = null,
    type = TYPE_ASSET,
    allSeriesConfig = [],
    defaultMetricKeys = [],
    isTablet = true,
    maxSelectedMetricsCount = 2,
    isPegDeviationChart = false,
}) => {
    const chartString = isPegDeviationChart
        ? 'peg deviation'
        : 'performance over time'

    const { data: approvedMetricKeys, isLoading } =
        useApprovedHistoricalMetricKeys(
            id,
            type,
            allSeriesConfig?.map(config => config?.key).filter(Boolean) ?? []
        )

    if (isLoading) {
        return (
            <TabLoading
                messages={[`Loading ${chartString} data...`]}
                small={true}
            />
        )
    }

    if (!approvedMetricKeys?.length) {
        return (
            <p className='p-4'>
                {`No ${chartString} data available for this ${type}.`}
            </p>
        )
    }

    const seriesConfig =
        allSeriesConfig?.filter(config =>
            approvedMetricKeys.includes(config?.key)
        ) ?? []

    const ChartComponent = isPegDeviationChart
        ? PegDeviationOverTime
        : PerformanceOverTime

    return (
        <div className='px-4 pt-4 pb-6'>
            <ChartComponent
                id={id}
                type={type}
                seriesConfig={seriesConfig}
                defaultMetricKeys={defaultMetricKeys}
                maxSelectedMetricsCount={maxSelectedMetricsCount}
                isTablet={isTablet}
                initialDayCount={
                    isPegDeviationChart
                        ? PEG_INITIAL_DAY_COUNT
                        : INITIAL_DAY_COUNT
                }
            />
        </div>
    )
}

export const PerformanceSection = ({
    id = null,
    slug = '',
    symbol = '',
    name = '',
    type = TYPE_ASSET,
    allSeriesConfig = [],
    defaultMetricKeys = [],
    maxSelectedMetricsCount = 2,
    isTablet = true,
    isAVS,
}) => {
    const [selectedTab, setSelectedTab] = useState()
    const router = useRouter()
    const currentPath = router.pathname
    const isAssetPage = currentPath.startsWith('/asset')

    const { data } = useNetStakingFlowData(slug, INITIAL_DAY_COUNT, isAVS)

    const { data: dataTextPerformance } = useAssetsWithMetrics(
        [slug],
        ['price', 'staked_tokens', 'inflation_rate']
    )

    const asset = dataTextPerformance?.assets?.[0]

    const stakedTokens = getMetricObject(asset, 'staked_tokens')

    const parsedStakedTokensAbsolutes =
        asset && getObjectFromJsonString(stakedTokens?.changeAbsolutes)

    const isTextPerformanceVisible =
        Number(parsedStakedTokensAbsolutes?.['30d'].toFixed(2)) !== 0.0

    const netStakingFlowData = toNetStakingFlowChartData(
        getLatestNetFlowMetricPerDays(
            data?.[`${type}s`]?.[0]?.metrics ?? []
        )?.slice(0, INITIAL_DAY_COUNT)
    )

    const hasAllNetStakingFlowData =
        (netStakingFlowData?.filter(m => !!m?.value)?.length ?? 0) >=
        INITIAL_DAY_COUNT

    const pegDeviationData = allSeriesConfig?.find(
        c => c?.key === 'peg_deviation'
    )

    const hasPegDeviationData = !!pegDeviationData

    const tabs = [
        ...(type === TYPE_ASSET && hasAllNetStakingFlowData
            ? [
                  {
                      key: 'net-staking-flow-30d',
                      name: 'Net Staking Flow 30d',
                      event: '',
                      content: (
                          <div className='w-full h-[360px] px-4 pt-4 pb-4'>
                              <BarChart
                                  maxBarSize={24}
                                  withLabels={true}
                                  chartData={netStakingFlowData}
                              />
                          </div>
                      ),
                  },
              ]
            : []),
        {
            key: 'performance-over-time',
            name: 'Performance over Time',
            event: '',
            content: (
                <ChartTab
                    id={id}
                    type={type}
                    allSeriesConfig={[
                        ...(allSeriesConfig
                            ? allSeriesConfig?.filter(
                                  c => c?.key !== 'peg_deviation'
                              )
                            : []),
                    ]}
                    defaultMetricKeys={defaultMetricKeys}
                    maxSelectedMetricsCount={maxSelectedMetricsCount}
                    isTablet={isTablet}
                    isPegDeviationChart={false}
                />
            ),
        },
        ...(type === TYPE_ASSET && hasPegDeviationData
            ? [
                  {
                      key: 'peg-deviation',
                      name: 'Peg Accuracy',
                      event: '',
                      content: (
                          <ChartTab
                              id={id}
                              type={type}
                              allSeriesConfig={[
                                  { ...(pegDeviationData ?? {}) },
                              ]}
                              defaultMetricKeys={['peg_deviation']}
                              isTablet={isTablet}
                              isPegDeviationChart={true}
                          />
                      ),
                  },
              ]
            : []),
    ]

    return (
        <>
            <div
                id='section-performance-charts'
                className='flex flex-col w-full'
            >
                <HeaderWithLink
                    title={`${symbol || name} ${
                        isAVS ? 'Restaking' : 'Staking'
                    } Performance Charts`}
                    href={`/${type}/${slug}#section-performance-charts`}
                />
                <p className='text-description mb-6'>{`Track ${name} ${
                    isAVS ? 're' : ''
                }staking over time by analyzing key performance metrics.`}</p>
                <ErrorBoundary
                    fallback={
                        <p className='mb-5'>
                            Something went wrong while loading charts...
                        </p>
                    }
                >
                    <ContainerWithTabs
                        isTextPerformanceVisible={isTextPerformanceVisible}
                        tabs={tabs}
                        isLoading={false}
                        onChange={setSelectedTab}
                        loadingMessages={['Loading charts...']}
                        darker={true}
                        minWidth='1000px'
                        height='100%'
                    />
                </ErrorBoundary>
                {isTextPerformanceVisible && isAssetPage && (
                    <TextPerformanceChart
                        parsedStakedTokensAbsolutes={
                            parsedStakedTokensAbsolutes
                        }
                        data={dataTextPerformance}
                        slug={slug}
                        darker={true}
                        name={name}
                        symbol={symbol}
                    />
                )}
            </div>
        </>
    )
}
