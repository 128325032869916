import '@interchain-ui/react/styles'
import '@interchain-ui/react/globalStyles'
import { wallets } from 'cosmos-kit'
import { ChainProvider } from '@cosmos-kit/react'
import { chains, assets } from 'chain-registry'
import { cosmos } from '@stakingrewards/staking-sdk'
import { SUPPORTED_COSMOS_CHAINS } from 'components/staking/cosmos/utils'

export const CosmosWalletContextProvider = ({ children }) => {
    const supportedChains = chains
        .filter(chain =>
            Object.values(cosmos.config.supportedChains).includes(
                chain.chain_name
            )
        )
        .filter(chain => SUPPORTED_COSMOS_CHAINS.includes(chain.chain_name))
    return (
        <ChainProvider
            chains={supportedChains}
            assetLists={assets}
            wallets={wallets}
            walletConnectOptions={{
                signClient: {
                    projectId: 'a8510432ebb71e6948cfd6cde54b70f7',
                    relayUrl: 'wss://relay.walletconnect.org',
                    metadata: {
                        name: 'Staking Rewards',
                        description: 'Staking Rewards CosmosKit dapp',
                        url: 'https://docs.cosmology.zone/cosmos-kit/',
                        icons: [],
                    },
                },
            }}
        >
            {children}
        </ChainProvider>
    )
}
