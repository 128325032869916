import { useRef } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import dynamic from 'next/dynamic'
import { useInView } from 'hooks'
import { scrollWithHeaderOffset, trimUnescapedHtml } from 'utils/actions'
import {
    AVS_KEY,
    DEFAULT_ASSET_TIMEFRAME,
    LIQUID_STAKING_KEY,
    TYPE_ASSET,
} from 'utils/constants'
import { AssetRewardCalculatorContextProvider } from 'contexts/assetRewardCalculatorContext'
import { NAVIGATION_BREAKPOINT } from '../constants'
import { AssetDescription } from './info/assetDescription'
import { AssetRewardCalculator } from './info/assetRewardCalculator'
import { ProviderInformation } from './lst/providerInformation'
import { HeaderWithLink } from 'components/text/headerWithLink'
import { ProfileMetricWithoutExpansion } from 'components/metrics/profileMetric2'
import { getMetricGroupsForAVS } from './utils'
import { REFRESH_INTERVAL_ASSET_PRICE_MS, useProfileMetrics } from 'data'

// ScrollableNavigation needs page html to parse h2 titles
const Navigation = dynamic(
    () => import('./info/navigation').then(module => module.Navigation),
    {
        ssr: false,
    }
)

export const AssetInformation = ({
    asset = null,
    faqRef = null,
    calculatorRef = null,
}) => {
    const metricGroups = getMetricGroupsForAVS()
    const { data: assetWithPrice, isLoading: isLoadingPrice } =
        useProfileMetrics(
            TYPE_ASSET,
            asset.slug,
            metricGroups?.map(mg => mg.key),
            REFRESH_INTERVAL_ASSET_PRICE_MS
        )
    const ref = useRef(null)
    const showContentDropdown = useMediaPredicate(
        `(max-width: ${NAVIGATION_BREAKPOINT})`
    )
    const isTablet = useMediaPredicate('(min-width: 768px)')
    const isSectionInView = useInView(
        ref,
        0,
        `-${isTablet ? 100 : 140}px 0px 0px 0px`
    )

    const isLst = !!asset?.tags?.find(t => t.tagKey === LIQUID_STAKING_KEY)
    const isAVS = !!asset?.tags?.find(t => t.tagKey === AVS_KEY)
    const provider = isLst
        ? asset?.rewardOptionsWithAssetAsOutput?.[0]?.providers?.[0]
        : null

    const hasFaqs = !!asset?.faqs?.find(
        faq =>
            !!trimUnescapedHtml(faq?.question) &&
            !!trimUnescapedHtml(faq?.answer)
    )

    return (
        <div
            id='section-key-staking-info'
            ref={ref}
            className={classNames(
                'flex flex-col xl:flex-row gap-7 overflow-x-auto sm:overflow-visible'
            )}
        >
            <AssetRewardCalculatorContextProvider asset={asset}>
                <div className='flex flex-row gap-7'>
                    {!showContentDropdown && (
                        <Navigation
                            symbol={asset?.symbol}
                            isSectionInView={isSectionInView}
                            isAVS={isAVS}
                        />
                    )}

                    {!isAVS ? (
                        <AssetRewardCalculator calculatorRef={calculatorRef} />
                    ) : (
                        <div className='h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-[max-content_auto] gap-5 bg-contrast-1 p-6 rounded-lg md:min-w-auto w-full'>
                            {metricGroups?.map((metricGroup, index) => (
                                <ProfileMetricWithoutExpansion
                                    key={index}
                                    className='!bg-contrast-0 !w-full'
                                    metricGroup={metricGroup}
                                    item={{
                                        ...asset,
                                        metrics: assetWithPrice?.metrics,
                                    }}
                                    timeframeKey={DEFAULT_ASSET_TIMEFRAME.key}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div
                    className={
                        'bg-contrast-1 max-w-full md:min-w-auto w-full xl:min-w-[400px] p-6 rounded-lg'
                    }
                >
                    <div className='flex flex-col h-full'>
                        <HeaderWithLink
                            className='!text-contrast-4 !text-lg'
                            title={`What is ${asset?.name}${
                                isLst ? '' : isAVS ? ' Restaking' : ' Staking'
                            }?`}
                            href={`/asset/${asset?.slug}#section-key-staking-info`}
                        />
                        <div className='flex flex-col justify-between h-full'>
                            <AssetDescription />
                            {Boolean(provider) && (
                                <ProviderInformation provider={provider} />
                            )}
                            {faqRef && hasFaqs && (
                                <div
                                    className='flex flex-row items-center gap-x-4 mt-4 hover:opacity-80 cursor-pointer'
                                    onClick={() => {
                                        scrollWithHeaderOffset(faqRef, isTablet)
                                    }}
                                >
                                    <span className='text-primary text-xs font-normal'>
                                        {isAVS
                                            ? `Learn more about ${asset?.name} Restaking`
                                            : `Learn how to stake ${asset?.symbol}`}
                                    </span>
                                    <span className='icon icon-arrow-down min-w-[10px] max-w-[10px] !bg-primary' />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </AssetRewardCalculatorContextProvider>
        </div>
    )
}
