import Link from 'next/link'
import { GTMEvent, logEvent } from 'utils/GTM'

export const AnnouncementBar = ({ setIsBarVisible }) => {
    return (
        <Link
            className=' text-white flex items-center w-screen px-6 py-[11px] bg-customGradientTopBar relative top-0 h-fit flex-row'
            href={'/stake-app?input=bitcoin'}
            onClick={() =>
                logEvent(GTMEvent.TopBarAnnouncement, {
                    url: '/stake-app?input=bitcoin',
                })
            }
        >
            <div className='flex items-center flex-grow justify-center'>
                <div className='rounded p-[6px] bg-white font-normal text-[10px] text-blue mr-[12px] hidden sm:inline'>
                    New Feature
                </div>

                <p className='mr-[12px] text-[14px] sm:text-[16px]'>
                    Non-custodial Bitcoin staking is here!{' '}
                    <span className='font-bold'>Stake $BTC with Babylon</span>
                </p>
                <span
                    className='
                        opacity-0
                        icon
                        icon-arrow-right
                        min-w-[10px]
                        max-w-[10px]
                        !bg-white
                        sm:opacity-100'
                />
            </div>
            <button
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsBarVisible(false)
                }}
                className='icon icon-cross !min-w-[24px] !min-h-[24px] !bg-white self-center'
            ></button>
        </Link>
    )
}
