import classNames from 'classnames'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { getAnimationClassNames } from 'components/rewardOptions/archive/v2/getFormattedData'
import { CopyAddress, Link, LogoImage, TooltipOnHover } from 'components/ui'
import Image from 'next/image'
import { compressAddress } from 'utils/stakingAssistant/utils'
import { STAKING_RISK_RATINGS } from 'utils/constants'
import { useSmartContractCardContext } from 'components/rewardOptions/smartContract/smartContractCardContext'
import { getObjectFromJsonString } from 'utils/actions'
import {
    MAX_TOOLTIP_TEXT_LENGTH,
    NO_DATA_INDICATOR,
    truncateString,
} from 'utils/formatter'
import { TOOLTIP_TEXT } from 'utils/stakingAssistant'
import { TOOLTIPS } from 'components/rewardOptions/constants'

export const ExpandedSmartContract = ({
    formattedData,
    isExpanded,
    asset,
    sortByKey,
}) => {
    const { rewardOption, chainAsset, isLoadingRelatedAssets } =
        useSmartContractCardContext()
    const tokenModel =
        formattedData?.outputAssetExchangeRatio === 1
            ? 'Rebasing'
            : 'Compounding'

    const rank = STAKING_RISK_RATINGS.find(
        r => r?.key === String(formattedData.riskRating)
    )

    // const network = formattedData.item?.chains?.[0]
    const network = null // Ignore network for now as we dont have the logos

    const links = getObjectFromJsonString(rewardOption?.links)
    const explorerLink = links?.['smart_contract']
    const parts = explorerLink?.split('/')
    const address = parts?.[parts.length - 1]

    return (
        <>
            <div className='flex w-full justify-around'>
                <MetricBox
                    title={
                        <div className='w-full flex justify-center'>
                            <Image
                                src={'/static/svg/vsp-tag-extended.svg'}
                                alt={`Verified Provider`}
                                width={42}
                                height={14}
                            />
                        </div>
                    }
                    value={
                        <div className='flex justify-center text-contrast-3'>{`${asset?.operators?.length} Operators`}</div>
                    }
                    valueClassName='!text-contrast-2'
                    isSmall
                    className='w-3/12 block xl:hidden'
                />
                <TooltipOnHover
                    text={network?.label}
                    className='w-2/12 text-center'
                >
                    <MetricBox
                        title='Network'
                        isSmall
                        value={
                            network && (
                                <Link
                                    href={`/asset/${network?.key}`}
                                    blank
                                    className='flex justify-center'
                                >
                                    <LogoImage
                                        slug={network?.key}
                                        src={chainAsset?.logoUrl}
                                        alt={`${chainAsset?.name} logo`}
                                        size={16}
                                    />
                                </Link>
                            )
                        }
                    />
                </TooltipOnHover>
                <MetricBox
                    title='SR Risk Rating'
                    value={rank?.letter}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(2, isExpanded),
                        'w-3/12',
                        {
                            ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                sortByKey === 'risk_rating',
                        }
                    )}
                />
                <div
                    className={classNames(
                        getAnimationClassNames(3, isExpanded),
                        'w-3/12'
                    )}
                >
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIPS.stakers.text,
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: TOOLTIPS.stakers.title,
                            allText: TOOLTIPS.stakers.text,
                        }}
                    >
                        <MetricBox
                            title='Stakers'
                            value={formattedData.stakers || NO_DATA_INDICATOR}
                            change={formattedData.stakersChangePercent}
                            isSmall
                            className={classNames({
                                ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                    sortByKey === 'staking_wallets',
                            })}
                        />
                    </TooltipOnHover>
                </div>
            </div>
            <div className='flex w-full justify-around'>
                <MetricBox
                    title='Reward Freq.'
                    value={formattedData.rewardFrequency}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(4, isExpanded),
                        'w-3/12'
                    )}
                />
                <div
                    className={classNames(
                        getAnimationClassNames(5, isExpanded),
                        'w-3/12'
                    )}
                >
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIP_TEXT.TokenModel,
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: 'Token Model',
                            allText: TOOLTIP_TEXT.TokenModel,
                        }}
                        className='w-full'
                    >
                        <MetricBox
                            title='Token Model'
                            value={tokenModel}
                            isSmall
                        />
                    </TooltipOnHover>
                </div>
                <MetricBox
                    title='Unstake Cooldown'
                    value={formattedData.unstakingTime}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(6, isExpanded),
                        'w-3/12',
                        {
                            ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                sortByKey === 'unstaking_time',
                        }
                    )}
                />
                <MetricBox
                    title={
                        <div className='w-[90px]'>
                            <CopyAddress
                                address={address}
                                textClassName='!text-[10px] whitespace-nowrap'
                            />
                        </div>
                    }
                    value={compressAddress(address, 4, 4)}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(7, isExpanded),
                        'w-3/12'
                    )}
                />
            </div>
        </>
    )
}
