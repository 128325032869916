import classNames from 'classnames'
import { AssetRewardCalculatorInputsForTable } from 'components/assets/profile/info/assetRewardCalculatorInputs'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { Link, LogoImage, TooltipOnHover } from 'components/ui'
import { useTimeElapsed } from 'hooks'
import Image from 'next/image'

export const MetricsLST = ({ formattedData, asset, sortByKey }) => {
    const lstToken = formattedData?.outputAsset
    const stakedTokensUpdatedAt = useTimeElapsed(
        formattedData.stakedTokensCreatedAt
    )

    const operatorCount = asset?.operators?.length

    return (
        <div className='flex flex-1 justify-between'>
            <div className='w-4/12'>
                <TooltipOnHover
                    text={<AssetRewardCalculatorInputsForTable />}
                    textClassName='!w-[300px]'
                >
                    <MetricBox
                        title={formattedData?.rewardsAfterTitle}
                        value={formattedData?.rewardsAfterValue}
                    />
                </TooltipOnHover>
            </div>
            <div className='w-4/12 flex items-center justify-center'>
                <TooltipOnHover
                    text={
                        <MetricBox
                            title='Staked Tokens'
                            value={formattedData.stakedTokens}
                            change={formattedData.stakedTokensChangePercent}
                        />
                    }
                    updatedTimeStatus={stakedTokensUpdatedAt}
                >
                    <MetricBox
                        title='TVL'
                        value={formattedData.aum}
                        change={formattedData.stakedTokensChangePercent}
                        className={classNames({
                            ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                sortByKey === 'staked_tokens',
                        })}
                    />
                </TooltipOnHover>
            </div>
            <TooltipOnHover
                text={
                    <div>
                        <Link
                            href={`/asset/${lstToken?.slug}`}
                            className='text-primary'
                        >
                            View LST Profile
                        </Link>
                    </div>
                }
                textClassName='!w-[120px]'
                className='w-3/12'
            >
                <MetricBox
                    title='LST'
                    value={
                        <Link
                            href={`/asset/${lstToken?.slug}`}
                            blank
                            className='flex items-center gap-1'
                        >
                            <LogoImage
                                slug={lstToken?.slug}
                                src={lstToken?.logoUrl}
                                alt={`${lstToken?.name} logo`}
                                size={24}
                            />
                            <span className='text-xs'>{lstToken?.symbol}</span>
                        </Link>
                    }
                />
            </TooltipOnHover>
            <div className='w-3/12 hidden xl:flex h-full items-center justify-center pt-2'>
                {operatorCount > 1 && (
                    <MetricBox
                        title={
                            <div className='w-full flex justify-center'>
                                <Image
                                    src={'/static/svg/vsp-tag-extended.svg'}
                                    alt={`Verified Provider`}
                                    width={42}
                                    height={14}
                                />
                            </div>
                        }
                        value={
                            <div className='flex justify-center text-contrast-3'>{`${operatorCount} Operators`}</div>
                        }
                        valueClassName='!text-contrast-2'
                        isSmall
                    />
                )}
            </div>
        </div>
    )
}
