import { useState } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { useTimeElapsed } from 'hooks'
import {
    getMetricPercentage,
    getMetricValueByKey,
    isMetricWithExpansion,
} from 'utils/actions'
import { DEFAULT_ASSET_TIMEFRAME } from 'utils/constants'
import {
    MAX_TOOLTIP_TEXT_LENGTH,
    formatOutputNumber,
    isInvalidNumber,
    truncateString,
} from 'utils/formatter'
import { LogoImage, TooltipOnHover } from 'components/ui'
import { ExpandedMetric } from './expandedMetric'
import {
    getFormattedMetricValue,
    getMarketPosition,
    getPercentageTextColorClassNames,
    getValueTextColorClassNames,
    isGrayScaleMetric,
} from './utils'
import { RaverTooltip } from 'components/assets/constants'

// NB: future replacement for components/metrics/profileMetric.js
export const ProfileMetric = ({
    className = '',
    metricGroup = null,
    item = null,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    minMarketValue = null,
    maxMarketValue = null,
    metricData = null,
    currentMetricKey = '',
    isSortableInArchive = false,
}) => {
    const value = getMetricValueByKey(
        metricData,
        metricGroup?.key,
        metricGroup?.timeframeKey
    )
    const percentage =
        metricGroup?.withPercentage && !isInvalidNumber(value)
            ? getMetricPercentage(
                  metricData,
                  metricGroup?.key,
                  timeframeKey,
                  metricGroup?.prefix || metricGroup?.postfix
              )
            : null

    const formattedValue = getFormattedMetricValue(value, metricGroup)

    const valueColorClassName = getValueTextColorClassNames(
        value,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )
    const percentageColorClassName = getPercentageTextColorClassNames(
        percentage,
        metricGroup?.withColor
    )

    const updatedTimeStatus = useTimeElapsed(
        metricData?.metrics?.find(
            metric => metric?.metricKey === metricGroup?.key
        )?.createdAt
    )

    const marketPosition = getMarketPosition(
        currentMetricKey,
        value,
        minMarketValue,
        maxMarketValue
    )

    const isTablet = useMediaPredicate('(min-width: 768px)')

    const [isExpanded, setIsExpanded] = useState(false)

    const hasExpansion =
        !isInvalidNumber(value) && isMetricWithExpansion(metricGroup?.key)

    const overlay = hasExpansion
        ? null
        : (metricGroup?.tooltipTextObj?.text?.length ?? 0) >
            MAX_TOOLTIP_TEXT_LENGTH
          ? {
                title: metricGroup?.tooltipTextObj?.title ?? '',
                allText: metricGroup?.tooltipTextObj?.text ?? '',
                footer: metricGroup?.tooltipTextObj?.footer ?? '',
            }
          : null

    return (
        <>
            {hasExpansion && isExpanded && (
                <ExpandedMetric
                    item={{
                        ...item,
                        metrics: metricData?.metrics ?? [],
                    }}
                    timeframeKey={timeframeKey}
                    metricGroup={metricGroup}
                    onClose={() => {
                        setIsExpanded(false)
                    }}
                    isSortableInArchive={isSortableInArchive}
                    isTablet={isTablet}
                />
            )}
            <div
                className={classNames(
                    `flex flex-col gap-y-3 p-4 bg-contrast-1 rounded-lg ${
                        hasExpansion ? 'cursor-pointer hover:shadow-lg' : ''
                    }`,
                    className
                )}
                onClick={() => {
                    if (hasExpansion) {
                        setIsExpanded(true)
                    }
                }}
            >
                <div className='flex flex-row items-center justify-between gap-x-3'>
                    <TooltipOnHover
                        text={truncateString(
                            metricGroup?.tooltipTextObj?.text ?? '',
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={hasExpansion ? null : overlay}
                        updatedTimeStatus={updatedTimeStatus}
                        withExpansion={hasExpansion}
                        withReadMore={hasExpansion}
                        onReadMore={() => {
                            if (hasExpansion) {
                                setIsExpanded(true)
                            }
                        }}
                    >
                        <p className={'text-xs font-bold text-contrast-3'}>
                            {metricGroup?.label ?? ''}
                        </p>
                    </TooltipOnHover>
                    {hasExpansion && (
                        <div
                            className='icon icon-expand !bg-contrast-3 cursor-pointer hover:scale-105'
                            onClick={() => {
                                setIsExpanded(true)
                            }}
                        />
                    )}
                </div>
                <div className='flex flex-row items-center gap-x-4'>
                    <p
                        className={classNames(
                            'text-md font-bold',
                            valueColorClassName
                        )}
                    >
                        {formattedValue}
                    </p>
                    {!isInvalidNumber(percentage) && (
                        <TooltipOnHover
                            text={`${timeframeKey} ${
                                metricGroup?.postfix === '%'
                                    ? 'Absolute Change'
                                    : 'Change Percentage'
                            }`}
                            textClassName={
                                'max-w-[100px] !text-center !text-[10px]'
                            }
                        >
                            <p
                                className={classNames(
                                    'text-xs font-bold',
                                    percentageColorClassName
                                )}
                            >
                                {formatOutputNumber(percentage, {
                                    precision: 2,
                                    forcePrecision: false,
                                    allowEmpty: false,
                                    withAbbreviation: true,
                                    showApproximation: true,
                                    postfix: '%',
                                    spaceAfterNumber: false,
                                    showPlus: true,
                                })}
                            </p>
                        </TooltipOnHover>
                    )}
                </div>
                {!isInvalidNumber(marketPosition) &&
                    !isInvalidNumber(value) && (
                        <div className='flex flex-col gap-y-3'>
                            <TooltipOnHover
                                text={truncateString(
                                    `The market position of the token is its relative performance in terms of ${metricGroup?.label}, compared to all tokens integrated on our website.`,
                                    MAX_TOOLTIP_TEXT_LENGTH
                                )}
                                overlay={{
                                    title: 'Market Position',
                                    allText: `The market position of the token is its relative performance in terms of ${metricGroup?.label}, compared to all tokens integrated on our website.`,
                                }}
                            >
                                <p className='text-[10px] font-bold text-contrast-3'>
                                    Market Position
                                </p>
                            </TooltipOnHover>
                            <div className='relative'>
                                <div
                                    className={`bg-gradient-to-r ${
                                        isGrayScaleMetric(currentMetricKey)
                                            ? 'from-shade-lightest via-shade-light to-shade-darkest'
                                            : 'from-[var(--c-red)] via-[var(--c-yellow)] to-[var(--c-green)]'
                                    } w-full h-[2px] rounded`}
                                />
                                <div
                                    className={`absolute -top-[8px]`}
                                    style={{
                                        left: `calc(${
                                            marketPosition * 100
                                        }% - 8px)`,
                                    }}
                                >
                                    <LogoImage
                                        src={item?.logoUrl}
                                        alt={item?.name}
                                        size={16}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </>
    )
}

export const ProfileMetricWithoutExpansion = ({
    className = '',
    metricGroup = null,
    item = null,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
}) => {
    const value = getMetricValueByKey(
        item,
        metricGroup?.key,
        metricGroup?.timeframeKey
    )
    const percentage =
        metricGroup?.withPercentage && !isInvalidNumber(value)
            ? getMetricPercentage(
                  item,
                  metricGroup?.key,
                  timeframeKey,
                  metricGroup?.prefix || metricGroup?.postfix
              )
            : null

    const formattedValue = getFormattedMetricValue(value, metricGroup)

    const valueColorClassName = getValueTextColorClassNames(
        value,
        metricGroup?.withColor && !metricGroup?.withPercentage,
        metricGroup?.key
    )
    const percentageColorClassName = getPercentageTextColorClassNames(
        percentage,
        metricGroup?.withColor
    )

    const updatedTimeStatus = useTimeElapsed(
        item?.metrics?.find(metric => metric?.metricKey === metricGroup?.key)
            ?.createdAt
    )

    const isRaver = metricGroup?.tooltipTextObj?.isRaver

    return (
        <>
            <div
                className={classNames(
                    'flex flex-col gap-y-3 p-4 bg-contrast-1 rounded-lg',
                    className
                )}
            >
                <div className='flex flex-row items-center justify-between gap-x-3'>
                    <TooltipOnHover
                        text={
                            isRaver ? (
                                <RaverTooltip />
                            ) : (
                                truncateString(
                                    metricGroup?.tooltipTextObj?.text ?? '',
                                    MAX_TOOLTIP_TEXT_LENGTH
                                )
                            )
                        }
                        overlay={
                            (metricGroup?.tooltipTextObj?.text?.length ?? 0) >
                            MAX_TOOLTIP_TEXT_LENGTH
                                ? {
                                      title:
                                          metricGroup?.tooltipTextObj?.title ??
                                          '',
                                      allText:
                                          metricGroup?.tooltipTextObj?.text ??
                                          '',
                                      footer:
                                          metricGroup?.tooltipTextObj?.footer ??
                                          '',
                                  }
                                : null
                        }
                        updatedTimeStatus={updatedTimeStatus}
                        textClassName={classNames({
                            '!w-[280px]': isRaver,
                        })}
                    >
                        <p className={'text-xs font-bold text-contrast-3'}>
                            {metricGroup?.label ?? ''}
                        </p>
                    </TooltipOnHover>
                </div>
                <div className='flex flex-row items-center gap-x-2'>
                    <p
                        className={classNames(
                            'text-md font-bold',
                            valueColorClassName
                        )}
                    >
                        {formattedValue}
                    </p>
                    {!isInvalidNumber(percentage) && (
                        <TooltipOnHover
                            text={`${timeframeKey} ${
                                metricGroup?.postfix === '%'
                                    ? 'Absolute Change'
                                    : 'Change Percentage'
                            }`}
                            textClassName={
                                'max-w-[100px] !text-center !text-[10px]'
                            }
                        >
                            <p
                                className={classNames(
                                    'text-xs font-bold',
                                    percentageColorClassName
                                )}
                            >
                                {formatOutputNumber(percentage, {
                                    precision: 2,
                                    forcePrecision: false,
                                    allowEmpty: false,
                                    withAbbreviation: true,
                                    showApproximation: true,
                                    postfix: '%',
                                    spaceAfterNumber: false,
                                    showPlus: true,
                                })}
                            </p>
                        </TooltipOnHover>
                    )}
                </div>
            </div>
        </>
    )
}
