import { LIQUID_STAKING_KEY, POS_PROVIDER_KEY } from 'utils/constants'
import { LstStakeNowButton } from './lstStakeNowButton'
import { PosStakeNowButton } from './posStakeNowButton'
import { checkIsDelegatable, checkIsStakeableLst } from './utils'

export const SRStakeNowButton = ({
    inputAsset = null,
    outputAsset = null,
    provider = null,
    rewardOption = null,
    ...buttonProps
}) => {
    if (
        rewardOption?.type?.key === LIQUID_STAKING_KEY &&
        checkIsStakeableLst(outputAsset, inputAsset, rewardOption)
    ) {
        return (
            <LstStakeNowButton
                asset={rewardOption?.outputAssets?.[0]}
                provider={rewardOption?.providers?.[0]}
                inputAssetSlug={rewardOption?.inputAssets?.[0]?.slug}
                {...buttonProps}
            />
        )
    }

    if (
        rewardOption?.type?.key === POS_PROVIDER_KEY &&
        checkIsDelegatable(inputAsset, provider, rewardOption)
    ) {
        return (
            <PosStakeNowButton
                asset={inputAsset}
                provider={provider}
                {...buttonProps}
            />
        )
    }

    return null
}
