export function logEvent(event, data) {
    console.log('EVENT', event, data)
    if (!window.dataLayer) {
        return
    }
    try {
        window.dataLayer.push({
            event,
            ...data,
        })
    } catch (e) {
        console.error('Failed to log GTM event', e)
    }
}

export const GTMEvent = {
    // Staking Assistant old events
    ConnectWithWalletClicked: 'portfolio_metamask_wallet',
    AddressConnectClicked: 'enter_wallet',

    DailyRewardChartSelected: 'select-dailyreward-chart',
    TotalBalanceChartSelected: 'select-total-balance',
    PotentialEarningsChartSelected: 'select-potential-earnings',

    DisconnectClicked: 'disconnect-wallet-modal',
    Disconnected: 'disconnect-wallet-success',

    // Terminal
    EnterTerminalClick: 'terminal_waitlist_btn',

    TerminalClaimRewardsClicked: 'terminal_claim_rewards',
    TerminalClaimRewardsSuccess: 'terminal_claim_rewards_success',
    TerminalClaimRewardsFailed: 'terminal_claim_rewards_failed',

    // Staking app
    ConnectWallet: 'connect_wallet',
    StakingAssetFilterChanged: 'app_asset_filter',
    StakingTypeFilterChanged: 'app_type_filter',
    StakingProviderFilterChanged: 'app_provider_filter',
    StakingRouteFilterChanged: 'app_route_filter',
    StakingViewChanged: 'app_stake_view',

    StakingClicked: 'app_stake',
    StakingSuccess: 'app_stake_success',
    StakingFailed: 'app_stake_failed',

    UndelegateClicked: 'app_undelegate',
    UndelegateSuccess: 'app_undelegate_success',
    UndelegateFailed: 'app_undelegate_failed',

    RedelegateClicked: 'app_redelegate',
    RedelegateSuccess: 'app_redelegate_success',
    RedelegateFailed: 'app_redelegate_failed',

    // Others
    SrETHSubscribed: 'sreth_signup',
    ApiKeyRequested: 'freeapikey_signup',
    StakingInsiderSubscribed: 'stakinginsider_signup',
    AnnouncementOpened: 'announcement_topnav',
    ProvidersVSPSelected: 'vsp_providers_toggle',
    ProvidersAllSelected: 'all_providers_toggle',
    VspStakeButton: 'vspstakebutton',
    StakeButtonClicked: 'stake_button',
    ExpandTableClick: 'expand_table_click',
    AdLeaderboardBanner: 'ad_leaderboardbanner',
    AdTextAd: 'ad_textad',
    GetAssetIntegratedButton: 'getassetintegrated_btn',
    ExternalLstStake: 'external_lst_stake',
    ExternalLstUnstake: 'external_lst_unstake',
    AdRoTable: 'ad_ro_table',
    StakeButtonTopNav: 'stakenow_topnav_btn',
    DealOfTheWeek: 'dealoftheweek_btn',
    TopBarAnnouncement: 'topbar_announcement',

    // Asset profile page
    RewardOptionFilterChanged: 'reward_option_filter',
    CustodialSignup: 'custodial_signup',
}
