import { startCase } from 'lodash'
import { getEthFromWei } from 'utils/stakingAssistant'
import { TRANSFER_STATUS } from 'utils/stakingAssistant/constants'
import { SWING_STATUS_MESSAGES } from './constants'
import { TransactionStatus } from '../constants'

export const areEqualQuotes = (quoteA = null, quoteB = null) => {
    return (
        quoteA?.quote?.type === quoteB?.quote?.type &&
        quoteA?.quote?.integration === quoteB?.quote?.integration
    )
}

export const getQuoteInformation = (quoteObj = null) => {
    const quote = quoteObj?.quote

    if (!quote) return null

    return {
        quote,
        receive: {
            token: Number(getEthFromWei(quote?.amount)),
            usd: Number(quote?.amountUSD),
        },
        route: quoteObj,
        fees:
            quote?.fees?.map((f, i) => ({
                label: startCase(f.type).replace('Partner', 'Service'),
                usd: f?.amountUSD || 0,
                amount: getEthFromWei(f?.amount || 0),
                symbol: f?.tokenSymbol || '',
            })) ?? [],
    }
}

export const getSwingTransactionInformation = (step, status) => {
    // Initialize status and message
    let step1Status = TransactionStatus.Pending
    let step1Message = SWING_STATUS_MESSAGES.send.IDLE

    // Handle 'allowance', 'approve', and 'send' steps
    if (['allowance', 'approve', 'send'].includes(step)) {
        if (step === 'allowance' || step === 'approve') {
            // For 'allowance' and 'approve' steps
            step1Status = TransactionStatus.Pending
            step1Message = SWING_STATUS_MESSAGES.send.PENDING
        } else if (step === 'send') {
            // For 'send' step
            switch (status) {
                case TRANSFER_STATUS.SUCCESS:
                    step1Status = TransactionStatus.Success
                    break
                case TRANSFER_STATUS.FAILED:
                    step1Status = TransactionStatus.Failed
                    break
                case TRANSFER_STATUS.IDLE:
                    step1Status = TransactionStatus.Pending
                    break
                default:
                    step1Status = TransactionStatus.Loading
            }
            step1Message =
                SWING_STATUS_MESSAGES.send?.[status] ||
                SWING_STATUS_MESSAGES.send.CONFIRMING
        }
    } else if (step === 'bridge') {
        // Handle 'bridge' step
        step1Status = TransactionStatus.Success
        step1Message = SWING_STATUS_MESSAGES.send.SUCCESS
    }

    // Initialize status and message
    let step2Status = TransactionStatus.Pending
    let step2Message = SWING_STATUS_MESSAGES.bridge.IDLE

    // Handle 'bridge' step
    if (step === 'bridge') {
        switch (status) {
            case TRANSFER_STATUS.SUCCESS:
                step2Status = TransactionStatus.Success
                step2Message = SWING_STATUS_MESSAGES.bridge.SUCCESS
                break
            case TRANSFER_STATUS.FAILED:
                step2Status = TransactionStatus.Failed
                step2Message = SWING_STATUS_MESSAGES.bridge.FAILED
                break
            case TRANSFER_STATUS.CONFIRMING:
            case TRANSFER_STATUS.PENDING:
                step2Status = TransactionStatus.Loading
                step2Message = SWING_STATUS_MESSAGES.bridge.CONFIRMING
                break
            default:
                step2Status = TransactionStatus.Pending
                step2Message = SWING_STATUS_MESSAGES.bridge.IDLE
                break
        }
    }

    return {
        step1: { status: step1Status, message: step1Message },
        step2: { status: step2Status, message: step2Message },
        step3: {
            status:
                step === 'bridge' && status === TRANSFER_STATUS.SUCCESS
                    ? TransactionStatus.Success
                    : TransactionStatus.Pending,
            message: 'Staked',
        },
    }
}
