import { cosmos } from '@stakingrewards/staking-sdk'
import { checkIfWrongWallet } from 'components/staking/cosmos/utils'
import { getObjectFromJsonString } from 'utils/actions'
import { getERC20TokenBalance, getEthBalance } from 'utils/stakingAssistant'

export const fetchEthereumAssetWalletBalance = async (
    asset = null,
    walletAddress = ''
) => {
    try {
        const slug = asset?.slug

        if (checkIfWrongWallet('ethereum-2-0', walletAddress)) {
            return null
        }

        if (slug === 'ethereum-2-0') {
            return await getEthBalance(walletAddress)
        }

        const metaConfig = getObjectFromJsonString(asset?.metaWebApp)
        const erc20Address = metaConfig?.contract
        return erc20Address
            ? await getERC20TokenBalance(walletAddress, erc20Address)
            : null
    } catch (error) {
        console.error('Error fetching Ethereum balances:', error)
        return null
    }
}

export const fetchCosmosAssetWalletBalance = async (
    chainId = '',
    walletAddress = ''
) => {
    if (!chainId || !walletAddress) {
        return null
    }

    try {
        const [available, staked] = await Promise.all([
            cosmos.CosmosClient.getBalance(chainId, walletAddress),
            cosmos.CosmosClient.getStakedBalance(chainId, walletAddress),
        ])

        return {
            staked,
            available,
        }
    } catch (error) {
        console.error('Error fetching Cosmos balances:', error)
        return null
    }
}
