import { useEffect, useState } from 'react'
import { cosmos, events } from '@stakingrewards/staking-sdk'
import { usePaginatedItemsForStaking } from 'data'
import { useStakingModalContext } from 'contexts/stakingModalContext'
import { useCosmosStakingContext } from 'contexts/cosmosStakingContext'
import { SDK, useCombinedWalletsContext } from 'contexts/combinedWalletsContext'
import { TYPE_PROVIDER } from 'utils/constants'
import { getChainIdByAssetSlug } from '../utils'
import { useChain } from '@cosmos-kit/react'
import { getCosmosChainName } from './utils'

export const useMyDelegations = () => {
    const { inputAsset } = useStakingModalContext()
    const { primaryWallet } = useCombinedWalletsContext()
    const [myDelegations, setMyDelegations] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const walletAddress = primaryWallet?.address
    const chainId = getChainIdByAssetSlug(inputAsset?.slug)

    useEffect(() => {
        async function fetchMyDelegations() {
            setIsLoading(true)
            try {
                if (walletAddress) {
                    const response = await cosmos.CosmosClient.getDelegations(
                        chainId,
                        walletAddress
                    )
                    setMyDelegations(response)
                } else {
                    setMyDelegations([])
                }
            } catch (error) {
                console.error('Error fetching delegations:', error)
            } finally {
                setIsLoading(false)
            }
        }

        if (walletAddress && chainId) {
            fetchMyDelegations()
        }
    }, [chainId, primaryWallet?.connector, walletAddress])

    return { data: myDelegations, isLoading }
}

export const useRewardOptionsForMyDelegation = (
    myDelegations = [],
    searchQuery = ''
) => {
    const validatorAddresses =
        myDelegations?.map(delegation => delegation.validatorAddress) ?? []

    const { inputAsset } = useStakingModalContext()
    const { stakingType } = useCosmosStakingContext()

    const { data: batches, isValidating: isLoading } =
        usePaginatedItemsForStaking(
            TYPE_PROVIDER,
            {
                isActive: 'True',
                stakeableInApp: true,
                inputAsset: { slugs: [inputAsset?.slug] },
                typeKeys: [stakingType?.key],
                providers: {
                    isActive: 'True',
                    isClaimed: true,
                    ...(searchQuery ? { search: searchQuery } : {}),
                },
                validators: { addresses: validatorAddresses },
            },
            // NB: order's object parsing is broken in the backend, passing temporarily as string
            `{ metricKey_desc: "staked_tokens" }`,
            validatorAddresses?.length ?? 0
        )

    return { data: batches?.[0], isLoading }
}

export const useCosmosTransaction = (selectedRewardOptions = []) => {
    const { primaryWallet } = useCombinedWalletsContext()

    const { inputAsset } = useStakingModalContext()

    const [transaction, setTransaction] = useState(null)

    const { getOfflineSignerAmino, wallet } = useChain(
        getCosmosChainName(primaryWallet?.chain?.name)
    )

    const offlineSigner =
        primaryWallet?.sdk === SDK.CosmosKit
            ? wallet
                ? getOfflineSignerAmino()
                : null
            : null

    useEffect(() => {
        const fetchTransaction = async () => {
            const delegations =
                selectedRewardOptions
                    ?.map(option => ({
                        stakingAddress: option?.validators?.[0]?.address,
                        amount: option?.amount ?? 0,
                    }))
                    ?.filter(d => d.amount && d.stakingAddress) ??
                []?.filter(d => d.amount > 0) ??
                [] // remove all zero amounts that are not valid

            const chainId = primaryWallet?.chain?.name
            if (offlineSigner && chainId && delegations.length > 0) {
                try {
                    if (offlineSigner) {
                        let transaction = null
                        try {
                            transaction =
                                await cosmos.transactions.delegateTokensTx(
                                    chainId,
                                    offlineSigner,
                                    delegations
                                )
                        } catch (error) {
                            console.log('tx error', error)
                        }
                        setTransaction(transaction)
                    }
                } catch (error) {
                    // NB: If the wallet is empty, the transaction will fail as well
                    setTransaction(null)
                }
            } else {
                setTransaction(null)
            }
        }

        fetchTransaction()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        primaryWallet?.address,
        primaryWallet?.chain?.name,
        selectedRewardOptions,
        inputAsset?.slug,
    ])

    return transaction
}

export const useCosmosTransactionState = (isSuccess, isInProgress) => {
    const [status, setStatus] = useState('')
    const { eventEmitter, TransactionEvents } = events

    useEffect(() => {
        const handlePending = () => setStatus(TransactionEvents.PENDING)
        const handleWaitingForSignature = () =>
            setStatus(TransactionEvents.WAITING_FOR_SIGNATURE)
        const handleBroadcasting = () =>
            setStatus(TransactionEvents.BROADCASTING)
        const handleSuccess = () => setStatus(TransactionEvents.SUCCESS)
        const handleFailed = () => setStatus(TransactionEvents.FAILED)

        eventEmitter.on(TransactionEvents.PENDING, handlePending)
        eventEmitter.on(
            TransactionEvents.WAITING_FOR_SIGNATURE,
            handleWaitingForSignature
        )
        eventEmitter.on(TransactionEvents.BROADCASTING, handleBroadcasting)
        eventEmitter.on(TransactionEvents.SUCCESS, handleSuccess)
        eventEmitter.on(TransactionEvents.FAILED, handleFailed)

        return () => {
            eventEmitter.off(TransactionEvents.PENDING, handlePending)
            eventEmitter.off(
                TransactionEvents.WAITING_FOR_SIGNATURE,
                handleWaitingForSignature
            )
            eventEmitter.off(TransactionEvents.BROADCASTING, handleBroadcasting)
            eventEmitter.off(TransactionEvents.SUCCESS, handleSuccess)
            eventEmitter.off(TransactionEvents.FAILED, handleFailed)
        }
    }, [])

    if (isInProgress) {
        return status || TransactionEvents.PENDING
    }

    return isSuccess ? TransactionEvents.SUCCESS : TransactionEvents.FAILED
}
