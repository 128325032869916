import { createContext, useState, useContext, useEffect } from 'react'
import { EthereumStakingContextProvider } from './ethereumStakingContext'
import { CosmosStakingContextProvider } from './cosmosStakingContext'
import { StakeViewType } from 'components/staking/constants'
import { LavaStakingContextProvider } from './lavaStakingContext'
import { useRouter } from 'next/router'
import { LIQUID_STAKING_KEY } from 'utils/constants'
import { CosmosLiquidStakingContextProvider } from 'contexts/cosmosLiquidStakingContext'
import { BitcoinStakingContextProvider } from 'contexts/bitcoinStakingContext'

export const StakingModalContext = createContext({})

export const StakingModalContextProvider = ({
    defaultRewardOption = null,
    children,
}) => {
    const [selectedStakeView, setSelectedStakeView] = useState(
        StakeViewType.Stake
    )
    const router = useRouter()
    const type = router?.query?.type

    const isLiquidStaking = type === LIQUID_STAKING_KEY

    const ContextMap = {
        'ethereum-2-0': EthereumStakingContextProvider,
        cosmos: isLiquidStaking
            ? CosmosLiquidStakingContextProvider
            : CosmosStakingContextProvider,
        lava: LavaStakingContextProvider,
        bitcoin: BitcoinStakingContextProvider,
    }

    const [stakingEvents, setStakingEvents] = useState([])

    const [rewardOption, setRewardOption] = useState(defaultRewardOption)
    const [inputAsset, setInputAsset] = useState(
        rewardOption?.inputAssets?.[0] ?? null
    )

    useEffect(() => {
        setRewardOption(defaultRewardOption)
        setInputAsset(defaultRewardOption?.inputAssets?.[0] ?? null)
    }, [defaultRewardOption])

    const value = {
        selectedStakeView,
        setSelectedStakeView,
        rewardOption,
        setRewardOption,
        inputAsset,
        setInputAsset,
        stakingEvents,
        setStakingEvents,
    }

    const StakingProvider =
        ContextMap[inputAsset?.slug] ?? CosmosStakingContextProvider

    return (
        <StakingModalContext.Provider value={value}>
            <StakingProvider>{children}</StakingProvider>
        </StakingModalContext.Provider>
    )
}

export const useStakingModalContext = () => {
    return useContext(StakingModalContext)
}
