import Image from 'next/image'
import {
    getMetricPrecision,
    getMetricValueByKey,
    getObjectFromJsonString,
} from 'utils/actions'
import {
    MAX_TOOLTIP_TEXT_LENGTH,
    NO_DATA_INDICATOR,
    formatOutputNumber,
    isInvalidNumber,
    truncateString,
} from 'utils/formatter'
import { useAssetRewardCalculatorContext } from 'contexts/assetRewardCalculatorContext'
import { Skeleton, TooltipOnHover } from 'components/ui'
import { TOOLTIPS } from 'components/assets/constants'

export const RewardRatePart = ({ withLstRates = false }) => {
    const { asset, isLoading } = useAssetRewardCalculatorContext()

    const rewardRate = getMetricValueByKey(asset, 'reward_rate')

    const methodologyLink = getObjectFromJsonString(
        asset?.links ?? ''
    )?.methodology
    const isSrb = !!methodologyLink

    const inflationRate = getMetricValueByKey(asset, 'inflation_rate')
    const realRewardRate = getMetricValueByKey(asset, 'real_reward_rate')
    const rewardRateMetric = asset?.metrics?.find(
        m => m.metricKey === 'reward_rate'
    )
    const rewardRateTooltip =
        rewardRateMetric?.tooltip || TOOLTIPS.rewardRate.text

    return (
        <>
            <TooltipOnHover
                text={truncateString(
                    rewardRateTooltip ?? '',
                    MAX_TOOLTIP_TEXT_LENGTH
                )}
                overlay={{
                    title: TOOLTIPS?.rewardRate?.title ?? '',
                    allText: rewardRateTooltip,
                }}
                className='w-full'
            >
                <div className='flex flex-row items-center gap-x-2'>
                    {isSrb && (
                        <div className='min-w-[40px] max-w-[40px] min-h-[20px] max-h-[20px]'>
                            <Image
                                alt='SRB'
                                src={'/static/svg/srb.svg'}
                                fill
                                sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                            />
                        </div>
                    )}

                    <p className='text-contrast-4 font-bold text-sm leading-none'>
                        {`${isSrb ? 'SRB ' : ''}Reward Rate`}
                    </p>
                </div>
                <div className='my-4'>
                    {isLoading ? (
                        <Skeleton
                            width={'100%'}
                            height={'40px'}
                            borderRadius={8}
                        />
                    ) : (
                        <p className='text-contrast-4 font-bold text-[40px] leading-none'>
                            {!isInvalidNumber(rewardRate)
                                ? formatOutputNumber(rewardRate, {
                                      postfix: '%',
                                      spaceAfterNumber: true,
                                      allowEmpty: false,
                                      withAbbreviation: false,
                                      precision: getMetricPrecision(
                                          'reward_rate',
                                          rewardRate
                                      ),
                                      forcePrecision: false,
                                      showApproximation: false,
                                  })
                                : NO_DATA_INDICATOR}
                        </p>
                    )}
                </div>
            </TooltipOnHover>
            {withLstRates && (
                <div className='flex flex-row gap-x-7 mt-0 mb-4 sm:my-7'>
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIPS.realRewardRate.text ?? '',
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: TOOLTIPS?.realRewardRate?.title ?? '',
                            allText: TOOLTIPS?.realRewardRate?.text ?? '',
                        }}
                    >
                        <p className='text-contrast-3 font-bold text-[14px] leading-none mb-3 whitespace-nowrap'>
                            Real Reward Rate
                        </p>

                        {isLoading ? (
                            <Skeleton
                                width={'100%'}
                                height={'24px'}
                                borderRadius={8}
                            />
                        ) : (
                            <p className='text-contrast-4 font-bold text-[24px] leading-none whitespace-nowrap'>
                                {!isInvalidNumber(realRewardRate)
                                    ? formatOutputNumber(realRewardRate, {
                                          postfix: '%',
                                          spaceAfterNumber: true,
                                          allowEmpty: false,
                                          withAbbreviation: false,
                                          precision: getMetricPrecision(
                                              'real_reward_rate',
                                              realRewardRate
                                          ),
                                          forcePrecision: false,
                                          showApproximation: false,
                                      })
                                    : NO_DATA_INDICATOR}
                            </p>
                        )}
                    </TooltipOnHover>
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIPS.inflationRate.text ?? '',
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: TOOLTIPS?.inflationRate?.title ?? '',
                            allText: TOOLTIPS?.inflationRate?.text ?? '',
                        }}
                    >
                        <p className='text-contrast-3 font-bold text-[14px] leading-none mb-3 whitespace-nowrap'>
                            Inflation Rate
                        </p>
                        {isLoading ? (
                            <Skeleton
                                width={'100%'}
                                height={'24px'}
                                borderRadius={8}
                            />
                        ) : (
                            <p className='text-contrast-4 font-bold text-[24px] leading-none whitespace-nowrap'>
                                {!isInvalidNumber(inflationRate)
                                    ? formatOutputNumber(inflationRate, {
                                          postfix: '%',
                                          spaceAfterNumber: true,
                                          allowEmpty: false,
                                          withAbbreviation: false,
                                          precision: getMetricPrecision(
                                              'inflation_rate',
                                              inflationRate
                                          ),
                                          forcePrecision: false,
                                          showApproximation: false,
                                      })
                                    : NO_DATA_INDICATOR}
                            </p>
                        )}
                    </TooltipOnHover>
                </div>
            )}
        </>
    )
}
