import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTrendingAssets } from 'data'
import { getMetricValueByKey } from 'utils/actions'
import { isInvalidNumber } from 'utils/formatter'
import { getSectionNameById } from './utils'

export const useIsTrending = (assetPageViewsTrend = 0) => {
    const [minPageViewsTrend, setMinPageViewsTrend] = useState(null)

    const { data } = useTrendingAssets(null, 10, 0)

    const trendingAssets = data?.assets

    useEffect(() => {
        if (trendingAssets) {
            setMinPageViewsTrend(
                getMetricValueByKey(
                    trendingAssets?.[trendingAssets?.length - 1],
                    'page_views_trend'
                )
            )
        } else {
            setMinPageViewsTrend(null)
        }
    }, [trendingAssets])

    return (
        !isInvalidNumber(minPageViewsTrend) &&
        assetPageViewsTrend >= Math.max(minPageViewsTrend, 0)
    )
}

export const useSectionTitles = (symbol = '', isAVS) => {
    const router = useRouter()
    const [titles, setTitles] = useState([])

    useEffect(() => {
        const contentToParse = document.body.innerHTML
        const parser = new DOMParser()
        const root = parser.parseFromString(contentToParse, 'text/html')
        setTitles(
            Array.from(root.querySelectorAll('div[id^="section-"]'))
                .map(div => ({
                    key: div.id,
                    name: getSectionNameById(div.id, symbol, isAVS),
                }))
                .filter(({ name }) => !!name)
        )
    }, [router.asPath, symbol, isAVS])

    return titles
}
