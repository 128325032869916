import classNames from 'classnames'
import { getMetricValueByKey } from 'utils/actions'
import {
    DECIMALS_LIMIT_TOKEN,
    formatOutputNumber,
    getPrecisionBySignificantFigures,
    isInvalidNumber,
} from 'utils/formatter'
import { NumericInput } from 'components/forms'
import { LogoImage } from 'components/ui'
import { DEFAULT_STAKING_AMOUNT_USD } from 'components/calculator/constants'
import { getStakingAmountUsd } from 'components/calculator/utils'
import { getStakingAmountTokens } from '../info/assetRewardCalculator'

export const AssetInput = ({
    asset = null,
    amount = null,
    usd = false,
    onChange = () => {},
    darker = false,
    isLoading = false,
    placeholder = '',
}) => {
    const tokenPrice = getMetricValueByKey(asset, 'price')

    const minValue = usd ? getStakingAmountUsd(0, false, tokenPrice) : 0

    const maxValue = usd
        ? getMetricValueByKey(asset, 'marketcap') ?? DEFAULT_STAKING_AMOUNT_USD
        : getStakingAmountTokens(
              getMetricValueByKey(asset, 'staking_marketcap') ??
                  DEFAULT_STAKING_AMOUNT_USD,
              true,
              tokenPrice
          )

    const emptyInput = amount?.rounded === ''
    const convertedValue = !usd
        ? getStakingAmountUsd(
              emptyInput ? DEFAULT_STAKING_AMOUNT_USD : amount?.actual,
              emptyInput || usd,
              tokenPrice
          )
        : getStakingAmountTokens(
              emptyInput ? DEFAULT_STAKING_AMOUNT_USD : amount?.actual,
              emptyInput || usd,
              tokenPrice
          )

    return (
        <div
            className={classNames(
                'flex flex-row items-center rounded-lg w-full xl:w-[420px]',
                darker ? 'bg-contrast-0' : 'bg-contrast-1'
            )}
        >
            <div className='border-r border-solid border-contrast-2/40'>
                <div className='p-4 flex gap-1 items-center justify-between'>
                    <div className='flex flex-row items-center w-fit gap-2'>
                        <LogoImage
                            src={asset?.logoUrl}
                            alt={asset?.name}
                            slug={asset?.slug}
                            size={28}
                        />
                        <div className='hidden sm:block text-xs font-bold'>
                            <div className='text-contrast-4'>{asset?.name}</div>
                            <div className='text-contrast-3'>
                                {asset?.symbol}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-2 flex items-center w-full h-[64px]'>
                <div className='text-lg font-bold text-contrast-4 text-right'>
                    {usd ? '$' : ''}
                </div>
                <NumericInput
                    className='w-full'
                    inputClassName='!bg-transparent h-full w-full !py-0 !text-left !text-xl'
                    placeholder={placeholder}
                    type='number'
                    value={amount?.rounded ?? ''}
                    onValueChange={newValue => {
                        onChange(newValue)
                    }}
                    decimalsLimit={DECIMALS_LIMIT_TOKEN}
                    minValue={minValue}
                    maxValue={maxValue}
                    minErrorMessage={`Please enter amount above ${formatOutputNumber(
                        minValue,
                        {
                            prefix: usd ? '$' : '',
                            postfix: !usd ? asset?.symbol ?? '' : '',
                            spaceAfterNumber: true,
                            allowEmpty: false,
                            withAbbreviation: false,
                            forcePrecision: false,
                            showApproximation: true,
                        }
                    )}`}
                    maxErrorMessage={`Please enter an amount below ${formatOutputNumber(
                        maxValue,
                        {
                            prefix: usd ? '$' : '',
                            postfix: !usd ? asset?.symbol ?? '' : '',
                            spaceAfterNumber: true,
                            allowEmpty: false,
                            withAbbreviation: false,
                            forcePrecision: false,
                            showApproximation: true,
                        }
                    )} ${
                        !isInvalidNumber(maxValue)
                            ? `which represents the ${
                                  usd
                                      ? 'current MarketCap'
                                      : 'entire circulating supply'
                              } of ${asset?.symbol ?? 'the asset'}`
                            : ''
                    }`}
                />
                {!isLoading && (
                    <div className='hidden sm:block text-sm font-normal text-contrast-3 italic whitespace-nowrap px-3'>
                        {formatOutputNumber(convertedValue, {
                            precision: Math.max(
                                2,
                                getPrecisionBySignificantFigures(
                                    convertedValue,
                                    3
                                )
                            ),
                            prefix: !usd ? '$' : '',
                            postfix: usd ? asset?.symbol ?? '' : '',
                            spaceAfterNumber: true,
                            allowEmpty: false,
                            withAbbreviation: true,
                            forcePrecision: false,
                            showApproximation: true,
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}
