import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useScrollYParams } from 'hooks'
import { THEME, useTheme } from 'state'
import { Initialize, FeedbackButton, Overlay } from 'components/ui'
import { AnnouncementsContextProvider } from 'components/announcements'
import { Header } from 'components/headers'
import { Footer } from 'components/footer'
import { FloatingBanner } from 'components/ads'
import { CookieBanner } from './cookieBanner'
import styles from './ui.module.scss'
import { useEffect, useState } from 'react'
import { FloatingBannerProvider } from 'contexts/floatingBannerContext'

// ScrollControl relies on the window scroll
const PageControl = dynamic(
    () => import('components/pageControl/').then(module => module.PageControl),
    {
        ssr: false,
    }
)

export function Ui({ children }) {
    const theme = useTheme()
    const [isAnnouncementBarVisible, setIsAnnouncementBarVisible] =
        useState(true)

    const router = useRouter()

    useScrollYParams()

    const [isSmallScreen, setIsSmallScreen] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 640)
        }

        handleResize()
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const isStakeAppPage = String(router.asPath).startsWith('/stake-app')

    const isPortfolioDashboard = String(router.asPath).startsWith(
        '/terminal/dashboard'
    )
    const isPortfolioLandingPage =
        String(router.asPath).match(/terminal.+/) && !isPortfolioDashboard

    const withFooter =
        !isStakeAppPage && !isPortfolioLandingPage && !isPortfolioDashboard

    const isBrandedStakeApp = isStakeAppPage && router?.query?.locked === 'true'

    return (
        <FloatingBannerProvider>
            <div
                id='ui'
                className={classNames(styles.ui, {
                    [styles.srEthBg]:
                        String(router.asPath).startsWith('/sreth') ||
                        String(router.asPath).startsWith('/404'),
                    ['bg-[linear-gradient(to_bottom,#002195,#02A4FF75)]']:
                        isStakeAppPage && theme === THEME.DARK,
                    'bg-[linear-gradient(to_bottom,var(--c-wb),#FEA73F00,#9C1BFA33,#02A4FF50)]':
                        isStakeAppPage &&
                        theme === THEME.LIGHT &&
                        !isBrandedStakeApp,
                    'bg-wb': isPortfolioDashboard && theme === THEME.DARK,
                    '!mt-[50px]': isAnnouncementBarVisible,
                })}
                data-theme={theme}
            >
                <Initialize />
                {children}
                {!isSmallScreen && <FeedbackButton />}
                {!isStakeAppPage && <FloatingBanner />}
                {withFooter && <PageControl />}
                <AnnouncementsContextProvider>
                    <Header
                        isPortfolioLandingPage={isPortfolioLandingPage}
                        isPortfolioPage={isPortfolioDashboard}
                        isStakeAppPage={isStakeAppPage}
                        isAnnouncementBarVisible={isAnnouncementBarVisible}
                        setIsAnnouncementBarVisible={
                            setIsAnnouncementBarVisible
                        }
                    />
                    <Overlay />
                    {withFooter && <Footer />}
                </AnnouncementsContextProvider>
                <CookieBanner />
            </div>
        </FloatingBannerProvider>
    )
}
