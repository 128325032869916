import classNames from 'classnames'
import { useTableFiltersContext } from 'contexts/tableFiltersContext'
import { GroupSize, IconStack, ItemWithLogo } from 'components/ui'
import { Filter } from './filter'
import { AssetFilter } from './assetFilter'

import styles from './typesFilter.module.scss'

const getScrollableAreaHeightPx = (typesCount = 0, ecosystemsCount = 0) => {
    const optionHeightPx = 38
    const bottomPaddingPx = 12
    const headerHeightPx = 50
    const typesHeight =
        typesCount > 0
            ? headerHeightPx + optionHeightPx * typesCount + bottomPaddingPx
            : 0
    const ecosystemsHeight =
        ecosystemsCount > 0
            ? headerHeightPx +
              optionHeightPx * ecosystemsCount +
              bottomPaddingPx
            : 0
    return typesHeight + ecosystemsHeight
}

export const TypesFilter = ({ withAssetFilter = false }) => {
    const {
        params,
        categories: options,
        setParams,
        resetParams,
    } = useTableFiltersContext()

    const { ecosystemTypes, types } = options.reduce(
        (acc, category) => {
            const keyGroup = String(category.key).endsWith('ecosystem')
                ? 'ecosystemTypes'
                : 'types'
            acc[keyGroup].push(category)
            return acc
        },
        { ecosystemTypes: [], types: [] }
    )

    const categoryFilteredBy =
        (params?.asset?.symbol || params?.search) &&
        params?.category?.name === 'All'
            ? params?.search
            : [params?.search, params?.category?.name]
                  .filter(Boolean)
                  .join(', ')

    const filteredBy = (
        <div className={styles.filteredBy}>
            {Boolean(params?.asset?.symbol) && (
                <div className={styles.asset} title={params?.asset?.name}>
                    <ItemWithLogo
                        className={styles.larger}
                        iconUrl={
                            params?.asset?.logoUrl || params?.asset?.logo_url
                        }
                        name={''}
                        details={params?.asset?.symbol}
                        alt={params?.asset?.symbol}
                        slug={params?.asset?.slug}
                        iconSize={20}
                        sizeType={GroupSize.Medium}
                        withDetails={true}
                    />
                </div>
            )}
            <p>
                {params?.asset?.symbol && categoryFilteredBy
                    ? `, ${categoryFilteredBy}`
                    : categoryFilteredBy}
            </p>
        </div>
    )

    return (
        <Filter
            label='Filtered by'
            icon='search'
            withSearch
            selectedName={filteredBy}
            height={`${
                (withAssetFilter ? 200 : 0) +
                getScrollableAreaHeightPx(types.length, ecosystemTypes.length)
            }px`}
        >
            {withAssetFilter && (
                <AssetFilter
                    searchTerms={params?.search}
                    onSelect={asset => {
                        setParams({
                            ...params,
                            asset,
                            search: '',
                        })
                    }}
                    withSeparator={
                        ecosystemTypes.length > 0 || types.length > 0
                    }
                    selectedAsset={params?.asset}
                />
            )}
            {types.length > 0 && (
                <div
                    className={classNames(styles.optionsGroup, {
                        [styles.withSeparator]: ecosystemTypes.length > 0,
                    })}
                >
                    <p className={styles.label}>Type</p>
                    {types.map((t, idx) => (
                        <div
                            key={`filter-types-${t.key}-${idx}`}
                            className={styles.optionWrap}
                            onClick={() => {
                                resetParams(t)
                            }}
                        >
                            <p
                                className={classNames(styles.option, {
                                    [styles.selected]:
                                        t.key === params?.category?.key,
                                })}
                            >
                                {t.name}
                            </p>
                        </div>
                    ))}
                </div>
            )}
            {ecosystemTypes.length > 0 && (
                <div className={styles.optionsGroup}>
                    <p className={styles.label}>Ecosystem</p>
                    {ecosystemTypes.map((t, idx) => (
                        <div
                            key={`filter-ecosystem-types-${t.key}-${idx}`}
                            className={styles.optionWrap}
                            onClick={() => {
                                resetParams(t)
                            }}
                        >
                            <div className={styles.iconsWrap}>
                                {Boolean(t?.icons?.length) && (
                                    <IconStack
                                        iconSize={16}
                                        overlap={12}
                                        icons={t?.icons ?? []}
                                    />
                                )}
                            </div>
                            <p
                                className={classNames(styles.option, {
                                    [styles.selected]:
                                        t.key === params?.category?.key,
                                })}
                            >
                                {t.name}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </Filter>
    )
}
