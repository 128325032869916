import { useMemo, useRef, useState, useEffect, useCallback } from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
    Legend,
    ReferenceLine,
    Label,
} from 'recharts'
import { getMaxWidthOfString } from 'utils/actions'
import { convertTime } from 'utils/converter'
import { formatTime, formatOutputNumber } from 'utils/formatter'

import styles from './chart.module.scss'
import classNames from 'classnames'

// Inline configuration for recharts
const axisLine = {
    stroke: 'var(--c-contrast-3)',
    strokeWidth: 1,
}

const axisLabel = {
    fontSize: 10,
    fill: 'var(--c-fg)',
    fontWeight: 'bold',
}

const hoverLineConfig = {
    ...axisLine,
    strokeDasharray: '2 2',
}

const defaultLegendConfig = {
    top: 0,
    left: 0,
    marginBottom: 10,
    verticalAlign: 'top',
}

const refText = {
    ...axisLabel,
    fontSize: 10,
}

const LegendCircle = ({ size = 12, color = '' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size * 2} ${size * 2}`}
            className={styles.legendCircle}
        >
            <path
                fill={color}
                cx={size}
                cy={size}
                type='circle'
                transform={`translate(${size}, ${size})`}
                d={`M${size},0A${size},${size},0,1,1,-${size},0A${size},${size},0,1,1,${size},0`}
            />
        </svg>
    )
}

const getSeriesConfig = (seriesToShow = []) => {
    let config = {}

    if (seriesToShow.includes('totalUsdValues')) {
        config.totalUsdValues = {
            name: 'Non-Compounded',
            color: 'var(--c-blue)',
        }
    }

    if (seriesToShow.includes('totalUsdValuesCompounded')) {
        config.totalUsdValuesCompounded = {
            name: 'Compounded',
            color: 'var(--c-cyan)',
        }
    }

    if (seriesToShow.includes('sellTotal')) {
        config.sellTotal = {
            name: 'Selling Rewards',
            color: 'var(--c-yellow)',
        }
    }

    if (seriesToShow.includes('notStaking')) {
        config.notStaking = {
            name: 'Not Staking',
            color: 'var(--c-magenta)',
        }
    }

    return config
}
const Chart = ({
    data = [],
    domainY = [0, 0],
    asset = null,
    yearsSpan = 1,
    usd = true,
    stakingTimeReference = {
        x: 0,
        label: '',
    },
    seriesToShow = [],
    legendConfig = defaultLegendConfig,
}) => {
    const chartRef = useRef(null)
    const [chartWidth, setChartWidth] = useState(300)

    useEffect(() => {
        if (chartRef.current) {
            setChartWidth(chartRef.current.clientWidth)
        }
    }, [chartRef])

    const seriesConfig = getSeriesConfig(seriesToShow)

    const referenceLabel = useCallback(
        ({ viewBox }) => {
            const { x, y } = viewBox

            const dataObj = data?.[Math.floor(stakingTimeReference?.x)]

            const CIRCLE_SIZE = 8
            const OFFSET_FROM_LINE = 10
            const OFFSET_FROM_CIRCLE = 12
            const Y_AXIS_WIDTH = 70

            const maxWidth =
                Math.max(
                    ...Object.keys(dataObj).map(key =>
                        getMaxWidthOfString(
                            formatOutputNumber(dataObj?.[key], {
                                prefix: usd ? '$' : '',
                                postfix: !usd ? asset?.symbol ?? '' : '',
                                spaceAfterNumber: true,
                                allowEmpty: false,
                                withAbbreviation: false,
                                precision: usd ? 2 : null,
                                forcePrecision: false,
                                showApproximation: false,
                            }),
                            'bold 11px var(--f-primary)'
                        )
                    )
                ) +
                OFFSET_FROM_CIRCLE +
                CIRCLE_SIZE

            return (
                <svg
                    x={
                        x - maxWidth < Y_AXIS_WIDTH
                            ? x + OFFSET_FROM_LINE
                            : x - maxWidth
                    }
                    y={y}
                >
                    {Object.keys(seriesConfig).map((key, index) => (
                        <g
                            key={`ref-legend-item-${index}`}
                            transform={`translate(0, ${16 * index})`}
                        >
                            <LegendCircle
                                size={10}
                                color={seriesConfig[key].color}
                            />
                            <text x={OFFSET_FROM_CIRCLE} y={8} {...refText}>
                                {formatOutputNumber(
                                    data?.[
                                        Math.floor(stakingTimeReference?.x)
                                    ]?.[key],
                                    {
                                        prefix: usd ? '$' : '',
                                        postfix: !usd
                                            ? asset?.symbol ?? ''
                                            : '',
                                        spaceAfterNumber: true,
                                        allowEmpty: false,
                                        withAbbreviation: false,
                                        precision: usd ? 2 : null,
                                        forcePrecision: false,
                                        showApproximation: false,
                                    }
                                )}
                            </text>
                        </g>
                    ))}
                </svg>
            )
        },
        [asset?.symbol, data, seriesConfig, usd, stakingTimeReference?.x]
    )

    const refLabelPosition = useMemo(() => {
        const lineX = Number(
            (Number(stakingTimeReference?.x) /
                convertTime(yearsSpan, 'years', 'days')) *
                chartWidth
        )
        const labelWidth = getMaxWidthOfString(
            stakingTimeReference?.label,
            'bold 11px var(--f-primary)'
        )

        const isLeft = Number(lineX + labelWidth) > chartWidth

        return {
            position: isLeft ? 'insideBottomRight' : 'bottom',
            dy: isLeft ? 15 : 0,
        }
    }, [
        chartWidth,
        stakingTimeReference?.label,
        stakingTimeReference?.x,
        yearsSpan,
    ])

    return (
        <div className={styles.chart} ref={chartRef}>
            <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 0,
                        left: usd ? -10 : 0,
                        bottom: 0,
                    }}
                >
                    <XAxis
                        type='category'
                        axisLine={axisLine}
                        tick={{ ...axisLabel, dy: 10, dx: -8 }}
                        tickLine={false}
                        dataKey='day'
                        padding={{ left: 5, right: 0 }}
                        tickFormatter={(value, index) => {
                            return index === 0
                                ? 'Today'
                                : String(value) === String(data?.length - 1)
                                ? formatTime(yearsSpan, 'Years', 0, true)
                                : ''
                        }}
                    />{' '}
                    <YAxis
                        type='number'
                        axisLine={axisLine}
                        tickLine={false}
                        tickFormatter={value => {
                            // Show only minimum and maximum
                            if (!domainY?.includes(value)) {
                                return ''
                            }

                            if (value > 1e12) {
                                return usd
                                    ? `>$1t`
                                    : `>1t${
                                          asset?.symbol
                                              ? ` ${asset?.symbol}`
                                              : ''
                                      }`
                            }

                            const postfixedNumber = formatOutputNumber(
                                value.toPrecision(3),
                                {
                                    precision: 3,
                                    allowEmpty: false,
                                    withAbbreviation: true,
                                    forcePrecision: false,
                                    showApproximation: false,
                                }
                            )
                            return usd
                                ? `$${postfixedNumber}`
                                : `${postfixedNumber}${
                                      asset?.symbol ? ` ${asset?.symbol}` : ''
                                  }`
                        }}
                        tick={axisLabel}
                        padding={{ top: 10, bottom: 5 }}
                        domain={domainY}
                    />
                    <Tooltip
                        content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className={styles.tooltip}>
                                        <p className={styles.tooltipHeader}>
                                            {label === 0
                                                ? 'Today'
                                                : `${label} days`}
                                        </p>
                                        {payload.map((entry, index) => (
                                            <p
                                                className={styles.tooltipItem}
                                                key={`tooltip-item-${index}`}
                                            >
                                                <span
                                                    style={{
                                                        color: entry.color,
                                                    }}
                                                >{`${entry.name}: `}</span>
                                                <span>
                                                    {formatOutputNumber(
                                                        entry.value,
                                                        {
                                                            prefix: usd
                                                                ? '$'
                                                                : '',
                                                            postfix: !usd
                                                                ? asset?.symbol ??
                                                                  ''
                                                                : '',
                                                            spaceAfterNumber: true,
                                                            allowEmpty: false,
                                                            withAbbreviation: false,
                                                            precision: usd
                                                                ? 2
                                                                : null,
                                                            forcePrecision: false,
                                                            showApproximation: false,
                                                        }
                                                    )}
                                                </span>
                                            </p>
                                        ))}
                                    </div>
                                )
                            }
                            return null
                        }}
                        cursor={hoverLineConfig}
                    />
                    <Legend
                        wrapperStyle={legendConfig}
                        layout={legendConfig.layout ?? 'vertical'}
                        align='center'
                        verticalAlign={legendConfig.verticalAlign}
                        content={({ payload }) => (
                            <ul
                                className={classNames(
                                    styles.legend,
                                    legendConfig.className
                                )}
                            >
                                {payload.map((entry, index) => (
                                    <li key={`legend-item-${index}`}>
                                        <LegendCircle
                                            size={14}
                                            color={entry.color}
                                        />
                                        <span className={styles.legendItem}>
                                            {entry.value}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    />
                    <ReferenceLine
                        {...hoverLineConfig}
                        x={Math.round(Number(stakingTimeReference?.x ?? 0))}
                        label={
                            <Label fontSize={10} {...refLabelPosition}>
                                {stakingTimeReference?.label}
                            </Label>
                        }
                    />
                    <ReferenceLine
                        x={Math.round(Number(stakingTimeReference?.x ?? 0))}
                        label={referenceLabel}
                        strokeWidth={0}
                        isFront
                    />
                    {Object.keys(seriesConfig).map(key => (
                        <Line
                            key={`lines-${key}`}
                            type='monotone'
                            dot={false}
                            name={seriesConfig[key].name}
                            dataKey={key}
                            stroke={seriesConfig[key].color}
                            strokeWidth={2}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Chart
