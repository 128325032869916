import DOMPurify from 'isomorphic-dompurify'
import { REFRESH_INTERVAL_MS, useProfileFaq, useProfileMetrics } from 'data'
import { TYPE_PROVIDER } from 'utils/constants'
import { getMetricValueByKey, trimUnescapedHtml } from 'utils/actions'
import { MAX_TOOLTIP_TEXT_LENGTH, truncateString } from 'utils/formatter'
import { Link, LogoImage, TooltipOnHover } from 'components/ui'
import { TOOLTIPS } from 'components/providers/profile/vspSection'
import { getRating } from 'components/providers/profile/utils'

export const VerificationScore = ({ slug = '' }) => {
    const { data: providerWithMetrics, isLoading } = useProfileMetrics(
        TYPE_PROVIDER,
        slug,
        ['vsp'],
        REFRESH_INTERVAL_MS
    )

    const vspValue = getMetricValueByKey(providerWithMetrics, 'vsp')

    if (!vspValue || isLoading) {
        return null
    }

    return (
        <TooltipOnHover
            text={truncateString(
                TOOLTIPS?.srRating.text ?? '',
                MAX_TOOLTIP_TEXT_LENGTH
            )}
            overlay={{
                title: TOOLTIPS?.srRating?.title ?? '',
                allText: TOOLTIPS?.srRating?.text ?? '',
            }}
        >
            <div className='flex flex-row gap-x-1 items-center'>
                <p className='text-xs font-bold text-contrast-4'>
                    {getRating(vspValue)}
                </p>
                <p className='text-xs font-bold text-contrast-4'>VSP Score</p>
                <span className='h-[12px] w-[12px] rounded-full bg-[var(--c-contrast-2)] text-center align-top font-bold text-[8px] text-[var(--c-lightest)] leading-[12px] top-[-4px] hidden md:block'>
                    i
                </span>
            </div>
        </TooltipOnHover>
    )
}

export const ProviderInformation = ({ provider = null }) => {
    const { data: providerFaq, isLoading } = useProfileFaq(
        TYPE_PROVIDER,
        provider?.slug,
        REFRESH_INTERVAL_MS
    )

    const providerDescription = providerFaq?.faqs?.find(
        ({ question }) => question === `What is ${provider?.name}?`
    )?.answer

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row items-center gap-x-2 mb-2'>
                <p className='text-contrast-4 text-sm font-bold'>About</p>
                <Link
                    className='flex flex-row items-center gap-x-2 hover:opacity-80'
                    href={`/provider/${provider?.slug}`}
                >
                    <LogoImage
                        src={provider?.logoUrl}
                        alt={provider?.name}
                        slug={provider?.slug}
                        size={16}
                    />
                    <p className='text-contrast-4 text-sm font-bold'>
                        {provider?.name}
                    </p>
                </Link>
                {provider?.isVerified && (
                    <span className='icon icon-og icon-vsp-extended !w-[42px]' />
                )}
                <VerificationScore slug={provider?.slug} />
            </div>
            <p
                className='text-contrast-3 text-sm leading-5 mb-2'
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        trimUnescapedHtml(providerDescription ?? ''),
                        {
                            ADD_ATTR: ['target'],
                        }
                    ),
                }}
            />
        </div>
    )
}
