import { useClientWidthMediaPredicate } from 'hooks'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { isMetricWithExpansion } from 'utils/actions'
import {
    ALL_KEY,
    AVS_KEY,
    BITCOIN_AND_OTHERS_KEY,
    LIQUID_STAKING_KEY,
    STABLECOINS_ASSET_CATEGORY,
    STABLECOIN_KEY,
} from 'utils/constants'
import {
    COLUMN_WIDTH,
    MetricGroup,
    SupportedItemsMetricGroup,
} from 'components/metrics'
import { PegDeviationMetricGroup } from 'components/metrics/metricGroup'
import {
    TOOLTIPS,
    MAIN_ASSET_METRIC_GROUPS,
    STABLECOINS_ASSET_METRIC_GROUPS,
    LST_MAIN_ASSET_METRIC_GROUPS,
    AVS_ASSET_METRIC_GROUPS,
    STABLECOIN_ASSET_METRIC_GROUPS,
    BITCOIN_AND_OTHERS_METRIC_GROUPS,
} from '../constants'
import { NetStakingFlowGroup } from './netStakingFlowGroup'
import { getAssetSortByOptionsPerCategory } from './utils'
import { StakingButtonInLstArchive } from './stakingButtonInLstArchive'
import { RewardRateRange } from 'components/metrics/rewardRateRange'
import { ProviderCountMetric } from 'components/metrics/providerCountMetric'

import styles from './assetMetricsRow.module.scss'

export const AssetMetricsRow = ({
    item = null,
    netStakingFlowItem = null,
    index = '',
    isLoading = false,
    providerCount = null,
    stablecoinData = null,
}) => {
    const { queryParams } = useTableArchiveContext()

    const categoryKey = queryParams?.category?.key
    const sortByKey = queryParams?.sortBy?.key
    const timeframeKey = queryParams?.timeframe?.key

    const showChart = useClientWidthMediaPredicate(1200)
    const showProviders = useClientWidthMediaPredicate(1180)

    if (categoryKey === STABLECOINS_ASSET_CATEGORY.key) {
        return (
            <div className={styles.assetMetricsRow}>
                {STABLECOINS_ASSET_METRIC_GROUPS?.map(metricGroup => (
                    <div
                        className={styles.metric}
                        key={`${item?.id}-${metricGroup?.key ?? ''}-${index}`}
                        style={{
                            width: metricGroup?.width ?? COLUMN_WIDTH,
                        }}
                    >
                        <MetricGroup
                            metricGroup={metricGroup}
                            item={item}
                            timeframeKey={timeframeKey}
                            isLoading={isLoading}
                            hasExpansion={isMetricWithExpansion(
                                metricGroup?.key
                            )}
                            isSortable={getAssetSortByOptionsPerCategory(
                                ALL_KEY
                            )
                                .map(category => category.key)
                                .includes(metricGroup?.key)}
                        />
                    </div>
                ))}
                <SupportedItemsMetricGroup
                    items={item?.rewardOptionsWithAssetAsInput
                        ?.map(ro => ro?.providers?.[0])
                        ?.filter(provider => Boolean(provider))}
                    label='SR Supported Providers'
                    tooltipTextObj={TOOLTIPS.providers}
                    isLoading={isLoading}
                />
            </div>
        )
    }

    if (categoryKey === LIQUID_STAKING_KEY) {
        return (
            <div className={styles.assetMetricsRow}>
                {LST_MAIN_ASSET_METRIC_GROUPS.map(metricGroup => (
                    <div
                        className={styles.metric}
                        key={`${item?.id}-${metricGroup?.key ?? ''}-${index}`}
                        // Static width to keep alignment in columns between rows
                        style={{
                            minWidth: metricGroup?.width ?? COLUMN_WIDTH,
                            maxWidth: metricGroup?.width ?? COLUMN_WIDTH,
                        }}
                    >
                        {metricGroup?.key === 'peg_deviation' ? (
                            <PegDeviationMetricGroup
                                metricGroup={metricGroup}
                                item={item}
                                timeframeKey={timeframeKey}
                                highlighted={metricGroup?.key === sortByKey}
                                isLoading={isLoading}
                            />
                        ) : (
                            <MetricGroup
                                metricGroup={metricGroup}
                                item={item}
                                timeframeKey={timeframeKey}
                                highlighted={metricGroup?.key === sortByKey}
                                isLoading={isLoading}
                                hasExpansion={isMetricWithExpansion(
                                    metricGroup?.key
                                )}
                                isSortable={getAssetSortByOptionsPerCategory(
                                    ALL_KEY
                                )
                                    .map(category => category.key)
                                    .includes(metricGroup?.key)}
                            />
                        )}
                    </div>
                ))}
                {showChart && (
                    <div className='w-[140px]'>
                        <NetStakingFlowGroup
                            netStakingFlowItem={netStakingFlowItem}
                            large={false}
                            isLoading={isLoading}
                            width={'140px'}
                            height={'18px'}
                        />
                    </div>
                )}
                <div className='min-w-[90px] max-w-[90px]'>
                    <StakingButtonInLstArchive asset={item} />
                </div>
            </div>
        )
    }

    if (categoryKey === AVS_KEY) {
        return (
            <div className={styles.assetMetricsRow}>
                {AVS_ASSET_METRIC_GROUPS.map(metricGroup => (
                    <div
                        className={styles.metric}
                        key={`${item?.id}-${metricGroup?.key ?? ''}-${index}`}
                        // Static width to keep alignment in columns between rows
                        style={{
                            minWidth: metricGroup?.width ?? COLUMN_WIDTH,
                            maxWidth: metricGroup?.width ?? COLUMN_WIDTH,
                        }}
                    >
                        <MetricGroup
                            metricGroup={metricGroup}
                            item={item}
                            timeframeKey={timeframeKey}
                            highlighted={metricGroup?.key === sortByKey}
                            isLoading={isLoading}
                            hasExpansion={isMetricWithExpansion(
                                metricGroup?.key
                            )}
                            isSortable={true}
                        />
                    </div>
                ))}
            </div>
        )
    }

    if (categoryKey === STABLECOIN_KEY) {
        return (
            <div className={styles.assetMetricsRow}>
                {STABLECOIN_ASSET_METRIC_GROUPS.map(metricGroup => (
                    <div
                        className={styles.metric}
                        key={`${item?.id}-${metricGroup?.key ?? ''}-${index}`}
                        // Static width to keep alignment in columns between rows
                        style={{
                            minWidth: metricGroup?.width ?? COLUMN_WIDTH,
                            maxWidth: metricGroup?.width ?? COLUMN_WIDTH,
                        }}
                    >
                        <MetricGroup
                            metricGroup={metricGroup}
                            item={item}
                            timeframeKey={timeframeKey}
                            highlighted={metricGroup?.key === sortByKey}
                            isLoading={isLoading}
                            hasExpansion={isMetricWithExpansion(
                                metricGroup?.key
                            )}
                            isSortable={true}
                        />
                    </div>
                ))}
                <RewardRateRange data={stablecoinData} />
                {showProviders && (
                    <ProviderCountMetric
                        providerCount={providerCount}
                        data={stablecoinData}
                    />
                )}
            </div>
        )
    }

    if (categoryKey === BITCOIN_AND_OTHERS_KEY) {
        return (
            <div className={styles.assetMetricsRow}>
                {BITCOIN_AND_OTHERS_METRIC_GROUPS.map(metricGroup => (
                    <div
                        className={styles.metric}
                        key={`${item?.id}-${metricGroup?.key ?? ''}-${index}`}
                        // Static width to keep alignment in columns between rows
                        style={{
                            minWidth: metricGroup?.width ?? COLUMN_WIDTH,
                            maxWidth: metricGroup?.width ?? COLUMN_WIDTH,
                        }}
                    >
                        <MetricGroup
                            metricGroup={metricGroup}
                            item={item}
                            timeframeKey={timeframeKey}
                            highlighted={metricGroup?.key === sortByKey}
                            isLoading={isLoading}
                            hasExpansion={isMetricWithExpansion(
                                metricGroup?.key
                            )}
                            isSortable={true}
                        />
                    </div>
                ))}
                <RewardRateRange data={stablecoinData} />
                <ProviderCountMetric
                    providerCount={providerCount}
                    data={stablecoinData}
                />
            </div>
        )
    }

    return (
        <div className={styles.assetMetricsRow}>
            {MAIN_ASSET_METRIC_GROUPS.map(metricGroup => (
                <div
                    className={styles.metric}
                    key={`${item?.id}-${metricGroup?.key ?? ''}-${index}`}
                    // Static width to keep alignment in columns between rows
                    style={{
                        width: metricGroup?.width ?? COLUMN_WIDTH,
                    }}
                >
                    <MetricGroup
                        metricGroup={metricGroup}
                        item={item}
                        timeframeKey={timeframeKey}
                        highlighted={metricGroup?.key === sortByKey}
                        isLoading={isLoading}
                        hasExpansion={isMetricWithExpansion(metricGroup?.key)}
                        isSortable={getAssetSortByOptionsPerCategory(ALL_KEY)
                            .map(category => category.key)
                            .includes(metricGroup?.key)}
                    />
                </div>
            ))}
            {showChart && (
                <div className={styles.metric}>
                    <NetStakingFlowGroup
                        netStakingFlowItem={netStakingFlowItem}
                        large={false}
                        isLoading={isLoading}
                        width={'140px'}
                        height={'18px'}
                    />
                </div>
            )}
        </div>
    )
}
