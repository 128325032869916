import { useRef } from 'react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useMediaPredicate } from 'react-media-hook'
import { REFRESH_INTERVAL_ASSET_PRICE_MS, useProfileMetrics } from 'data'
import { SMART_CONTRACT_KEY, TYPE_ASSET } from 'utils/constants'
import { FaqSection } from 'components/faqSection'
import { PerformanceSection } from 'components/performanceChart'
import { Metrics } from './profile/metrics'
import { JournalSection } from './profile/journalSection'
import { getAllPerformanceChartSeriesConfig } from './profile/utils'
import { CalculatorSection } from './profile/calculatorSection'
import { UnderMaintenanceSection } from './profile/underMaintenanceSection'
import { AssetInformation } from './profile/assetInformation'
import { RewardOptionsSection } from 'components/assets/profile/rewardOptionsSection'

const HeroSection = dynamic(
    () => import('./profile/heroSection').then(module => module.HeroSection),
    {
        ssr: false,
    }
)

export const SmartContractAssetProfilePage = ({
    asset = null,
    query = null,
    hasJournalPosts = false,
    rewardOptionCategories,
}) => {
    const isTablet = useMediaPredicate('(min-width: 768px)')

    const { data: assetWithPrice, isLoading: isLoadingPrice } =
        useProfileMetrics(
            TYPE_ASSET,
            asset.slug,
            ['price'],
            REFRESH_INTERVAL_ASSET_PRICE_MS
        )

    const pageRef = useRef()

    const faqRef = useRef(null)
    const calculatorRef = useRef(null)

    return (
        <div
            ref={pageRef}
            className={classNames(
                'pt-[var(--p-page-top)] px-[var(--p-page-sides)] pb-0 mb-24'
            )}
        >
            <div className='container'>
                <HeroSection asset={asset} hasRewardOptionsTable={true} />
                <div className='flex flex-col w-full gap-y-12'>
                    {asset?.underMaintenance ? (
                        <UnderMaintenanceSection asset={asset} />
                    ) : (
                        <AssetInformation
                            faqRef={faqRef?.current}
                            calculatorRef={calculatorRef?.current}
                            asset={asset}
                        />
                    )}
                    <RewardOptionsSection
                        asset={asset}
                        query={query}
                        rewardOptionCategories={[SMART_CONTRACT_KEY]}
                    />
                    <PerformanceSection
                        id={asset?.id}
                        symbol={asset?.symbol}
                        name={asset?.name}
                        type={TYPE_ASSET}
                        allSeriesConfig={getAllPerformanceChartSeriesConfig(
                            asset?.symbol,
                            asset?.tags
                        )}
                        defaultMetricKeys={['staked_tokens', 'fee_revenue']}
                        slug={asset?.slug}
                        isTablet={isTablet}
                        maxSelectedMetricsCount={3}
                    />
                    <Metrics asset={asset} />
                    <div ref={calculatorRef}>
                        <CalculatorSection
                            asset={asset}
                            scenariosExpandable={false}
                        />
                    </div>
                    <div ref={faqRef}>
                        <FaqSection item={asset} type={TYPE_ASSET} />
                    </div>
                    {hasJournalPosts && (
                        <JournalSection
                            slug={asset?.slug}
                            assetName={asset?.name}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
