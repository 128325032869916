import {
    useCallback,
    useState,
    createContext,
    useContext,
    useMemo,
} from 'react'
import {
    formatInputNumber,
    isInvalidNumber,
    EMPTY_STAKING_TIME_OBJECT,
    DECIMALS_LIMIT_USD,
    DECIMALS_LIMIT_TOKEN,
} from 'utils/formatter'
import { FORMULA_TYPE_REWARD, FORMULA_TYPE_INFLATION } from './constants'
import { getStakingAmountTokens } from './utils'
import {
    useInflationFormulaWithMetrics,
    useRewardFormulaWithMetrics,
    useAssetFromUrl,
    useRewardOptionData,
    useAssetPrice,
} from './hooks'

const ReactContext = createContext()

export const CalculatorContext = ({
    noRedirect = false,
    defaultAssetSlug = null,
    children,
}) => {
    const [asset, setAsset] = useAssetFromUrl(noRedirect, defaultAssetSlug)
    const {
        option,
        setOption,
        validator,
        defaultOption,
        existingRewardOptionCategories,
    } = useRewardOptionData(asset)

    const [amount, setAmount] = useState({
        actual: '',
        rounded: '',
    })
    const [usd, setUsd] = useState(true)
    const [stakingTime, setStakingTime] = useState(EMPTY_STAKING_TIME_OBJECT)
    const [expectedPrice, setExpectedPrice] = useState('')
    const [rewardFormulaWithMetrics, setRewardFormulaWithMetrics] =
        useRewardFormulaWithMetrics(option)
    const [inflationFormulaWithMetrics, setInflationFormulaWithMetrics] =
        useInflationFormulaWithMetrics(asset)
    const [customInitialTokenPrice, setCustomInitialTokenPrice] = useState('')

    const resetStakingAmountAndUsd = () => {
        setAmount({ actual: '', rounded: '' })
        setUsd(true)
    }

    const onSetOption = (newOption, newValidator = undefined) => {
        if (option?.id !== newOption?.id) {
            setRewardFormulaWithMetrics({
                formula: undefined,
                metrics: [],
            })
        }
        setOption(newOption, newValidator)
    }

    const onSetAsset = newAsset => {
        setAsset(newAsset)
        resetStakingAmountAndUsd()
        setStakingTime(EMPTY_STAKING_TIME_OBJECT)
        setExpectedPrice('')

        if (newAsset?.slug !== asset?.slug) {
            setInflationFormulaWithMetrics({
                formula: undefined,
                metrics: [],
            })
            onSetOption(undefined)
        }
    }

    const showActiveText = useMemo(() => {
        const hasSliderUserInput = rewardFormulaWithMetrics?.metrics.find(
            metric =>
                metric?.userValue && metric?.defaultValue !== metric?.userValue
        )
        return (
            Number(amount?.rounded) > 0 ||
            Number(expectedPrice) > 0 ||
            JSON.stringify(stakingTime) !==
                JSON.stringify(EMPTY_STAKING_TIME_OBJECT) ||
            hasSliderUserInput
        )
    }, [amount?.rounded, expectedPrice, stakingTime, rewardFormulaWithMetrics])

    const initialTokenPriceValue = useAssetPrice(asset?.slug) ?? 0
    const initialTokenPrice = customInitialTokenPrice || initialTokenPriceValue

    const expectedTokenPrice = useMemo(() => {
        return isInvalidNumber(expectedPrice)
            ? initialTokenPrice
            : expectedPrice
    }, [initialTokenPrice, expectedPrice])

    const stakingAmountTokens = useMemo(() => {
        const stakingAmount = getStakingAmountTokens(
            amount?.actual,
            usd,
            initialTokenPrice,
            option
        )
        return Number(stakingAmount)
    }, [amount?.actual, initialTokenPrice, option, usd])

    const onSetAmount = useCallback(
        (newAmount = '', round = true) => {
            setAmount({
                actual: newAmount,
                rounded: !isInvalidNumber(newAmount)
                    ? round
                        ? formatInputNumber(
                              newAmount,
                              usd ? DECIMALS_LIMIT_USD : DECIMALS_LIMIT_TOKEN
                          )
                        : newAmount
                    : '',
            })
        },
        [usd]
    )

    const onSetUsd = useCallback(
        (newIsUsd = true) => {
            setUsd(newIsUsd)

            if (amount?.actual !== '') {
                const floatAmount = isInvalidNumber(amount?.actual)
                    ? 0
                    : parseFloat(amount?.actual)
                const newAmount = newIsUsd
                    ? floatAmount * initialTokenPrice
                    : initialTokenPrice !== 0
                    ? floatAmount / initialTokenPrice
                    : floatAmount
                onSetAmount(newAmount, true)
            }
        },
        [onSetAmount, amount?.actual, initialTokenPrice]
    )

    const isReady = useMemo(() => {
        return (
            asset !== undefined &&
            option !== undefined &&
            rewardFormulaWithMetrics?.formula !== undefined &&
            inflationFormulaWithMetrics?.formula !== undefined
        )
    }, [
        inflationFormulaWithMetrics?.formula,
        option,
        rewardFormulaWithMetrics?.formula,
        asset,
    ])

    return (
        <ReactContext.Provider
            value={{
                asset,
                setAsset: onSetAsset,
                option,
                defaultOption,
                setOption: onSetOption,
                validator,
                existingRewardOptionCategories:
                    existingRewardOptionCategories ?? [],
                initialTokenPrice,
                // Used in untracked asset profile, we need to set token price without fetching for token
                setCustomInitialTokenPrice,
                amount,
                setAmount: onSetAmount,
                usd,
                setUsd: onSetUsd,
                resetStakingAmountAndUsd,
                showActiveText,
                stakingTime,
                setStakingTime,
                expectedPrice,
                setExpectedPrice,
                rewardFormulaWithMetrics,
                inflationFormulaWithMetrics,
                setMetrics: (updatedMetric = {}, formulaSources = []) => {
                    if (formulaSources?.includes(FORMULA_TYPE_REWARD)) {
                        setRewardFormulaWithMetrics({
                            ...rewardFormulaWithMetrics,
                            metrics: [...rewardFormulaWithMetrics.metrics]?.map(
                                m =>
                                    m?.metricKey === updatedMetric?.metricKey
                                        ? updatedMetric
                                        : m
                            ),
                        })
                    }

                    if (formulaSources?.includes(FORMULA_TYPE_INFLATION)) {
                        setInflationFormulaWithMetrics({
                            ...inflationFormulaWithMetrics,
                            metrics: [
                                ...inflationFormulaWithMetrics.metrics,
                            ]?.map(m =>
                                m?.metricKey === updatedMetric?.metricKey
                                    ? updatedMetric
                                    : m
                            ),
                        })
                    }
                },
                expectedTokenPrice,
                stakingAmountTokens,
                isReady,
            }}
        >
            {children}
        </ReactContext.Provider>
    )
}

export const useCalculatorContext = () => useContext(ReactContext)
