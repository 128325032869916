import { SubscribeForm } from 'components/forms'

import styles from './underMaintenanceSection.module.scss'
import { MailerLiteGroupId } from 'utils/constants'

export const UnderMaintenanceSection = ({ asset }) => {
    return (
        <div className={styles.underMaintenance}>
            <div className={styles.title}>Under Maintenance</div>
            <div className={styles.description}>
                Subscribe to our newsletter to get an update on when this page
                is out of maintenance.
            </div>
            <SubscribeForm
                groupId={MailerLiteGroupId.Maintenance}
                asset={asset}
            />
        </div>
    )
}
