import { NumericInput, Slider, Switch } from 'components/forms'
import {
    DECIMALS_LIMIT_TOKEN,
    NO_DATA_INDICATOR,
    formatOutputNumber,
    formatSeconds,
    getPrecisionBySignificantFigures,
    isInvalidNumber,
} from 'utils/formatter'
import { getStakingAmountTokens } from './assetRewardCalculator'
import { useAssetRewardCalculatorContext } from 'contexts/assetRewardCalculatorContext'
import { getNumberOrDefault } from 'components/calculator/utils'
import { getMetricValueByKey } from 'utils/actions'
import { LIQUID_STAKING_KEY } from 'utils/constants'
import { useAssetRewardOptionsCalculatorContext } from 'contexts/assetRewardOptionsCalculatorContext'

const DEFAULT_STAKING_AMOUNT_USD = 10000

const getStakingAmountUsd = (amount = '', usd = true, tokenPrice = null) => {
    if (usd) {
        return getNumberOrDefault(amount, DEFAULT_STAKING_AMOUNT_USD)
    }

    const validatedTokenPrice = getNumberOrDefault(tokenPrice, 0)

    return isInvalidNumber(amount)
        ? DEFAULT_STAKING_AMOUNT_USD
        : amount * validatedTokenPrice
}

const AssetRewardCalculatorInputsComponent = ({
    asset,
    amount,
    setAmount,
    usd,
    setUsd,
    stakingTimeInDays,
    setStakingTimeInDays,
    isLoading,
}) => {
    const tokenPrice = getMetricValueByKey(asset, 'price')

    const minValue = usd ? getStakingAmountUsd(0, false, tokenPrice) : 0

    const maxValue = usd
        ? getMetricValueByKey(asset, 'marketcap') ?? DEFAULT_STAKING_AMOUNT_USD
        : getStakingAmountTokens(
              getMetricValueByKey(asset, 'staking_marketcap') ??
                  DEFAULT_STAKING_AMOUNT_USD,
              true,
              tokenPrice
          )

    const isLst = !!asset?.tags?.find(t => t.tagKey === LIQUID_STAKING_KEY)
    const rewardOption = isLst
        ? asset?.rewardOptionsWithAssetAsOutput?.[0]
        : null

    const emptyInput = amount?.rounded === ''
    const convertedInputValue = !usd
        ? getStakingAmountUsd(
              emptyInput ? DEFAULT_STAKING_AMOUNT_USD : amount?.actual,
              emptyInput || usd,
              tokenPrice
          )
        : getStakingAmountTokens(
              emptyInput ? DEFAULT_STAKING_AMOUNT_USD : amount?.actual,
              emptyInput || usd,
              tokenPrice
          )

    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-row w-full items-center justify-between gap-x-2 mb-2'>
                <p className='text-contrast-3 font-bold text-sm leading-1'>
                    Enter Staking Amount
                </p>
                <Switch
                    labelLeft={'USD'}
                    labelRight={asset?.symbol ?? NO_DATA_INDICATOR}
                    onClick={() => setUsd(!usd)}
                    isLeft={usd}
                    textClassName='text-[10px] text-contrast-4'
                />
            </div>
            <div className='flex flex-row items-center w-full h-9 rounded-lg px-4 mb-5 bg-[var(--c-grey-8)]'>
                <div className='flex flex-row w-full justify-between items-center'>
                    <div className='flex flex-row items-center gap-x-2'>
                        <div className='text-lg font-bold text-contrast-4 text-right'>
                            {usd ? '$' : asset?.symbol ?? NO_DATA_INDICATOR}
                        </div>
                        <NumericInput
                            className='w-full'
                            inputClassName='w-full !h-9 !text-lg !text-left !bg-transparent !text-contrast-4'
                            value={amount?.rounded ?? ''}
                            onValueChange={newValue => {
                                setAmount(newValue, false)
                            }}
                            minValue={minValue}
                            maxValue={maxValue}
                            placeholder={formatOutputNumber(
                                usd
                                    ? DEFAULT_STAKING_AMOUNT_USD
                                    : getStakingAmountTokens(
                                          DEFAULT_STAKING_AMOUNT_USD,
                                          true,
                                          tokenPrice
                                      ),
                                {
                                    allowEmpty: false,
                                    withAbbreviation: false,
                                    forcePrecision: false,
                                    showApproximation: true,
                                }
                            )}
                            minErrorMessage={`Please enter amount above ${formatOutputNumber(
                                minValue,
                                {
                                    prefix: usd ? '$' : '',
                                    postfix: !usd ? asset?.symbol ?? '' : '',
                                    spaceAfterNumber: true,
                                    allowEmpty: false,
                                    withAbbreviation: false,
                                    forcePrecision: false,
                                    showApproximation: true,
                                }
                            )}`}
                            maxErrorMessage={`Please enter an amount below ${formatOutputNumber(
                                maxValue,
                                {
                                    prefix: usd ? '$' : '',
                                    postfix: !usd ? asset?.symbol ?? '' : '',
                                    spaceAfterNumber: true,
                                    allowEmpty: false,
                                    withAbbreviation: false,
                                    forcePrecision: false,
                                    showApproximation: true,
                                }
                            )} ${
                                !isInvalidNumber(maxValue)
                                    ? `which represents the ${
                                          usd
                                              ? 'current MarketCap'
                                              : 'entire circulating supply'
                                      } of ${asset?.symbol ?? 'the asset'}`
                                    : ''
                            }`}
                            decimalsLimit={DECIMALS_LIMIT_TOKEN}
                            large
                        />
                    </div>
                </div>
                {!isLoading && (
                    <div className='text-xs font-formal text-contrast-3 whitespace-nowrap ml-3'>
                        {formatOutputNumber(convertedInputValue, {
                            precision: Math.max(
                                2,
                                getPrecisionBySignificantFigures(
                                    convertedInputValue,
                                    3
                                )
                            ),
                            prefix: !usd ? '$' : '',
                            postfix: usd ? asset?.symbol ?? '' : '',
                            spaceAfterNumber: true,
                            allowEmpty: false,
                            withAbbreviation: true,
                            forcePrecision: false,
                            showApproximation: true,
                        })}
                    </div>
                )}
            </div>
            <div className='flex flex-row w-full items-center justify-between gap-x-2 mb-2'>
                <p className='text-contrast-3 font-bold text-sm'>
                    {isLst ? 'Staking / Holding Time' : 'Staking Time'}
                </p>
                <p className='text-[10px] text-contrast-4 font-normal'>
                    {formatSeconds(stakingTimeInDays * 86400, false)}
                </p>
            </div>
            <Slider
                inputClassName='!h-4 !mb-6'
                minValue={1}
                maxValue={10 * 365}
                value={stakingTimeInDays}
                step={1}
                onChange={e => setStakingTimeInDays(e.target.value)}
            />
            {isLst && (
                <div className='grid grid-cols-2 mb-4'>
                    <div className='flex flex-col'>
                        <p className='font-bold text-sm text-contrast-3'>
                            Reward Freq.
                        </p>
                        <p className='font-bold text-sm text-contrast-4'>
                            Daily
                        </p>
                    </div>
                    <div className='flex flex-col'>
                        <p className='font-bold text-sm text-contrast-3'>
                            Token Model
                        </p>
                        <p className='font-bold text-sm text-contrast-4'>
                            {rewardOption?.rewardInfo || NO_DATA_INDICATOR}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export const AssetRewardCalculatorInputsForTable = () => {
    const {
        asset,
        amount,
        setAmount,
        usd,
        setUsd,
        stakingTimeInDays,
        setStakingTimeInDays,
        isLoading,
    } = useAssetRewardOptionsCalculatorContext()

    return (
        <AssetRewardCalculatorInputsComponent
            asset={asset}
            amount={amount}
            setAmount={setAmount}
            usd={usd}
            setUsd={setUsd}
            stakingTimeInDays={stakingTimeInDays}
            setStakingTimeInDays={setStakingTimeInDays}
            isLoading={isLoading}
        />
    )
}

export const AssetRewardCalculatorInputs = () => {
    const {
        asset,
        amount,
        setAmount,
        usd,
        setUsd,
        stakingTimeInDays,
        setStakingTimeInDays,
        isLoading,
    } = useAssetRewardCalculatorContext()

    return (
        <AssetRewardCalculatorInputsComponent
            asset={asset}
            amount={amount}
            setAmount={setAmount}
            usd={usd}
            setUsd={setUsd}
            stakingTimeInDays={stakingTimeInDays}
            setStakingTimeInDays={setStakingTimeInDays}
            isLoading={isLoading}
        />
    )
}
