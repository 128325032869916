import { useContext, createContext, useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { COSMOS_STAKING_TYPE_OPTIONS } from 'components/staking/cosmos/constants'
import { useStakingModalContext } from 'contexts/stakingModalContext'
import { useCombinedWalletsContext } from 'contexts/combinedWalletsContext'
import { babylon } from '@stakingrewards/staking-sdk'
import { useMutation, useQuery } from '@tanstack/react-query'
import { subscribeToSdkTransactionEvents } from 'utils/subscribeToSdkTransactionEvents'
import { v4 as uuid } from 'uuid'
import { TransactionEvents } from '@stakingrewards/staking-sdk/dist/events'
import { GTMEvent, logEvent } from 'utils/GTM'
import { Psbt } from 'bitcoinjs-lib'
import { fetchBabylonStakedTokens } from 'data'

export const BitcoinStakingContext = createContext({})

export const BitcoinStakingContextProvider = ({ children }) => {
    const router = useRouter()
    const { rewardOption } = useStakingModalContext()
    const { isLoggedIn, primaryWallet } = useCombinedWalletsContext()

    const [stakingAmount, setStakingAmount] = useState(0)
    const [transactionStatus, setTransactionStatus] = useState(null)

    const [feeRate, setFeeRate] = useState(0)

    const defaultStakingType = router?.query?.type ?? ''

    const { data: capData, isLoading: isLoadingCapData } = useQuery({
        queryKey: ['getBabyloStakedTokens'],
        queryFn: async () => {
            const response = await fetchBabylonStakedTokens()
            const data = response?.data
            return {
                tvl: data?.total_tvl / 100000000 || 0, // converting from satoshi to btc
                stakers: data?.total_stakers || 0,
                cap: 1000, // hardcoded for now
            }
        },
    })

    const { data: blockNumber } = useQuery({
        // enabled: primaryWallet?.address?.length > 0,
        enabled: false, // cap has been reached
        queryKey: ['getBlockHeight'],
        queryFn: async () => {
            try {
                const result = await babylon.calls.getBlockHeight()
                return result
            } catch (err) {
                console.error('getBlockHeight', err)
            }
        },
    })

    const { data: recommendedFeeRateSats } = useQuery({
        enabled: primaryWallet?.address?.length > 0,
        queryKey: ['getRecommendedFeeRateSats'],
        queryFn: async () => {
            let recommendedFeeRate = 0
            try {
                recommendedFeeRate =
                    await babylon.calls.getRecommendedFeeRateSats()
                setFeeRate(recommendedFeeRate)
            } catch (err) {
                console.error('getRecommendedFeeRateSats', err)
            }

            return recommendedFeeRate ?? 0
        },
    })

    const { data: config } = useQuery({
        enabled: primaryWallet?.address?.length > 0,
        queryKey: ['getBabylonConfig', primaryWallet?.address, isLoggedIn],
        queryFn: async () => {
            let config = null
            try {
                config = await babylon.calls.getConfigParams()
            } catch (err) {
                console.error('getBabylonConfig', err)
            }

            return config
        },
    })

    const { data: availableBalance, isLoading: isLoadingBalance } = useQuery({
        enabled: primaryWallet?.address?.length > 0,
        queryKey: ['getBitcoinBalance', primaryWallet?.address, isLoggedIn],
        queryFn: async () => {
            let balance = 0
            try {
                balance = await babylon.calls.getBalance(primaryWallet?.address)
            } catch (err) {
                console.error('getBitcoinBalance', err)
            }

            return balance ?? 0
        },
    })

    // Calculates transaction using staking-sdk every time stakingAmount or selectedValidator changes
    const { data: transactionDetail, isLoading: isLoadingTransactionDetail } =
        useQuery({
            queryKey: ['getBitcoinDelegateTx', feeRate, stakingAmount],
            enabled:
                Number(stakingAmount) > 0 && primaryWallet?.address?.length > 0,
            queryFn: async () => {
                const duration = config?.minimumDuration || 64000
                const publicKey =
                    primaryWallet?.additionalAddresses?.[0].publicKey
                // const signMessage = primaryWallet.connector?.signMessage

                const signMessage = async psbtHex => {
                    return (
                        await primaryWallet?.connector?.signPsbt({
                            allowedSighash: [],
                            unsignedPsbtBase64:
                                Psbt.fromHex(psbtHex).toBase64(),
                        })
                    ).signedPsbt
                }

                let result = null
                try {
                    result = await babylon.transactions.delegateTokensTx(
                        {
                            amount: stakingAmount,
                            stakingAddress:
                                rewardOption?.validators?.[0]?.address,
                        },
                        duration,
                        feeRate,
                        primaryWallet?.address,
                        publicKey,
                        signMessage
                    )
                    console.log('result', result)
                } catch (err) {
                    console.error('getBitcoinDelegateTx', err)
                }
                return result
            },
        })

    // Executes transaction, when stake button is clicked
    const {
        data: transactionMutationData,
        mutate: executeTransactionMutate,
        isPending: isInProgress,
    } = useMutation({
        mutationKey: ['executeBitcoinTransaction'],
        mutationFn: async ({
            transaction,
            stakingAmount,
            primaryWallet,
            rewardOption,
        }) => {
            const provider = rewardOption?.providers?.[0]
            const eventPayload = {
                transaction_id: uuid(),
                wallet: primaryWallet?.key,
                address: primaryWallet?.address,
                amount: stakingAmount,
                input_token: 'BTC',
                transaction_fee: transaction?.estimatedFee,
                provider: provider?.name,
                type: 'stake',
                chain: 'bitcoin',
            }

            const unsubscribeFromSdkTransactionEvents =
                subscribeToSdkTransactionEvents({
                    handlePending: () => {
                        console.log('PENDING')
                        setTransactionStatus(TransactionEvents.PENDING)
                    },
                    handleWaitingForSignature: () => {
                        console.log('WAITING_FOR_SIGNATURE')
                        setTransactionStatus(
                            TransactionEvents.WAITING_FOR_SIGNATURE
                        )
                    },
                    handleBroadcasting: () => {
                        console.log('BROADCASTING')
                        setTransactionStatus(TransactionEvents.BROADCASTING)
                    },
                    handleSuccess: () => {
                        console.log('SUCCESS')
                        setTransactionStatus(TransactionEvents.SUCCESS)
                    },
                    handleFailed: error => {
                        console.log('FAILED')
                        setTransactionStatus(TransactionEvents.FAILED)
                        logEvent(GTMEvent.StakingFailed, {
                            ...eventPayload,
                            error_message: error,
                        })
                    },
                })

            try {
                logEvent(GTMEvent.StakingClicked, eventPayload)
                console.log('executing transaction', transaction)
                const [success, transactionHash] =
                    await transaction?.signAndBroadcast()
                console.log('TRANSACTION EXECUTED', success, transactionHash)
                if (success) {
                    logEvent(GTMEvent.StakingSuccess, {
                        ...eventPayload,
                        transaction_hash: '_' + transactionHash,
                    })
                }

                return [success, transactionHash]
            } catch (error) {
                logEvent(GTMEvent.StakingFailed, {
                    ...eventPayload,
                    error_message: error,
                })
            } finally {
                unsubscribeFromSdkTransactionEvents()
            }
        },
    })
    const executeTransaction = useCallback(() => {
        if (!transactionDetail) {
            return
        }
        return executeTransactionMutate({
            transaction: transactionDetail,
            stakingAmount,
            primaryWallet,
            rewardOption,
        })
    }, [
        transactionDetail,
        stakingAmount,
        primaryWallet,
        rewardOption,
        executeTransactionMutate,
    ])

    const [stakingType, setStakingType] = useState(
        COSMOS_STAKING_TYPE_OPTIONS.find(
            option => option.key === defaultStakingType
        )
    )

    const value = {
        stakingAmount,
        setStakingAmount,
        stakingType,
        setStakingType,
        availableBalance,
        isLoadingBalance,
        feeRate,
        setFeeRate,
        isInProgress,
        executeTransaction,
        transactionStatus,
        recommendedFeeRateSats,
        blockNumber,
        transactionDetail,
        isLoadingTransactionDetail,
        capData,
        isLoadingCapData,

        // isLoading: isLoadingExchangeRate || isLoadingTransaction,
    }

    return (
        <BitcoinStakingContext.Provider value={value}>
            {children}
        </BitcoinStakingContext.Provider>
    )
}

export const useBitcoinStakingContext = () => {
    return useContext(BitcoinStakingContext)
}
