import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
    useOperatorsForRewardoptions,
    usePaginatedRewardOptionsForProfileAppended,
    usePaginatedValidatorsForProfile,
} from 'data'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import {
    DEFAULT_ORDER,
    PAGE_SIZE_10,
    DEFAULT_REWARD_OPTION_CATEGORY,
    DEFAULT_REWARD_OPTION_TIMEFRAME,
    TYPE_ASSET,
    PageSizes,
    AVS_KEY,
    RewardOptionType,
} from 'utils/constants'
import { DEFAULT_REWARD_OPTIONS_SORT_BY } from '../constants'
import { getParamsFromUrl } from './utils'
import { useAVSProfilePageContext } from 'contexts/avsProfilePageContext'

// use to fetch pages with appending
export const useRewardOptionsData = (
    profileItem = null,
    profileType = TYPE_ASSET
) => {
    const { paginationParams, queryParams } = useTableArchiveContext()
    const { AVSData } = useAVSProfilePageContext()

    const isAVS = profileItem?.tags?.find(t => t.tagKey === AVS_KEY)

    const categoryKey = isAVS ? AVS_KEY : queryParams?.category?.key
    const sortByKey = queryParams?.sortBy?.key
    const orderKey = queryParams?.order?.key
    const timeframeKey = queryParams?.timeframe?.key
    const byChange = queryParams?.byChange
    const searchTerms = queryParams?.search
    const showVerifiedFirst = queryParams?.verifiedFirst

    const page = Number(paginationParams?.page ?? 0)
    const pageSizeNumber = Number(
        paginationParams?.pageSize?.key ?? PageSizes.Twenty
    )

    const { data, size, setSize, isValidating } =
        usePaginatedRewardOptionsForProfileAppended(
            {
                search: searchTerms,
                category: categoryKey,
                sortBy: sortByKey ?? DEFAULT_REWARD_OPTIONS_SORT_BY.key,
                order: orderKey ?? DEFAULT_ORDER.key,
                timeframe: timeframeKey,
                byChange,
                profileSlug: profileItem?.slug,
                showVerifiedFirst,
            },
            pageSizeNumber + 1,
            true,
            profileType
        )

    useEffect(() => {
        // NB: SWR's size (page number) starts from 1
        if (page + 1 !== size) {
            setSize(page + 1)
        }
    }, [page, setSize, size])

    const lastBatch = data?.[(data?.length ?? 0) - 1]
    const hasMore = (lastBatch?.rewardOptions?.length ?? 0) > pageSizeNumber
    const noDataFound = !Boolean(
        data?.map(batch => batch?.rewardOptions ?? [])?.flat()?.length
    )

    // Since we fetched pageSizeNumber + 1 items (for hasMore check), remove the last item
    const batches = data?.map(
        batch => batch?.rewardOptions?.slice(0, pageSizeNumber) ?? []
    )

    return {
        data: batches,
        avsLogos: isAVS ? AVSData : null,
        isLoading: isValidating,
        noDataFound,
        isEnd: !hasMore,
    }
}

export const useValidatorsData = (
    rewardOptionId = null,
    orderKey = DEFAULT_ORDER.key,
    sortByKey = DEFAULT_REWARD_OPTIONS_SORT_BY.key,
    page = 0,
    pageSizeNumber = PAGE_SIZE_10,
    isInfinite = false
) => {
    const { data, size, setSize, isValidating } =
        usePaginatedValidatorsForProfile(
            {
                order: orderKey,
                sortBy: sortByKey,
                rewardOptionId,
            },
            pageSizeNumber + 1,
            true
        )

    useEffect(() => {
        // NB: SWR's size (page number) starts from 1
        if (page + 1 !== size) {
            setSize(page + 1)
        }
    }, [page, setSize, size])

    const lastBatch = data?.[(data?.length ?? 0) - 1]
    const hasMore = (lastBatch?.validators?.length ?? 0) > pageSizeNumber
    const noDataFound = !Boolean(
        data?.map(batch => batch?.validators ?? [])?.flat()?.length
    )
    const isLoading = (isInfinite && hasMore) || isValidating

    // Since we fetched pageSizeNumber + 1 items (for hasMore check), remove the last item
    const batches = data?.map(
        batch => batch?.validators?.slice(0, pageSizeNumber) ?? []
    )

    return {
        data: batches,
        isValidating,
        isLoading,
        noDataFound,
        isEnd: !hasMore,
    }
}

export function useParamsFromUrl(
    query = {
        slug: '',
        category: DEFAULT_REWARD_OPTION_CATEGORY.key,
        sort: DEFAULT_REWARD_OPTIONS_SORT_BY.key,
        timeframe: DEFAULT_REWARD_OPTION_TIMEFRAME.key,
        order: DEFAULT_ORDER.key,
        byChange: false,
        search: '',
        verifiedFirst: true,
    },
    type
) {
    const router = useRouter()

    const getParams = () => {
        return {
            category: getParamsFromUrl(query, 'category'),
            sortBy: getParamsFromUrl(query, 'sort', type),
            timeframe: getParamsFromUrl(query, 'timeframe'),
            order: getParamsFromUrl(query, 'order'),
            byChange: getParamsFromUrl(query, 'byChange'),
            search: getParamsFromUrl(query, 'search'),
            ...(type === TYPE_ASSET
                ? {
                      verifiedFirst: getParamsFromUrl(query, 'verifiedFirst'),
                  }
                : {}),
        }
    }

    const [params, setParams] = useState(getParams())

    // Update params when the item changes
    useEffect(() => {
        setParams(getParams())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query?.slug])

    const onSetParams = newParams => {
        const searchQuery = newParams?.search
            ? {
                  search: newParams?.search,
              }
            : {}
        const newQuery = {
            category: newParams?.category?.key,
            sort: newParams?.sortBy?.key,
            timeframe: newParams?.timeframe?.key,
            byChange: newParams?.byChange,
            order: newParams?.order?.key,
            ...(type === TYPE_ASSET
                ? {
                      verifiedFirst: newParams?.verifiedFirst,
                  }
                : {}),
            ...searchQuery,
        }
        router.replace(
            `/${type}/${query?.slug}`,
            {
                pathname: `/${type}/${query?.slug}`,
                query: newQuery,
            },
            {
                shallow: false,
                scroll: false,
            }
        )
        setParams({ ...newParams })
    }

    return [params, onSetParams]
}

export const useOperatorData = (profileItem = null) => {
    const { data } = useOperatorsForRewardoptions(profileItem?.slug)
    const rewardOptions = data?.[0]?.rewardOptions ?? []
    //sort by vsp first
    const sortedData = rewardOptions.sort((a, b) => {
        const aProvider = a?.providers?.find(
            p => p?.type?.label === RewardOptionType.PROOF_OF_STAKE
        )
        const bProvider = b?.providers?.find(
            p => p?.type?.label === RewardOptionType.PROOF_OF_STAKE
        )
        if (aProvider?.isVerified < bProvider?.isVerified) {
            return 1
        }
        return -1
    })
    return { data: sortedData }
}
