import { formatOutputNumber } from 'utils/formatter'

function StakingCapBox({ tvl, cap, symbol, stakers }) {
    return (
        <div className='bg-white/50 dark:bg-contrast-0/10 w-full rounded-2xl'>
            <div className='flex flex-col divide-solid divide-contrast-1 divide-y lg:flex-row lg:divide-x lg:divide-y-0 '>
                <div className='flex-grow p-4 lg:p-6'>
                    <div className='flex items-center justify-between'>
                        <div>
                            <div className='text-sm text-contrast-4 mb-2'>
                                Total Value Locked
                            </div>
                            <div className='text-xl text-contrast-6 font-bold'>
                                {formatOutputNumber(tvl, {
                                    precision: 4,
                                })}{' '}
                                {symbol}
                            </div>
                        </div>
                        <div className='text-right'>
                            <div className='text-sm text-contrast-4 mb-2'>
                                CAP
                            </div>
                            <div className='text-xl text-primary font-bold'>
                                {cap} {symbol}
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 w-full h-1 rounded-full bg-contrast-1'>
                        <div
                            className='h-1 rounded-full bg-primary'
                            style={{
                                width: `${Math.min(tvl / cap, 1) * 100}%`,
                            }}
                        />
                    </div>
                </div>
                {stakers !== undefined && (
                    <div className='mt-0 lg:mt-6 m-6 lg:ml-0 lg:pl-6'>
                        <div className='text-sm text-contrast-4 mb-2'>
                            Stakers
                        </div>
                        <div className='text-xl text-primary font-bold text-left lg:text-center'>
                            {stakers}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default StakingCapBox
