import { signal } from '@preact/signals-react'
import SwingSDK from '@swing.xyz/sdk'
import { GTMEvent, logEvent } from 'utils/GTM'
import { TRANSFER_STATUS } from 'utils/stakingAssistant/constants'

const swing = new SwingSDK({
    projectId: 'staking-assistant',
    analytics: false,
    environment:
        process.env.NEXT_PUBLIC_SWING_ENV === 'production'
            ? 'production'
            : 'testnet', // "production" or "testnet"
})

const SWING_CHAIN =
    process.env.NEXT_PUBLIC_SWING_ENV === 'production' ? 'ethereum' : 'goerli' // "ethereum" for mainnet or "goerli" for testnet

const transferStatus = signal(TRANSFER_STATUS.IDLE)
const transfer = signal(null)
const executionQuote = signal(null)
const GTMStakingEvent = signal(null)

export const SwingState = {
    transfer,
    transferStatus,
    executionQuote,
    GTMStakingEvent,
}

let address = null

export const getExchangeQuote = async (fromToken, toToken, amount) => {
    const transferParams = {
        fromChain: SWING_CHAIN, // Source chain
        fromToken, // Source token
        fromUserAddress: address, // Source chain wallet address

        amount: amount.toString(), // Amount to transfer in token decimals

        toChain: SWING_CHAIN, // Destination chain
        toToken, // Destination token
        toUserAddress: address, // Ending chain wallet address
    }

    try {
        const quote = await swing.getQuote(transferParams)
        console.log(quote)
        return quote
    } catch (e) {
        console.error(e)
    } finally {
    }
}

export const executeQuote = async (route, fromToken, toToken, amount) => {
    const transferParams = {
        fromChain: SWING_CHAIN, // Source chain
        fromToken, // Source token
        fromUserAddress: address, // Source chain wallet address

        amount: amount.toString(), // Amount to transfer in token decimals

        toChain: SWING_CHAIN, // Destination chain
        toToken, // Destination token
        toUserAddress: address, // Ending chain wallet address
    }
    executionQuote.value = { route, transferParams }
    try {
        await swing.transfer(route, transferParams)
    } catch (e) {
        console.error(e)
    }
}

export const getIntegration = route => {
    if (!route?.quote?.integration) return null

    try {
        return swing.getIntegration(route?.quote?.integration)
    } catch (e) {
        console.error(e)
        return null
    }
}

swing.on('TRANSFER', t => {
    transfer.value = t
    switch (t.status) {
        case 'PENDING':
            transferStatus.value = TRANSFER_STATUS.PENDING
            console.log(`Creating a transaction for the ${t.step} step`)
            break
        case 'CHAIN_SWITCH_REQUIRED':
            transferStatus.value = TRANSFER_STATUS.CHAIN_SWITCH_REQUIRED
            console.log('Chain switch required')
            // Handle switching chains or alert the user to do it manually
            break
        case 'ACTION_REQUIRED':
            transferStatus.value = TRANSFER_STATUS.ACTION_REQUIRED
            console.log(
                'Please complete the required action within your connected wallet'
            )
            break
        case 'CONFIRMING':
            transferStatus.value = TRANSFER_STATUS.CONFIRMING
            console.log(
                `Waiting for the transaction from the ${t.step} step to complete`
            )
            break
        case 'SUCCESS':
            if (t.step === 'bridge') {
                logEvent(GTMEvent.StakingSuccess, {
                    ...GTMStakingEvent.value,
                    transaction_hash: '_' + t?.txHash,
                })
            }
            transferStatus.value = TRANSFER_STATUS.SUCCESS
            console.log(`Transfer has completed the ${t.step} step`)
            break
        case 'FAILED':
            transferStatus.value = TRANSFER_STATUS.FAILED
            const msg = `Transfer failed at the ${t.step} step: ${t.error}`
            console.log(msg)
            logEvent(GTMEvent.StakingFailed, {
                ...GTMStakingEvent.value,
                error_message: msg,
            })
            break
    }
})

export async function initializeSwing(newAddress) {
    if (!newAddress) return
    address = newAddress

    try {
        await swing.init()

        if (window.ethereum) {
            await swing.wallet.connect(window.ethereum, SWING_CHAIN)
        }
    } catch (e) {
        console.error('Failed to initialize swing', e)
    }
}
