import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import {
    TableArchiveContextProvider,
    useTableArchiveContext,
} from 'contexts/tableArchiveContext'
import { TableView } from 'components/ui'
import {
    PaginatedTableArchive,
    TableArchiveWithControl,
} from 'components/tables'
import {
    AVS_KEY,
    ALL_KEY,
    DEFAULT_REWARD_OPTION_TIMEFRAME,
    REWARD_OPTION_CATEGORIES,
    TYPE_ASSET,
    TYPE_PROVIDER,
    LENDING_PROVIDER_KEY,
    RewardOptionType,
    BITCOIN_SLUG,
} from 'utils/constants'
import {
    getDefaultRewardOptionSortByOptionPerCategory,
    getPrimaryRewardOptionMetricGroups,
    getRewardOptionSortByOptionsPerCategory,
    getTotalCountPerCategoryKey,
} from './utils'
import { useOperatorData, useParamsFromUrl } from './hooks'
import { useDispatch } from 'react-redux'
import { setIsTableExpanded } from 'state'
import {
    fetchBabylonStakedTokens,
    useCountRewardOptions,
    useIsWithPerformanceMetric,
} from 'data'
import { RewardOptionRows } from 'components/rewardOptions/archive/v2/rewardOptionRows'
import { ArchiveTable } from 'components/rewardOptions/archive/rewardOptionsTable'
import { AssetRewardOptionsCalculatorContextProvider } from 'contexts/assetRewardOptionsCalculatorContext'
import { CustodialRewardOptionRows } from './v2/custodialRewardOptionRows'
import { RewardOptionAds } from './v2/rewardOptionAds'
import { useQuery } from '@tanstack/react-query'
import StakingCapBox from 'components/ui/stakingCapBox'
import BabylonLockCard from 'components/ui/babylonLockCard'

// Relies on client-size window logic
const FiltersRow = dynamic(
    () => import('components/tables').then(module => module.FiltersRow),
    {
        ssr: false,
    }
)

const RewardOptionsArchiveTable = ({
    headerType = TYPE_ASSET,
    profileItem = null,
    type,
    countsData = null,
}) => {
    const { selectedTableView, queryParams, withPerformanceMetric } =
        useTableArchiveContext()

    const { data: operatorData } = useOperatorData(profileItem)

    const isAVS = !!profileItem?.tags?.find(t => t.tagKey === AVS_KEY)

    const dispatch = useDispatch()
    // Expand all table rows when a sorting metric is in the expansion
    useEffect(() => {
        if (
            !getPrimaryRewardOptionMetricGroups(
                profileItem?.slug,
                queryParams?.category?.key,
                withPerformanceMetric
            ).includes(queryParams?.sortBy?.key) &&
            selectedTableView === TableView.List &&
            !isAVS
        ) {
            dispatch(setIsTableExpanded(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams?.sortBy?.key])

    const isAsset = type === TYPE_ASSET

    const totalCount = getTotalCountPerCategoryKey(
        queryParams?.category?.key,
        countsData
    )
    return (
        <TableArchiveWithControl forceHideControl={false}>
            <PaginatedTableArchive
                title={
                    isAsset ? (
                        <div className='font-bold text-2xl text-contrast-4'>
                            Step #2: Choose your Provider
                        </div>
                    ) : undefined
                }
                withoutViewControl={isAsset}
            >
                {isAsset && !isAVS ? (
                    <AssetRewardOptionsCalculatorContextProvider
                        asset={profileItem}
                    >
                        <div className='mt-4'>
                            {queryParams?.category?.key ===
                            RewardOptionType.CUSTODIAL ? (
                                <CustodialRewardOptionRows
                                    profileItem={profileItem}
                                    profileType={type}
                                    totalCount={totalCount}
                                />
                            ) : (
                                <>
                                    <RewardOptionAds
                                        operatorData={operatorData}
                                        profileItem={profileItem}
                                        profileType={type}
                                    />
                                    <RewardOptionRows
                                        operatorData={operatorData}
                                        profileItem={profileItem}
                                        profileType={type}
                                        totalCount={totalCount}
                                    />
                                </>
                            )}
                        </div>
                    </AssetRewardOptionsCalculatorContextProvider>
                ) : (
                    <ArchiveTable
                        headerType={headerType}
                        profileItem={profileItem}
                    />
                )}
            </PaginatedTableArchive>
        </TableArchiveWithControl>
    )
}

export const RewardOptionsTableArchiveSection = ({
    item = null,
    type = TYPE_ASSET,
    query = {},
    existingRewardOptionCategories = [],
    isAVS = false,
}) => {
    const updatedQuery = {
        ...query,
        category:
            query.slug === BITCOIN_SLUG
                ? RewardOptionType.BABYLON_STAKING
                : !query.category || query.category === ALL_KEY
                  ? existingRewardOptionCategories.filter(
                        c => c !== ALL_KEY && c !== LENDING_PROVIDER_KEY
                    )?.[0]
                  : query.category,
    }
    const cap = 1000

    const [params, setParams] = useParamsFromUrl(updatedQuery, type)

    const withPerformanceMetric = useIsWithPerformanceMetric(type, item?.slug)

    const sortByChoices = getRewardOptionSortByOptionsPerCategory(
        params?.category?.key,
        type,
        item?.slug,
        withPerformanceMetric
    )

    const { data: countsData, isLoading: isLoadingCountsData } =
        useCountRewardOptions(
            existingRewardOptionCategories,
            item?.slug,
            type,
            params?.search
        )

    const { data } = useQuery({
        queryKey: ['get babylon staked tokens'],
        queryFn: async () => {
            const response = await fetchBabylonStakedTokens()
            const data = response?.data
            return {
                tvl: data?.active_tvl / 100000000 || 0,
                stakers: data?.total_stakers || 0,
            }
        },
        refetchInterval: 10000,
    })
    return (
        <>
            <FiltersRow
                params={{
                    ...params,
                    ...(isAVS && {
                        category: REWARD_OPTION_CATEGORIES.find(
                            ro => ro.key === AVS_KEY
                        ),
                        sortBy: sortByChoices.find(
                            so => so.key === 'staked_tokens'
                        ),
                    }),
                }}
                setParams={setParams}
                getDefaultSortBy={getDefaultRewardOptionSortByOptionPerCategory}
                defaultTimeframe={DEFAULT_REWARD_OPTION_TIMEFRAME}
                otherDefaultParams={{ verifiedFirst: true }}
                categories={REWARD_OPTION_CATEGORIES.filter(c =>
                    existingRewardOptionCategories?.includes(c?.key)
                )}
                sortByChoices={sortByChoices}
                useNewFilters={type === TYPE_ASSET && !isAVS}
            />
            {/* {type === TYPE_ASSET && (
                <VerifiedToggleButton
                    onChange={onToggleVerified}
                    forRewardOptions={!isAVS}
                    initialVerifiedFirst={params?.verifiedFirst}
                />
            )} */}
            {params?.category?.key === 'babylon-staking' &&
                query?.slug === BITCOIN_SLUG &&
                data?.tvl <= cap && (
                    <div className='mb-2 mt-4'>
                        <StakingCapBox
                            cap={cap}
                            tvl={data?.tvl}
                            symbol={'BTC'}
                        />
                    </div>
                )}
            {params?.category?.key === 'babylon-staking' &&
                query.slug === BITCOIN_SLUG &&
                data?.tvl >= cap && (
                    <BabylonLockCard
                        stakers={data?.stakers}
                        setParams={setParams}
                        params={params}
                    />
                )}
            <TableArchiveContextProvider
                params={params}
                withPerformanceMetric={withPerformanceMetric}
                isEth={item?.slug === 'ethereum-2-0'}
            >
                <RewardOptionsArchiveTable
                    headerType={
                        type === TYPE_ASSET ? TYPE_PROVIDER : TYPE_ASSET
                    }
                    type={type}
                    profileItem={item}
                    countsData={countsData}
                />
            </TableArchiveContextProvider>
        </>
    )
}
