import { useRouter } from 'next/router'
import React, { createContext, useState, useContext, useEffect } from 'react'

const FloatingBannerContext = createContext()

export const FloatingBannerProvider = ({ children }) => {
    const router = useRouter()

    const [floatingBannerState, setFloatingBannerState] = useState({
        asset: null,
        tag: null,
        ecosystem: null,
    })

    const setFloatingBannerProperties = properties => {
        setFloatingBannerState(prevState => ({
            ...prevState,
            ...properties,
        }))
    }

    useEffect(() => {
        if (
            !router.asPath.startsWith('/asset') &&
            !router.asPath.startsWith('/assets')
        ) {
            setFloatingBannerProperties({
                asset: null,
                tag: null,
                ecosystem: null,
            })
        }
    }, [router.asPath])

    return (
        <FloatingBannerContext.Provider
            value={{ floatingBannerState, setFloatingBannerProperties }}
        >
            {children}
        </FloatingBannerContext.Provider>
    )
}

export const useFloatingBanner = () => {
    return useContext(FloatingBannerContext)
}
