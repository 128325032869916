import { memo, useMemo } from 'react'
import classNames from 'classnames'

import { Link } from 'components/ui'
import styles from './feedbackButton.module.scss'
import { DISCORD_LINK } from 'utils/constants'

export const FeedbackButton = memo(({ isDesktop }) => {
    const preventClick = useMemo(
        () => e => {
            // To fix text selection bug on mobile
            e?.preventDefault()
            e?.stopPropagation()
        },
        []
    )
    return (
        <div className={styles.feedbackButtonContainer}>
            {/* Prevent click to fix iphone safari bug, where it would open the link immidately */}
            <div className={styles.feedbackButton} onTouchEnd={preventClick}>
                <div className={styles.feedbackText}>Feedback</div>
            </div>
            <Link href={DISCORD_LINK} blank className={styles.feedbackContent}>
                <div className={styles.text1}>Got feedback? Roast us!</div>
                <div className={styles.text2Container}>
                    <div className={styles.text2}>Let us know on Discord</div>
                    <span
                        className={classNames(
                            styles.discordIcon,
                            'icon icon-discord'
                        )}
                    />
                </div>
                <div
                    className={classNames(styles.shareIcon, 'icon icon-share')}
                ></div>
            </Link>
        </div>
    )
})
FeedbackButton.displayName = 'FeedbackButton'
