import { useContext, createContext, useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { v4 as uuid } from 'uuid'
import { COSMOS_STAKING_TYPE_OPTIONS } from 'components/staking/cosmos/constants'
import { useStakingModalContext } from 'contexts/stakingModalContext'
import { useCosmosAssetWalletBalance } from 'components/stakingAssistant/hooks'
import { useCombinedWalletsContext } from 'contexts/combinedWalletsContext'
import { cosmos, events } from '@stakingrewards/staking-sdk'
import { useChain } from '@cosmos-kit/react'
import { GTMEvent, logEvent } from 'utils/GTM'
import { formatToken } from 'utils/formatter'
import { useMutation, useQuery } from '@tanstack/react-query'
import { TransactionEvents } from '@stakingrewards/staking-sdk/dist/events'

export const CosmosLiquidStakingContext = createContext({})

export const CosmosLiquidStakingContextProvider = ({ children }) => {
    const router = useRouter()
    const { chain, getOfflineSignerDirect, getOfflineSigner } =
        useChain('cosmoshub')
    const { rewardOption } = useStakingModalContext()
    const { isLoggedIn, primaryWallet } = useCombinedWalletsContext()

    const provider = rewardOption?.providers?.[0]

    const [validatorPreference, setValidatorPreference] = useState('preference') // 'all' or 'preference'
    const [stakingAmount, setStakingAmount] = useState(0)
    const [transactionStatus, setTransactionStatus] = useState(null)
    const [selectedValidator, setSelectedValidator] = useState(null)

    // Read exchange rate, fetches only once
    const { data: exchangeRate, isLoading: isLoadingExchangeRate } = useQuery({
        queryKey: ['drop', 'exchangeRate'],
        queryFn: async () => {
            return await cosmos.transactions.drop.exchangeRate(
                getOfflineSignerDirect()
            )
        },
    })

    // Calculates transaction using staking-sdk every time stakingAmount or selectedValidator changes
    const { data: transaction, isLoading: isLoadingTransaction } = useQuery({
        queryKey: ['drop', 'transaction', stakingAmount, selectedValidator?.id],
        enabled: Number(stakingAmount) > 0,
        queryFn: async () => {
            const result = await cosmos.transactions.drop.liquidStakeTxs(
                chain.chain_id,
                getOfflineSigner(),
                [
                    {
                        amount: stakingAmount,
                        stakingAddresses: selectedValidator?.validators?.map(
                            v => v?.address
                        ), // pass the selected validator address
                    },
                ]
            )
            return result?.[0]
        },
    })

    // Executes transaction, when stake button is clicked
    const {
        data: transactionMutationData,
        mutate: executeTransactionMutate,
        isPending: isInProgress,
    } = useMutation({
        mutationKey: ['drop', 'executeTransaction'],
        mutationFn: async ({
            selectedValidator,
            stakingAmount,
            exchangeRate,
            primaryWallet,
            provider,
            chain,
        }) => {
            const strategy =
                selectedValidator?.providers?.[0]?.slug ||
                'strategy_equal_distribution'

            const eventPayload = {
                transaction_id: uuid(),
                wallet: primaryWallet?.prettyName || primaryWallet?.name,
                address: primaryWallet?.address,
                amount: stakingAmount,
                amount_received: Number(
                    formatToken(stakingAmount / exchangeRate)
                ),
                input_token: 'ATOM',
                output_token: 'dATOM',
                provider: provider?.name,
                type: 'stake',
                strategy,
                chain: chain?.chain_id,
                output_chain: 'neutron',
            }

            const unsubscribeFromSdkTransactionEvents =
                subscribeToSdkTransactionEvents({
                    handlePending: () => {
                        console.log('PENDING')
                        setTransactionStatus(TransactionEvents.PENDING)
                    },
                    handleWaitingForSignature: () => {
                        console.log('WAITING_FOR_SIGNATURE')
                        setTransactionStatus(
                            TransactionEvents.WAITING_FOR_SIGNATURE
                        )
                    },
                    handleBroadcasting: () => {
                        console.log('BROADCASTING')
                        setTransactionStatus(TransactionEvents.BROADCASTING)
                    },
                    handleSuccess: () => {
                        console.log('SUCCESS')
                        setTransactionStatus(TransactionEvents.SUCCESS)
                    },
                    handleFailed: error => {
                        console.log('FAILED')
                        setTransactionStatus(TransactionEvents.FAILED)
                        logEvent(GTMEvent.StakingFailed, {
                            ...eventPayload,
                            error_message: error,
                        })
                    },
                })

            try {
                logEvent(GTMEvent.StakingClicked, eventPayload)
                const [success, transactionHash] =
                    await cosmos.CosmosClient.signAndSendTransaction(
                        transaction
                    )
                console.log('TRANSACTION EXECUTED', success, transactionHash)
                if (success) {
                    logEvent(GTMEvent.StakingSuccess, {
                        ...eventPayload,
                        transaction_hash: '_' + transactionHash,
                    })
                }

                return [success, transactionHash]
            } catch (error) {
                logEvent(GTMEvent.StakingFailed, {
                    ...eventPayload,
                    error_message: error,
                })
            } finally {
                unsubscribeFromSdkTransactionEvents()
            }
        },
    })

    const executeTransaction = useCallback(() => {
        if (!transaction) {
            return
        }
        return executeTransactionMutate({
            selectedValidator,
            stakingAmount,
            exchangeRate,
            primaryWallet,
            provider,
            chain,
        })
    }, [
        transaction,
        selectedValidator,
        stakingAmount,
        exchangeRate,
        primaryWallet,
        provider,
        chain,
        executeTransactionMutate,
    ])

    const defaultStakingType = router?.query?.type ?? ''

    const [stakingType, setStakingType] = useState(
        COSMOS_STAKING_TYPE_OPTIONS.find(
            option => option.key === defaultStakingType
        )
    )

    const { data: balanceData, isLoading: isLoadingBalance } =
        useCosmosAssetWalletBalance(primaryWallet)

    const availableBalance = balanceData?.available ?? 0

    const value = {
        stakingAmount,
        setStakingAmount,
        stakingType,
        setStakingType,
        availableBalance,
        isLoadingBalance,
        transaction,
        exchangeRate,
        isLoading: isLoadingExchangeRate || isLoadingTransaction,
        executeTransaction,
        transactionStatus,
        isInProgress,
        setValidatorPreference,
        validatorPreference,
        selectedValidator,
        setSelectedValidator,
        txHash: transactionMutationData?.[1],
    }

    return (
        <CosmosLiquidStakingContext.Provider value={value}>
            {children}
        </CosmosLiquidStakingContext.Provider>
    )
}

export const useCosmosLiquidStakingContext = () => {
    return useContext(CosmosLiquidStakingContext)
}

function subscribeToSdkTransactionEvents({
    handlePending = () => {},
    handleWaitingForSignature = () => {},
    handleBroadcasting = () => {},
    handleSuccess = () => {},
    handleFailed = () => {},
}) {
    const { eventEmitter, TransactionEvents } = events

    eventEmitter.on(TransactionEvents.PENDING, handlePending)
    eventEmitter.on(
        TransactionEvents.WAITING_FOR_SIGNATURE,
        handleWaitingForSignature
    )
    eventEmitter.on(TransactionEvents.BROADCASTING, handleBroadcasting)
    eventEmitter.on(TransactionEvents.SUCCESS, handleSuccess)
    eventEmitter.on(TransactionEvents.FAILED, handleFailed)

    return () => {
        eventEmitter.off(TransactionEvents.PENDING, handlePending)
        eventEmitter.off(
            TransactionEvents.WAITING_FOR_SIGNATURE,
            handleWaitingForSignature
        )
        eventEmitter.off(TransactionEvents.BROADCASTING, handleBroadcasting)
        eventEmitter.off(TransactionEvents.SUCCESS, handleSuccess)
        eventEmitter.off(TransactionEvents.FAILED, handleFailed)
    }
}
