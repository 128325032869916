import classNames from 'classnames'
import { RaverTooltip } from 'components/assets/constants'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { LogoImage, TooltipOnHover } from 'components/ui'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import Image from 'next/image'
import {
    getMetricChangePercentage,
    getMetricDefaultValueByKey,
    getMetricValueByKey,
} from 'utils/actions'
import {
    CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
    RewardOptionType,
} from 'utils/constants'
import { NO_DATA_INDICATOR, formatOutputNumber } from 'utils/formatter'

export const OperatorRow = ({ operator, tokenPrice, className, sortByKey }) => {
    const { queryParams } = useTableArchiveContext()

    const timeframe = queryParams?.timeframe?.key

    const operatorProvider = operator?.providers?.find(
        p => p?.type?.label === RewardOptionType.PROOF_OF_STAKE
    )

    const formattedData = getFormattedOperatorData(
        operator,
        tokenPrice,
        timeframe
    )
    return (
        <div
            className={classNames(
                'flex items-center justify-between px-4 py-3 border-t border-solid border-shade-light/20 bg-contrast-0',
                className
            )}
        >
            <div className='flex items-center'>
                <div className='w-[42px] h-full flex items-center'>
                    {operatorProvider?.isVerified && (
                        <Image
                            src={'/static/svg/vsp-tag-extended.svg'}
                            alt={`Verified Provider`}
                            width={42}
                            height={14}
                        />
                    )}
                </div>
                <LogoImage
                    src={operatorProvider?.logoUrl}
                    size={27}
                    className='ml-4'
                />
                <div className='ml-2 font-bold leading-3 flex flex-col justify-center'>
                    <div className='text-[11px] text-contrast-5'>
                        {operatorProvider?.name}
                    </div>
                </div>
            </div>
            <div className='flex w-6/12'>
                <div className='flex flex-1'>
                    <MetricBox
                        title='Staked Tokens'
                        value={formattedData.aum}
                        change={formattedData.stakedTokensChangePercent}
                        isSmall
                        className={classNames({
                            ['bg-contrast-1 p-2 rounded-lg']:
                                sortByKey === 'staked_tokens',
                        })}
                    />
                </div>
                {/* <MetricBox
                    title='Validators'
                    value={formattedData.stakers}
                    change={formattedData.stakersChangePercent}
                    isSmall
                    className={classNames('flex-1', {
                        ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                            sortByKey === 'staking_wallets',
                    })}
                    className='flex-1'
                /> */}
                <div className='flex flex-1'>
                    <TooltipOnHover
                        text={<RaverTooltip />}
                        textClassName='!w-[280px]'
                    >
                        <MetricBox
                            title={
                                <div className='flex gap-1 items-center'>
                                    Effectiveness
                                    <span className='icon icon-candy !bg-contrast-2' />
                                </div>
                            }
                            value={
                                formattedData.performance || NO_DATA_INDICATOR
                            }
                            isSmall
                        />
                    </TooltipOnHover>
                </div>
                <div className='flex flex-1'>
                    <MetricBox
                        title='Net Staking Flow 7d'
                        value={formattedData.netStakingFlow7d}
                        isSmall
                        className={classNames('', {
                            ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                                sortByKey ===
                                CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
                        })}
                    />
                </div>
                {/* <MetricBox
                    title='Fee'
                    value={formattedData.fee}
                    isSmall
                    className={classNames('flex-1', {
                        ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                            sortByKey === 'commission',
                    })}
                /> */}
            </div>
        </div>
    )
}

export const getFormattedOperatorData = (operator, tokenPrice, timeframe) => {
    if (!operator) return {}
    const rewardRate = formatOutputNumber(
        getMetricDefaultValueByKey(operator, 'reward_rate'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const stakedTokensNumber = getMetricDefaultValueByKey(
        operator,
        'staked_tokens'
    )

    const stakedTokens = formatOutputNumber(stakedTokensNumber, {
        precision: 2,
        forcePrecision: false,
    })
    const stakedTokensCreatedAt = operator?.metrics?.find(
        m => m.metricKey === 'staked_tokens'
    )?.createdAt

    const aum = formatOutputNumber(tokenPrice * stakedTokensNumber, {
        precision: 2,
        prefix: '$',
    })

    const stakedTokensChangePercent = formatOutputNumber(
        getMetricChangePercentage(
            operator?.metrics,
            'staked_tokens',
            timeframe
        ),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const fee = formatOutputNumber(
        getMetricDefaultValueByKey(operator, 'commission'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const netStakingFlow7d = formatOutputNumber(
        getMetricValueByKey(operator, 'net_staking_flow', '7d'),
        {
            precision: 2,
            forcePrecision: false,
            prefix: '$',
            showPlus: true,
        }
    )

    const stakers = formatOutputNumber(
        getMetricDefaultValueByKey(operator, 'staking_wallets'),
        {
            precision: 2,
            forcePrecision: false,
        }
    )

    const stakersChangePercent = formatOutputNumber(
        getMetricChangePercentage(
            operator?.metrics,
            'staking_wallets',
            timeframe
        ),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const performance = formatOutputNumber(
        getMetricDefaultValueByKey(operator, 'performance'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    return {
        rewardRate,
        aum,
        stakedTokens,
        stakedTokensCreatedAt,
        stakedTokensChangePercent,
        fee,
        netStakingFlow7d,
        stakers,
        stakersChangePercent,
        performance,
    }
}
