import { useRef } from 'react'
import dynamic from 'next/dynamic'
import { TYPE_ASSET } from 'utils/constants'
import { FaqSection } from 'components/faqSection'
import { JournalSection } from './profile/journalSection'
import { CalculatorSection } from './profile/calculatorSection'
import { UnderMaintenanceSection } from './profile/underMaintenanceSection'
import { AssetInformation } from './profile/assetInformation'

const HeroSection = dynamic(
    () => import('./profile/heroSection').then(module => module.HeroSection),
    {
        ssr: false,
    }
)

export const MinimalAssetProfilePage = ({
    asset = null,
    query = null,
    hasJournalPosts = false,
}) => {
    const faqRef = useRef(null)
    const calculatorRef = useRef(null)

    return (
        <div className='pt-[var(--p-page-top)] px-[var(--p-page-sides)] pb-0 mb-24'>
            <div className='container'>
                <HeroSection asset={asset} />
                <div className='flex flex-col w-full gap-y-12'>
                    {asset?.underMaintenance ? (
                        <UnderMaintenanceSection asset={asset} />
                    ) : (
                        <AssetInformation
                            faqRef={faqRef?.current}
                            calculatorRef={calculatorRef?.current}
                            asset={asset}
                        />
                    )}
                    <div ref={calculatorRef}>
                        <CalculatorSection
                            asset={asset}
                            scenariosExpandable={false}
                        />
                    </div>
                    <div ref={faqRef}>
                        <FaqSection item={asset} type={TYPE_ASSET} />
                    </div>
                    {hasJournalPosts && (
                        <JournalSection
                            slug={asset.slug}
                            assetName={asset.name}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
