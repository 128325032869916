import classNames from 'classnames'
import { getFormattedData } from 'components/rewardOptions/archive/v2/getFormattedData'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { TYPE_ASSET } from 'utils/constants'

export const CustodialRewardOptionSubRowMobile = ({
    item,
    provider,
    className,
    assetRewardCalculatorContext,
    timeframe,
}) => {
    const formattedData = getFormattedData(
        item,
        TYPE_ASSET,
        timeframe,
        undefined,
        assetRewardCalculatorContext
    )

    const lockupIcon =
        formattedData?.lockupTimeInSeconds === 0 ? (
            <span className='icon icon-lock-0 !bg-contrast-2' />
        ) : (
            <span className='icon icon-lock-1 !bg-contrast-3' />
        )

    return (
        <div
            className={classNames(
                'flex flex-col gap-2 items-center justify-between px-4 py-3 rounded-lg border-t border-solid border-contrast-2/20 bg-contrast-0',
                className
            )}
        >
            <div className='w-full text-left text-xs text-contrast-4 font-bold pb-2 border-b border-solid border-contrast-2/20'>
                {item?.label}
            </div>
            <div className='flex w-full items-center'>
                <div className='flex-1'>
                    <MetricBox
                        title='Reward Rate'
                        value={formattedData?.rewardRate}
                        className='rounded-lg bg-gradient-sr-light/10 px-3 py-2'
                        isSmall
                    />
                </div>
                <div className='flex-1'>
                    <MetricBox
                        title='Duration'
                        value={
                            <div className='flex items-center gap-1'>
                                {lockupIcon}
                                {formattedData?.lockupTimeInSeconds === 0
                                    ? 'Flexible'
                                    : formattedData?.lockupTime}
                            </div>
                        }
                        isSmall
                    />
                </div>
            </div>
        </div>
    )
}
