import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import {
    DynamicContextProvider,
    useDynamicContext,
    useIsLoggedIn,
    useUserUpdateRequest,
    useUserWallets,
    RemoveWallets,
} from '@dynamic-labs/sdk-react-core'
import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import { CosmosWalletConnectors } from '@dynamic-labs/cosmos'
import { CosmosWalletContextProvider } from './cosmosWalletsContext'
import { WalletStatus } from 'cosmos-kit'
import { useChain, useModalTheme } from '@cosmos-kit/react'
import { getDynamicWalletIcon } from 'components/account/utils'
import {
    checkIfWrongWallet,
    getCosmosChainName,
} from 'components/staking/cosmos/utils'
import { THEME, useTheme } from 'state'
import { BitcoinWalletConnectors } from '@dynamic-labs/bitcoin'

export const SDK = {
    Dynamic: 'dynamic',
    CosmosKit: 'cosmos-kit',
}

const useDynamicSDKAccount = () => {
    let { primaryWallet, user } = useDynamicContext()
    primaryWallet = primaryWallet
        ? {
              ...primaryWallet,
              icon: getDynamicWalletIcon(primaryWallet),
          }
        : null
    const isLoggedIn = useIsLoggedIn()
    const { updateUser } = useUserUpdateRequest()
    const userWallets = useUserWallets()

    return {
        primaryWallet,
        user,
        isLoggedIn,
        updateUser,
        userWallets,
    }
}

const useCosmosKitAccount = (chainId = '') => {
    const { status, address, wallet, username, disconnect } = useChain(
        getCosmosChainName(chainId)
    )
    const isLoggedIn = address && status === WalletStatus.Connected
    let primaryWallet = isLoggedIn ? wallet : null
    primaryWallet = primaryWallet
        ? {
              ...primaryWallet,
              address,
              icon: primaryWallet?.logo,
          }
        : null
    const user = isLoggedIn ? { name: username } : null
    const updateUser = () => {}
    const userWallets = isLoggedIn ? [primaryWallet] : []

    return {
        primaryWallet,
        user,
        isLoggedIn,
        updateUser,
        userWallets,
        disconnect,
    }
}

export const CombinedWalletsContext = createContext({})

export const useCombinedWalletsContext = () => {
    return useContext(CombinedWalletsContext)
}

const WalletsContextProvider = ({ children }) => {
    const [primaryWallet, setPrimaryWallet] = useState(null)
    const [cosmosChain, setCosmosChain] = useState(null)

    useEffect(() => {
        const savedChain = window.localStorage.getItem('selected_cosmos_chain')
        if (cosmosChain !== savedChain && savedChain) {
            setCosmosChain(window.localStorage.getItem('selected_cosmos_chain'))
        }
    }, [cosmosChain])

    const {
        primaryWallet: dynamicPrimaryWallet, // NB: default
        user: dynamicUser,
        isLoggedIn: isLoggedInDynamic,
        updateUser: updateUserDynamic,
        userWallets: dynamicUserWallets,
    } = useDynamicSDKAccount()

    const {
        primaryWallet: cosmosKitPrimaryWallet, //NB: set when connecting through Cosmos Kit
        user: cosmosKitUser,
        isLoggedIn: isLoggedInCosmosKit,
        updateUser: updateUserCosmosKit,
        userWallets: cosmosKitUserWallets,
        disconnect: disconnectCosmosKit,
    } = useCosmosKitAccount(getCosmosChainName(cosmosChain))

    const onSetPrimaryWallet = useCallback(
        (newWallet, sdkType) => {
            if (sdkType === SDK.Dynamic) {
                const isEthereum = !checkIfWrongWallet(
                    'ethereum-2-0',
                    newWallet?.address
                )
                if (isLoggedInCosmosKit && isLoggedInDynamic) {
                    disconnectCosmosKit()
                }
                // NB: backward compatibility for Dynamic's Cosmos Hub
                setPrimaryWallet(
                    newWallet
                        ? {
                              ...newWallet,
                              icon: getDynamicWalletIcon(newWallet),
                              chain: {
                                  name: isEthereum ? 'eip155' : 'cosmoshub',
                                  label: isEthereum
                                      ? 'Ethereum: Mainnet'
                                      : 'Cosmos Hub: Mainnet',
                              },
                              sdk: SDK.Dynamic,
                          }
                        : null
                )
            } else if (sdkType === SDK.CosmosKit) {
                setPrimaryWallet(
                    newWallet
                        ? {
                              ...newWallet,
                              icon: newWallet?.logo,
                              sdk: SDK.CosmosKit,
                              chain: {
                                  name:
                                      newWallet?.chain?.name ||
                                      getCosmosChainName(
                                          window.localStorage.getItem(
                                              'selected_cosmos_chain'
                                          )
                                      ),
                              },
                          }
                        : null
                )
            }
        },
        [isLoggedInCosmosKit, disconnectCosmosKit, isLoggedInDynamic]
    )

    useEffect(() => {
        if (isLoggedInDynamic) {
            onSetPrimaryWallet(dynamicPrimaryWallet, SDK.Dynamic)
        } else {
            onSetPrimaryWallet(null, SDK.Dynamic)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicPrimaryWallet?.address, isLoggedInDynamic])

    useEffect(() => {
        if (!isLoggedInDynamic) {
            if (
                isLoggedInCosmosKit &&
                cosmosKitPrimaryWallet &&
                primaryWallet?.address !== cosmosKitPrimaryWallet?.address
            ) {
                onSetPrimaryWallet(cosmosKitPrimaryWallet, SDK.CosmosKit)
            } else {
                // onSetPrimaryWallet(null, SDK.CosmosKit)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedInCosmosKit])

    // Sync Cosmos-kit modal theme and global theme
    const { setModalTheme } = useModalTheme()
    const theme = useTheme()
    useEffect(() => {
        setModalTheme(theme === THEME.DARK ? 'dark' : 'light')
    }, [setModalTheme, theme])

    const isLoggedIn =
        primaryWallet?.sdk === SDK.CosmosKit
            ? isLoggedInCosmosKit
            : isLoggedInDynamic

    return (
        <CombinedWalletsContext.Provider
            value={{
                isLoggedIn,
                primaryWallet: isLoggedIn ? primaryWallet : null,
                setPrimaryWallet: onSetPrimaryWallet,
                user:
                    primaryWallet?.sdk === SDK.CosmosKit
                        ? cosmosKitUser
                        : dynamicUser,
                updateUser:
                    primaryWallet?.sdk === SDK.CosmosKit
                        ? updateUserCosmosKit
                        : updateUserDynamic,
                userWallets:
                    primaryWallet?.sdk === SDK.CosmosKit
                        ? cosmosKitUserWallets
                        : dynamicUserWallets,
                cosmosChain,
                setCosmosChain,
            }}
        >
            {children}
        </CombinedWalletsContext.Provider>
    )
}

export const CombinedWalletsContextProvider = ({ children }) => {
    return (
        <DynamicContextProvider
            settings={{
                environmentId: process.env.NEXT_PUBLIC_DYNAMIC_ENV_ID,
                walletConnectors: [
                    EthereumWalletConnectors,
                    CosmosWalletConnectors,
                    BitcoinWalletConnectors,
                ],
                cssOverrides: `.dynamic-widget-header { padding: 0; } .dynamic-widget-header__switcher { display: none; } .dynamic-widget-wallets { padding: 0; } .dynamic-widget-wallets__title { font-size: 14px; } .dynamic-user-profile-layout { height: auto; padding: 0 1.5rem; } .dynamic-widget-wallets__body { margin-bottom: 24px; } .dynamic-widget-footer { padding: 1rem 0; } .modal-header { border-radius: 100px; } .dynamic-footer { display: none; } .tos-and-pp__link:hover { text-decoration: underline; } .modal-header__trailing { opacity: 0; pointer-events: none; }`,
                privacyPolicyUrl: '/privacy-policy',
                termsOfServiceUrl: '/terms-and-conditions',
                walletsFilter: RemoveWallets([
                    'magicedenbtc',
                    'xverse',
                    'leather',
                    'phantombtc',
                    'phantombtc',
                    'unisat',
                ]),
            }}
        >
            <CosmosWalletContextProvider>
                <WalletsContextProvider>{children}</WalletsContextProvider>
            </CosmosWalletContextProvider>
        </DynamicContextProvider>
    )
}
