import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
    REFRESH_INTERVAL_ASSET_PRICE_MS,
    fetchFormulaMetricsFromAsset,
    fetchFormulaMetricsFromRewardOption,
    fetchRewardOptionCategoriesForCalculator,
    fetchSelectedAsset,
    useProfileMetrics,
} from 'data'
import { getMetricValueByKey } from 'utils/actions'
import {
    getAssetSlugFromRewardOption,
    getOptimalStakingFrequencyNetworkFeeKey,
} from './utils'
import { getMetricsFromFormula } from './calculations/parser'
import { expectedRewardRate } from 'state/assetProfile'
import { SMART_CONTRACT_KEY, TYPE_ASSET } from 'utils/constants'

export function useAssetFromUrl(noRedirect = false, defaultAssetSlug = null) {
    const router = useRouter()
    const [asset, setAsset] = useState(undefined)

    // On the first load, set the asset by the url slug
    useEffect(() => {
        async function fetchAssetData() {
            const slugFromUrl =
                defaultAssetSlug ||
                new URLSearchParams(
                    String(router.asPath).slice(
                        String(router.asPath).indexOf('?')
                    )
                )?.get('asset')
            const calculatorWhere = { includeInCalculator: true }
            const data = await fetchSelectedAsset(
                slugFromUrl,
                slugFromUrl
                    ? calculatorWhere
                    : {
                          ...calculatorWhere,
                          tags: { tagKeys: ['proof-of-stake'] },
                      }
            )
            const assetFromUrl = data?.assets?.[0]

            if (!assetFromUrl) {
                if (slugFromUrl) {
                    // If no asset was found, set the default asset
                    const defaultData = await fetchSelectedAsset('', {
                        includeInCalculator: true,
                        tags: { tagKeys: ['proof-of-stake'] },
                    })
                    setAsset(defaultData?.assets?.[0] ?? null)
                    // and push the default page
                    if (!noRedirect) {
                        router.replace(
                            '/calculator',
                            {
                                pathname: '/calculator',
                            },
                            {
                                shallow: true,
                                scroll: false,
                            }
                        )
                    }
                } else {
                    setAsset(null)
                }
            } else {
                setAsset(assetFromUrl)
            }
        }
        fetchAssetData(defaultAssetSlug)
    }, [defaultAssetSlug]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSetAsset = newAsset => {
        if (newAsset?.slug !== asset?.slug) {
            if (!noRedirect) {
                router.replace(
                    '/calculator',
                    {
                        pathname: '/calculator',
                        query: newAsset?.slug ? { asset: newAsset.slug } : {},
                    },
                    {
                        shallow: true,
                        scroll: false,
                    }
                )
            }
        }
        setAsset(newAsset)
    }

    return [asset, onSetAsset]
}

export function useInflationFormulaWithMetrics(asset) {
    const [inflationFormulaWithMetrics, setInflationFormulaWithMetrics] =
        useState({
            formula: undefined,
            metrics: [],
        })

    useEffect(() => {
        async function fetchInflationMetrics() {
            const assetSlug = asset?.slug
            const inflationFormula = asset?.inflationFormula ?? null
            let metrics = []
            if (assetSlug && inflationFormula) {
                const metricKeys = getMetricsFromFormula(inflationFormula)
                if ((metricKeys?.length ?? 0) > 0) {
                    const response = await fetchFormulaMetricsFromAsset(
                        assetSlug,
                        metricKeys
                    )
                    const fetchedMetrics = response?.assets?.[0]?.metrics ?? []
                    const metricsWithUserValues = fetchedMetrics?.map(metric =>
                        metric?.isEditable
                            ? { ...metric, userValue: metric?.defaultValue }
                            : metric
                    )
                    metrics = metricsWithUserValues
                }
            }

            setInflationFormulaWithMetrics({
                formula: inflationFormula,
                metrics,
            })
        }

        setInflationFormulaWithMetrics({
            formula: undefined,
            metrics: [],
        })

        if (asset) {
            fetchInflationMetrics()
        }
    }, [asset])

    return [inflationFormulaWithMetrics, setInflationFormulaWithMetrics]
}

export function useRewardFormulaWithMetrics(option) {
    const [rewardFormulaWithMetrics, setRewardFormulaWithMetrics] = useState({
        formula: undefined,
        metrics: [],
    })

    useEffect(() => {
        async function updateRewardOptionFormulaData() {
            const assetSlug = getAssetSlugFromRewardOption(option)
            let formula
            let metrics
            if (assetSlug && option?.rewardFormula) {
                // Set metrics from option?.rewardFormula
                const allMetricKeys = getMetricsFromFormula(
                    option?.rewardFormula
                )
                const formulaKeys = allMetricKeys?.filter(
                    key =>
                        ![
                            'user_price',
                            'user_input',
                            'user_days',
                            'reward_frequency',
                        ].includes(key)
                )
                const optFrequencyMetricKey =
                    getOptimalStakingFrequencyNetworkFeeKey(assetSlug)
                const metricKeys = Boolean(optFrequencyMetricKey)
                    ? [...formulaKeys, optFrequencyMetricKey]
                    : formulaKeys

                if ((metricKeys?.length ?? 0) > 0) {
                    let fetchedMetrics = []
                    if (option?.type?.key === SMART_CONTRACT_KEY) {
                        const rewardOptionWithMetrics =
                            await fetchFormulaMetricsFromRewardOption(
                                option?.id,
                                metricKeys
                            )
                        const rewardOptionMetrics =
                            rewardOptionWithMetrics?.rewardOptions?.[0]
                                ?.metrics ?? []

                        let assetMetrics = []
                        if (Boolean(optFrequencyMetricKey)) {
                            const assetWithMetrics =
                                await fetchFormulaMetricsFromAsset(assetSlug, [
                                    optFrequencyMetricKey,
                                ])
                            assetMetrics =
                                assetWithMetrics?.assets?.[0]?.metrics ?? []
                        }
                        fetchedMetrics = [
                            ...rewardOptionMetrics,
                            ...assetMetrics,
                        ]
                    } else {
                        const assetWithMetrics =
                            await fetchFormulaMetricsFromAsset(
                                assetSlug,
                                metricKeys
                            )
                        fetchedMetrics =
                            assetWithMetrics?.assets?.[0]?.metrics ?? []
                    }
                    const metricsWithUserValues = fetchedMetrics?.map(metric =>
                        metric?.isEditable
                            ? { ...metric, userValue: metric?.defaultValue }
                            : metric
                    )
                    formula = option?.rewardFormula ?? null
                    metrics = metricsWithUserValues
                } else {
                    formula = option?.rewardFormula ?? null
                    metrics = []
                }
            } else {
                formula = null
                metrics = []
            }
            setRewardFormulaWithMetrics({
                formula,
                metrics,
            })
        }

        setRewardFormulaWithMetrics({
            formula: undefined,
            metrics: [],
        })

        if (option) {
            updateRewardOptionFormulaData(option)
        }
    }, [option])

    return [rewardFormulaWithMetrics, setRewardFormulaWithMetrics]
}

export function useRewardOptionData(asset = null) {
    // Use "undefined" for loading and "null" for the missing/empty state
    const [option, setOption] = useState(undefined)
    const [validator, setValidator] = useState(null)
    const [defaultOption, setDefaultOption] = useState(undefined)
    const [existingRewardOptionCategories, setExistingRewardOptionCategories] =
        useState([])

    const onSetOption = (newOption, newValidator = undefined) => {
        setOption(newOption)
        const optionValidator =
            newValidator === undefined
                ? newOption?.validators?.[0]
                : newValidator

        setValidator(optionValidator ?? null)
    }

    useEffect(() => {
        async function updateRewardOptionCategoryData() {
            if (!asset?.isActive) {
                setExistingRewardOptionCategories([])
                // TODO fix this
                const testRewardOption = {
                    metrics: [
                        {
                            metricKey: 'reward_rate',
                            defaultValue: expectedRewardRate.value,
                        },
                    ],
                }
                setDefaultOption(testRewardOption)
                onSetOption(testRewardOption)
            } else {
                const { existingRewardOptionCategories, defaultRewardOption } =
                    await fetchRewardOptionCategoriesForCalculator(asset?.slug)

                setDefaultOption(defaultRewardOption)
                setExistingRewardOptionCategories(
                    existingRewardOptionCategories
                )
                onSetOption(defaultRewardOption)
            }
        }

        setDefaultOption(undefined)
        setExistingRewardOptionCategories([])

        if (asset?.slug) {
            updateRewardOptionCategoryData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset?.slug])

    return {
        option,
        setOption: onSetOption,
        validator,
        setValidator,
        defaultOption,
        existingRewardOptionCategories,
    }
}

export const useAssetPrice = (slug = '') => {
    const { data: assetWithMetrics, isLoading } = useProfileMetrics(
        TYPE_ASSET,
        slug,
        ['price'],
        REFRESH_INTERVAL_ASSET_PRICE_MS
    )
    if (isLoading) {
        return undefined
    }
    const price = getMetricValueByKey(assetWithMetrics, 'price')
    return price ?? null
}
