import { parse } from 'esprima'

const metricsKeysMapping = {
    users: 'total_staking_wallets',
    total_users: 'total_staking_wallets',
    annual_earnings: 'fee_revenue',
    annual_earning: 'fee_revenue',
    annual_revenue: 'fee_revenue',
    inflation: 'inflation_rate',
    engaged_balance: 'staked_tokens',
    fees24h: 'daily_fees',
    fees_24h: 'daily_fees',
    fees30d: 'benchmark_daily_fees',
    fees_30d: 'benchmark_daily_fees',
    frequency: 'reward_frequency',
    available_supply: 'total_supply',
    available_supp: 'total_supply',
    floating_supply: 'liquid_supply',
    funds: 'self_staked_tokens',
    self_stake_balance: 'self_staked_tokens',
    self_staked_balance: 'self_staked_tokens',
    staked_balance: 'self_staked_tokens',
    delegated_balance: 'delegated_tokens',
    delegated_amount: 'delegated_tokens',
    total_staked: 'staking_ratio',
    total_validator: 'total_validators',
    ideal_total_staked: 'target_staking_ratio',
    default_provider_fee: 'benchmark_commission',
    default_service_fee: 'benchmark_commission',
    locked_balance: 'total_locked_tokens',
    proposed_inflation: 'proposed_inflation_rate',
    current_reward: 'reward_rate',
    current_reward_rate: 'reward_rate',
    current_inflation: 'inflation_rate',
    // TODO: Add more keys if needed
}

export const getMetricsFromFormula = (rewardFormula = '') => {
    // Format the formula to replace old keys with new keys
    const formattedFormula = rewardFormula

    // Remove all Math. functions
    const string = formattedFormula?.replace(/Math\.[a-z]+/g, '') ?? ''

    // Get all the rest variables from the formula
    const variables = string?.match(/\b\w+\b/g) ?? []

    // Filter out all the numbers and key words
    const metrics = variables
        .filter(variable => isNaN(variable))
        .filter(variable => !['if', 'else', 'return'].includes(variable))

    // Filter out all the duplicate variables
    const uniqueMetrics = metrics.filter(
        (variable, index) => metrics.indexOf(variable) === index
    )

    const newlyMappedMetrics = uniqueMetrics.map(oldKey => {
        const newKey = metricsKeysMapping?.[oldKey]
        return newKey ?? oldKey
    })

    return newlyMappedMetrics
}

export const evaluate = (node = {}, scope = {}) => {
    switch (node?.type) {
        case 'BinaryExpression':
            const left = evaluate(node?.left, scope)
            const right = evaluate(node?.right, scope)

            switch (node?.operator) {
                case '+':
                    return left + right
                case '-':
                    return left - right
                case '*':
                    return left * right
                case '/':
                    return right !== 0 ? left / right : NaN
                case '^':
                case '**':
                    return Math.pow(left, right)
                case '==':
                case '===':
                    return left === right
                case '!=':
                case '!==':
                    return left !== right
                case '<':
                    return left < right
                case '<=':
                    return left <= right
                case '>':
                    return left > right
                case '>=':
                    return left >= right
                default:
                    throw new Error(`Unsupported operator: ${node?.operator}`)
                // TODO: Add other operators if needed
            }
            break
        case 'UnaryExpression':
            const arg = evaluate(node?.argument, scope)
            switch (node?.operator) {
                case '-':
                    return -arg
                default:
                    throw new Error(`Unsupported operator: ${node?.operator}`)
            }
        case 'Literal':
            return node?.value
        case 'Identifier':
            if (node?.name in scope) {
                return scope?.[node?.name]
            } else if (node?.name in metricsKeysMapping) {
                const newKey = metricsKeysMapping[node?.name]
                return scope?.[newKey]
            } else {
                throw new Error(`Undefined variable: ${node?.name}`)
            }
        case 'BlockStatement':
            let result
            node?.body?.forEach(n => {
                result = evaluate(n, scope)
                if (n?.type === 'ReturnStatement') {
                    return false
                }
            })
            return result
        case 'ReturnStatement':
            return evaluate(node?.argument, scope)
        case 'ConditionalExpression':
            if (evaluate(node?.test, scope)) {
                return evaluate(node?.consequent, scope)
            } else {
                return evaluate(node?.alternate, scope)
            }
        case 'FunctionExpression':
            const innerScope = { ...scope }
            node?.params?.forEach((param, index) => {
                innerScope[param?.name] = node?.arguments?.[index]
            })
            return evaluate(node?.body, innerScope)
        case 'ArrowFunctionExpression':
            const body = node?.body
            if (body?.type === 'BlockStatement') {
                // Evaluate each statement in the block
                for (const statement of body?.body) {
                    if (statement?.type === 'ReturnStatement') {
                        return evaluate(statement?.argument, scope)
                    } else if (statement?.type === 'IfStatement') {
                        const branchResult = evaluate(statement, scope)
                        if (branchResult !== undefined) {
                            return branchResult
                        }
                        continue
                    }
                    // TODO: Add other statement types if needed
                }
            } else {
                // If the body is not a BlockStatement, it must be an expression
                // Return the evaluated value of the expression
                return evaluate(body, scope)
            }
            break
        case 'IfStatement':
            // Evaluate the condition
            let test = evaluate(node?.test, scope)
            // Check the condition and return either the consequent or alternate
            if (test && node?.consequent) {
                return evaluate(node?.consequent, scope)
            }
            if (!test && node?.alternate) {
                return evaluate(node?.alternate, scope)
            }
            return undefined // No consequent or alternate, proceed to the next statement
        case 'CallExpression':
            // Get the arguments passed to the function
            let args = node?.arguments?.map(arg => evaluate(arg, scope))

            if (node?.callee?.type === 'MemberExpression') {
                if (node?.callee?.object?.name === 'Math') {
                    return Math?.[node?.callee?.property?.name]?.(...args)
                } else {
                    throw new Error(
                        `Unsupported JS function: ${
                            (node, node?.callee?.object?.name)
                        }`
                    )
                }
            } else if (node?.callee?.type === 'ArrowFunctionExpression') {
                // Evaluate the arrow function
                return evaluate(node?.callee, scope)
            } else {
                // TODO: Add other call expressions if needed
                throw new Error(`Unsupported CallExpression: ${node}`)
            }
        case 'LogicalExpression':
            const leftLogical = evaluate(node?.left, scope)
            const rightLogical = evaluate(node?.right, scope)

            switch (node?.operator) {
                case '&&':
                    return leftLogical && rightLogical
                case '||':
                    return leftLogical || rightLogical
                default:
                    throw new Error(
                        `Unsupported logical operator: ${node?.operator}`
                    )
            }
        default:
            throw new Error(`Unsupported expression: ${node?.type}`)
    }
}

export const parseAndEvaluateFormula = (expression = '', scope = {}) => {
    const ast = parse(expression, { ecmaVersion: 11, sourceType: 'script' })
    const astRoot = ast?.body?.[0]?.expression ?? null
    return astRoot !== null ? evaluate(astRoot, scope) : null
}
