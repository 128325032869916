import dynamic from 'next/dynamic'
import { Table } from 'components/tables'
import { TYPE_ASSET, TYPE_PROVIDER } from 'utils/constants'
import { useRewardOptionsData } from './hooks'

const RewardOptionCard = dynamic(
    () => import('./rewardOptionCard').then(mod => mod.RewardOptionCard),
    {
        ssr: false,
    }
)

const RewardOptionTableRow = dynamic(
    () =>
        import('./rewardOptionTableRow').then(mod => mod.RewardOptionTableRow),
    {
        ssr: false,
    }
)

export const ArchiveTable = ({
    headerType = TYPE_ASSET,
    profileItem = null,
}) => {
    const { data, isLoading, noDataFound, isEnd, avsLogos } =
        useRewardOptionsData(
            profileItem,
            // on the asset profile, show providers, and vice versa
            headerType === TYPE_ASSET ? TYPE_PROVIDER : TYPE_ASSET
        )
    return (
        <Table
            data={data}
            isLoading={isLoading}
            noDataFound={noDataFound}
            isEnd={isEnd}
            getItemsFromData={data => {
                return [...data].flat()
            }}
            profileItem={profileItem}
            renderItemComponent={(isGrid, params) => {
                const ItemComponent = isGrid
                    ? RewardOptionCard
                    : RewardOptionTableRow
                return (
                    <ItemComponent
                        {...params}
                        headerType={headerType}
                        profileItem={profileItem}
                        avsLogos={avsLogos}
                    />
                )
            }}
        />
    )
}
