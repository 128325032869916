import classNames from 'classnames'
import { StatusLabel } from 'components/ui/itemWithLogo'
import { TooltipOnHover } from 'components/ui/tooltip'

export const ActiveMarker = ({ status }) => {
    return (
        <TooltipOnHover text={status?.status} className='mr-2'>
            <div
                className={classNames(
                    'w-[6px] h-[6px] min-w-[6px] min-h-[6px] rounded-full',
                    {
                        activeMarker:
                            status?.status === StatusLabel.Active ||
                            !status?.status,
                        standbyMarker: status?.status === StatusLabel.Standby,
                        inactiveMarker: status?.status === StatusLabel.Inactive,
                    }
                )}
            />
        </TooltipOnHover>
    )
}
