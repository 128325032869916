import classNames from 'classnames'

export const ItemCountTag = ({ count, className }) => {
    return (
        <span
            className={classNames(
                'ml-1 text-[10px] font-normal p-1 !text-contrast-0 !bg-contrast-2/20 rounded-[4px]',
                className
            )}
            style={{ background: 'none' }}
        >
            {count}
        </span>
    )
}
