import classNames from 'classnames'
import { getRating } from 'components/providers/profile/utils'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { getAnimationClassNames } from 'components/rewardOptions/archive/v2/getFormattedData'
import { CopyAddress, TooltipOnHover } from 'components/ui'
import { useTimeElapsed } from 'hooks'
import Image from 'next/image'
import { compressAddress } from 'utils/stakingAssistant/utils'
import { TOOLTIPS } from 'components/rewardOptions/constants'
import { MAX_TOOLTIP_TEXT_LENGTH, truncateString } from 'utils/formatter'
import {
    CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
    LAVA_SLUG,
} from 'utils/constants'

export const ExpandedPoS = ({
    formattedData,
    validators,
    isExpanded,
    sortByKey,
}) => {
    const isLava = formattedData?.item?.inputAssets?.[0]?.slug === LAVA_SLUG
    const stakedTokensUpdatedAt = useTimeElapsed(
        formattedData.stakedTokensCreatedAt
    )
    return (
        <>
            <MetricBox
                title='Performance'
                value={
                    formattedData.performance === ''
                        ? '-'
                        : formattedData.performance
                }
                className={classNames(
                    'block xl:hidden',
                    getAnimationClassNames(0, isExpanded),
                    {
                        ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                            sortByKey === 'performance',
                    }
                )}
                isSmall
            />
            <div className={classNames(getAnimationClassNames(0, isExpanded))}>
                <TooltipOnHover
                    text={truncateString(
                        TOOLTIPS.netStakingFlow7d.text,
                        MAX_TOOLTIP_TEXT_LENGTH
                    )}
                    overlay={{
                        title: TOOLTIPS.netStakingFlow7d.title,
                        allText: TOOLTIPS.netStakingFlow7d.text,
                    }}
                >
                    <MetricBox
                        title='Net Staking Flow 7d'
                        value={formattedData?.netStakingFlow7d}
                        isSmall
                        className={classNames({
                            ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                                sortByKey ===
                                CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
                        })}
                    />
                </TooltipOnHover>
            </div>
            <MetricBox
                title={
                    <div>
                        <Image
                            src={'/static/svg/vsp-tag-extended.svg'}
                            alt={`Verified Provider`}
                            width={36}
                            height={12}
                        />
                    </div>
                }
                value={`${getRating(formattedData.providerVspScore)} VSP Score`}
                isSmall
                className={classNames(getAnimationClassNames(1, isExpanded))}
            />
            {!isLava && (
                <div className={getAnimationClassNames(2, isExpanded)}>
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIPS.stakers.text,
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: TOOLTIPS.stakers.title,
                            allText: TOOLTIPS.stakers.text,
                        }}
                    >
                        <MetricBox
                            title='Stakers'
                            value={formattedData.stakers}
                            change={formattedData.stakersChangePercent}
                            isSmall
                            className={classNames({
                                ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                                    sortByKey === 'staking_wallets',
                            })}
                        />
                    </TooltipOnHover>
                </div>
            )}
            <TooltipOnHover
                text={
                    <MetricBox
                        title='Self Staked Tokens'
                        value={formattedData.selfStakedTokens}
                        change={formattedData.selfStakedTokensChangePercent}
                        className={classNames({
                            ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                                sortByKey === 'self_staked_tokens',
                        })}
                    />
                }
                updatedTimeStatus={stakedTokensUpdatedAt}
            >
                <MetricBox
                    title='Self Staked'
                    value={formattedData.selfStakedUsd}
                    change={formattedData.selfStakedTokensChangePercent}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(3, isExpanded)
                    )}
                />
            </TooltipOnHover>
            <TooltipOnHover
                text={truncateString(
                    TOOLTIPS.networkControl.text,
                    MAX_TOOLTIP_TEXT_LENGTH
                )}
                overlay={{
                    title: TOOLTIPS.networkControl.title,
                    allText: TOOLTIPS.networkControl.text,
                }}
            >
                <MetricBox
                    title='Network Control'
                    value={formattedData.networkControl}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(4, isExpanded),
                        {
                            ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                                sortByKey === 'staking_share',
                        }
                    )}
                />
            </TooltipOnHover>
            {validators?.length === 1 && (
                <MetricBox
                    title={
                        <div className='w-[90px]'>
                            <CopyAddress
                                address={validators[0]?.address}
                                textClassName='!text-[10px] whitespace-nowrap'
                            />
                        </div>
                    }
                    value={compressAddress(validators[0]?.address, 4, 4)}
                    isSmall
                    className={getAnimationClassNames(5, isExpanded)}
                />
            )}
        </>
    )
}
