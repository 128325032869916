import { useTimeElapsed } from 'hooks'

export const ExpandedCustodial = ({
    formattedData,
    validators,
    isExpanded,
    bestRewardOption,
}) => {
    const stakedTokensUpdatedAt = useTimeElapsed(
        formattedData.stakedTokensCreatedAt
    )
    return (
        <div>
            {/* {bestRewardOption?.items?.map(item => {
                return (
                    <div className='flex' key={item?.id}>
                        <MetricBox title='Tier' value={item?.label} isSmall />
                        <div className=''>
                            <TooltipOnHover
                                text={<AssetRewardCalculatorInputsForTable />}
                                textClassName='!w-[300px]'
                            >
                                <MetricBox
                                    title={formattedData?.rewardsAfterTitle}
                                    value={formattedData?.rewardsAfterValue}
                                    isSmall
                                />
                            </TooltipOnHover>
                        </div>
                    </div>
                )
            })} */}
        </div>
    )
}
