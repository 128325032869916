import classNames from 'classnames'
import { useAssetsWithMetrics } from 'data'
import { getObjectFromJsonString } from 'utils/actions'
import { formatOutputNumber } from 'utils/formatter'
import { getMetricObject } from 'utils/getMetricObject'

export const TextAnalyseStakingData = ({ asset }) => {
    const { data } = useAssetsWithMetrics(
        [asset.slug],
        [
            'staked_tokens',
            'staking_ratio',
            'total_staking_wallets',
            'annualized_rewards_usd',
            'net_staking_flow',
        ]
    )

    const assetMetric = data?.assets?.[0]

    const totalStakingWallets = getMetricObject(
        assetMetric,
        'total_staking_wallets'
    )

    const annualizedRewards = getMetricObject(
        assetMetric,
        'annualized_rewards_usd'
    )

    const stakedTokens = getMetricObject(assetMetric, 'staked_tokens')

    const stakingRatio = getMetricObject(assetMetric, 'staking_ratio')

    const netStakingFlow = getMetricObject(assetMetric, 'net_staking_flow')

    const parsedNetStakingFlowPercentages =
        assetMetric &&
        getObjectFromJsonString(netStakingFlow?.changePercentages)

    const isNetStakingFlowPercentage30dNegative =
        parsedNetStakingFlowPercentages?.['30d'] < 0

    const parsedTotalStakingWalletsPercentages =
        assetMetric &&
        getObjectFromJsonString(totalStakingWallets?.changeAbsolutes)

    const parsedStakingRatioPercentages =
        assetMetric && getObjectFromJsonString(stakingRatio?.changePercentages)

    const isTotalStakingWalletsIncreaseNegative =
        parsedTotalStakingWalletsPercentages?.['7d'] < 0

    const formattedAnualizedRewards = formatOutputNumber(
        annualizedRewards?.defaultValue
    )

    const text = `As of today, there are ${totalStakingWallets?.defaultValue} ${asset.symbol === 'ETH' ? 'validators' : 'stakers'} actively staking on the network. ${isTotalStakingWalletsIncreaseNegative ? '' : `An additional ${parsedTotalStakingWalletsPercentages?.['7d'].toFixed(2)} ${asset.symbol} ${asset.symbol === 'ETH' ? 'validators' : 'stakers'} became active over the past 7 days, representing a ${parsedTotalStakingWalletsPercentages?.['7d'].toFixed(2)}% increase over this time period.`} The Staking Ratio, or percentage of ${asset.symbol} being staked, is currently ${stakingRatio?.defaultValue.toFixed(2)}% of the total eligible circulating supply, increasing by ${parsedStakingRatioPercentages?.['7d'].toFixed(2)}% over the past 7 days. In total, ${formatOutputNumber(stakedTokens?.defaultValue)} ${asset.symbol} is staked across the network, generating $${formattedAnualizedRewards} worth of staking rewards per year.`

    return (
        <div
            className={classNames(
                'pt-[48px] pb-[48px] pl-[24px] pr-[24px] border border-r border-l border-b m:border-t border-solid border-contrast-2/40 w-full bg-contrast-1 rounded-b-lg p-3 h-full top-[-6px]'
            )}
        >
            <h3 className='mb-[16px] text-lg font-bold'>{`${asset.name} Staking Over the Past 7 Days`}</h3>

            <p className='text-[--c-mid] font-normal text-base'>{text}</p>
        </div>
    )
}
