import { createSlice } from '@reduxjs/toolkit'

const tableSlice = createSlice({
    name: 'table',
    initialState: {
        showTableControl: false,
        isTableExpanded: false,
        isTouchedExpansion: false,
    },
    reducers: {
        setShowTableControl: (state, action) => {
            state.showTableControl = action.payload
        },
        setIsTableExpanded: (state, action) => {
            state.isTableExpanded = action.payload
        },
        setIsTouchedExpansion: (state, action) => {
            state.isTouchedExpansion = action.payload
        },
    },
})

export const { setShowTableControl, setIsTableExpanded, setIsTouchedExpansion } = tableSlice.actions

export const tableReducer = tableSlice.reducer
