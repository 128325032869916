import classNames from 'classnames'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import {
    ALL_KEY,
    AVS_KEY,
    BITCOIN_AND_OTHERS_KEY,
    LIQUID_STAKING_KEY,
    STABLECOIN_KEY,
} from 'utils/constants'
import { MetricGroup, SortingMetricGroup } from 'components/metrics'
import { PegDeviationMetricGroup } from 'components/metrics/metricGroup'
import { getMoreAssetMetricGroups } from '../constants'
import {
    getAssetMetricGroupsPerCategory,
    getAssetSortByOptionsPerCategory,
} from './utils'
import { StakingButtonInLstArchive } from './stakingButtonInLstArchive'
import { isMetricWithExpansion } from 'utils/actions'

import styles from './assetMetricsCard.module.scss'
import { RewardRateRange } from 'components/metrics/rewardRateRange'

export const AssetMetricsCard = ({
    item = null,
    index = '',
    isLoading = false,
    sortBy = null,
    minimal = false,
    stablecoinData,
}) => {
    const { queryParams } = useTableArchiveContext()

    const categoryKey = queryParams?.category?.key
    const sortByKey = queryParams?.sortBy?.key || sortBy
    const timeframeKey = queryParams?.timeframe?.key

    const isLst = categoryKey === LIQUID_STAKING_KEY
    const isAVS = categoryKey === AVS_KEY
    const isStablecoins = categoryKey === STABLECOIN_KEY
    const isBtc = categoryKey === BITCOIN_AND_OTHERS_KEY
    let metricKeys = []
    if (minimal) {
        metricKeys = ['reward_rate', sortBy === 'price' ? 'price' : undefined]
    } else if (isLst) {
        metricKeys = ['marketcap', 'reward_rate', 'peg_deviation']
    } else if (isAVS) {
        metricKeys = [
            'restaked_tokens',
            'restaking_marketcap',
            'operators',
            'restaking_wallets',
        ]
    } else if (isStablecoins) {
        metricKeys = [
            'price',
            'marketcap',
            'daily_trading_volume',
            'peg_stability',
        ]
    } else if (isBtc) {
        metricKeys = ['price', 'marketcap', 'daily_trading_volume']
    } else {
        metricKeys = [
            'price',
            'reward_rate',
            'staking_marketcap',
            'staking_ratio',
        ]
    }

    const orderedMetrics = metricKeys
        .map(key =>
            getAssetMetricGroupsPerCategory(categoryKey).find(
                g => g.key === key
            )
        )
        .filter(Boolean)

    const sortingMetricGroup = isLst
        ? null
        : getMoreAssetMetricGroups(item?.slug).find(gr => gr.key === sortByKey)

    return (
        <div className={styles.assetMetricsCard}>
            {Boolean(sortingMetricGroup) &&
                !minimal &&
                !isStablecoins &&
                !isBtc && (
                    <SortingMetricGroup
                        metricGroup={{
                            ...sortingMetricGroup,
                            label:
                                getAssetSortByOptionsPerCategory(
                                    categoryKey
                                ).find(opt => opt.key === sortByKey)?.name ||
                                sortingMetricGroup?.label,
                        }}
                        item={item}
                        isLoading={isLoading}
                        timeframeKey={timeframeKey}
                        hasExpansion={isMetricWithExpansion(
                            sortingMetricGroup?.key
                        )}
                        isSortable={getAssetSortByOptionsPerCategory(ALL_KEY)
                            .map(category => category.key)
                            .includes(sortingMetricGroup?.key)}
                    />
                )}
            <div
                className={classNames(styles.grid, {
                    '!gap-x-0': minimal || isAVS,
                })}
            >
                {orderedMetrics.map(metricGroup => (
                    <div
                        className={styles.metric}
                        key={`${item?.id}-${metricGroup?.key ?? ''}-${index}`}
                    >
                        {metricGroup?.key === 'peg_deviation' ? (
                            <PegDeviationMetricGroup
                                metricGroup={metricGroup}
                                item={item}
                                timeframeKey={timeframeKey}
                                highlighted={metricGroup?.key === sortByKey}
                                isLoading={isLoading}
                            />
                        ) : (
                            <MetricGroup
                                metricGroup={
                                    minimal
                                        ? {
                                              ...metricGroup,
                                              withPercentage: false,
                                              withColor: false,
                                          }
                                        : metricGroup
                                }
                                item={item}
                                timeframeKey={timeframeKey}
                                isLoading={isLoading}
                                highlighted={metricGroup?.key === sortByKey}
                                minimal={minimal}
                                hasExpansion={isMetricWithExpansion(
                                    metricGroup?.key
                                )}
                                isSortable={getAssetSortByOptionsPerCategory(
                                    ALL_KEY
                                )
                                    .map(category => category.key)
                                    .includes(metricGroup?.key)}
                            />
                        )}
                    </div>
                ))}
                {sortingMetricGroup &&
                    minimal &&
                    (sortingMetricGroup?.key === 'peg_deviation' ? (
                        <PegDeviationMetricGroup
                            metricGroup={sortingMetricGroup}
                            item={item}
                            timeframeKey={timeframeKey}
                            highlighted={true}
                            isLoading={isLoading}
                        />
                    ) : (
                        <MetricGroup
                            metricGroup={
                                minimal
                                    ? {
                                          ...sortingMetricGroup,
                                          withPercentage: false,
                                          withColor: false,
                                      }
                                    : sortingMetricGroup
                            }
                            item={item}
                            timeframeKey={timeframeKey}
                            isLoading={isLoading}
                            highlighted={true}
                            minimal={minimal}
                            hasExpansion={isMetricWithExpansion(
                                sortingMetricGroup?.key
                            )}
                            isSortable={getAssetSortByOptionsPerCategory(
                                ALL_KEY
                            )
                                .map(category => category.key)
                                .includes(sortingMetricGroup?.key)}
                        />
                    ))}
                {(isStablecoins || isBtc) && (
                    <RewardRateRange data={stablecoinData} />
                )}
            </div>
            <StakingButtonInLstArchive asset={item} />
        </div>
    )
}
