import { getMetricValueByKey, calculateIfValid } from 'utils/actions'
import { getNumberOrDefault, getRateObject } from '../utils'
import { parseAndEvaluateFormula } from './parser'
import { SMART_CONTRACT_KEY, SOLO_STAKING_KEY } from 'utils/constants'

export const evaluateFormula = (formula = '', scope = {}) => {
    let result = null
    try {
        result = parseAndEvaluateFormula(formula, scope)
    } catch (err) {
        console.log(`Error evaluating the formula: ${err?.message}`)
    }

    return result
}

export const getTokenPriceOnDay = (
    day = null,
    startingPriceUsd = null,
    expectedPriceUsd = null,
    stakingTimeInDays = null
) => {
    if (day !== 0) {
        const timePower = calculateIfValid(
            ({ day, stakingTimeInDays }) =>
                Math.min(day, stakingTimeInDays) / stakingTimeInDays,
            { day, stakingTimeInDays }
        )
        const result = calculateIfValid(
            ({ expectedPriceUsd, startingPriceUsd, timePower }) =>
                expectedPriceUsd -
                (expectedPriceUsd - startingPriceUsd) *
                    ((1 / 120) ** timePower - 1 / 120),
            {
                expectedPriceUsd,
                startingPriceUsd,
                timePower,
            }
        )
        return getNumberOrDefault(result, 0)
    } else {
        return getNumberOrDefault(startingPriceUsd, 0)
    }
}

export const getFormulaResultWithUserParams = (
    afterNumberOfDays = 365,
    rewardFormula = null,
    metrics = [],
    clientMetricsScope = {}
) => {
    const userMetricsScope = metrics.reduce(
        (acc, { metricKey, defaultValue, userValue, isEditable }) => {
            acc[metricKey] = isEditable ? userValue : defaultValue
            return acc
        },
        {}
    )
    const userResult = evaluateFormula(rewardFormula, {
        ...userMetricsScope,
        ...clientMetricsScope,
        user_days: afterNumberOfDays,
    })
    return Number(userResult)
}

export const getDiscrepancy = (
    afterNumberOfDays = 365,
    rewardFormula = null,
    metrics = [],
    clientMetricsScope = {}
) => {
    let calculateWithoutFormula = false
    let defaultResult = 1
    if (!rewardFormula) {
        calculateWithoutFormula = true
    } else {
        const defaultMetricsScope = metrics.reduce(
            (acc, { metricKey, defaultValue }) => {
                acc[metricKey] = defaultValue
                return acc
            },
            {}
        )
        defaultResult = rewardFormula
            ? evaluateFormula(rewardFormula, {
                  ...defaultMetricsScope,
                  ...clientMetricsScope,
                  user_days: 365,
              })
            : null

        if (defaultResult === null) {
            calculateWithoutFormula = true
        }
    }

    if (calculateWithoutFormula) {
        return afterNumberOfDays / 365
    } else {
        const userResult = getFormulaResultWithUserParams(
            afterNumberOfDays,
            rewardFormula,
            metrics,
            clientMetricsScope
        )
        return Number(userResult / defaultResult)
    }
}

export const getInflationRate = (
    inflationFormula = null,
    metrics = [],
    clientMetricsScope = {},
    assetInflationRate = { fraction: null, percentage: null },
    stakingTimeInDays = 0
) => {
    let calculateWithoutFormula = false
    let fraction

    if (!inflationFormula) {
        calculateWithoutFormula = true
    } else {
        const metricsScope = metrics.reduce(
            (acc, { metricKey, defaultValue, userValue, isEditable }) => {
                acc[metricKey] = isEditable ? userValue : defaultValue
                return acc
            },
            {}
        )

        fraction = inflationFormula
            ? evaluateFormula(inflationFormula, {
                  ...metricsScope,
                  ...clientMetricsScope,
                  user_days: stakingTimeInDays,
              })
            : null

        if (fraction === null) {
            calculateWithoutFormula = true
        }
    }

    if (calculateWithoutFormula) {
        fraction = calculateIfValid(({ percentage }) => percentage / 100, {
            percentage: assetInflationRate?.percentage,
        })
    }

    return getRateObject(fraction)
}

export const getStakingRewardInTokens = (
    afterNumberOfDays = 1,
    stakingAmountTokens = 0,
    expectedTokenPrice = 0,
    rewardRateFraction = 0,
    rewardFrequency = 0,
    formula = null,
    metrics = [],
    rewardOptionType = ''
) => {
    let adjRewardRateFraction
    if (
        !formula ||
        ![SOLO_STAKING_KEY, SMART_CONTRACT_KEY].includes(rewardOptionType)
    ) {
        const discrepancy = getDiscrepancy(
            afterNumberOfDays,
            formula,
            metrics,
            {
                user_input: stakingAmountTokens,
                user_price: expectedTokenPrice,
                reward_frequency: rewardFrequency,
            }
        )
        adjRewardRateFraction = Number(rewardRateFraction * discrepancy)
    } else {
        adjRewardRateFraction = getFormulaResultWithUserParams(afterNumberOfDays, formula, metrics, {
            user_input: stakingAmountTokens,
            user_price: expectedTokenPrice,
            reward_frequency: rewardFrequency,
        })
    }

    return Number(adjRewardRateFraction * stakingAmountTokens)
}

export const getStakingReward = (
    afterNumberOfDays = 1,
    stakingAmountTokens = 0,
    usd = true,
    stakingTimeInDays = '',
    initialTokenPrice = 0,
    expectedTokenPrice = '',
    rewardRateFraction = 0,
    rewardFrequency = 0,
    formula = null,
    metrics = [],
    rewardOptionType = ''
) => {
    if (usd) {
        const day = Math.min(stakingTimeInDays, afterNumberOfDays)
        const tokenPrice = getTokenPriceOnDay(
            day,
            initialTokenPrice,
            expectedTokenPrice,
            stakingTimeInDays
        )

        const stakingRewardInTokens = getStakingRewardInTokens(
            day,
            stakingAmountTokens,
            expectedTokenPrice,
            rewardRateFraction,
            rewardFrequency,
            formula,
            metrics,
            rewardOptionType
        )

        return (
            tokenPrice * stakingRewardInTokens +
            (tokenPrice - initialTokenPrice) * stakingAmountTokens
        )
    }

    return getStakingRewardInTokens(
        afterNumberOfDays,
        stakingAmountTokens,
        expectedTokenPrice,
        rewardRateFraction,
        rewardFrequency,
        formula,
        metrics,
        rewardOptionType
    )
}

export const getTotalRewardRate = (
    stakingAmountTokens = 0,
    stakingTimeInDays = '',
    initialTokenPrice = 0,
    expectedTokenPrice = '',
    rewardRateFraction = 0,
    rewardFrequency = 0,
    formula = null,
    metrics = [],
    rewardOptionType = ''
) => {
    const initialTokenRevenue = getStakingRewardInTokens(
        0,
        stakingAmountTokens,
        expectedTokenPrice,
        rewardRateFraction,
        rewardFrequency,
        formula,
        metrics,
        rewardOptionType
    )

    const fraction = calculateIfValid(
        ({
            lastTokenPrice,
            stakingAmountTokens,
            finalTokenRevenue,
            initialTotalUsdValue,
        }) => {
            const lastTotalUsdValue =
                lastTokenPrice * (stakingAmountTokens + finalTokenRevenue)
            return lastTotalUsdValue / initialTotalUsdValue - 1
        },
        {
            lastTokenPrice: getTokenPriceOnDay(
                stakingTimeInDays,
                initialTokenPrice,
                expectedTokenPrice,
                stakingTimeInDays
            ),
            stakingAmountTokens,
            finalTokenRevenue: getStakingRewardInTokens(
                stakingTimeInDays,
                stakingAmountTokens,
                expectedTokenPrice,
                rewardRateFraction,
                rewardFrequency,
                formula,
                metrics,
                rewardOptionType
            ),
            initialTotalUsdValue: calculateIfValid(
                ({
                    initialTokenPrice,
                    stakingAmountTokens,
                    initialTokenRevenue,
                }) =>
                    initialTokenPrice *
                    (stakingAmountTokens + initialTokenRevenue),
                { initialTokenPrice, stakingAmountTokens, initialTokenRevenue }
            ),
        }
    )

    return getRateObject(fraction)
}

export const getAdjReward = (
    asset = null,
    rewardRateFraction = 0,
    stakingTimeInDays = 0,
    rewardFormula = null,
    rewardMetrics = [],
    inflationFormula = null,
    inflationMetrics = [],
    clientMetricsScope = {},
    rewardOptionType = ''
) => {
    const assetInflationRate = getRateObject(
        calculateIfValid(({ percentage }) => percentage / 100, {
            percentage: getMetricValueByKey(asset, 'inflation_rate'),
        })
    )

    const inflationRate = getInflationRate(
        inflationFormula,
        inflationMetrics,
        clientMetricsScope,
        assetInflationRate,
        stakingTimeInDays
    )

    const adjRewardRateFraction = Number(
        getStakingRewardInTokens(
            stakingTimeInDays,
            clientMetricsScope.user_input,
            clientMetricsScope.user_price,
            rewardRateFraction,
            clientMetricsScope.reward_frequency,
            rewardFormula,
            rewardMetrics,
            rewardOptionType
        ) / clientMetricsScope.user_input
    )

    const adjRewardFraction = calculateIfValid(
        ({ adjRewardRateFraction, inflationRateFraction }) =>
            (1 + adjRewardRateFraction) / (1 + inflationRateFraction) - 1,
        {
            adjRewardRateFraction,
            inflationRateFraction: inflationRate?.fraction,
        }
    )

    return getRateObject(adjRewardFraction)
}

export const getNetworkShare = (
    stakingAmountTokens = null,
    availableSupply = null
) => {
    const networkShareFraction = calculateIfValid(
        ({ stakingAmountTokens, availableSupply }) =>
            stakingAmountTokens / availableSupply,
        {
            stakingAmountTokens,
            availableSupply,
        }
    )
    return getRateObject(networkShareFraction)
}

export const getOptimalStakingFrequencyResults = (
    stakingAmountTokens = 0,
    stakingTimeInDays = 0,
    restakingFee = 0,
    totalRewardRateFraction = 0,
    annualizedRewardRateFraction = 0
) => {
    const finalBalance = []

    for (let i = 1; i <= stakingTimeInDays; i++) {
        let balance = stakingAmountTokens
        const stakingAmount =
            (totalRewardRateFraction / i) * stakingAmountTokens

        let totalDays = 0
        let daysTaken
        let loopLength = 0
        while (totalDays <= stakingTimeInDays) {
            daysTaken = getNumberOrDefault(
                calculateIfValid(
                    ({
                        stakingAmount,
                        stakingTimeInDays,
                        balance,
                        totalRewardRateFraction,
                    }) =>
                        (stakingAmount * stakingTimeInDays) /
                        (balance * totalRewardRateFraction),
                    {
                        stakingAmount,
                        stakingTimeInDays,
                        balance,
                        totalRewardRateFraction,
                    }
                ),
                0
            )

            totalDays += daysTaken // infinite loop is prevented by loopLength
            balance = balance + (stakingAmount - restakingFee)
            loopLength += 1

            if (loopLength > stakingTimeInDays) break
        }

        const remainingTime = stakingTimeInDays - (totalDays - daysTaken)
        const finalReward =
            (remainingTime / stakingTimeInDays) *
            totalRewardRateFraction *
            balance
        finalBalance.push(balance + finalReward - stakingAmount)
    }

    const MB = Math.max(...finalBalance)

    const apyFraction = calculateIfValid(
        ({ MB, stakingAmountTokens, stakingTimeInDays }) =>
            (MB / stakingAmountTokens) ** (1 / (stakingTimeInDays / 365)) - 1,
        { MB, stakingAmountTokens, stakingTimeInDays }
    )

    const bonusTokens = calculateIfValid(
        ({
            apy,
            annualizedRewardRateFraction,
            stakingAmountTokens,
            stakingTimeInDays,
        }) =>
            (apy - annualizedRewardRateFraction) *
            stakingAmountTokens *
            (stakingTimeInDays / 365),
        {
            apy: apyFraction,
            annualizedRewardRateFraction,
            stakingAmountTokens,
            stakingTimeInDays,
        }
    )

    const optimalStakingFrequency = calculateIfValid(
        ({ finalBalanceMBIndex, stakingTimeInDays }) =>
            (stakingTimeInDays / (finalBalanceMBIndex + 1)) * 86400,
        { finalBalanceMBIndex: finalBalance?.indexOf(MB), stakingTimeInDays }
    ) // in seconds

    return {
        optimalStakingFrequency,
        apy: getRateObject(apyFraction),
        bonusTokens,
    }
}

export const getGraphSeriesForStakingTime = (
    stakingAmountTokens = 0,
    formula = null,
    metrics = [],
    rewardRateDbFraction = 0,
    rewardFrequency = 0,
    initialTokenPrice = 0,
    expectedTokenPrice = 0,
    stakingTimeInDays = 0,
    graphTimeSpanInDays = 0,
    usd = true,
    rewardOptionType = ''
) => {
    const totalUsdValues = []
    const totalUsdValuesCompounded = []
    const sellTotal = []
    const notStaking = []

    let lastCompound = 0
    let tokensNotCompounded = 0
    let tokensStaked = stakingAmountTokens
    let previousTokenRevenueValue = 0
    let sellRevenueCumsum = 0
    let tokenRevenueDiff = 0
    let tokenRevenueValue = 0
    let tokenRevenueCompoundedValue = 0

    for (let x = 0; x <= graphTimeSpanInDays; x++) {
        const rewardRate = getNumberOrDefault(
            Number(
                getStakingRewardInTokens(
                    x,
                    stakingAmountTokens,
                    expectedTokenPrice,
                    rewardRateDbFraction,
                    rewardFrequency,
                    formula,
                    metrics,
                    rewardOptionType
                ) / stakingAmountTokens
            ),
            0
        )
        tokensNotCompounded =
            x === 0
                ? tokensNotCompounded
                : tokensNotCompounded + (rewardRate / x) * tokensStaked
        if (x * 86400 - rewardFrequency > lastCompound) {
            lastCompound = x * 86400
            tokensStaked += tokensNotCompounded
            tokensNotCompounded = 0
        }
        tokenRevenueValue = rewardRate * stakingAmountTokens
        tokenRevenueCompoundedValue =
            tokensNotCompounded + tokensStaked - stakingAmountTokens
        tokenRevenueDiff = tokenRevenueValue - previousTokenRevenueValue

        previousTokenRevenueValue = tokenRevenueValue

        if (usd) {
            const priceOnDay = getTokenPriceOnDay(
                x,
                initialTokenPrice,
                expectedTokenPrice,
                stakingTimeInDays
            )
            const notStakingValue = priceOnDay * stakingAmountTokens
            const usdRevenueValue = priceOnDay * tokenRevenueValue
            const usdRevenueCompoundedValue =
                priceOnDay * tokenRevenueCompoundedValue

            sellRevenueCumsum += tokenRevenueDiff * priceOnDay

            notStaking.push(
                notStakingValue - initialTokenPrice * stakingAmountTokens
            )
            totalUsdValues.push(
                notStakingValue +
                    usdRevenueValue -
                    initialTokenPrice * stakingAmountTokens
            )
            totalUsdValuesCompounded.push(
                notStakingValue +
                    usdRevenueCompoundedValue -
                    initialTokenPrice * stakingAmountTokens
            )
            sellTotal.push(
                sellRevenueCumsum +
                    notStakingValue -
                    initialTokenPrice * stakingAmountTokens
            )
        } else {
            sellRevenueCumsum += tokenRevenueDiff

            notStaking.push(0)
            totalUsdValues.push(tokenRevenueValue)
            totalUsdValuesCompounded.push(tokenRevenueCompoundedValue)
            sellTotal.push(sellRevenueCumsum)
        }
    }

    return {
        totalUsdValues,
        totalUsdValuesCompounded,
        sellTotal,
        notStaking,
    }
}

export const getBreakEven = (
    stakingAmountTokens = 0,
    formula = null,
    metrics = [],
    rewardRateDbFraction = 0,
    rewardFrequency = 0,
    initialTokenPrice = 0,
    expectedTokenPrice = 0,
    stakingTimeInDays = 0,
    graphTimeSpanInDays = 0,
    usd = true,
    rewardOptionType = ''
) => {
    const totalUsdValues = []

    let lastCompound = 0
    let tokensNotCompounded = 0
    let tokensStaked = stakingAmountTokens

    let x
    for (x = 0; x <= graphTimeSpanInDays; x++) {
        let tokenRevenueValue
        const rewardRate = getNumberOrDefault(
            Number(
                getStakingRewardInTokens(
                    x,
                    stakingAmountTokens,
                    expectedTokenPrice,
                    rewardRateDbFraction,
                    rewardFrequency,
                    formula,
                    metrics,
                    rewardOptionType
                ) / stakingAmountTokens
            ),
            0
        )

        tokensNotCompounded =
            x === 0
                ? tokensNotCompounded
                : tokensNotCompounded + (rewardRate / x) * tokensStaked
        if (x * 86400 - rewardFrequency > lastCompound) {
            lastCompound = x * 86400
            tokensStaked += tokensNotCompounded
            tokensNotCompounded = 0
        }
        tokenRevenueValue = rewardRate * stakingAmountTokens

        if (usd) {
            const priceOnDay = getTokenPriceOnDay(
                x,
                initialTokenPrice,
                expectedTokenPrice,
                stakingTimeInDays
            )
            const notStakingValue = priceOnDay * stakingAmountTokens
            const usdRevenueValue = priceOnDay * tokenRevenueValue

            totalUsdValues.push(
                notStakingValue +
                    usdRevenueValue -
                    initialTokenPrice * stakingAmountTokens
            )
        } else {
            totalUsdValues.push(tokenRevenueValue)
        }

        if (totalUsdValues?.[x - 1] <= 0 && totalUsdValues?.[x] >= 0) {
            return x
        }
    }

    return null
}
