import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { Pagination, Skeleton } from 'components/ui'
import { ArticlePost, useJournalPosts } from 'components/journal'

import styles from './journalSection.module.scss'
import { HeaderWithLink } from 'components/text/headerWithLink'

const PAGE_SIZE = 8
const initialState = { 0: [] }

const JournalSectionComponent = ({
    slug = '',
    assetName = '',
    page = 0,
    pageSizeNumber = PAGE_SIZE,
    setHasMore = () => {},
}) => {
    const showVerticalPosts = useMediaPredicate('(max-width: 1199px)')
    const [batches, setBatches] = useState(initialState)
    const { data, isLoading } = useJournalPosts(
        '',
        page * pageSizeNumber,
        pageSizeNumber,
        slug,
        true
    )

    useEffect(() => {
        if (page === 0) {
            setBatches({ [page]: data })
        } else {
            setBatches(batches => ({ ...batches, [page]: data }))
        }
        setHasMore((data?.length ?? 0) >= pageSizeNumber)
    }, [page, pageSizeNumber, data, setHasMore])

    return (
        <div id='section-research-and-guides' className={styles.journalSection}>
            <div className={classNames('container', styles.hfull)}>
                <div className={styles.content}>
                    <p className={styles.description}>
                        From the Staking Rewards Journal
                    </p>
                    <HeaderWithLink
                        title={`Dive Deep into ${assetName}`}
                        href={`/asset/${slug}#section-research-and-guides`}
                    />
                    <div className='mt-4'>
                        {Object.values(batches).map((batch, batchIdx) => (
                            <div
                                key={`post-batch-${batchIdx}`}
                                className={styles.latestPosts}
                            >
                                {isLoading && batchIdx === page
                                    ? Array.from(
                                          { length: PAGE_SIZE },
                                          (_, index) => index
                                      ).map(idx => (
                                          <div
                                              key={`post-${idx}`}
                                              className={styles.skeleton}
                                          >
                                              <Skeleton
                                                  width={'100%'}
                                                  height={'100%'}
                                              />
                                          </div>
                                      ))
                                    : batch?.map((post, idx) => (
                                          <ArticlePost
                                              key={`post-${post?.slug}-${idx}`}
                                              post={post?.attributes}
                                              category={''}
                                              isDynamic={showVerticalPosts}
                                              isLoading={isLoading}
                                          />
                                      ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const JournalSection = ({ slug = '', assetName = '' }) => {
    const [hasMore, setHasMore] = useState(false)
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        pageSize: PAGE_SIZE,
    })
    const page = Number(paginationParams?.page ?? 0)
    const pageSizeNumber = PAGE_SIZE

    useEffect(() => {
        setPaginationParams({ page: 0, pageSize: PAGE_SIZE })
    }, [slug])

    return (
        <div className={styles.tableWithPagination}>
            <JournalSectionComponent
                slug={slug}
                assetName={assetName}
                page={page}
                pageSizeNumber={pageSizeNumber}
                setHasMore={setHasMore}
                isInfinite={false}
                hasDataAppended={true}
            />
            <Pagination
                className='justify-center'
                pageSizeOptions={[]}
                paginationParams={paginationParams}
                setPaginationParams={setPaginationParams}
                hasMore={hasMore}
                withLoadMoreButton={true}
                showPageSizeDropdown={false}
            />
        </div>
    )
}
