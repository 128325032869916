import dynamic from 'next/dynamic'
import {
    BITCOIN_AND_OTHERS_KEY,
    BNB_ECOSYSTEM_KEY,
    COSMOS_ECOSYSTEM_KEY,
    ETHEREUM_ECOSYSTEM_KEY,
    LIQUID_RESTAKING_KEY,
    LIQUID_STAKING_KEY,
    NATIVE_RESTAKING_KEY,
    POLKADOT_ECOSYSTEM_KEY,
    POS_ASSET_CATEGORY,
    STABLECOIN_KEY,
    STABLECOINS_ASSET_CATEGORY,
} from 'utils/constants'
import { Subtitle, Title, TitleDescription } from 'components/text'
import { ApiBanner, NewsletterSection } from 'components/ads'
import { IconStack, YoutubeLink } from 'components/ui'
import {
    getMetaInformationForPage,
    getSortedCategoryIcons,
} from './archive/utils'
import { PoSMetrics } from './archive/categoryMetrics'

// Available types of the table are selected based on the window size.
const AssetsTableArchiveSection = dynamic(
    () =>
        import('./archive/assetsTableArchiveSection').then(
            module => module.AssetsTableArchiveSection
        ),
    {
        ssr: false,
    }
)

// Dynamically scales overlapping photos depending on the browser's viewport width
// Import dynamically to prevent hydration
const SummitBanner = dynamic(
    () => import('components/ads').then(module => module.SummitBanner),
    {
        ssr: false,
    }
)

// The height adapts to the window size through useMediaPredicate().
// Import dynamically to set the right window size and prevent hydration.
const YoutubeVideo = dynamic(
    () => import('components/ui').then(module => module.YoutubeVideo),
    {
        ssr: false,
    }
)

import styles from './assetsPage.module.scss'
import { useEffect } from 'react'
import { useFloatingBanner } from 'contexts/floatingBannerContext'

export const AssetsPage = ({ page = '', query = {}, iconsData = null }) => {
    const meta = getMetaInformationForPage(query?.page)

    const categoryIcons = getSortedCategoryIcons(query?.page, iconsData)

    const { setFloatingBannerProperties } = useFloatingBanner()

    const isTag =
        query.page === LIQUID_STAKING_KEY ||
        query.page === STABLECOIN_KEY ||
        query.page === BITCOIN_AND_OTHERS_KEY ||
        query.page === LIQUID_RESTAKING_KEY ||
        query.page === NATIVE_RESTAKING_KEY ||
        query.page === POS_ASSET_CATEGORY.key ||
        query.page === STABLECOINS_ASSET_CATEGORY.key

    const isEcosystem =
        query.page === ETHEREUM_ECOSYSTEM_KEY ||
        query.page === COSMOS_ECOSYSTEM_KEY ||
        query.page === POLKADOT_ECOSYSTEM_KEY ||
        query.page === BNB_ECOSYSTEM_KEY

    useEffect(() => {
        setFloatingBannerProperties({
            asset: null,
            tag: isTag ? query.page : null,
            ecosystem: isEcosystem ? query.page : null,
        })
    }, [query.page])

    return (
        <div className={styles.assetsPage}>
            <div className='container'>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <Subtitle>{meta?.type ?? ''}</Subtitle>
                        <div className={styles.withIcons}>
                            {Boolean(categoryIcons?.length) && (
                                <div className={styles.stack}>
                                    <IconStack
                                        icons={categoryIcons}
                                        iconSize={52}
                                        overlap={40}
                                    />
                                </div>
                            )}
                            <Title>{meta?.title || ''}</Title>
                        </div>
                        <TitleDescription className={styles.description}>
                            {meta?.description || ''}
                        </TitleDescription>
                    </div>
                    <div className={styles.right}>
                        {query?.page === POS_ASSET_CATEGORY.key && (
                            <PoSMetrics />
                        )}
                        <YoutubeLink
                            href={`https://youtube.com/watch?v=CEAf2yyBQbk`}
                            label={'Learn how to use the Asset Explorer'}
                        />
                    </div>
                </div>
                <div className={styles.tableWrap}>
                    <AssetsTableArchiveSection
                        page={page}
                        query={query}
                        iconsData={iconsData}
                    />
                </div>
                <div className={styles.videoWrap}>
                    <YoutubeVideo
                        src={`https://www.youtube-nocookie.com/embed/CEAf2yyBQbk`}
                        header={'How to Use the Asset Table.'}
                    />
                </div>
            </div>
            <div className={styles.banners}>
                <ApiBanner />
                <SummitBanner />
                <NewsletterSection />
            </div>
        </div>
    )
}
