import { useGlobalData } from 'data'
import { MetricGroup } from 'components/metrics'

const POS_METRIC_KEY = 'pos_assets_marketcap'

export const PoSMetrics = () => {
    const { data, isLoading } = useGlobalData([POS_METRIC_KEY])

    return (
        <MetricGroup
            metricGroup={{
                key: POS_METRIC_KEY,
                label: 'Proof of Stake Market Cap',
                prefix: '$',
                width: '180px',
                showPlus: false,
                withPercentage: true,
                withColor: true,
            }}
            item={data}
            isLoading={isLoading}
            timeframeKey={'24h'}
            highlighted
            large
        />
    )
}
