import { useEffect, useState } from 'react'
import {
    fetchEthHistory,
    fetchLSTBalanceHistory,
} from 'data/stakingAssistant/fetchData'
import { useStakeableAssetsWithMetrics } from 'data'
import { getMetricValueByKey } from 'utils/actions'
import {
    fetchCosmosAssetWalletBalance,
    fetchEthereumAssetWalletBalance,
} from './utils'
import {
    checkIfWrongWallet,
    getCosmosChainName,
} from 'components/staking/cosmos/utils'
import { useChain } from '@cosmos-kit/react'
import { SDK } from 'contexts/combinedWalletsContext'
import { useQuery } from '@tanstack/react-query'

export const useWalletsBalances = (walletAddresses = []) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const { data: inputAssetData, isLoading: isLoadingInputAssets } =
        useStakeableAssetsWithMetrics(['price', 'reward_rate'], true)
    const { data: outputAssetData, isLoading: isLoadingOutputAssets } =
        useStakeableAssetsWithMetrics(['price', 'reward_rate'], false)

    const assets = [
        ...(inputAssetData?.assets
            ? inputAssetData?.assets.map(asset => ({
                  ...asset,
                  isOutput: false,
              }))
            : []),
        ...(outputAssetData?.assets
            ? outputAssetData?.assets.map(asset => ({
                  ...asset,
                  isOutput: true,
              }))
            : []),
    ]

    const walletAddressesStr = JSON.stringify(walletAddresses)

    useEffect(() => {
        const fetchAssetsWithBalances = async () => {
            setIsLoading(true)

            const ethPrice =
                getMetricValueByKey(
                    assets?.find(asset => asset?.slug === 'ethereum-2-0'),
                    'price'
                ) ?? 0
            if (
                assets?.length &&
                walletAddresses?.length &&
                !isLoadingInputAssets &&
                !isLoadingOutputAssets
            ) {
                const balancesPromises = walletAddresses?.map(
                    async walletAddress => {
                        const assetsWithBalances = await Promise.all(
                            assets?.map(async asset => {
                                const balance =
                                    await fetchEthereumAssetWalletBalance(
                                        asset,
                                        walletAddress
                                    )
                                const rateUsd = getMetricValueByKey(
                                    asset,
                                    'price'
                                )
                                const rateEth = rateUsd / ethPrice

                                return {
                                    ...asset,
                                    balance,
                                    eth: balance * rateEth,
                                    usd: balance * rateUsd,
                                    rateUsd,
                                    rateEth,
                                }
                            })
                        )

                        const totalBalance = assetsWithBalances.reduce(
                            (acc, holding) => {
                                acc.eth += holding.eth
                                acc.usd += holding.usd
                                return acc
                            },
                            { eth: 0, usd: 0 }
                        )

                        return {
                            walletAddress,
                            eth: totalBalance.eth,
                            usd: totalBalance.usd,
                            assets: assetsWithBalances,
                        }
                    }
                )

                try {
                    const allBalances = await Promise.all(balancesPromises)
                    setData(allBalances)
                } catch (error) {
                    console.error('Error fetching wallet balances:', error)
                    setData([])
                }
            } else {
                setData([])
            }

            setIsLoading(false)
        }

        fetchAssetsWithBalances()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddressesStr, isLoadingInputAssets, isLoadingOutputAssets])

    return {
        data,
        isLoading: isLoading || isLoadingInputAssets || isLoadingOutputAssets,
    }
}

export const useEthereumAssetWalletBalance = (asset = null, address = '') => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        fetchEthereumAssetWalletBalance(asset, address)
            .then(data => {
                setData(data)
            })
            .catch(() => {
                setData(null)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [address, asset])

    return { data, isLoading }
}

export const useCosmosAssetWalletBalance = (wallet = null) => {
    const chainId = wallet?.chain?.name
    const { getOfflineSignerAmino, wallet: cosmosWallet } = useChain(
        getCosmosChainName(chainId)
    )

    const offlineSigner =
        wallet?.sdk === SDK.CosmosKit
            ? cosmosWallet
                ? getOfflineSignerAmino()
                : null
            : null

    return useQuery({
        queryKey: ['cosmosAssetWalletBalance', chainId, wallet?.address],
        queryFn: async () => {
            const isEthereumWallet = !checkIfWrongWallet(
                'ethereum-2-0',
                wallet?.address
            )
            if (isEthereumWallet || !offlineSigner) {
                return null
            }
            if (wallet?.address && chainId) {
                const data = await fetchCosmosAssetWalletBalance(
                    chainId,
                    wallet?.address
                )
                return data
            }

            return null
        },
    })
}

export const useEthBalanceHistory = (address = '') => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (address) {
            setIsLoading(true)
            fetchEthHistory(address)
                .then(data => {
                    setData(data)
                })
                .catch(() => {
                    setData(null)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [address])

    return { data, isLoading }
}

export const useLSTBalanceHistory = (address = '') => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (address) {
            setIsLoading(true)
            fetchLSTBalanceHistory(address)
                .then(data => {
                    setData(data)
                })
                .catch(() => {
                    setData(null)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [address])

    return { data, isLoading }
}
