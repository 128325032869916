import { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useIntersection } from 'hooks'
import { usePaginatedItems } from 'data'
import {
    TYPE_ASSET,
    PAGE_SIZE_20,
    PAGE_SIZE_10,
    TYPE_VALIDATOR,
    TYPE_PROVIDER,
} from 'utils/constants'
import { getItemsFromPaginatedData } from './utils'
import { NoData } from './selectorBox'

import styles from './infiniteItemScroll.module.scss'

export const InfiniteItemScroll = ({
    entity = TYPE_ASSET,
    searchTerms = '',
    categoryKey = '',
    assetSlug = '',
    optionId = '',
    renderItemGroup = () => {},
}) => {
    const [intersecting, ref] = useIntersection()

    const category = useMemo(
        () => (searchTerms === '' ? categoryKey : null),
        [categoryKey, searchTerms]
    )

    const { data, setSize, isValidating } = usePaginatedItems(
        entity,
        {
            assetSlug,
            rewardOptionId: optionId,
            category,
            search: searchTerms,
        },
        entity !== TYPE_VALIDATOR ? PAGE_SIZE_20 : PAGE_SIZE_10
    )

    const isScrollEnd = useMemo(
        () =>
            !Boolean(
                getItemsFromPaginatedData(
                    data?.[(data?.length ?? 0) - 1],
                    entity,
                    false
                )?.length
            ),
        [data, entity]
    )

    useEffect(() => {
        if (intersecting && !isValidating && !isScrollEnd) {
            setSize(size => size + 1)
        }
    }, [intersecting, isValidating, setSize, isScrollEnd])

    const noDataFound = !Boolean(
        data
            ?.map(batch => getItemsFromPaginatedData(batch, entity, true))
            ?.flat()?.length
    )

    const isLoading = !isScrollEnd || isValidating

    return (
        <>
            {data?.map(unfiltered => {
                const items = getItemsFromPaginatedData(
                    unfiltered,
                    entity,
                    true
                )

                return items?.map((item, idx) => (
                    <div key={`${entity}-${item?.id ?? ''}-${idx}`}>
                        {renderItemGroup(item)}
                    </div>
                ))
            })}

            <div
                className={classNames(styles.statusWrap, {
                    [styles.offset]: entity === TYPE_PROVIDER,
                })}
            >
                <div ref={ref} className={styles.placeholder} />
                {(noDataFound || isLoading) && (
                    <NoData
                        isLoading={isLoading}
                        entity={entity}
                        centered={true}
                    />
                )}
            </div>
        </>
    )
}
