import { useState } from 'react'
import classNames from 'classnames'
import { getPageSizeOption } from 'utils/actions'
import { PageSizes } from 'utils/constants'
import { DropdownSelect } from 'components/forms'
import { BUTTON_VARIANT_FG, ButtonWithBg } from 'components/ui'

import styles from './pagination.module.scss'

export const Pagination = ({
    className = '',
    pageSizeOptions = [getPageSizeOption(PageSizes.Twenty)],
    paginationParams = {
        page: 0,
        pageSize: getPageSizeOption(PageSizes.Twenty),
    },
    setPaginationParams = () => {},
    hasMore = false,
    withLoadMoreButton = false,
    showPageSizeDropdown = true,
}) => {
    const page = Number(paginationParams?.page ?? 0)
    return (
        <div className={classNames(styles.pagination, className)}>
            {showPageSizeDropdown && (
                <div className={styles.pageSizeDropdownWrap}>
                    <div className={styles.pageSizeDropdown}>
                        <DropdownSelect
                            choices={pageSizeOptions}
                            selected={paginationParams?.pageSize}
                            onSelect={newPageSize => {
                                setPaginationParams({
                                    page: 0,
                                    pageSize: newPageSize,
                                })
                            }}
                            small
                        />
                    </div>
                    <p className={styles.label}>items per page</p>
                </div>
            )}
            {withLoadMoreButton ? (
                <div className={styles.loadMoreWrap}>
                    {hasMore && (
                        <ButtonWithBg
                            onClick={() => {
                                setPaginationParams({
                                    ...paginationParams,
                                    page: page + 1,
                                })
                            }}
                            variant={BUTTON_VARIANT_FG}
                        >
                            Load More
                        </ButtonWithBg>
                    )}
                </div>
            ) : (
                <div className={styles.pages}>
                    {page > 0 && (
                        <button
                            className={styles.btn}
                            onClick={() => {
                                setPaginationParams({
                                    ...paginationParams,
                                    page: page - 1,
                                })
                            }}
                        >
                            Previous
                        </button>
                    )}
                    <p className={styles.page}>{page + 1}</p>
                    {hasMore && (
                        <button
                            className={styles.btn}
                            onClick={() => {
                                setPaginationParams({
                                    ...paginationParams,
                                    page: page + 1,
                                })
                            }}
                        >
                            Next
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export const withPagination = (
    TableComponent,
    withLoadMoreButton = true,
    pageSizeOptions = [getPageSizeOption(PageSizes.Twenty)],
    defaultPageSize = getPageSizeOption(PageSizes.Twenty),
    showPageSizeDropdown = true,
    className = ''
) => {
    return function TableWithPaginationWrapper(props) {
        const [hasMore, setHasMore] = useState(false)
        const [paginationParams, setPaginationParams] = useState({
            page: 0,
            pageSize: defaultPageSize,
        })
        const page = Number(paginationParams?.page ?? 0)
        const pageSizeNumber = Number(
            paginationParams?.pageSize?.key ?? PageSizes.Twenty
        )

        return (
            <div className={styles.tableWithPagination}>
                <TableComponent
                    {...props}
                    page={page}
                    pageSizeNumber={pageSizeNumber}
                    setHasMore={setHasMore}
                    isInfinite={false}
                    hasDataAppended={withLoadMoreButton}
                />
                <Pagination
                    className={className}
                    pageSizeOptions={pageSizeOptions}
                    paginationParams={paginationParams}
                    setPaginationParams={setPaginationParams}
                    hasMore={hasMore}
                    withLoadMoreButton={withLoadMoreButton}
                    showPageSizeDropdown={showPageSizeDropdown}
                />
            </div>
        )
    }
}
