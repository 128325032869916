import classNames from 'classnames'
import { AssetRewardCalculatorInputsForTable } from 'components/assets/profile/info/assetRewardCalculatorInputs'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { TOOLTIPS } from 'components/rewardOptions/constants'
import { TooltipOnHover } from 'components/ui'
import { useTimeElapsed } from 'hooks'
import { MAX_TOOLTIP_TEXT_LENGTH, truncateString } from 'utils/formatter'

export const MetricsNativeRestaking = ({ formattedData, sortByKey }) => {
    const stakedTokensUpdatedAt = useTimeElapsed(
        formattedData.stakedTokensCreatedAt
    )
    return (
        <div className='flex flex-1 justify-around'>
            <div className='w-4/12 pl-4'>
                <TooltipOnHover
                    text={<AssetRewardCalculatorInputsForTable />}
                    textClassName='!w-[300px]'
                >
                    <MetricBox
                        title={formattedData?.rewardsAfterTitle}
                        value={formattedData?.rewardsAfterValue}
                    />
                </TooltipOnHover>
            </div>
            <div className='w-4/12 flex items-center'>
                <TooltipOnHover
                    text={
                        <MetricBox
                            title='Staked Tokens'
                            value={formattedData.stakedTokens}
                            change={formattedData.stakedTokensChangePercent}
                        />
                    }
                    updatedTimeStatus={stakedTokensUpdatedAt}
                >
                    <MetricBox
                        title='AuM'
                        value={formattedData.aum}
                        change={formattedData.stakedTokensChangePercent}
                        className={classNames({
                            ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                sortByKey === 'staked_tokens',
                        })}
                    />
                </TooltipOnHover>
            </div>
            <div className='w-2/12'>
                <TooltipOnHover
                    text={truncateString(
                        TOOLTIPS.fee.text,
                        MAX_TOOLTIP_TEXT_LENGTH
                    )}
                    overlay={{
                        title: TOOLTIPS.fee.title,
                        allText: TOOLTIPS.fee.text,
                    }}
                    className='w-full'
                >
                    <MetricBox
                        title='Fee'
                        value={formattedData.fee}
                        className={classNames('hidden xl:block', {
                            ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                sortByKey === 'commission',
                        })}
                    />
                </TooltipOnHover>
            </div>
            <div className='w-2/12'>
                <MetricBox
                    title='Services'
                    value={
                        <span>
                            {`${formattedData?.supportedChains}/${formattedData?.totalSupportedChains}`}
                        </span>
                    }
                />
            </div>
        </div>
    )
}
