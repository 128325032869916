import { useTheme } from 'state'

import styles from './button.module.scss'

export function Button({
    className = '',
    children,
    label = '',
    onClick,
    active = false,
    style = {},
    round = false,
}) {
    const theme = useTheme()

    return (
        <button
            className={`${styles.button} ${className}`}
            data-theme={theme}
            aria-label={label}
            onClick={onClick}
            data-active={active}
            data-round={round}
            style={style}>
            <div className={`${styles.background} ${styles.wrapper} `} />
            <div className={`${styles.foreground} ${styles.wrapper} `}>
                <span>{children}</span>
            </div>
        </button>
    )
}
