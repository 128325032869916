import classNames from 'classnames'
import { useExistingAssetCategories } from 'data'
import {
    TYPE_ASSET,
    DEFAULT_ASSET_CATEGORY,
    ASSET_CATEGORIES,
    TYPE_PROVIDER,
    PROVIDER_CATEGORIES,
    DEFAULT_PROVIDER_CATEGORY,
} from 'utils/constants'
import { useCalculatorContext } from 'components/calculator'
import { Box, ResetButton, Loader, TooltipOnHover, Link } from 'components/ui'
import { capitalize, truncateString } from 'utils/formatter'
import { TOOLTIPS } from './constants'
import { getNoDataMessage } from './utils'
import { ItemSelect } from './itemSelect'

import styles from './selectorBox.module.scss'

export const NoData = ({
    isLoading = false,
    entity = TYPE_ASSET,
    centered = false,
}) =>
    isLoading ? (
        <Loader centered={centered} />
    ) : entity === TYPE_PROVIDER ? (
        <div>
            Missing a provider? Ask them to{' '}
            <Link
                className={styles.link}
                blank={true}
                href={'https://stakingrewards.typeform.com/get-verified'}
            >
                claim their profile
            </Link>{' '}
            to show up in this list.
        </div>
    ) : (
        <p>{getNoDataMessage(entity)}</p>
    )

const SelectorBox = ({
    className = '',
    selectedItem = null,
    entity = TYPE_ASSET,
    step = 1,
    linkLabel = '',
    linkHref = '',
    onReset = () => {},
    showResetButton = false,
    categories = [],
    defaultCategory = null,
    isLoading = false,
    linkDisabled = false,
    tooltipTextObj = {
        title: '',
        text: '',
    },
    isDisabled = false,
}) => {
    return (
        <div className={classNames(styles.selectorBox, className)}>
            <Box
                className={classNames(styles.selectorBox, className)}
                fgClass={styles.fg}
            >
                <div className={styles.selectorTitle}>
                    <div className={styles.headerWrap}>
                        <TooltipOnHover
                            text={truncateString(
                                tooltipTextObj?.text ?? '',
                                50
                            )}
                            textClassName={styles.tooltip}
                            overlay={{
                                title: tooltipTextObj?.title ?? '',
                                allText: tooltipTextObj?.text ?? '',
                            }}
                        >
                            <p>{`Step #${step}`}</p>
                        </TooltipOnHover>
                        <ResetButton show={showResetButton} onClick={onReset} />
                    </div>
                    <sub>{`Choose ${capitalize(entity)}`}</sub>
                </div>
                {selectedItem ? (
                    <>
                        <ItemSelect
                            selectedItem={selectedItem}
                            entity={entity}
                            categories={categories}
                            defaultCategory={defaultCategory}
                            isDisabled={isDisabled}
                        />
                        <a
                            href={linkHref}
                            className={classNames(styles.learnMore, {
                                [styles.hidden]: linkDisabled,
                            })}
                            target='_blank'
                            rel='noopener noreferrer'
                            disabled={linkDisabled}
                            onClick={e => {
                                if (linkDisabled) {
                                    e.preventDefault()
                                }
                            }}
                        >
                            {linkLabel}
                        </a>
                    </>
                ) : (
                    <NoData isLoading={isLoading} entity={entity} />
                )}
            </Box>
        </div>
    )
}

export const AssetsBox = ({ className = '', isLocked = false }) => {
    const { asset, setAsset } = useCalculatorContext()
    const { defaultAsset, existingAssetCategories } =
        useExistingAssetCategories()

    const categories = ASSET_CATEGORIES.filter(({ key }) =>
        existingAssetCategories?.includes(key)
    )

    const tags = asset?.tags?.map(tag => tag?.tagKey)
    const defaultCategory =
        categories.find(({ key }) => tags?.includes(key)) ??
        categories?.[0] ??
        DEFAULT_ASSET_CATEGORY

    const assetForReset = defaultAsset

    return (
        <SelectorBox
            className={className}
            selectedItem={asset}
            entity={TYPE_ASSET}
            step={1}
            linkLabel={`Learn more about ${asset?.name ?? ''} →`}
            linkHref={`/asset/${asset?.slug ?? ''}/`}
            onReset={() => {
                setAsset(assetForReset)
            }}
            showResetButton={
                !isLocked && assetForReset && assetForReset?.id !== asset?.id
            }
            categories={categories}
            defaultCategory={defaultCategory}
            isLoading={asset === undefined}
            tooltipTextObj={TOOLTIPS?.step1}
            isDisabled={isLocked}
            linkDisabled={isLocked}
        />
    )
}

export const ProvidersBox = ({ className = '' }) => {
    const { option, setOption, defaultOption, existingRewardOptionCategories } =
        useCalculatorContext()

    const categories = PROVIDER_CATEGORIES.filter(({ key }) =>
        existingRewardOptionCategories?.includes(key)
    )
    const defaultCategory =
        categories.find(({ key }) => key === option?.type?.key) ??
        categories?.[0] ??
        DEFAULT_PROVIDER_CATEGORY
    return (
        <SelectorBox
            className={className}
            selectedItem={option}
            entity={TYPE_PROVIDER}
            step={2}
            linkLabel={`How to choose a ${TYPE_PROVIDER} →`}
            linkHref=''
            onReset={() => {
                setOption(defaultOption)
            }}
            showResetButton={defaultOption && defaultOption?.id !== option?.id}
            categories={categories}
            defaultCategory={defaultCategory}
            isLoading={option === undefined}
            linkDisabled={true}
            tooltipTextObj={TOOLTIPS?.step2}
        />
    )
}
