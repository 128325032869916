import { createContext, useContext, useState } from 'react'
import { fetchAnnouncements } from 'data'
import { useEffectOnceOnMount } from 'hooks'

const AnnouncementsContext = createContext()

export const useAnnouncementsContext = () => {
    return useContext(AnnouncementsContext)
}

export const AnnouncementsContextProvider = ({ children }) => {
    const [announcements, setAnnouncements] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffectOnceOnMount(() => {
        async function fetchAndSetAnnouncements() {
            const data = await fetchAnnouncements(30) // fetches last 30 announcements
            setAnnouncements(data?.posts?.nodes ?? [])
            setIsLoading(false)
        }
        fetchAndSetAnnouncements()
    })

    return (
        <AnnouncementsContext.Provider
            value={{
                announcements,
                lastAnnouncement: announcements.length
                    ? announcements[0]
                    : null,
                isLoading,
            }}
        >
            {children}
        </AnnouncementsContext.Provider>
    )
}
