import { useCallback } from 'react'
import classNames from 'classnames'
import { useTableFiltersContext } from 'contexts/tableFiltersContext'
import { NO_DATA_INDICATOR } from 'utils/formatter'
import { ORDER_CHOICES } from 'utils/constants'
import { Switch } from 'components/forms'
import { Filter } from './filter'

import styles from './sortingFilter.module.scss'

const LABEL = 'Sorted by'
const MAX_OPTIONS_COUNT = 6
const FILTER_OPTION_HEIGHT_PX = 38

const getScrollableAreaHeightPx = (metricsCount = 0) => {
    const bottomPaddingPx = 24
    const topAreaHeightPx = 62
    return (
        topAreaHeightPx +
        FILTER_OPTION_HEIGHT_PX * Math.min(metricsCount, MAX_OPTIONS_COUNT) +
        bottomPaddingPx
    )
}

export const SortingFilter = ({ useNewFilters = false }) => {
    const {
        params,
        setParams,
        sortByChoices: options,
    } = useTableFiltersContext()

    const metricsCount = options?.length ?? 0

    const selected = params?.sortBy
    const order = params?.order
    const byChange = params?.byChange
    const timeframe = params?.timeframe

    const onSetSortingParams = useCallback(
        (newSortBy = {}, byChange = false) => {
            setParams({
                ...params,
                sortBy: newSortBy,
                byChange,
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params, setParams]
    )

    return (
        <Filter
            label={LABEL}
            icon='sort'
            withSearch={false}
            selectedName={`${selected?.name || NO_DATA_INDICATOR} ${
                byChange && timeframe?.key ? timeframe?.key : ''
            }`}
            height={`${getScrollableAreaHeightPx(metricsCount)}px`}
            forceMobileView={useNewFilters}
        >
            <div className={styles.orderWrap}>
                <div className={styles.order}>
                    <p
                        className={classNames(styles.label, {
                            [styles.selected]: order?.key === 'asc',
                        })}
                        onClick={() => {
                            setParams({
                                ...params,
                                order: ORDER_CHOICES.find(
                                    ch => ch.key === 'asc'
                                ),
                            })
                        }}
                    >
                        Asc
                    </p>
                    <p className={styles.label}>{'/'}</p>
                    <p
                        className={classNames(styles.label, {
                            [styles.selected]: order?.key === 'desc',
                        })}
                        onClick={() => {
                            setParams({
                                ...params,
                                order: ORDER_CHOICES.find(
                                    ch => ch.key === 'desc'
                                ),
                            })
                        }}
                    >
                        Desc
                    </p>
                </div>
                <p className={styles.byChange}>By Change %</p>
            </div>
            <div className={classNames(styles.scrollable, 'vertical-scroll')}>
                <div
                    className={styles.options}
                    style={{
                        '--height': `${
                            Math.min(metricsCount, MAX_OPTIONS_COUNT) *
                            FILTER_OPTION_HEIGHT_PX
                        }px`,
                    }}
                >
                    {options.map((opt, idx) => {
                        const isSelected = selected?.key === opt.key
                        const showTimeframeKey =
                            isSelected &&
                            opt.withPercentage &&
                            byChange &&
                            timeframe?.key
                        return (
                            <div key={`sorting-metric-${opt.key}-${idx}`}>
                                <div
                                    className={styles.optionWrap}
                                    onClick={() => {
                                        onSetSortingParams(opt, false)
                                    }}
                                >
                                    <div
                                        className={classNames(styles.option, {
                                            [styles.selected]: isSelected,
                                        })}
                                    >
                                        {`${opt.name} ${
                                            showTimeframeKey
                                                ? timeframe?.key
                                                : ''
                                        }`}
                                    </div>
                                    <div className={styles.switchWrap}>
                                        {opt.withPercentage && (
                                            <Switch
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    onSetSortingParams(
                                                        opt,
                                                        isSelected
                                                            ? !params?.byChange
                                                            : true
                                                    )
                                                }}
                                                isLeft={
                                                    !byChange || !isSelected
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Filter>
    )
}
