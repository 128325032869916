import styles from './ad.module.scss'

export function Ad({ className = '' }) {
    return (
        <div className={`${styles.ad} ${className}`}>
            <div className={styles.media}></div>

            <div className={styles.content}></div>
        </div>
    )
}
