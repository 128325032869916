import { useRef } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import { useUiState } from 'state'
import {
    REFRESH_INTERVAL_ASSET_PRICE_MS,
    REFRESH_INTERVAL_MS,
    useProfileMetrics,
} from 'data'
import { baseUrl, getObjectFromJsonString } from 'utils/actions'
import {
    AVS_KEY,
    BITCOIN_AND_OTHERS_KEY,
    LIQUID_STAKING_KEY,
    SMART_CONTRACT_KEY,
    STABLECOIN_KEY,
    TYPE_ASSET,
} from 'utils/constants'
import { Title, TitleDescription } from 'components/text'
import {
    ItemWithLogo,
    CopyLinkButton,
    ShareButton,
    ButtonWithBg,
    BUTTON_VARIANT_FG,
    Link,
    CopyAddress,
    BUTTON_VARIANT_BLUE,
    LogoImage,
} from 'components/ui'
import { MetricGroup } from 'components/metrics'
import {
    MAIN_ASSET_METRIC_GROUPS,
    NAVIGATION_BREAKPOINT,
    PRESEARCH_METRIC_GROUPS,
    UNTRACKED_ASSET_METRIC_GROUPS,
    getMoreAssetMetricGroups,
} from '../constants'
import { getMetricTextForSharing } from './utils'
import { TagsList } from './tagsList'
import { useSectionTitles } from './hooks'
import { checkIsStakeableLst } from 'components/rewardOptions/archive/utils'
import { LstStakeNowButton } from 'components/rewardOptions/archive/lstStakeNowButton'

const TableOfContents = dynamic(
    () => import('components/ui').then(module => module.TableOfContents),
    {
        ssr: false,
    }
)

const StakeNowRewardOptionsButton = dynamic(
    () =>
        import('./stakeNowRewardOptionsButton').then(
            mod => mod.StakeNowRewardOptionsButton
        ),
    {
        ssr: false,
    }
)

import styles from './heroSection.module.scss'

export const LstProviderLabel = ({
    className = '',
    asset = null,
    withProvider = true,
}) => {
    const provider = asset?.rewardOptionsWithAssetAsOutput?.[0]?.providers?.[0]
    if (!provider || !withProvider) return null

    return (
        <Link href={`/provider/${provider?.slug}`} blank={false}>
            <div
                className={classNames(
                    'flex flex-col rounded-lg py-3 px-4 h-fit hover:shadow-lg bg-contrast-1',
                    className
                )}
            >
                <div className='flex gap-2'>
                    <div className='flex justify-center'>
                        <LogoImage
                            src={provider?.logoUrl}
                            alt={provider?.name}
                            slug={provider?.slug}
                            size={32}
                        />
                        {provider?.isVerified && (
                            <div className='absolute -right-[5px] -bottom-[5px]'>
                                <Image
                                    src={'/static/svg/vsp-tag.svg'}
                                    alt={`Verified Provider`}
                                    width={16}
                                    height={16}
                                    priority
                                />
                            </div>
                        )}
                    </div>
                    <div className='text-xs font-bold'>
                        <div className='text-contrast-3'>by</div>
                        <div className='text-contrast-4'>{provider?.name}</div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

const StakingButtons = ({
    asset = null,
    inHeader = false,
    hasRewardOptionsTable = false,
}) => {
    const isLst = !!asset?.tags?.find(t => t.tagKey === LIQUID_STAKING_KEY)
    const provider = asset?.rewardOptionsWithAssetAsOutput?.[0]?.providers?.[0]

    if (isLst) {
        const inputAsset =
            asset?.rewardOptionsWithAssetAsOutput?.[0]?.inputAssets?.[0]
        const isStakeableLst = checkIsStakeableLst(
            asset,
            inputAsset,
            asset?.rewardOptionsWithAssetAsOutput?.[0]
        )

        const externalStakeLink = getObjectFromJsonString(asset?.links)?.[
            'stakelink'
        ]
        const externalSwapLink = getObjectFromJsonString(asset?.links)?.[
            'swaplink'
        ]

        return (
            <div className='flex flex-row flex-wrap items-center gap-y-5'>
                {isStakeableLst ? (
                    <LstStakeNowButton
                        className={classNames('h-8 px-10 !w-fit mr-3', {
                            'hidden lg:block': inHeader,
                        })}
                        asset={asset}
                        provider={provider}
                        variant={BUTTON_VARIANT_BLUE}
                        label='Stake'
                        inputAssetSlug={inputAsset?.slug}
                    />
                ) : (
                    Boolean(externalStakeLink) && (
                        <Link href={externalStakeLink} blank={true}>
                            <ButtonWithBg
                                variant={BUTTON_VARIANT_BLUE}
                                className={classNames('h-8 px-10 mr-3 !w-fit', {
                                    ' hidden lg:block': inHeader,
                                })}
                            >
                                Stake ↗
                            </ButtonWithBg>
                        </Link>
                    )
                )}
                {isStakeableLst ? (
                    <LstStakeNowButton
                        className={classNames('h-8 px-10 !w-fit', {
                            'hidden lg:block': inHeader,
                        })}
                        asset={asset}
                        provider={provider}
                        variant={BUTTON_VARIANT_FG}
                        label='Swap'
                        inputAssetSlug={inputAsset?.slug}
                    />
                ) : (
                    Boolean(externalSwapLink) && (
                        <Link href={externalSwapLink} blank={true}>
                            <ButtonWithBg
                                variant={BUTTON_VARIANT_FG}
                                className={classNames('h-8 px-10 !w-fit', {
                                    ' hidden lg:block': inHeader,
                                })}
                            >
                                Swap ↗
                            </ButtonWithBg>
                        </Link>
                    )
                )}
            </div>
        )
    }

    if (hasRewardOptionsTable) {
        return <StakeNowRewardOptionsButton asset={asset} />
    }

    return null
}

const FixedSection = ({
    asset = null,
    isFixed = false,
    showContentDropdown = false,
    sectionTitles = [],
    hasRewardOptionsTable = false,
}) => {
    const page = `/asset/${asset.slug}`
    const link = `${baseUrl}${page}`
    return (
        <div
            className={classNames(
                'flex flex-col md:flex-row md:items-center justify-between gap-y-3',
                { ['mb-3']: !isFixed }
            )}
        >
            <div className='flex flex-row items-center'>
                <div
                    className={classNames(styles.assetGroupWrap, {
                        ['flex-wrap']: !isFixed,
                    })}
                >
                    <div className='flex flex-row items-center'>
                        <ItemWithLogo
                            className={styles.logo}
                            iconUrl={asset?.logoUrl}
                            iconSize={24}
                            slug={asset?.slug}
                        />
                        <p className={styles.assetName}>
                            {isFixed ? asset?.symbol : asset?.name}
                        </p>
                    </div>
                    <div className='flex flex-row gap-x-3 gap-y-3 justify-start items-start'>
                        <ShareButton
                            href={link}
                            hashtags={[asset?.name, 'Staking', asset?.symbol]}
                            title={asset?.name}
                            metricsText={
                                asset?.slug === 'presearch'
                                    ? PRESEARCH_METRIC_GROUPS.map(metricGroup =>
                                          getMetricTextForSharing(
                                              asset,
                                              metricGroup.key,
                                              metricGroup
                                          )
                                      ).join('')
                                    : [
                                          getMetricTextForSharing(
                                              asset,
                                              'net_staking_flow',
                                              {
                                                  label: 'Net Staking Flow 7d',
                                                  precision: 2,
                                                  forcePrecision: false,
                                                  allowEmpty: false,
                                                  withAbbreviation: true,
                                                  showApproximation: true,
                                                  prefix: '$',
                                                  postfix: '',
                                                  showPlus: true,
                                                  timeframeKey: '7d',
                                              }
                                          ),
                                          getMetricTextForSharing(
                                              asset,
                                              'staked_tokens_trend_24h',
                                              getMoreAssetMetricGroups(
                                                  asset
                                              ).find(
                                                  group =>
                                                      group.key ===
                                                      'staked_tokens_trend_24h'
                                              )
                                          ),
                                      ].join('')
                            }
                        />
                        <CopyLinkButton href={link} />
                    </div>
                </div>
            </div>
            {isFixed && (
                <div className='flex flex-row items-center gap-x-3 sm:gap-x-6 gap-y-5'>
                    <StakingButtons
                        asset={asset}
                        inHeader
                        hasRewardOptionsTable={hasRewardOptionsTable}
                    />
                    {showContentDropdown && (
                        <TableOfContents
                            titles={sectionTitles}
                            defaultOptionName='On This Page'
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export const HeroSection = ({
    asset = null,
    isUntracked = false,
    isStakeableToken = false,
    hasRewardOptionsTable = false,
}) => {
    const showContentDropdown = useMediaPredicate(
        `(max-width: ${NAVIGATION_BREAKPOINT})`
    )

    const { data: assetWithPrice, isLoading: isLoadingPrice } =
        useProfileMetrics(
            TYPE_ASSET,
            asset?.slug,
            ['price'],
            REFRESH_INTERVAL_ASSET_PRICE_MS
        )

    const { data: assetWithMetrics, isLoading: isLoadingProfileMetrics } =
        useProfileMetrics(
            TYPE_ASSET,
            asset?.slug,
            [
                'marketcap',
                'reward_rate',
                'net_staking_flow',
                'staked_tokens_trend_24h',
                'page_views_trend',
                'daily_trading_volume',
            ],
            REFRESH_INTERVAL_MS
        )

    const assetWithLatestMetrics = {
        ...asset,
        metrics: [
            ...(assetWithPrice?.metrics ?? []),
            ...(assetWithMetrics?.metrics ?? []),
        ],
    }

    const isLoadingMetrics = isLoadingPrice || isLoadingProfileMetrics

    const hasSmartContract = Boolean(
        asset.tags?.find(tag => tag.tagKey === SMART_CONTRACT_KEY)
    )
    const hasDeFiOrSC =
        hasSmartContract || asset.tags?.some(a => a.tagKey === 'defi')

    const isLst = !!asset?.tags?.find(t => t.tagKey === LIQUID_STAKING_KEY)
    const isAVS = !!asset?.tags?.find(t => t.tagKey === AVS_KEY)
    const isStablecoin = !!asset?.tags?.find(t => t.tagKey === STABLECOIN_KEY)
    const isBtc = !!asset?.tags?.find(t => t.tagKey === BITCOIN_AND_OTHERS_KEY)
    const links = getObjectFromJsonString(asset?.links)

    const headerRef = useRef(null)
    const hasScrolled = useUiState('scrolled')
    const scrollDir = useUiState('scrollDir')
    const isMenuOpen = useUiState('hamburgerActive')
    const showFixedHeader = hasScrolled && scrollDir === 1 && !isMenuOpen

    const sectionTitles = useSectionTitles(asset?.symbol, isAVS)

    const metaConfig = getObjectFromJsonString(asset?.metaWebApp)
    const contractAddress = metaConfig?.contract

    return (
        <div className={styles.heroSection}>
            <div className={styles.titleSection}>
                <div
                    className={classNames(
                        'fixed left-0 py-6 px-[var(--p-page-sides)] w-full bg-contrast-0 z-[1001]',
                        styles.animated,
                        {
                            [styles.scrolled]: hasScrolled,
                            [styles.hidden]: !showFixedHeader,
                        }
                    )}
                >
                    <div className='container'>
                        <FixedSection
                            asset={assetWithLatestMetrics}
                            isFixed={true}
                            showContentDropdown={showContentDropdown}
                            sectionTitles={sectionTitles}
                            hasRewardOptionsTable={hasRewardOptionsTable}
                        />
                    </div>
                </div>
                <div ref={headerRef}>
                    <FixedSection
                        asset={assetWithLatestMetrics}
                        isFixed={false}
                        showContentDropdown={showContentDropdown}
                        sectionTitles={sectionTitles}
                        hasRewardOptionsTable={hasRewardOptionsTable}
                    />
                </div>
                <div className='flex flex-col sm:flex-row items-start sm:items-center gap-x-7 gap-y-2 mb-5 sm:mb-2'>
                    <Title>
                        {isLst
                            ? asset?.symbol
                            : isAVS
                              ? `Restake with ${asset?.name}`
                              : isStablecoin
                                ? `Earn ${asset?.symbol}`
                                : `Stake ${asset?.symbol}`}
                    </Title>
                    {isLst && <LstProviderLabel asset={asset} />}
                </div>
                <div className='mb-5'>
                    {isLst ? (
                        <div className='flex flex-col sm:flex-row items-start sm:items-center gap-x-5 gap-y-2'>
                            {Boolean(contractAddress) && (
                                <div className='w-full sm:w-fit'>
                                    <CopyAddress
                                        className='hover:opacity-80'
                                        textClassName='truncate'
                                        address={contractAddress}
                                        initialText={contractAddress}
                                        copiedText={contractAddress}
                                    />
                                </div>
                            )}
                            {Boolean(links?.['explorer']) && (
                                <Link
                                    className='text-sm font-normal text-contrast-4 whitespace-nowrap hover:underline hover:opacity-80'
                                    href={links?.['explorer']}
                                    label='View via Explorer'
                                    blank
                                >
                                    View via Explorer ↗
                                </Link>
                            )}
                        </div>
                    ) : (
                        !isStablecoin &&
                        !isBtc && (
                            <TitleDescription>
                                {hasDeFiOrSC ||
                                (isUntracked && !isStakeableToken)
                                    ? 'Deposit tokens and earn rewards.'
                                    : isAVS
                                      ? 'Enhancing Ethereum Scalability Through Its Security'
                                      : 'Contribute to network security & earn rewards.'}
                            </TitleDescription>
                        )
                    )}
                </div>
                {!isStablecoin && !isBtc && (
                    <TagsList
                        asset={assetWithLatestMetrics}
                        isUntracked={isUntracked}
                    />
                )}
            </div>
            <div className={styles.buttonsAndChartWrapper}>
                <div className='flex flex-row flex-wrap items-center gap-x-3 sm:gap-x-6 gap-y-5 mb-3'>
                    <StakingButtons
                        asset={asset}
                        inHeader={false}
                        hasRewardOptionsTable={hasRewardOptionsTable}
                    />
                    {showContentDropdown && (
                        <TableOfContents
                            titles={sectionTitles}
                            defaultOptionName='On This Page'
                        />
                    )}
                </div>
                {!hasSmartContract &&
                    (isUntracked || asset?.slug === 'presearch' ? (
                        <div className='grid grid-cols-2 grid-rows-2 gap-4'>
                            {UNTRACKED_ASSET_METRIC_GROUPS.map(
                                (metricGroup, idx) => (
                                    <MetricGroup
                                        large
                                        key={`metric-${metricGroup?.key}-${idx}`}
                                        metricGroup={metricGroup}
                                        item={assetWithLatestMetrics}
                                        isLoading={isLoadingMetrics}
                                        highlighted
                                        timeframeKey='24h'
                                    />
                                )
                            )}
                        </div>
                    ) : asset?.slug === 'autonity' ? (
                        <Link
                            href='https://autonity.org/validators#:~:text=REGISTER%20YOUR%20INTEREST'
                            blank
                        >
                            <div
                                className={classNames(
                                    styles.banner,
                                    'bg-gradient-to-bl from-cyan/10 via-magenta/10 to-yellow/10'
                                )}
                            >
                                <div className={styles.text}>
                                    <div className={styles.titleWrapper}>
                                        <div className={styles.title}>
                                            Interested in Validating?
                                        </div>
                                    </div>
                                    <div className={styles.slogan}>
                                        Become a Genesis Validator
                                    </div>
                                </div>
                                <ButtonWithBg
                                    className={styles.getStarted}
                                    variant={BUTTON_VARIANT_FG}
                                    iconName={'icon-share'}
                                >
                                    Get Started
                                </ButtonWithBg>
                            </div>
                        </Link>
                    ) : (
                        asset?.underMaintenance && (
                            <div className={styles.underMaintenanceMetricsWrap}>
                                {[
                                    ...MAIN_ASSET_METRIC_GROUPS,
                                    ...getMoreAssetMetricGroups(asset.slug),
                                ]
                                    .filter(metricGroup =>
                                        ['price', 'marketcap'].includes(
                                            metricGroup.key
                                        )
                                    )
                                    .map((metricGroup, idx) => (
                                        <MetricGroup
                                            large
                                            key={`metric-${metricGroup?.key}-${idx}`}
                                            metricGroup={metricGroup}
                                            item={asset}
                                            isLoading={isLoadingMetrics}
                                            highlighted
                                        />
                                    ))}
                            </div>
                        )
                    ))}
            </div>
        </div>
    )
}
