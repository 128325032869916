import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'

export const BrellaScript = () => {
    const router = useRouter()

    useEffect(() => {
        // Function that reloads the script or reinitializes the widget
        const handleRouteChange = () => {
            // Remove existing script
            const existingScript = document.getElementById(
                'brella-widget-script'
            )
            existingScript?.parentNode?.removeChild(existingScript)

            // Recreate the script
            const script = document.createElement('script')
            script.src = 'https://widget.brella.io'
            script.async = true
            script.id = 'brella-widget-script'
            document.body.appendChild(script)
        }

        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return (
        <Script
            async
            id='brella-widget-script'
            strategy='afterInteractive'
            src='https://widget.brella.io'
        />
    )
}
