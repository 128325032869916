import { useState, useEffect } from 'react'
import { useUiState } from 'state'

export function useInView(ref, threshold = 0.3, rootMargin = '0px') {
    const firstLoad = useUiState('firstLoad')
    const [isInView, setInView] = useState(false)

    useEffect(() => {
        if (!firstLoad) return null

        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry?.isIntersecting ?? false)
            },
            {
                rootMargin,
                threshold,
            }
        )
        const currentRef = ref.current
        if (currentRef) {
            observer.observe(currentRef)
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef)
            }
        }
    }, [ref, threshold, firstLoad]) // eslint-disable-line react-hooks/exhaustive-deps

    return isInView
}
