import classNames from 'classnames'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { formatOutputNumber } from 'utils/formatter'
import {
    getMetricDefaultValueByKey,
    getMetricObjectByKey,
    getObjectFromJsonString,
} from 'utils/actions'

export const Dropdown = ({
    formattedData,
    additionalRewardOption,
    data,
    setAdditionalRewardOption,
    isMobile,
    isLoading,
    showChain = false,
}) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const defaultChoice = data?.[0]
    const currentChoice = additionalRewardOption
    useEffect(() => {
        setAdditionalRewardOption(defaultChoice)
    }, [defaultChoice, setAdditionalRewardOption])

    const rewardRate = formatOutputNumber(
        getMetricDefaultValueByKey(currentChoice, 'reward_rate'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )
    const provider = currentChoice?.providers?.[0]

    const chain = currentChoice?.chains?.[0]?.label

    const variations = getObjectFromJsonString(
        getMetricObjectByKey(currentChoice, 'reward_rate')?.variation ?? {}
    )

    const currentChoiceSlug = currentChoice?.providers?.[0]?.name?.toLowerCase()

    const multiplier = variations?.points?.[currentChoiceSlug]?.multipliers
    const logo = variations?.points?.[currentChoiceSlug]?.logo

    return (
        <div
            className={classNames(
                'w-full h-full z-10 bg-ultra-light dark:bg-blackCard hover:shadow-md rounded-lg cursor-pointer transition-all duration-300 ',
                {
                    'shadow-dropdown rounded-lg': isExpanded,
                }
            )}
        >
            <div
                className={classNames(
                    'flex items-center w-full justify-between',
                    showChain ? 'h-[62px]' : 'h-[42px]',
                    {
                        'rounded-b-none': isExpanded,
                        '!h-full': isMobile,
                    }
                )}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {isLoading ? (
                    <div className='flex items-center justify-center w-full h-[16px]'>
                        <span className='spinner'></span>
                    </div>
                ) : (
                    <>
                        <div
                            className={classNames('flex w-full', {
                                'flex-col': isMobile,
                                'items-center': !isMobile,
                            })}
                        >
                            <div
                                className={classNames(
                                    'flex items-center h-full border-r border-contrast-2/20 border-solid pl-4 py-3 pr-8 w-[120px] xl:w-[150px]',
                                    {
                                        'border-none': isMobile,
                                    }
                                )}
                            >
                                <div className='min-w-[16px]'>
                                    {provider?.logoUrl && (
                                        <Image
                                            src={provider?.logoUrl}
                                            alt={`${provider?.name} logo`}
                                            width={16}
                                            height={16}
                                            className='rounded-full'
                                        />
                                    )}
                                </div>
                                <span className='ml-2 text-contrast-5 font-bold text-xs'>
                                    {provider?.name}
                                </span>
                            </div>
                            <div
                                className={classNames(
                                    'flex justify-between h-full w-full flex-1 p-4 !pr-0 font-bold text-contrast-3 text-xs items-center',
                                    {
                                        'pb-3 pt-0': isMobile,
                                    }
                                )}
                            >
                                <div className='flex flex-col gap-1'>
                                    <span>{currentChoice?.label}</span>
                                    {showChain && chain && (
                                        <div className='flex items-center gap-1'>
                                            <span className='font-normal text-black dark:text-gray text-xs leading-4 dark:group-hover:text-black'>
                                                {`on`}
                                            </span>
                                            <Image
                                                src={`/static/svg/${chain?.toLowerCase()}.svg`}
                                                width={14}
                                                height={14}
                                                alt={`${chain} logo`}
                                            />{' '}
                                            <span className='font-normal text-black dark:text-gray text-xs leading-4 dark:group-hover:text-black'>
                                                {chain}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className='flex items-center gap-1'>
                                    <span>{rewardRate}</span>
                                    {variations?.points && (
                                        <div className='flex items-center gap-0.5'>
                                            <span className='text-black text-sm font-normal leading-none group-hover:dark:text-black dark:text-white '>
                                                {' '}
                                                <strong>+</strong>{' '}
                                                {` ${multiplier}x`}
                                            </span>
                                            <Image
                                                src={logo}
                                                alt={`${provider?.name} logo`}
                                                width={16}
                                                height={16}
                                                className='rounded-full'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={classNames(
                                'icon icon-og icon-chevron-arrow-down !w-[8px] !h-[5px] mx-3 top-[3px] !transition-all !duration-300',
                                {
                                    '!rotate-[360deg] -top-[-3px]': isExpanded,
                                }
                            )}
                        />
                    </>
                )}
            </div>
            <div
                className={classNames(
                    'h-0 opacity-0 -mt-1 transition-all duration-300 overflow-y-auto max-h-[280px]',
                    {
                        'h-auto opacity-100 shadow-dropdown': isExpanded,
                    }
                )}
            >
                {data
                    ?.filter(item => item !== currentChoice)
                    ?.map((item, index) => {
                        return (
                            <DropdownRow
                                key={index}
                                item={item}
                                isExpanded={isExpanded}
                                isLast={index === data.length - 1}
                                showChain={showChain}
                                onSelect={() => {
                                    setAdditionalRewardOption(item)
                                    setIsExpanded(false)
                                }}
                            />
                        )
                    })}
            </div>
        </div>
    )
}

const DropdownRow = ({
    item,
    isExpanded,
    isLast,
    onSelect,
    showChain = false,
}) => {
    const rewardRate = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'reward_rate'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const provider = item?.providers?.[0]
    const variations = getObjectFromJsonString(
        getMetricObjectByKey(item, 'reward_rate')?.variation ?? {}
    )

    const providerSlug = item?.providers?.[0]?.name?.toLowerCase()

    const multiplier = variations?.points?.[providerSlug]?.multipliers
    const logo = variations?.points?.[providerSlug]?.logo

    const chain = item?.chains?.[0]?.label

    return (
        <div
            className={classNames(
                'group flex w-full items-center bg-contrast-0 dark:bg-black h-0 cursor-pointer transition-all duration-300 hover:bg-shade-ultra-light hover:dark:bg-shade-darkest ',
                showChain && isExpanded ? 'min-h-[62px]' : 'min-h-[42px]',
                {
                    'border-t border-solid border-contrast-2/20': isExpanded,
                    'overflow-hidden': !isExpanded,
                    'rounded-b-lg': isLast,
                }
            )}
            onClick={onSelect}
        >
            <div
                className={classNames(
                    'flex items-center h-full border-r border-contrast-2/20 border-solid pl-4 py-3 pr-8 w-[120px] xl:w-[150px]'
                )}
            >
                <div className='min-w-[16px]'>
                    {provider?.logoUrl && (
                        <Image
                            src={provider?.logoUrl}
                            alt={`${provider?.name} logo`}
                            width={16}
                            height={16}
                            className='rounded-full'
                        />
                    )}
                </div>
                <div className='flex flex-col gap-2'>
                    <span className='tracking-[0.3px] ml-2 text-contrast-5 text-sm font-normal '>
                        {provider?.name}
                    </span>
                </div>
            </div>
            <div className='flex h-full flex-1 justify-between p-4 font-bold text-contrast-3 text-xs items-center'>
                <div className='flex flex-col gap-2'>
                    <span className=' font-semibold text-sm leading-none text-black tracking-[0.3px] dark:text-white'>
                        {item?.label}
                    </span>
                    {showChain && chain && (
                        <div className='flex gap-1 items-center'>
                            <span className='font-normal text-black dark:text-gray text-xs leading-4 '>
                                {`on`}
                            </span>
                            <Image
                                src={`/static/svg/${chain?.toLowerCase()}.svg`}
                                width={14}
                                height={14}
                                alt={`${chain} logo`}
                            />{' '}
                            <span className='font-normal text-black dark:text-gray text-xs leading-4 '>
                                {chain}
                            </span>
                        </div>
                    )}
                </div>
                <div className='flex items-center gap-0.5'>
                    <span className='text-black font-bold ml-1 dark:text-white '>
                        {rewardRate}
                    </span>
                    {(multiplier || logo) && (
                        <div className='flex items-center gap-0.5'>
                            {multiplier && (
                                <span className='text-black text-sm font-normal leading-none  dark:text-white '>
                                    {' '}
                                    <strong>+</strong> {` ${multiplier}x`}
                                </span>
                            )}
                            {logo && (
                                <Image
                                    src={logo}
                                    alt={`${provider?.name} logo`}
                                    width={16}
                                    height={16}
                                    className='rounded-full'
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
