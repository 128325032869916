import { getStakingAmountUsd } from 'components/calculator/utils'
import {
    isInvalidNumber,
    formatOutputNumber,
    getPrecisionBySignificantFigures,
    NO_DATA_INDICATOR,
} from 'utils/formatter'

export const ReceiveBlock = ({
    reward = null,
    unit = '',
    tokenPrice = null,
    precision = 2,
}) => {
    const rewardInUsd = getStakingAmountUsd(reward, false, tokenPrice, null)
    return (
        <div className='flex flex-col'>
            <p className='font-bold text-sm text-contrast-3 whitespace-nowrap'>
                Receive
            </p>
            <p className='font-bold text-xl text-contrast-4 whitespace-nowrap'>
                {!isInvalidNumber(reward)
                    ? formatOutputNumber(reward, {
                          postfix: unit ?? '',
                          spaceAfterNumber: true,
                          allowEmpty: false,
                          withAbbreviation: true,
                          precision: Math.max(
                              precision,
                              getPrecisionBySignificantFigures(reward, 3)
                          ),
                          forcePrecision: false,
                          showApproximation: false,
                      })
                    : NO_DATA_INDICATOR}
            </p>

            <p className='text-sm font-normal whitespace-nowrap text-contrast-4'>
                {!isInvalidNumber(rewardInUsd)
                    ? formatOutputNumber(rewardInUsd, {
                          prefix: '$',
                          spaceAfterNumber: true,
                          allowEmpty: false,
                          withAbbreviation: false,
                          precision: Math.max(
                              2,
                              getPrecisionBySignificantFigures(rewardInUsd, 3)
                          ),
                          forcePrecision: false,
                          showApproximation: false,
                      })
                    : NO_DATA_INDICATOR}
            </p>
        </div>
    )
}
