import classNames from 'classnames'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { useTimeElapsed } from 'hooks'
import {
    formatOutputNumber,
    MAX_TOOLTIP_TEXT_LENGTH,
    truncateString,
} from 'utils/formatter'
import { TOOLTIPS } from 'components/rewardOptions/constants'
import { CopyAddress, TooltipOnHover } from 'components/ui'
import { compressAddress } from 'utils/stakingAssistant/utils'
import { getMetricObject } from 'utils/getMetricObject'
import Image from 'next/image'

export const MetricsBabylon = ({ formattedData, sortByKey }) => {
    const stakedTokensUpdatedAt = useTimeElapsed(
        formattedData.stakedTokensCreatedAt
    )
    const address = formattedData?.item?.validators?.[0]?.address

    const pointsPerBlockMetric = getMetricObject(
        formattedData.item,
        'points_per_block'
    )

    const valuePointsPerBlock = (
        <div className='flex'>
            <Image
                src={'/static/svg/babylon-white.svg'}
                alt={'Babylon icon'}
                priority
                width={16}
                height={16}
            />
            <span className='ml-2'>
                {formatOutputNumber(pointsPerBlockMetric?.defaultValue, {
                    precision: 4,
                })}
            </span>
        </div>
    )

    return (
        <div className='flex flex-1 justify-around'>
            <MetricBox
                title={
                    <div className='w-[90px]'>
                        <CopyAddress
                            address={address}
                            textClassName='!text-[10px] whitespace-nowrap'
                        />
                    </div>
                }
                value={compressAddress(address, 4, 4)}
                isSmall
            />
            <div>
                <TooltipOnHover
                    text={truncateString(
                        TOOLTIPS.staked.text,
                        MAX_TOOLTIP_TEXT_LENGTH
                    )}
                    overlay={{
                        title: TOOLTIPS.staked.title,
                        allText: TOOLTIPS.staked.text,
                    }}
                    updatedTimeStatus={stakedTokensUpdatedAt}
                >
                    <MetricBox
                        title='Staked Tokens'
                        value={formattedData.stakedTokens + ' BTC'}
                        change={formattedData.stakedTokensChangePercent}
                        className={classNames({
                            ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                sortByKey === 'staked_tokens',
                        })}
                    />
                </TooltipOnHover>
            </div>
            <div>
                <TooltipOnHover
                    text={truncateString(
                        TOOLTIPS.fee.text,
                        MAX_TOOLTIP_TEXT_LENGTH
                    )}
                    overlay={{
                        title: TOOLTIPS.fee.title,
                        allText: TOOLTIPS.fee.text,
                    }}
                    className='w-full'
                >
                    <MetricBox
                        title='Fee'
                        value={formattedData.fee}
                        className={classNames('hidden xl:block', {
                            ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                sortByKey === 'commission',
                        })}
                    />
                </TooltipOnHover>
            </div>
            <div>
                <MetricBox
                    title='Points per block per 0.05 BTC'
                    value={valuePointsPerBlock}
                    className={classNames({
                        ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                            sortByKey === 'points_per_block',
                    })}
                />
            </div>
        </div>
    )
}
