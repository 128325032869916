import classNames from 'classnames'
import {
    getMetricChangePercentage,
    getMetricDefaultValueByKey,
    getMetricValueByKey,
    getObjectFromJsonString,
} from 'utils/actions'
import { CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY } from 'utils/constants'
import { getLatestNetFlowMetricPerDays } from 'utils/converter'
import {
    NO_DATA_INDICATOR,
    formatOutputNumber,
    formatSeconds,
    getPrecisionBySignificantFigures,
} from 'utils/formatter'

/**
 * Formats the metric data for easier access.
 *
 * @param {Object} item - The reward option item.
 * @param {Object} profileItem - The current profile item in the url. (Either asset or provider)
 * @param {string} timeframe - The timeframe for the metrics. (e.g. '30d')
 * @param {string} type - The type of the reward option. (PoS, LST, smart contract, etc.)
 * @returns {
 *    rewardRate: string,
 *    aum: string,
 *    stakedTokens: string,
 *    stakedTokensChangePercent: string,
 *    stakers: string,
 *    stakersChangePercent: string,
 *    selfStakedUsd: string,
 *    selfStakedTokens: string,
 *    selfStakedTokensChangePercent: string,
 *    networkControl: string,
 *    fee: string,
 *    performance: string,
 *    netStakingFlow7d: string,
 *    lstToken: Object,
 * } The formatted data as a dictionary object.
 */
export const getFormattedData = (
    item,
    profileItem,
    timeframe,
    type,
    assetRewardCalculatorContext
) => {
    if (!item || !profileItem) return {}
    const rewardRateUnformatted = getMetricDefaultValueByKey(
        item,
        'reward_rate'
    )

    const totalSupportedChains = getMetricDefaultValueByKey(
        item,
        'total_supported_chains'
    )

    const supportedChains = getMetricDefaultValueByKey(item, 'supported_chains')

    const rewardRate = formatOutputNumber(rewardRateUnformatted, {
        precision: Math.max(
            2,
            getPrecisionBySignificantFigures(rewardRateUnformatted, 3)
        ),
        forcePrecision: false,
        postfix: '%',
    })

    const price = getMetricDefaultValueByKey(profileItem, 'price')
    const stakedTokensNumber = getMetricDefaultValueByKey(item, 'staked_tokens')

    const stakedTokens = formatOutputNumber(stakedTokensNumber, {
        precision: 2,
        forcePrecision: false,
    })
    const stakedTokensCreatedAt = item?.metrics?.find(
        m => m.metricKey === 'staked_tokens'
    )?.createdAt

    const aum = formatOutputNumber(price * stakedTokensNumber, {
        precision: 2,
        prefix: '$',
    })

    const stakedTokensChangePercent = formatOutputNumber(
        getMetricChangePercentage(item?.metrics, 'staked_tokens', timeframe),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const stakers = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'staking_wallets'),
        {
            precision: 2,
            forcePrecision: false,
        }
    )

    const stakersChangePercent = formatOutputNumber(
        getMetricChangePercentage(item?.metrics, 'staking_wallets', timeframe),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const selfStakedTokens = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'self_staked_tokens'),
        {
            precision: 2,
            forcePrecision: false,
        }
    )

    const selfStakedUsd = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'self_staked_tokens') * price,
        {
            precision: 2,
            forcePrecision: false,
            prefix: '$',
        }
    )

    const selfStakedTokensChangePercent = formatOutputNumber(
        getMetricChangePercentage(
            item?.metrics,
            'self_staked_tokens',
            timeframe
        ),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    const networkControl = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'staking_share') * 100,
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )

    // const lstImage = (
    //     <div className='w-full flex items-center justify-center'>
    //         <LogoImage src={rewardOption?.logoUrl} size={16} />
    //     </div>
    // )

    const isPrivateNode =
        getMetricValueByKey(item, 'is_not_delegation_node') === 1
    const fee = isPrivateNode
        ? NO_DATA_INDICATOR
        : formatOutputNumber(getMetricDefaultValueByKey(item, 'commission'), {
              precision: 2,
              forcePrecision: false,
              postfix: '%',
          })
    const performance = formatOutputNumber(
        getMetricDefaultValueByKey(item, 'performance'),
        {
            precision: 2,
            forcePrecision: false,
            postfix: '%',
        }
    )
    const itemWithPrice = {
        ...item,
        metrics: [
            ...(item?.metrics ?? []),
            profileItem?.metrics?.find(m => m.metricKey === 'price'),
        ],
    }

    const netStakingFlow7d = formatOutputNumber(
        getMetricValueByKey(
            itemWithPrice,
            CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY
        ),
        {
            precision: 2,
            forcePrecision: false,
            prefix: '$',
            showPlus: true,
        }
    )

    const outputAsset = item?.outputAssets?.[0]
    const outputAssetLinks = getObjectFromJsonString(outputAsset?.links)
    const outputAssetPegDeviation = formatOutputNumber(
        getMetricDefaultValueByKey(outputAsset, 'peg_deviation') * 100,
        {
            precision: 2,
            forcePrecision: false,
            withColor: true,
            postfix: '%',
        }
    )
    const outputAssetExchangeRatio = formatOutputNumber(
        getMetricDefaultValueByKey(outputAsset, 'exchange_ratio'),
        {
            precision: 2,
            forcePrecision: true,
        }
    )

    const providerVspScore = getMetricDefaultValueByKey(
        item?.providers?.[0],
        'vsp'
    )

    const latestMetricsPerDay = getLatestNetFlowMetricPerDays(
        item?.metrics ?? []
    )?.slice(0, 7)

    const { stakingTimeInDays, calculateResult } = assetRewardCalculatorContext

    const rewardsAfterTitle = `Rewards After ${formatSeconds(
        stakingTimeInDays * 86400,
        false
    )}`
    const rewardsAfter = calculateResult(rewardRate?.replace('%', ''), price)
    const rewardsAfterValue = formatOutputNumber(rewardsAfter, {
        precision: Math.max(
            2,
            getPrecisionBySignificantFigures(rewardsAfter, 3)
        ),
        prefix: '$',
        allowEmpty: false,
        withAbbreviation: false,
        forcePrecision: false,
        showApproximation: false,
    })

    const rewardsAfterAdditional = calculateResult(
        rewardRate?.replace('%', ''),
        price
    )

    const hostingFeeMetric = item?.metrics?.find(
        m => m?.metricKey === 'hosting_fee'
    )

    const hostingFee =
        formatOutputNumber(hostingFeeMetric?.defaultValue, {
            prefix: '$',
            forcePrecision: false,
            postfix: hostingFeeMetric?.unit === 'one-off' ? ' One-off' : ' / m',
        }) || NO_DATA_INDICATOR

    const riskRating = getMetricDefaultValueByKey(item, 'risk_rating')

    const rewardFrequency = formatSeconds(
        getMetricDefaultValueByKey(item, 'reward_frequency'),
        false
    )

    const unstakingTime = formatSeconds(
        getMetricDefaultValueByKey(item, 'unstaking_time'),
        false
    )

    const lockupTimeInSeconds = getMetricDefaultValueByKey(item, 'lockup_time')
    const lockupTime = formatSeconds(lockupTimeInSeconds)

    const provider = item?.providers?.[0]

    return {
        rewardRateUnformatted,
        rewardRate,
        aum,
        stakedTokens,
        stakedTokensCreatedAt,
        stakedTokensChangePercent,
        stakers,
        stakersChangePercent,
        selfStakedUsd,
        selfStakedTokens,
        selfStakedTokensChangePercent,
        networkControl,
        fee,
        performance,
        netStakingFlow7d,
        outputAsset,
        outputAssetLinks,
        outputAssetPegDeviation,
        providerVspScore,
        latestMetricsPerDay,
        item,
        outputAssetExchangeRatio,
        rewardsAfterTitle,
        rewardsAfterValue,
        hostingFee,
        riskRating,
        rewardFrequency,
        unstakingTime,
        lockupTimeInSeconds,
        lockupTime,
        provider,
        totalSupportedChains,
        supportedChains,
    }
}

export const getFormattedDataForCustodial = (
    item,
    profileItem,
    assetRewardCalculatorContext
) => {
    if (!item || !profileItem) return {}
    const bestRewardRate = item?.rewardOptions?.reduce((acc, option) => {
        const rewardRate = getMetricDefaultValueByKey(option, 'reward_rate')
        return rewardRate > acc ? rewardRate : acc
    }, 0)

    const rewardRate = formatOutputNumber(bestRewardRate, {
        precision: Math.max(
            2,
            getPrecisionBySignificantFigures(bestRewardRate, 3)
        ),
        forcePrecision: false,
        postfix: '%',
    })

    const country = item?.country

    const price = getMetricDefaultValueByKey(profileItem, 'price')

    const { stakingTimeInDays, calculateResult } = assetRewardCalculatorContext
    const rewardsAfterTitle = `Rewards After ${formatSeconds(
        stakingTimeInDays * 86400,
        false
    )}`
    const rewardsAfter = calculateResult(rewardRate?.replace('%', ''), price)
    const rewardsAfterValue = formatOutputNumber(rewardsAfter, {
        precision: Math.max(
            2,
            getPrecisionBySignificantFigures(rewardsAfter, 3)
        ),
        prefix: '$',
        allowEmpty: false,
        withAbbreviation: false,
        forcePrecision: false,
        showApproximation: false,
    })

    return {
        rewardRate,
        country,
        rewardsAfterTitle,
        rewardsAfterValue,
    }
}

// For tailwind to not purge the classes
const delays =
    'delay-[100ms] delay-[200ms] delay-[300ms] delay-[400ms] delay-[500ms] delay-[600ms] delay-[700ms] delay-[800ms]'
const delays2 =
    'delay-[150ms] delay-[250ms] delay-[350ms] delay-[450ms] delay-[550ms] delay-[650ms] delay-[750ms] delay-[850ms]'

export const getAnimationClassNames = (i, isExpanded) => {
    return classNames(
        `opacity-0 transition-all duration-300 ease-in-out delay-[${
            100 + i * 50
        }ms]`,
        {
            ['opacity-100']: isExpanded,
        }
    )
}
