import { useState } from 'react'
import classNames from 'classnames'
import { getObjectFromJsonString, trimUnescapedHtml } from 'utils/actions'
import { TYPE_ASSET } from 'utils/constants'
import { truncateString } from 'utils/formatter'
import {
    ExternalLink,
    Flag,
    GroupSize,
    ItemWithLogo,
    Link,
} from 'components/ui'
import { FaqItems } from './faqItems'

import styles from './faqSection.module.scss'
import { HeaderWithLink } from 'components/text/headerWithLink'

const INITIAL_TEXT_LENGTH = 350

export const FaqSection = ({ item = null, type = TYPE_ASSET, isAVS }) => {
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)

    const faqs =
        item?.faqs?.filter(
            faq =>
                !!trimUnescapedHtml(faq?.question) &&
                !!trimUnescapedHtml(faq?.answer)
        ) ?? []

    const links = getObjectFromJsonString(item?.links)

    const description = `${item?.name} is ${item?.description}`

    const team = getObjectFromJsonString(item?.team)

    return (
        <div id='section-staking-faqs' className={styles.faqSection}>
            {Boolean(faqs?.length) && (
                <div className={styles.faqsWrap}>
                    <HeaderWithLink
                        title={`Learn about ${item?.name ?? ''}${
                            type === TYPE_ASSET
                                ? ` ${isAVS ? 'Restaking' : 'Staking'}`
                                : ''
                        }`}
                        href={`/${type}/${item?.slug}#section-staking-faqs`}
                    />
                    <FaqItems team={team} faqs={faqs} />
                </div>
            )}
            <div className={styles.about}>
                <div className={styles.itemGroup}>
                    <ItemWithLogo
                        iconUrl={item?.logoUrl}
                        details={item?.symbol}
                        name={item?.name}
                        slug={item?.slug}
                        sizeType={GroupSize.Large}
                        iconSize={40}
                        reverseDetailsOrder={true}
                        withDetails={true}
                        isVerified={false}
                    />
                    {Boolean(item?.country) && (
                        <Flag country={item?.country} width={17} height={10} />
                    )}
                </div>
                {Boolean(item?.operationInitiationDate) && (
                    <p>{`Operating since ${item?.operationInitiationDate}.`}</p>
                )}
                {Boolean(item?.description) && (
                    <div className={styles.description}>
                        {description?.length > INITIAL_TEXT_LENGTH ? (
                            <div>
                                {isDescriptionExpanded
                                    ? description
                                    : truncateString(
                                          description,
                                          INITIAL_TEXT_LENGTH
                                      )}
                                <span
                                    className='ml-2 text-primary cursor-pointer'
                                    onClick={() =>
                                        setIsDescriptionExpanded(
                                            !isDescriptionExpanded
                                        )
                                    }
                                >
                                    {isDescriptionExpanded
                                        ? 'Read less'
                                        : 'Read more'}
                                </span>
                            </div>
                        ) : (
                            description
                        )}
                    </div>
                )}
                <div className={styles.linksWrap}>
                    {[
                        {
                            key: 'website',
                            name: 'Website',
                            icon: 'icon-globe',
                        },
                        {
                            key: 'whitepaper',
                            name: 'Whitepaper',
                            icon: 'icon-newspaper',
                        },
                        {
                            key: 'sourceCode',
                            name: 'Github',
                            icon: 'icon-code',
                        },
                        {
                            key: 'github',
                            name: 'Github',
                            icon: 'icon-github',
                        },
                        {
                            key: 'supportChat',
                            name: 'Support Chat',
                            icon: 'icon-chat',
                        },
                        {
                            key: 'blog',
                            name: 'Blog',
                            icon: 'icon-document',
                        },
                        {
                            key: 'explorer',
                            name: 'Explorer',
                            icon: 'icon-cube',
                        },
                        {
                            key: 'coinpaprika',
                            name: `${item?.symbol} Market Data`,
                            icon: 'icon-coinpaprika',
                        },
                    ].map(
                        (obj, idx) =>
                            links?.[obj.key] && (
                                <ExternalLink
                                    icon={obj?.icon || ''}
                                    key={`item-link-${obj.key}-${idx}`}
                                    href={links?.[obj.key] ?? ''}
                                    label={obj?.name || ''}
                                    large
                                />
                            )
                    )}
                </div>
                <div className={styles.icons}>
                    {[
                        'twitter',
                        'discord',
                        'youtube',
                        'reddit',
                        'linkedin',
                        'telegram',
                        'forum',
                    ].map(
                        (key, idx) =>
                            links?.[key] && (
                                <Link
                                    key={`${key}-${idx}`}
                                    blank
                                    href={links?.[key]}
                                >
                                    <div className={styles.iconWrap}>
                                        <span
                                            className={classNames(
                                                `icon icon-${key}`,
                                                styles.icon
                                            )}
                                        />
                                    </div>
                                </Link>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}
