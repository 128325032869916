import { useState } from 'react'
import classNames from 'classnames'
import DOMPurify from 'isomorphic-dompurify'
import { HorizontalSeparator, Link, ToggleButton } from 'components/ui'

import styles from './faqItems.module.scss'

const Team = ({ team = [] }) => {
    return (
        <div className={styles.team}>
            {team?.map((member, idx) => (
                <div
                    key={`team-member-${member?.name?._id}-${idx}`}
                    className={styles.member}
                >
                    <p className={styles.name}>{member?.name}</p>
                    <p className={styles.position}>{member?.position}</p>
                    {Boolean(member?.url) && (
                        <Link blank href={member?.url}>
                            <div className={styles.iconWrap}>
                                <span
                                    className={classNames(
                                        `icon icon-linkedin`,
                                        styles.icon,
                                        styles.linkedIn
                                    )}
                                />
                            </div>
                        </Link>
                    )}
                </div>
            ))}
        </div>
    )
}

const FaqItem = ({
    question = '',
    answer = '',
    team = [],
    isExpanded = false,
    onToggle = () => {},
    isLast = false,
}) => {
    const isTeamFaq =
        question === 'What is your teams expertise / track record?'

    return (
        <div className={styles.faq}>
            <div className={styles.questionWrap} onClick={onToggle}>
                <div
                    itemScope
                    itemProp='mainEntity'
                    itemType='https://schema.org/Question'
                >
                    <h3
                        className={classNames(styles.question, {
                            [styles.expanded]: isExpanded,
                        })}
                        itemProp='name'
                    >
                        {question || 'Learn more'}
                    </h3>
                </div>
                <div className={styles.toggleWrap}>
                    <ToggleButton expanded={isExpanded} />
                </div>
            </div>
            <div
                className={classNames(styles.expandable, 'vertical-scroll', {
                    [styles.expanded]: isExpanded,
                })}
            >
                <div className={styles.answerWrap}>
                    {isTeamFaq && (
                        <>
                            {Boolean(team?.length) && (
                                <>
                                    <h3 className={styles.header}>Team</h3>
                                    <Team team={team} />
                                </>
                            )}
                            <h3 className={styles.header}>Team Track Record</h3>
                        </>
                    )}
                    <div
                        itemScope
                        itemProp='acceptedAnswer'
                        itemType='https://schema.org/Answer'
                    >
                        <div
                            className={classNames('cms', styles.answer, {
                                [styles.expanded]: isExpanded,
                            })}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(answer ?? '', {
                                    ADD_ATTR: ['target'],
                                }),
                            }}
                            itemProp='text'
                        />
                    </div>
                </div>
            </div>
            {!isLast && <HorizontalSeparator className={styles.separator} />}
        </div>
    )
}

export const FaqItems = ({ team = null, faqs = [] }) => {
    const [expandedItemIdx, setExpandedItemIdx] = useState(0)
    return (
        <div className={styles.faqs}>
            {faqs?.map((faq, idx) => {
                return (
                    <FaqItem
                        key={`faq-${idx}`}
                        question={faq?.question}
                        answer={faq?.answer}
                        team={team}
                        isExpanded={expandedItemIdx === idx}
                        onToggle={() => {
                            setExpandedItemIdx(
                                expandedItemIdx === idx ? null : idx
                            )
                        }}
                        isLast={faqs.length - 1 === idx}
                    />
                )
            })}
        </div>
    )
}
