import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import {
    calculateIfValid,
    getMetricValueByKey,
    getObjectFromJsonString,
    scrollWithHeaderOffset,
} from 'utils/actions'
import {
    formatOutputNumber,
    formatSeconds,
    getPrecisionBySignificantFigures,
    isInvalidNumber,
} from 'utils/formatter'
import { useAssetRewardCalculatorContext } from 'contexts/assetRewardCalculatorContext'
import { Link, Skeleton } from 'components/ui'
import { getNumberOrDefault } from 'components/calculator/utils'
import { RewardRatePart } from './rewardRatePart'
import { RewardDistribution } from './rewardRateDistribution'
import { LIQUID_STAKING_KEY } from 'utils/constants'
import { AssetRewardCalculatorInputs } from './assetRewardCalculatorInputs'

const DEFAULT_STAKING_AMOUNT_USD = 10000

const getStakingAmountUsd = (amount = '', usd = true, tokenPrice = null) => {
    if (usd) {
        return getNumberOrDefault(amount, DEFAULT_STAKING_AMOUNT_USD)
    }

    const validatedTokenPrice = getNumberOrDefault(tokenPrice, 0)

    return isInvalidNumber(amount)
        ? DEFAULT_STAKING_AMOUNT_USD
        : amount * validatedTokenPrice
}

export const getStakingAmountTokens = (
    amount = '',
    usd = true,
    tokenPrice = null
) => {
    const isInvalidTokenPrice = isInvalidNumber(tokenPrice) || tokenPrice === 0

    if (usd) {
        const stakingAmountUsd = getNumberOrDefault(
            amount,
            DEFAULT_STAKING_AMOUNT_USD
        )
        return isInvalidTokenPrice ? 0 : stakingAmountUsd / tokenPrice
    }

    return getNumberOrDefault(
        amount,
        isInvalidTokenPrice ? 0 : DEFAULT_STAKING_AMOUNT_USD / tokenPrice
    )
}

export const AssetRewardCalculator = ({ calculatorRef = null }) => {
    const isTablet = useMediaPredicate('(min-width: 768px)')

    const { asset, amount, usd, stakingTimeInDays, isLoading } =
        useAssetRewardCalculatorContext()

    const tokenPrice = getMetricValueByKey(asset, 'price')

    const rewardRate = getMetricValueByKey(asset, 'reward_rate')

    const result = calculateIfValid(
        ({ rewardRate, stakingTimeInDays, stakingAmountInUsd }) =>
            (rewardRate / 100) * (stakingTimeInDays / 365) * stakingAmountInUsd,
        {
            rewardRate,
            stakingTimeInDays,
            stakingAmountInUsd: getStakingAmountUsd(
                amount?.actual,
                usd,
                tokenPrice
            ),
        }
    )

    const methodologyLink = getObjectFromJsonString(
        asset?.links ?? ''
    )?.methodology

    const isLst = !!asset?.tags?.find(t => t.tagKey === LIQUID_STAKING_KEY)

    const rewardBreakdown = getObjectFromJsonString(
        asset?.metrics?.find(m => m?.metricKey === 'reward_rate')?.variation
    )?.reward_distribution

    return (
        <div className='grid grid-cols-1 sm:grid-cols-[max-content_auto] md:grid-cols-[max-content_auto] gap-y-5 bg-contrast-1 w-full p-6 rounded-lg md:min-w-auto'>
            <div
                className={classNames(
                    'flex flex-col w-full sm:pr-6 sm:border-r border-solid border-contrast-2/20 mb-0',
                    { ['h-full justify-between w-fit']: isTablet }
                )}
            >
                <div className='flex flex-col h-full w-full mb-5'>
                    {!!rewardBreakdown ? (
                        <RewardDistribution rewardBreakdown={rewardBreakdown} />
                    ) : (
                        <RewardRatePart withLstRates={isLst} />
                    )}
                </div>
                <Link
                    className='flex flex-row items-center gap-x-2'
                    href={
                        methodologyLink ||
                        'https://docs.stakingrewards.com/staking-data/metrics/reward-rate'
                    }
                    blank
                >
                    <span className='text-primary text-xs font-normal hover:underline'>
                        Learn more about the methodology
                    </span>
                    <span className='icon icon-arrow-right min-w-[10px] max-w-[10px] !bg-primary' />
                </Link>
            </div>
            <div className='flex flex-col pl-0 sm:pl-6 w-full justify-between xl:min-w-[280px] 2xl:min-w-[320px]'>
                <AssetRewardCalculatorInputs />
                <div className='flex flex-col w-full gap-y-3 p-4 bg-[var(--c-grey-8)] rounded-lg'>
                    <p className='text-sm text-contrast-3 font-bold leading-none'>{`Rewards after ${formatSeconds(
                        stakingTimeInDays * 86400,
                        false
                    )}`}</p>
                    {isLoading ? (
                        <Skeleton
                            width={'80px'}
                            height={'16px'}
                            borderRadius={4}
                        />
                    ) : (
                        <p className='text-[28px] text-contrast-4 font-bold leading-none'>
                            {formatOutputNumber(result, {
                                precision: Math.max(
                                    2,
                                    getPrecisionBySignificantFigures(result, 3)
                                ),
                                prefix: '$',
                                allowEmpty: false,
                                withAbbreviation: false,
                                forcePrecision: false,
                                showApproximation: false,
                            })}
                        </p>
                    )}
                </div>
                {calculatorRef && (
                    <div
                        className='flex flex-row items-center gap-x-4 mt-4 hover:opacity-80 cursor-pointer'
                        onClick={() => {
                            scrollWithHeaderOffset(calculatorRef, isTablet)
                        }}
                    >
                        <span className='text-primary text-xs font-normal'>
                            Advanced Calculator
                        </span>
                        <span className='icon icon-arrow-down min-w-[10px] max-w-[10px] !bg-primary' />
                    </div>
                )}
            </div>
        </div>
    )
}
