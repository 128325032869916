import classNames from 'classnames'
import { useTableFiltersContext } from 'contexts/tableFiltersContext'
import {
    BITCOIN_AND_OTHERS_KEY,
    STABLECOIN_KEY,
    TIMEFRAME_CHOICES,
} from 'utils/constants'
import { NO_DATA_INDICATOR } from 'utils/formatter'
import { Filter } from './filter'

import styles from './timeFrameFilter.module.scss'

export const TimeFrameFilter = ({ useNewFilters = false }) => {
    const { params, setParams } = useTableFiltersContext()

    const selected = params?.timeframe

    const choices = [STABLECOIN_KEY, BITCOIN_AND_OTHERS_KEY].includes(
        params?.category?.key
    )
        ? TIMEFRAME_CHOICES.filter(t => t.key === '24h')
        : TIMEFRAME_CHOICES

    return (
        <Filter
            label='Change %'
            icon='clock'
            withSearch={false}
            selectedName={selected?.name ?? NO_DATA_INDICATOR}
            height='160px'
            forceMobileView={useNewFilters}
        >
            <div className={styles.timeframesWrap}>
                <div className={styles.timeframes}>
                    {choices.map((choice, idx) => (
                        <p
                            key={`timeframe-${choice.key}-${idx}`}
                            className={classNames(styles.label, {
                                [styles.selected]: choice.key === selected?.key,
                            })}
                            onClick={() => {
                                setParams({
                                    ...params,
                                    timeframe: choice,
                                })
                            }}
                        >
                            {choice.name}
                        </p>
                    ))}
                </div>
            </div>
        </Filter>
    )
}
