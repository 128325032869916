import {
    getMetricPrecision,
    getMetricValueByKey,
    trimUnescapedHtml,
} from 'utils/actions'
import {
    NO_DATA_INDICATOR,
    formatOutputNumber,
    isInvalidNumber,
} from 'utils/formatter'
import { useAssetRewardCalculatorContext } from 'contexts/assetRewardCalculatorContext'

export const AssetDescription = () => {
    const { asset } = useAssetRewardCalculatorContext()

    const rewardRate = getMetricValueByKey(asset, 'reward_rate')
    const rewardRateFormatted = !isInvalidNumber(rewardRate)
        ? formatOutputNumber(rewardRate, {
              postfix: '%',
              spaceAfterNumber: true,
              allowEmpty: false,
              withAbbreviation: false,
              precision: getMetricPrecision('reward_rate', rewardRate),
              forcePrecision: false,
              showApproximation: false,
          })
        : NO_DATA_INDICATOR

    const description = (
        asset?.introDescription || `${asset?.name} is ${asset?.description}.`
    ).replace('{{reward_rate}}', rewardRateFormatted)

    return (
        <p className='text-contrast-3 text-sm leading-5 mb-6'>
            {trimUnescapedHtml(description ?? '')}
        </p>
    )
}
