import { useRef, useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import numeral from 'numeral'
import { useClickOutside } from 'hooks'
import { Tooltip } from 'components/ui'
import {
    DECIMALS_LIMIT_DEFAULT,
    isInvalidNumber,
    formatOutputNumber,
    formatInputNumber,
    countDecimalPlaces,
} from 'utils/formatter'
import {
    isIncompleteValidTyping,
    validateWithinMinMaxRange,
    replaceTypedCommaWithDot,
} from './utils'

import styles from './numericInput.module.scss'

export const NumericInput = ({
    className = '',
    inputClassName = '',
    value = '',
    onValueChange = () => {},
    minValue = 0,
    maxValue = 1000000,
    defaultValue = '',
    placeholder = 'Enter a value',
    symbol = '',
    minErrorMessage = '',
    maxErrorMessage = '',
    decimalsLimit = DECIMALS_LIMIT_DEFAULT,
    showValidationError = true,
    large = false,
    symbolFontSize = '12px',
    symbolContainerSize = '22px',
    symbolClassName = '',
    focusOnMount = false,
}) => {
    const inputRef = useRef(null)

    const [isEditing, setIsEditing] = useState(false)
    const [validationError, setValidationError] = useState('')
    const [inputValue, setInputValue] = useState(
        formatOutputNumber(value, {
            precision: Math.min(countDecimalPlaces(value), decimalsLimit),
            allowEmpty: true,
            withAbbreviation: false,
            forcePrecision: true,
            showApproximation: false,
        })
    )

    useEffect(() => {
        if (inputRef?.current && focusOnMount) {
            inputRef.current.focus()
        }
    }, [focusOnMount])

    useEffect(() => {
        setInputValue(
            formatOutputNumber(value, {
                precision: Math.min(countDecimalPlaces(value), decimalsLimit),
                allowEmpty: true,
                withAbbreviation: false,
                forcePrecision: true,
                showApproximation: false,
            })
        )
    }, [decimalsLimit, value])

    const validateWithErrorMessage = useCallback(
        _inputValue => {
            const inputValueNumber = Number(_inputValue)
            const isOutOfRange =
                inputValueNumber < minValue || inputValueNumber > maxValue

            if (isOutOfRange && _inputValue !== '') {
                setValidationError(
                    inputValueNumber < minValue
                        ? minErrorMessage
                        : maxErrorMessage
                )
            } else {
                setValidationError('')
            }
        },
        [maxErrorMessage, maxValue, minErrorMessage, minValue]
    )

    const onSetValueOnSubmit = useCallback(
        (submittedValue = '') => {
            // Parse newValue with numeral to get the unformatted value
            const newValue = numeral(submittedValue).value() ?? ''

            if (newValue === '') {
                if (defaultValue === '') {
                    setInputValue('')
                    onValueChange('')
                } else {
                    const precision = Math.min(
                        countDecimalPlaces(defaultValue),
                        decimalsLimit
                    )
                    // Reset to the default value
                    setInputValue(
                        formatOutputNumber(defaultValue, {
                            precision,
                            allowEmpty: true,
                            withAbbreviation: false,
                            forcePrecision: true,
                            showApproximation: false,
                        })
                    )
                    onValueChange(
                        formatInputNumber(Number(defaultValue), precision)
                    )
                }
            } else {
                validateWithinMinMaxRange(
                    newValue,
                    minValue,
                    maxValue,
                    decimalsLimit,
                    setInputValue,
                    onValueChange,
                    true
                )
            }
        },
        [decimalsLimit, defaultValue, maxValue, minValue, onValueChange]
    )

    const onSetValueOnChange = useCallback(
        (_rawInputValue = '') => {
            const rawInputValue = replaceTypedCommaWithDot(
                inputValue,
                _rawInputValue
            )
            if (
                isIncompleteValidTyping(
                    rawInputValue,
                    decimalsLimit > 0,
                    minValue < 0,
                    decimalsLimit
                )
            ) {
                // Allow typing values like "123."
                setInputValue(rawInputValue)
            } else {
                // Parse newValue with numeral to get the unformatted value
                const newValue = numeral(rawInputValue).value() ?? ''

                const precision = Math.min(
                    countDecimalPlaces(newValue),
                    decimalsLimit
                )

                validateWithErrorMessage(newValue)

                if (newValue === '') {
                    setInputValue('')
                    if (defaultValue === '') {
                        onValueChange('')
                    }
                } else {
                    const croppedValue = formatInputNumber(
                        Number(newValue),
                        precision
                    )
                    if (isInvalidNumber(croppedValue)) {
                        // ignore
                    } else {
                        validateWithinMinMaxRange(
                            croppedValue,
                            minValue,
                            maxValue,
                            precision,
                            setInputValue,
                            onValueChange,
                            false
                        )
                    }
                }
            }
        },
        [
            decimalsLimit,
            defaultValue,
            inputValue,
            maxValue,
            minValue,
            onValueChange,
            validateWithErrorMessage,
        ]
    )

    // Clear the error message when clicking outside the input
    useClickOutside(inputRef, () => {
        setValidationError('')
        if (isEditing) {
            setIsEditing(false)

            onSetValueOnSubmit(inputValue)
        }
    })
    return (
        <div className={className}>
            <Tooltip
                className={styles.tooltip}
                text={showValidationError ? validationError : ''}
                hasInputTarget
            >
                <div
                    className={classNames(styles.numericInput, {
                        [styles.large]: large,
                        [styles.error]: validationError !== '',
                    })}
                >
                    {symbol && (
                        <span
                            className={classNames(
                                styles.withSymbol,
                                symbolClassName
                            )}
                            style={{
                                minWidth: symbolContainerSize,
                                width: symbolContainerSize,
                                maxWidth: symbolContainerSize,
                                fontSize: symbolFontSize,
                            }}
                        >
                            {symbol}
                        </span>
                    )}
                    <input
                        className={classNames(styles.input, inputClassName)}
                        tabIndex={1}
                        ref={inputRef}
                        value={inputValue}
                        type='text'
                        inputMode='decimal'
                        pattern='[0-9]*'
                        step='any'
                        title=''
                        min={minValue}
                        max={maxValue}
                        placeholder={placeholder}
                        onWheel={e => e.target.blur()}
                        onClick={() => setIsEditing(true)}
                        onChange={e => {
                            onSetValueOnChange(e.target.value)
                        }}
                        onBlur={e => {
                            // Hovering the chart steals the focus from the input.
                            // Force keeping the focus on the input if still typing.
                            if (isEditing) {
                                e.target.focus()
                            } else {
                                onSetValueOnSubmit(inputValue)
                            }
                        }}
                        onKeyDown={e => {
                            if (
                                e.key === 'e' ||
                                (minValue >= 0 && e.key === '-')
                            ) {
                                e.preventDefault()
                            }
                        }}
                    />
                </div>
            </Tooltip>
        </div>
    )
}
