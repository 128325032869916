import classNames from 'classnames'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import {
    Loader,
    LogoImage,
    ButtonWithBg,
    BUTTON_VARIANT_FG,
} from 'components/ui'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { fetchDropOperators } from 'data'
import { DROP_WHITELISTED_VALIDATORS } from 'utils/constants'
import { getMetricValueByKey } from 'utils/actions'
import { formatPercentage } from 'utils/formatter'

export const ExpandedDrop = ({
    formattedData = null,
    className = '',
    isExpanded = false,
    profileItem = null,
    type = null,
    assetRewardCalculatorContext = null,
    timeframe = null,
    onSelectItem = () => {},
    selectedOption = null,
}) => {
    const PAGE_SIZE = 100
    const router = useRouter()

    // TODO remove this extra fetch, as we already have operator info coming from the parent components
    const { isLoading, data, fetchNextPage, isFetching, hasNextPage } =
        useInfiniteQuery({
            queryKey: ['fetchDropValidators'],
            enabled: isExpanded,
            initialPageParam: 0,
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = lastPage?.length
                    ? allPages?.length + 1
                    : undefined
                return nextPage
            },
            queryFn: async ({ pageParam = 0 }) => {
                const offset = pageParam * PAGE_SIZE
                const data = await fetchDropOperators({
                    offset, // offset for next page
                    limit: PAGE_SIZE,
                    metrics: ['weight'],
                })
                return data
            },
        })

    const validators = data?.pages?.[0]?.rewardOptions?.flat() ?? []

    if (!isExpanded) return null
    if (isLoading) return <Loader />
    return (
        <div className='flex flex-col w-full duration-200 transition-all'>
            <div
                className={classNames(
                    'flex flex-col  overflow-y-auto lg:rounded-b-lg lg:shadow-md h-full lg:gap-0 lg:!max-h-[240px] transition-all duration-300 operator-container'
                )}
            >
                <div className='flex flex-col gap-3 lg:gap-0 '>
                    {validators?.map((validator, index) => {
                        const validatorData = validator?.providers?.[0]
                        if (!validatorData) return null

                        const isSelected =
                            selectedOption?.id === validatorData?.id

                        const searchParams = new URLSearchParams({
                            input: 'cosmos',
                            output: 'drop-atom',
                            type: 'liquid-staking',
                            provider: validatorData?.slug,
                        })?.toString()

                        const weight = formatPercentage(
                            getMetricValueByKey(validator, 'weight') ?? 0
                        )

                        return (
                            <div
                                key={index}
                                onClick={() => onSelectItem?.(validatorData)}
                                className={classNames(
                                    'flex items-center justify-center gap-4 rounded-lg lg:rounded-none lg:flex lg:items-center lg:justify-between px-3 py-2 lg:px-[42px] lg:py-3 border-t border-solid border-shade-light/20 duration-200',
                                    className,
                                    isSelected ? 'bg-white' : 'bg-contrast-0'
                                )}
                            >
                                <div className='flex items-center gap-2 w-auto lg:gap-4'>
                                    <div
                                        className={classNames(
                                            '!w-4 !h-4 lg:!w-[42px] lg:!h-[12px]',
                                            {
                                                'icon icon-vsp-extended icon-og':
                                                    validatorData?.isVerified,
                                            }
                                        )}
                                    ></div>
                                    <div className='w-[6px] h-[6px] min-w-[6px] min-h-[6px] rounded-full activeMarker'></div>
                                    <div className='flex items-center gap-3 w-[145px]'>
                                        <LogoImage
                                            src={validatorData?.logoUrl}
                                            slug={validatorData?.slug}
                                            size={24}
                                        />
                                        <div className='flex flex-col'>
                                            <span className='font-bold text-[11px] text-[#303030] dark:text-white'>
                                                {validatorData?.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex items-center gap-2 ml-auto'>
                                    <MetricBox
                                        title='Weight'
                                        value={weight ?? '-'}
                                        isSmall
                                    />
                                    <div className='w-[100px]'>
                                        {DROP_WHITELISTED_VALIDATORS.includes(
                                            validatorData?.slug
                                        ) && (
                                            <button
                                                onClick={() => {
                                                    router.push(
                                                        `/stake-app?${searchParams}`
                                                    )
                                                }}
                                                className='max-h-[40px] items-center gap-2 bg-transparent dark:bg-shade-darker/80 rounded-lg py-2 px-3 duration-200'
                                            >
                                                <span className=' text-shade-darker dark:text-white text-sm font-semibold'>
                                                    Stake now
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {validators?.length > 0 && hasNextPage && (
                    <div className='flex justify-center py-2'>
                        <ButtonWithBg
                            className='mx-auto w-full !max-w-[200px]'
                            disabled={isFetching}
                            onClick={fetchNextPage}
                            variant={BUTTON_VARIANT_FG}
                        >
                            {isFetching ? 'Loading...' : 'Load More'}
                        </ButtonWithBg>
                    </div>
                )}
            </div>
        </div>
    )
}
