import { useEffect, useMemo, useRef } from 'react'
import { useDeferredRendering, useIntersection } from 'hooks'
import { Skeleton } from 'components/ui'
import { usePaginatedJournalPosts } from './hooks'
import { ArticlePost } from './articlePost'

import styles from './otherPosts.module.scss'

const GRID_PAGE_SIZE = 12

export const OtherPosts = ({ category = '' }) => {
    const ref = useRef(null)
    const isReady = useDeferredRendering(ref)
    const {
        batches: otherPostsBatches,
        isLoading: isLoadingPosts,
        paginationPage,
        setPaginationPage,
    } = usePaginatedJournalPosts(category, 4, GRID_PAGE_SIZE, '', isReady)

    const isLoading = !isReady || isLoadingPosts

    const isScrollEnd = useMemo(() => {
        const batches = Object.values(otherPostsBatches)
        const lastBatchLength = batches?.[batches?.length - 1]?.length ?? 0
        return lastBatchLength < GRID_PAGE_SIZE
    }, [otherPostsBatches])

    const [intersecting, intersectionRef] = useIntersection()

    useEffect(() => {
        if (intersecting && !isLoading && !isScrollEnd) {
            setPaginationPage(paginationPage => paginationPage + 1)
        }
    }, [intersecting, isLoading, isScrollEnd, setPaginationPage])

    useEffect(() => {
        setPaginationPage(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category])

    return (
        <div ref={ref} className={styles.otherPostsBatches}>
            {Object.values(otherPostsBatches).map((batch, batchIdx) => (
                <div
                    key={`other-post-batch-${batchIdx}`}
                    className={styles.otherPosts}
                >
                    {isLoading && batchIdx === paginationPage
                        ? Array.from(
                              { length: GRID_PAGE_SIZE },
                              (_, index) => index
                          ).map(idx => (
                              <div
                                  key={`other-post-${idx}`}
                                  className={styles.skeleton}
                              >
                                  <Skeleton width={'100%'} height={'100%'} />
                              </div>
                          ))
                        : batch?.map((post, idx) => (
                              <ArticlePost
                                  key={`other-post-${post?.slug}-${idx}`}
                                  post={post?.attributes}
                                  category={category}
                                  isDynamic
                              />
                          ))}
                </div>
            ))}
            <div ref={intersectionRef} />
        </div>
    )
}
