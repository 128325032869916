import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { CalculatorContext, useCalculatorContext } from 'components/calculator'
import { CalculatorChart } from 'components/calculator/calculatorChart'
import { ItemSelect } from 'components/calculator/itemSelect'
import {
    getStakingAmountTokens,
    getStakingAmountUsd,
} from 'components/calculator/utils'
import { FaqSection } from 'components/faqSection'
import { NumericInput, Switch } from 'components/forms'
import { Slider } from 'components/forms/slider2'
import { SubscribeSection } from 'components/modal'
import { Link, TooltipOnHover } from 'components/ui'
import { AssetConverter } from 'components/ui/assetConverter'
import { useGlobalData, useUntracketAssetProfile } from 'data'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import {
    expectedRewardRate,
    stakingResults,
    stakingTime,
    targetAsset,
} from 'state/assetProfile'
import {
    getMetricAbsoluteChange,
    getMetricValueByKey,
    getObjectFromJsonString,
} from 'utils/actions'
import { TYPE_ASSET } from 'utils/constants'
import {
    DECIMALS_LIMIT_TOKEN,
    formatDaysToObject,
    formatOutputNumber,
    formatSeconds,
} from 'utils/formatter'
import { SimilarAssetsSection } from './profile/similarAssetsSection'

const HeroSection = dynamic(
    () => import('./profile/heroSection').then(module => module.HeroSection),
    {
        ssr: false,
    }
)

export const UntrackedAssetProfilePage = ({ asset }) => {
    const faqRef = useRef(null)
    const { data: untrackedAssetData, isLoading: isLoadingAsset } =
        useUntracketAssetProfile(asset?.slug)

    const untrackedAsset = untrackedAssetData?.assets?.[0]

    const { data: globalData } = useGlobalData(['benchmark_reward_rate'])
    const defaultRate = formatOutputNumber(
        globalData?.metrics?.[0]?.defaultValue,
        {
            precision: 2,
        }
    )
    const assetWithMetrics = useMemo(
        () => ({
            ...asset,
            metrics: untrackedAsset?.metrics,
        }),
        [untrackedAsset] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const isStakeableToken = asset?.tags?.length > 0

    const dailyTradingVolume = getMetricValueByKey(
        assetWithMetrics,
        'daily_trading_volume'
    )
    const dailyTradingVolumeFormatted = formatOutputNumber(dailyTradingVolume, {
        precision: 2,
        prefix: '$',
    })

    const price = formatOutputNumber(
        getMetricValueByKey(assetWithMetrics, 'price'),
        {
            precision: 2,
            prefix: '$',
        }
    )
    const priceChange24h =
        getMetricAbsoluteChange(assetWithMetrics?.metrics, 'price', '24h') * 100
    const pticeChange24hFormatted = formatOutputNumber(priceChange24h, {
        precision: 2,
        postfix: '%',
    })
    const links = getObjectFromJsonString(asset?.links)
    const websiteLink =
        links?.website &&
        `<a href='${links?.website}' target='_blank'>
            Learn how to stake ${asset?.symbol}
        </a>`

    const commonFaqs = [
        {
            question: `How has the price of ${asset?.symbol} changed over the past 24h?`,
            answer: `The price of ${asset?.name} (${
                asset?.symbol
            }) is currently ${price}, which represents a ${pticeChange24hFormatted} ${
                priceChange24h > 0 ? 'increase' : 'decrease'
            } over the past 24 hours.`,
        },
    ]
    const assetWithFaqs = {
        ...asset,
        faqs: isStakeableToken
            ? // stakeable
              [
                  ...(assetWithMetrics?.faqs || [
                      {
                          question: `What is ${asset?.name} (${asset?.name}) Staking?`,
                          answer: `Staking ${asset?.name} (${asset?.symbol}) is where you commit ${asset.symbol} tokens to contribute to network security and the validation of new blocks. In return, participants or “stakers” are rewarded with additional ${asset?.name} tokens.`,
                      },
                      {
                          question: `How to Stake ${asset?.name} (${asset?.symbol})?`,
                          answer: `The best way to stake ${asset?.symbol} is detailed in the official ${asset?.name} documentation.
                      ${websiteLink}`,
                      },
                      {
                          question: `What are the rewards for staking ${asset?.name} (${asset?.symbol})?`,
                          answer: `The reward rate of staking ${asset?.name} is not currently available on Staking Rewards, however, you can benchmark any potential rewards against the average reward rate across all tracked staking assets, which is ${defaultRate}%.`,
                      },
                      {
                          question: `What are the risks when staking ${asset?.name} (${asset?.symbol})?`,
                          answer: `Staking involves certain risks, such as potential market volatility and the risk of slashing (penalties for malicious behavior). Familiarize yourself with the risks associated with staking ${asset?.symbol} and consider diversifying your stake.`,
                      },
                      {
                          question: `What is the difference between ${asset?.symbol} lending and ${asset?.symbol} staking?`,
                          answer: `Staking ${asset?.symbol} and lending ${asset?.symbol} are both ways to earn a yield on your holdings. They are often confused as the same thing, even though they are very different.

                When you stake a PoS asset you are directly contributing to the security of the network, in exchange you are rewarded with the network’s native token.
                
                When you lend ${asset?.name} (${asset?.symbol}), you are essentially giving a loan of your crypto assets in return for an interest on the amount you lend. `,
                      },
                  ]),
                  {
                      question: `What is the 24h Trading Volume of  ${asset?.name} (${asset?.symbol})?`,
                      answer: `Over the past 24 hours, ${asset?.name} (${asset?.symbol}) has seen a trading volume of  ${dailyTradingVolumeFormatted} across all available exchanges.`,
                  },
                  ...commonFaqs,
              ]
            : // non-stakeable
              [
                  ...(assetWithMetrics?.faqs || [
                      {
                          question: `How to Stake ${asset?.name} (${asset?.symbol})?`,
                          answer: `<p>To our knowledge, ${asset?.symbol} cannot be staked since we do not currently recognize ${asset?.name} as a proof-of–stake network.
                        If you feel this is wrong, please request to <a href="https://integration.stakingrewards.com/" target="_blank">get ${asset?.symbol} asset listed</a> on stakingrewards.com.</p>`,
                      },
                      {
                          question: `If I can’t stake ${asset?.name} (${asset?.symbol}), can I still earn an interest on my holdings?`,
                          answer: `You still may be able to earn an interest by lending your ${asset?.symbol} holdings. Typically, you can earn around 5% APR through lending.`,
                      },
                      {
                          question: `What is the difference between ${asset?.symbol} lending and ${asset?.symbol} staking?`,
                          answer: `Staking ${asset?.symbol} and lending ${asset?.symbol} are both ways to earn a yield on your holdings. They are often confused as the same thing, even though they are very different.

              When you stake a PoS asset you are directly contributing to the security of the network, in exchange you are rewarded with the network’s native token.
              
              When you lend ${asset?.name} (${asset?.symbol}), you are essentially giving a loan of your crypto assets in return for an interest on the amount you lend. `,
                      },
                  ]),
                  {
                      question: `What is the 24h Trading Volume of  ${asset?.name} (${asset?.symbol})?`,
                      answer: `Over the past 24 hours, ${asset?.name} (${asset?.symbol}) has seen a trading volume of ${dailyTradingVolumeFormatted} across all available exchanges.`,
                  },
                  ...commonFaqs,
              ],
    }

    const textForStakeableToken = `${asset?.name} (${asset?.symbol}) staking data is not available on Staking Rewards. You can still convert token prices, estimate your rewards and explore reward options for similar proof-of-stake assets.`
    const textForNonStakeableToken = `${asset?.name} (${asset?.symbol}) is not listed as a staking asset on Staking Rewards. You can still convert token prices, calculate reward rates and compare against rewards earned for other top staking assets.`
    return (
        <div className='mb-[100px] pt-[var(--p-page-top)] px-[var(--p-page-sides)]'>
            <div className='container'>
                <HeroSection
                    asset={assetWithMetrics}
                    isUntracked={true}
                    isStakeableToken={isStakeableToken}
                />
                <CalculatorContext
                    noRedirect={true}
                    defaultAssetSlug={asset?.slug}
                >
                    <div>
                        <div className='flex gap-10 mb-8 flex-col md:flex-row'>
                            <div className='bg-contrast-1 w-full h-[204px] mb-8 p-6 rounded-lg'>
                                <h3 className='text-contrast-4 text-xl font-bold mb-6'>
                                    {asset?.name} Staking
                                </h3>
                                <p className='pr-16 text-xs mb-6'>
                                    {isStakeableToken
                                        ? textForStakeableToken
                                        : textForNonStakeableToken}
                                </p>
                                <span
                                    className='text-primary text-xs flex items-center gap-1 cursor-pointer'
                                    onClick={() =>
                                        faqRef?.current?.scrollIntoView({
                                            behavior: 'smooth',
                                        })
                                    }
                                >
                                    Learn more about {asset?.name} Staking{' '}
                                    <span className='icon icon-arrow-right !bg-primary !w-[12px]' />
                                </span>
                            </div>
                            <div className='w-full'>
                                <h3 className='text-contrast-4 text-xl font-bold mb-4'>
                                    Convert {asset?.symbol} to{' '}
                                    {targetAsset?.value?.symbol}
                                </h3>
                                <AssetConverter asset={assetWithMetrics} />
                            </div>
                        </div>
                        <div className='w-full'>
                            <h3 className='text-contrast-4 text-xl font-bold mb-6'>
                                {asset?.name}{' '}
                                {isStakeableToken ? 'Staking' : ''} Rewards
                                Calculator
                            </h3>
                            <div className='text-contrast-3 text-base mb-6'>
                                Calculate your rewards over time using the
                                average interest rate, or adjust for different
                                reward rate assumptions.
                            </div>

                            <CalculatorSection
                                asset={assetWithMetrics}
                                defaultRate={defaultRate}
                            />
                            <div className='mt-20'>
                                <SimilarAssetsSection
                                    asset={assetWithMetrics}
                                />
                            </div>
                        </div>
                        <div className='mt-24' ref={faqRef}>
                            <FaqSection
                                item={assetWithFaqs}
                                type={TYPE_ASSET}
                            />
                        </div>
                        <div className='rounded-lg bg-contrast-1 p-20 mt-12'>
                            <SubscribeSection />
                        </div>
                    </div>
                </CalculatorContext>
            </div>
        </div>
    )
}

const DEFAUlT_USD_AMOUNT = 10000

const CalculatorSection = ({ asset, defaultRate }) => {
    const {
        setStakingTime: setStakingTimeForContext,
        setExpectedPrice: setExpectedPriceForContext,
        defaultAmountUsd,
        initialTokenPrice,
        setCustomInitialTokenPrice,
        setAsset: setAssetForContext,
        setOption,
        setAmount: setAmountForContext,
        setUsd: setIsUsdForContext,
        usd: isUsd,
    } = useCalculatorContext()
    const [customizeStakeSymbol, setCustomizeStakeSymbol] = useState('USD')
    const [calcValue, setCalcValue] = useState()
    const price = getMetricValueByKey(asset, 'price')
    const [expectedPrice, setExpectedPrice] = useState()
    const [selectedItem, setSelectedItem] = useState(asset)

    const isStakeableToken = asset?.tags?.length > 0

    useEffect(() => {
        if (!price) {
            return
        }
        setAssetForContext(asset)
        setCustomInitialTokenPrice(price)
    }, [price, asset]) // eslint-disable-line react-hooks/exhaustive-deps

    // Just setting the default value to expected reward rate input field because of the async call
    useEffect(() => {
        if (!defaultRate) return
        const testRewardOption = {
            metrics: [
                {
                    metricKey: 'reward_rate',
                    defaultValue: expectedRewardRate.value || price,
                },
            ],
        }
        setOption(testRewardOption)
    }, [defaultRate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const testRewardOption = {
            metrics: [
                {
                    metricKey: 'reward_rate',
                    defaultValue: expectedRewardRate.value || defaultRate,
                },
            ],
        }
        setOption(testRewardOption)
    }, [expectedRewardRate.value]) // eslint-disable-line react-hooks/exhaustive-deps

    const tooltipText = `⚠️ This is not the actual reward rate for ${asset?.symbol} Staking. This is simply an estimation based on the industry average reward rate of ${defaultRate}%`

    const isUsdSelected = customizeStakeSymbol === 'USD'

    const isCalcValueEmpty = calcValue === ''

    return (
        <>
            <div className='gap-6 mb-6 w-full grid grid-cols-1 lg:grid-cols-[456px_minmax(0,_1fr)]'>
                <div className='flex flex-col md:flex-row lg:flex-col gap-4 lg:gap-0 lg:max-w-[456px]'>
                    <div className='flex gap-4 mb-4 flex-col lg:flex-row sm:flex-row md:flex-col'>
                        <div className='rounded-lg bg-contrast-1 w-[220px] h-[184px] p-4 text-xs'>
                            <div className='text-sm font-bold text-contrast-3 mb-4'>
                                1. Asset
                            </div>
                            <ItemSelect
                                selectedItem={selectedItem}
                                onChange={item => {
                                    setSelectedItem(item)
                                }}
                                isDisabled
                            />
                            <div className='mt-4'>
                                <Link href='/assets' className='text-primary'>
                                    Explore more Assets
                                </Link>
                            </div>
                        </div>
                        <TooltipOnHover text={tooltipText}>
                            <div className='rounded-lg bg-contrast-1 w-[220px] h-[184px] p-4'>
                                <div className='text-sm font-bold text-contrast-3 mb-4'>
                                    2. Estimate Your Reward Rate
                                </div>
                                <CustomNumberInput
                                    className='!mb-2'
                                    value={expectedRewardRate.value}
                                    onValueChange={value =>
                                        (expectedRewardRate.value = value)
                                    }
                                    placeholder={defaultRate}
                                    symbol='%'
                                />
                                <p className='text-xs text-contrast-3 italic'>
                                    *The industry average reward rate is{' '}
                                    {defaultRate}%
                                </p>
                            </div>
                        </TooltipOnHover>
                    </div>
                    <div className='rounded-lg bg-contrast-1 w-full flex-1 p-4 md:h-[384px]'>
                        <div className='flex justify-between items-center mb-3'>
                            <div className='text-sm font-bold text-contrast-3'>
                                3.{' '}
                                {isStakeableToken
                                    ? 'Customize Your Stake'
                                    : 'Customize Your Amount'}
                            </div>
                            <div className='flex text-[10px] text-contrast-4'>
                                <Switch
                                    labelLeft='USD'
                                    labelRight={selectedItem?.symbol}
                                    isLeft={customizeStakeSymbol === 'USD'}
                                    onClick={() => {
                                        const newSymbol =
                                            customizeStakeSymbol === 'USD'
                                                ? selectedItem?.symbol
                                                : 'USD'
                                        setCustomizeStakeSymbol(newSymbol)
                                        setIsUsdForContext(newSymbol === 'USD')
                                        setCalcValue(
                                            newSymbol === 'USD'
                                                ? getStakingAmountUsd(
                                                      isCalcValueEmpty
                                                          ? defaultAmountUsd
                                                          : calcValue,
                                                      isCalcValueEmpty ||
                                                          isUsdSelected,
                                                      price,
                                                      selectedItem
                                                  )
                                                : getStakingAmountTokens(
                                                      isCalcValueEmpty
                                                          ? DEFAUlT_USD_AMOUNT
                                                          : calcValue,
                                                      isCalcValueEmpty ||
                                                          isUsdSelected,
                                                      price,
                                                      selectedItem
                                                  )
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div className='relative'>
                            <CustomNumberInput
                                value={calcValue}
                                onValueChange={value => {
                                    setCalcValue(value)
                                    setAmountForContext(value)
                                }}
                                symbol={
                                    customizeStakeSymbol === 'USD'
                                        ? '$'
                                        : customizeStakeSymbol
                                }
                                placeholder={formatOutputNumber(
                                    DEFAUlT_USD_AMOUNT,
                                    {
                                        withAbbreviation: false,
                                        forcePrecision: false,
                                        showApproximation: false,
                                    }
                                )}
                            />
                            <div className='text-xs text-contrast-3 absolute top-3 right-2'>
                                {formatOutputNumber(
                                    isUsdSelected
                                        ? getStakingAmountTokens(
                                              isCalcValueEmpty
                                                  ? DEFAUlT_USD_AMOUNT
                                                  : calcValue,
                                              isCalcValueEmpty || isUsdSelected,
                                              price,
                                              asset
                                          )
                                        : getStakingAmountUsd(
                                              isCalcValueEmpty
                                                  ? defaultAmountUsd
                                                  : calcValue,
                                              isCalcValueEmpty || isUsdSelected,
                                              price,
                                              asset
                                          ),
                                    {
                                        precision: !isUsdSelected ? 2 : null,
                                        prefix: !isUsdSelected ? '$' : '',
                                        postfix: isUsdSelected
                                            ? selectedItem?.symbol ?? ''
                                            : '',
                                        spaceAfterNumber: true,
                                        allowEmpty: false,
                                        withAbbreviation: false,
                                        forcePrecision: false,
                                        showApproximation: true,
                                    }
                                )}
                            </div>
                        </div>
                        <div className='flex justify-between items-center'>
                            <div className='text-sm font-bold text-contrast-3 mb-3 mt-4'>
                                {isStakeableToken ? 'Staking Time' : 'Time'}
                            </div>
                            <div className='text-xs underline text-contrast-4'>
                                {formatSeconds(
                                    stakingTime.value * 86400,
                                    false,
                                    3
                                )}
                            </div>
                        </div>
                        <Slider
                            minValue={1}
                            maxValue={365 * 10}
                            defaultValue={stakingTime.value}
                            onChange={value => {
                                stakingTime.value = value
                            }}
                            onMouseUp={() => {
                                setStakingTimeForContext(
                                    formatDaysToObject(stakingTime.value)
                                )
                            }}
                        />

                        <div className='flex justify-between items-center'>
                            <div className='text-sm font-bold text-contrast-3 mb-3 mt-4'>
                                Expected Price after{' '}
                                {formatSeconds(
                                    stakingTime.value * 86400,
                                    true,
                                    3
                                )}
                            </div>
                            <div
                                className='text-xs underline text-contrast-4 cursor-pointer'
                                onClick={() => {
                                    setExpectedPrice(price)
                                    setExpectedPriceForContext(price)
                                }}
                            >
                                {`${formatOutputNumber(price, {
                                    prefix: '$',
                                    allowEmpty: false,
                                    withAbbreviation: false,
                                    forcePrecision: false,
                                    showApproximation: false,
                                })}`}
                            </div>
                        </div>
                        <div className='relative'>
                            <CustomNumberInput
                                value={expectedPrice}
                                onValueChange={value => {
                                    setExpectedPrice(value)
                                    setExpectedPriceForContext(value)
                                }}
                                minValue={0}
                                maxValue={1000000}
                                placeholder={formatOutputNumber(price, {
                                    withAbbreviation: false,
                                    forcePrecision: false,
                                    showApproximation: false,
                                })}
                                minErrorMessage={`Please enter an expected price above 
                                                    ${formatOutputNumber(0, {
                                                        prefix: '$',
                                                        allowEmpty: false,
                                                        withAbbreviation: false,
                                                        forcePrecision: false,
                                                        showApproximation: false,
                                                    })}.`}
                                maxErrorMessage={`Please enter an expected price below 
                                                    ${formatOutputNumber(
                                                        1000000,
                                                        {
                                                            prefix: '$',
                                                            allowEmpty: false,
                                                            withAbbreviation: false,
                                                            forcePrecision: false,
                                                            showApproximation: false,
                                                        }
                                                    )}.`}
                                decimalsLimit={DECIMALS_LIMIT_TOKEN}
                                symbol='$'
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full rounded-lg bg-contrast-1 sm:h-[458px]'>
                    <div className='flex items-center justify-between px-6 pb-12 sm:pb-6 py-5 border-b border-solid border-contrast-2/40'>
                        <div className='text-sm font-bold text-contrast-3'>
                            Rewards Over Time
                        </div>
                        <div className='flex'></div>
                    </div>
                    <CalculatorChartMemoized />
                </div>
            </div>
            <div className='flex w-full justify-between rounded-lg bg-contrast-1 h-[88px] py-6 px-4 md:px-9 mb-12'>
                {[
                    {
                        label: '1 Day',
                        value: '1d',
                    },
                    {
                        label: '7 Days',
                        value: '7d',
                    },
                    {
                        label: '30 Days',
                        value: '30d',
                    },
                    {
                        label: '90 Days',
                        value: '90d',
                    },
                    {
                        label: '1 Year',
                        value: '365d',
                    },
                ].map(({ label, value }) => (
                    <div className='font-bold flex flex-col gap-2' key={value}>
                        <div className='text-xs text-contrast-3'>{label}</div>
                        <div className='text-sm md:text-base text-contrast-4'>
                            {formatOutputNumber(stakingResults.value?.[value], {
                                allowEmpty: false,
                                withAbbreviation: false,
                                forcePrecision: false,
                                showApproximation: false,
                                prefix: isUsd ? '$' : '',
                                postfix: isUsd
                                    ? ''
                                    : ` ${customizeStakeSymbol}`,
                                precision: isUsd ? 2 : null,
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

const CustomNumberInput = props => {
    const { symbol } = props
    return (
        <NumericInput
            className='bg-transparent !text-base'
            symbolClassName={classNames(
                '!bg-contrast-2/20 !text-contrast-4 !py-2 rounded-l-lg !h-auto',
                {
                    '!text-base': symbol === '$',
                    '!text-xs !pl-6': symbol !== '$',
                }
            )}
            inputClassName={classNames(
                '!bg-contrast-2/20 !text-left !text-base !py-2 !rounded-r-lg !h-auto',
                {
                    '!pl-0': symbol === '$',
                    '!pl-3': symbol !== '$',
                }
            )}
            {...props}
        />
    )
}

const CalculatorChartMemoized = memo(CalculatorChart)
