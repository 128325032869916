import classNames from 'classnames'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { ALL_KEY } from 'utils/constants'
import { MetricGroup } from 'components/metrics'
import {
    MOMENTUM_COLUMN,
    REWARD_COLUMN,
    RISK_COLUMN,
    GENERAL_COLUMN,
    ROI_365D_COLUMN,
    getMoreAssetMetricGroups,
} from '../constants'
import { NetStakingFlowGroup } from './netStakingFlowGroup'
import { getAssetSortByOptionsPerCategory } from './utils'

import styles from './assetExpandedMetricsCard.module.scss'
import { isMetricWithExpansion } from 'utils/actions'

export const AssetExpandedMetricsCard = ({
    netStakingFlowItem = null,
    item = null,
    index = '',
    isLoading = false,
}) => {
    const { queryParams } = useTableArchiveContext()

    const timeframeKey = queryParams?.timeframe?.key

    const showMetricGroups = getMoreAssetMetricGroups(item?.slug)
    return (
        <div className={classNames(styles.assetExpandedMetricsRow)}>
            <div className={classNames(styles.column, styles.chart)}>
                <NetStakingFlowGroup
                    netStakingFlowItem={netStakingFlowItem}
                    large
                    isLoading={isLoading}
                    width={'100%'}
                    height={'100px'}
                />
            </div>
            <div className={styles.metrics}>
                <div className={styles.metricsColumn}>
                    {[GENERAL_COLUMN, REWARD_COLUMN].map(column => (
                        <div key={`col-${column}`} className={styles.column}>
                            <div className={styles.name}>{column}</div>
                            {showMetricGroups
                                .filter(
                                    metricGroup => metricGroup.column === column
                                )
                                .map(metricGroup => (
                                    <MetricGroup
                                        key={`${item?.id}-${
                                            metricGroup?.key ?? ''
                                        }-${index}`}
                                        metricGroup={{
                                            ...metricGroup,
                                            width: '120px',
                                        }}
                                        item={item}
                                        timeframeKey={timeframeKey}
                                        isLoading={isLoading}
                                        hasExpansion={isMetricWithExpansion(metricGroup?.key)}
                                        isSortable={getAssetSortByOptionsPerCategory(
                                            ALL_KEY
                                        )
                                            .map(category => category.key)
                                            .includes(metricGroup?.key)}
                                    />
                                ))}
                        </div>
                    ))}
                </div>
                <div className={styles.metricsColumn}>
                    {[RISK_COLUMN, MOMENTUM_COLUMN].map(column => (
                        <div key={`col-${column}`} className={styles.column}>
                            <div className={styles.name}>{column}</div>
                            {showMetricGroups
                                .filter(
                                    metricGroup => metricGroup.column === column
                                )
                                .map(metricGroup => (
                                    <MetricGroup
                                        key={`${item?.id}-${
                                            metricGroup?.key ?? ''
                                        }-${index}`}
                                        metricGroup={{
                                            ...metricGroup,
                                            width: '120px',
                                        }}
                                        item={item}
                                        timeframeKey={timeframeKey}
                                        isLoading={isLoading}
                                        hasExpansion={isMetricWithExpansion(metricGroup?.key)}
                                        isSortable={getAssetSortByOptionsPerCategory(
                                            ALL_KEY
                                        )
                                            .map(category => category.key)
                                            .includes(metricGroup?.key)}
                                    />
                                ))}
                        </div>
                    ))}
                    <div className={styles.column}>
                        <div className={styles.name}>{ROI_365D_COLUMN}</div>
                        <div className={styles.roi}>
                            {showMetricGroups
                                ?.filter(
                                    metricGroup =>
                                        metricGroup.column === ROI_365D_COLUMN
                                )
                                .map(metricGroup => (
                                    <MetricGroup
                                        key={`${item?.id}-${
                                            metricGroup?.key ?? ''
                                        }`}
                                        metricGroup={metricGroup}
                                        item={item}
                                        timeframeKey={timeframeKey}
                                        isLoading={isLoading}
                                        hasExpansion={isMetricWithExpansion(metricGroup?.key)}
                                        isSortable={getAssetSortByOptionsPerCategory(
                                            ALL_KEY
                                        )
                                            .map(category => category.key)
                                            .includes(metricGroup?.key)}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
