import { useEffect } from 'react'
import { calculateIfValid, getMetricValueByKey } from 'utils/actions'
import { GroupSize, ItemWithLogo, withPagination } from 'components/ui'
import {
    ALIGNMENT_RIGHT,
    MetricGroup,
    ValidatorMetricGroup,
} from 'components/metrics'
import { NoDataStatus } from 'components/tables'
import {
    REWARD_OPTIONS_METRIC_GROUPS,
    STAKED_TOKENS_METRIC_KEY,
    STAKING_WALLETS_METRIC_KEY,
} from '../constants'
import { useOtherRewardOptionAssets } from './hooks'

import styles from './otherSupportedAssetsList.module.scss'

const STAKED_VALUE_KEY = 'staked_value'

const OtherSupportedAssetsListComponent = ({
    slug = '',
    page,
    pageSizeNumber,
    setHasMore,
}) => {
    const { data, isLoading, noDataFound, isEnd } = useOtherRewardOptionAssets(
        slug,
        page,
        pageSizeNumber
    )

    useEffect(() => {
        setHasMore(!isEnd)
    }, [isEnd, setHasMore])

    return (
        <div className={styles.otherSupportedAssetsList}>
            <div className={styles.otherSupportedAssets}>
                {data?.map(batch =>
                    batch?.map((option, idx) => {
                        const asset = option?.inputAssets?.[0]
                        const validator = option?.validators?.[0]
                        const stakersMetric = {
                            ...REWARD_OPTIONS_METRIC_GROUPS.find(
                                metricGroup =>
                                    metricGroup.key ===
                                    STAKING_WALLETS_METRIC_KEY
                            ),
                            withColor: false,
                        }
                        const stakedValueMetric = {
                            ...REWARD_OPTIONS_METRIC_GROUPS.find(
                                metricGroup =>
                                    metricGroup.key === STAKED_TOKENS_METRIC_KEY
                            ),
                            key: STAKED_VALUE_KEY,
                            withColor: false,
                            label: 'Staked Value',
                            width: '95px',
                            withPercentage: false,
                            prefix: '$',
                        }

                        return (
                            <div
                                key={`other-asset-option-${slug}-${option?.id}-${idx}`}
                                className={styles.assetWrap}
                            >
                                <div className={styles.asset}>
                                    <div className={styles.header}>
                                        <ItemWithLogo
                                            className={styles.assetGroup}
                                            iconUrl={asset?.logoUrl}
                                            name={asset?.name}
                                            details={asset?.symbol}
                                            isVerified={false}
                                            iconSize={40}
                                            reverseDetailsOrder={true}
                                            withDetails={true}
                                            slug={asset?.slug}
                                            sizeType={GroupSize.Large}
                                        />
                                        {Boolean(validator) && (
                                            <ValidatorMetricGroup
                                                validator={validator}
                                                withAddress={false}
                                                alignment={ALIGNMENT_RIGHT}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.assetMetrics}>
                                        <MetricGroup
                                            metricGroup={stakersMetric}
                                            item={option}
                                            timeframeKey={'30d'}
                                            isLoading={false}
                                        />
                                        <MetricGroup
                                            metricGroup={stakedValueMetric}
                                            item={{
                                                ...option,
                                                metrics: [
                                                    ...(option?.metrics ?? []),
                                                    {
                                                        ...option?.metrics?.find(
                                                            m =>
                                                                m?.metricKey ===
                                                                STAKED_TOKENS_METRIC_KEY
                                                        ),
                                                        metricKey:
                                                            STAKED_VALUE_KEY,
                                                        defaultValue:
                                                            calculateIfValid(
                                                                ({
                                                                    stakedTokens,
                                                                    price,
                                                                }) =>
                                                                    stakedTokens *
                                                                    price,
                                                                {
                                                                    stakedTokens:
                                                                        getMetricValueByKey(
                                                                            option,
                                                                            STAKED_TOKENS_METRIC_KEY
                                                                        ),
                                                                    price: getMetricValueByKey(
                                                                        asset,
                                                                        'price'
                                                                    ),
                                                                }
                                                            ),
                                                    },
                                                ],
                                            }}
                                            timeframeKey={'30d'}
                                            isLoading={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
            {(noDataFound || isLoading) && (
                <NoDataStatus isLoading={isLoading} />
            )}
        </div>
    )
}

export const OtherSupportedAssetsList = withPagination(
    OtherSupportedAssetsListComponent,
    true,
    [],
    { key: 6, name: '6' },
    false,
    styles.centered
)
