import { memo } from 'react'
import classNames from 'classnames'
import { ComponentWithLink } from 'components/ui'

import styles from './navigationMenu.module.scss'

export const NavigationMenuComponent = ({
    items = [],
    onSelect = () => {},
    selected = null,
    selectOnHover = false,
}) => {
    return (
        <div className={styles.navigationMenu}>
            {items?.map((view, idx) => (
                <ComponentWithLink
                    key={`footer-page-${view.key}-${idx}`}
                    link={view?.href}
                    blank={Boolean(view?.blank)}
                    external={true}
                >
                    <div
                        className={classNames(styles.navItem, {
                            [styles.selected]: view.key === selected,
                        })}
                        onClick={() => {
                            onSelect(view)
                        }}
                        onMouseEnter={() => {
                            if (selectOnHover) {
                                onSelect(view)
                            }
                        }}
                    >
                        {view.name}
                    </div>
                </ComponentWithLink>
            ))}
        </div>
    )
}

export const NavigationMenu = memo(NavigationMenuComponent)
