import { Link } from 'components/ui'
import { PRODUCT_LINKS } from './constants'

export function ProductsSubnav({ className = '' }) {
    return (
        <ul className={className}>
            {PRODUCT_LINKS.map((link, idx) => (
                <li key={`product-link-${link?.href}-${idx}`}>
                    <Link
                        href={link?.href}
                        blank={Boolean(link?.blank)}
                        external={Boolean(link?.external)}
                    >
                        {`${link?.title}${link?.external ? ' ↗' : ''}`}
                    </Link>
                </li>
            ))}
        </ul>
    )
}
