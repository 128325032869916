import Image from 'next/image'
import { Card } from './cards/card'

const BabylonLockCard = ({ stakers = '', setParams = null, params = null }) => {
    const setCategoryFilter = category => {
        setParams({
            ...params,
            category: {
                ...params.category,
                key: category,
            },
        })
    }

    return (
        <div>
            <Card
                className='mt-8 relative rounded-3xl'
                customBackground='bg-custom-blue-points-babylon'
                borderRadius='rounded-3xl'
            >
                <div className='flex items-center justify-center'>
                    <div className='absolute top-[-90px] left-[-50px] hidden sm:block'>
                        <Image
                            src='/static/svg/lock-babylon.svg'
                            alt='Babylon Lock'
                            width={254}
                            height={254}
                        />
                    </div>
                    <div className='flex flex-col ml-4 sm:ml-[200px] pr-4 sm:pr-[50px] border-[24px] leading-5'>
                        <p className='text-lg font-normal mb-4 sm:mb-[16px] leading-5'>
                            Babylon Mainnet Phase-1
                        </p>
                        <p className='text-xl sm:text-[28px] font-bold mb-3'>
                            Staking Cap Completed!
                        </p>
                        <p className='text-base sm:text-lg font-normal'>
                            We have successfully staked{' '}
                            <span className='font-semibold'>
                                1000 BTC amongst {stakers} Stakers.
                            </span>{' '}
                            The next phase will start soon. Feel free to{' '}
                            <span
                                onClick={() => {
                                    setCategoryFilter('liquid-staking')
                                }}
                                className='font-semibold text-primary cursor-pointer'
                            >
                                Liquid Stake
                            </span>{' '}
                            your BTC until then.
                        </p>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default BabylonLockCard
