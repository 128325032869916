import classNames from 'classnames'
import { useAssetRewardCalculatorContext } from 'contexts/assetRewardCalculatorContext'
import {
    NO_DATA_INDICATOR,
    formatOutputNumber,
    getPrecisionBySignificantFigures,
    isInvalidNumber,
} from 'utils/formatter'
import {
    calculateIfValid,
    getMetricValueByKey,
    getObjectFromJsonString,
} from 'utils/actions'
import {
    BUTTON_VARIANT_BLUE,
    BUTTON_VARIANT_FG,
    ButtonWithBg,
    Link,
} from 'components/ui'
import { DEFAULT_STAKING_AMOUNT_USD } from 'components/calculator/constants'
import { Switch } from 'components/forms'
import { getStakingAmountTokens } from '../info/assetRewardCalculator'
import { AssetInput } from './assetInput'
import { ReceiveBlock } from './receiveBlock'
import { getNumberOrDefault } from 'components/calculator/utils'
import { GTMEvent, logEvent } from 'utils/GTM'
import { HeaderWithLink } from 'components/text/headerWithLink'
import { checkIsStakeableLst } from 'components/rewardOptions/archive/utils'
import { LstStakeNowButton } from 'components/rewardOptions/archive/lstStakeNowButton'

const convertToStakeOrBuyAmount = (
    amount = '',
    usd = false,
    tokenPrice = null,
    toStake = false,
    exchangeRatio = null,
    marketRatio = null
) => {
    const result = calculateIfValid(
        ({ ratio, amountInTokens }) => amountInTokens / ratio,
        {
            ratio: toStake ? exchangeRatio : marketRatio,
            amountInTokens: getStakingAmountTokens(amount, usd, tokenPrice),
        }
    )
    return getNumberOrDefault(result, '')
}

export const GetLstCalculator = () => {
    const {
        asset: inputAsset,
        outputAsset,
        amount,
        setAmount,
        isLoading,
        usd,
        setUsd,
    } = useAssetRewardCalculatorContext()

    const inputAssetPrice = getMetricValueByKey(inputAsset, 'price')
    const tokenPrice = getMetricValueByKey(outputAsset, 'price')
    const exchangeRatio = getMetricValueByKey(outputAsset, 'exchange_ratio')
    const marketRatio = getMetricValueByKey(outputAsset, 'market_ratio')

    const buyReward = convertToStakeOrBuyAmount(
        amount?.actual,
        usd,
        inputAssetPrice,
        false,
        exchangeRatio,
        marketRatio
    )
    const stakeReward = convertToStakeOrBuyAmount(
        amount?.actual,
        usd,
        inputAssetPrice,
        true,
        exchangeRatio,
        marketRatio
    )

    const diffPercentage = calculateIfValid(
        ({ buyReward, stakeReward }) => {
            return (
                (Math.abs(buyReward - stakeReward) /
                    Math.min(buyReward, stakeReward)) *
                100
            )
        },
        {
            buyReward,
            stakeReward,
        }
    )
    const isBuyBest =
        buyReward >= stakeReward &&
        !isInvalidNumber(stakeReward) &&
        !isInvalidNumber(buyReward)
    const isStakeBest =
        !isBuyBest &&
        !isInvalidNumber(stakeReward) &&
        !isInvalidNumber(buyReward)

    const provider =
        outputAsset?.rewardOptionsWithAssetAsOutput?.[0]?.providers?.[0]

    const isStakeableLst = checkIsStakeableLst(
        outputAsset,
        inputAsset,
        outputAsset?.rewardOptionsWithAssetAsOutput?.[0]
    )

    const externalSwapLink = getObjectFromJsonString(outputAsset?.links)?.[
        'swaplink'
    ]

    const externalStakeLink = getObjectFromJsonString(outputAsset?.links)?.[
        'stakelink'
    ]

    const precision = isInvalidNumber(diffPercentage)
        ? 2
        : getPrecisionBySignificantFigures(diffPercentage / 100, 2)

    const onStake = (type, url) => {
        logEvent(GTMEvent.ExternalLstStake, {
            token: outputAsset?.symbol,
            provider: provider?.name,
            type,
            url,
        })
    }

    return (
        <div
            id={`section-get-${outputAsset?.symbol}`}
            className='flex flex-col w-full'
        >
            <HeaderWithLink
                title={`Get ${outputAsset?.symbol}`}
                href={`/asset/${outputAsset?.slug}#section-get-${outputAsset?.symbol}`}
            />
            <p className='text-description mb-6'>
                Swap your {inputAsset?.symbol} for {outputAsset?.symbol}, or
                stake with {provider?.name} directly.
            </p>
            <div className='flex flex-col xl:flex-row gap-y-12 xl:bg-contrast-1 w-full rounded-lg justify-between'>
                <div className='flex flex-col justify-center w-full xl:w-fit xl:border-r xl:border-solid xl:border-contrast-2/20 py-6 pl-8 pr-8 xl:pr-12 bg-contrast-1 rounded-lg'>
                    <div className='flex flex-col w-full'>
                        <Switch
                            className='flex justify-end mb-3'
                            textClassName='text-[10px] text-contrast-4 top-[1px]'
                            labelLeft='USD'
                            labelRight={inputAsset?.symbol ?? NO_DATA_INDICATOR}
                            isLeft={usd}
                            onClick={() => {
                                setUsd(!usd)
                            }}
                        />
                        <AssetInput
                            asset={inputAsset}
                            amount={amount}
                            onChange={newValue => {
                                setAmount(newValue, false)
                            }}
                            usd={usd}
                            isLoading={isLoading}
                            placeholder={formatOutputNumber(
                                usd
                                    ? DEFAULT_STAKING_AMOUNT_USD
                                    : getStakingAmountTokens(
                                          DEFAULT_STAKING_AMOUNT_USD,
                                          true,
                                          inputAssetPrice
                                      ),
                                {
                                    allowEmpty: false,
                                    withAbbreviation: false,
                                    forcePrecision: false,
                                    showApproximation: true,
                                }
                            )}
                            darker
                        />
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 w-full bg-contrast-1 rounded-lg'>
                    <div
                        className={classNames(
                            'flex flex-col p-8 md:px-12 md:py-8',
                            {
                                'border border-solid border-blue rounded-lg bg-contrast-0':
                                    isBuyBest,
                            }
                        )}
                    >
                        {isBuyBest && (
                            <div className='flex justify-center'>
                                <div className='-mt-[56px] py-1 px-5 h-fit w-fit bg-blue text-[var(--c-lightest)] text-xs font-bold rounded-tr-lg rounded-tl-lg whitespace-nowrap'>
                                    Best Rate
                                </div>
                            </div>
                        )}
                        <p className='font-bold text-xl mb-3'>Swap</p>
                        <div
                            className={classNames('flex flex-row mb-6', {
                                ['opacity-0']: !isBuyBest,
                            })}
                        >
                            {!isInvalidNumber(diffPercentage) && (
                                <div className='flex flex-col gap-y-2'>
                                    <p className='text-contrast-3 font-normal text-sm italic md:whitespace-nowrap'>
                                        {`Save ${formatOutputNumber(
                                            diffPercentage,
                                            {
                                                postfix: '%',
                                                spaceAfterNumber: true,
                                                allowEmpty: false,
                                                withAbbreviation: false,
                                                precision: 2,
                                                forcePrecision: false,
                                                showApproximation: false,
                                            }
                                        )} currently when swapping!`}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='grid grid-cols-1 gap-x-14 h-[64px] mb-5'>
                            <ReceiveBlock
                                reward={buyReward}
                                unit={outputAsset?.symbol}
                                tokenPrice={tokenPrice}
                                precision={precision}
                            />
                        </div>
                        {externalSwapLink ? (
                            <Link
                                href={externalSwapLink}
                                blank={true}
                                onClick={() => {
                                    onStake('swap', externalSwapLink)
                                }}
                            >
                                <ButtonWithBg
                                    className='h-8 px-10 !w-fit'
                                    variant={BUTTON_VARIANT_FG}
                                >
                                    Swap ↗
                                </ButtonWithBg>
                            </Link>
                        ) : isStakeableLst ? (
                            <LstStakeNowButton
                                className='h-8 px-10 !w-fit'
                                asset={outputAsset}
                                provider={provider}
                                variant={BUTTON_VARIANT_FG}
                                label='Swap'
                                inputAssetSlug={inputAsset?.slug}
                            />
                        ) : (
                            <div className='flex justify-center items-center h-8 px-8 !w-fit bg-contrast-3 text-contrast-0 font-bold text-xs rounded-sm'>
                                Coming Soon
                            </div>
                        )}
                    </div>
                    <div
                        className={classNames(
                            'flex flex-col p-8 md:px-12 md:py-8',
                            {
                                'border border-solid border-blue rounded-lg bg-contrast-0':
                                    isStakeBest,
                            }
                        )}
                    >
                        {isStakeBest && (
                            <div className='flex justify-center'>
                                <div className='-mt-[56px] py-1 px-5 h-fit w-fit bg-blue text-[var(--c-lightest)] text-xs font-bold rounded-tr-lg rounded-tl-lg whitespace-nowrap'>
                                    Best Rate
                                </div>
                            </div>
                        )}
                        <p className='font-bold text-xl mb-3'>Stake</p>
                        <div
                            className={classNames('flex flex-row mb-6', {
                                ['opacity-0']: !isStakeBest,
                            })}
                        >
                            {!isInvalidNumber(diffPercentage) && (
                                <div className='flex flex-col gap-y-2'>
                                    <p className='text-contrast-3 font-normal text-sm italic md:whitespace-nowrap'>
                                        {`Save ${formatOutputNumber(
                                            diffPercentage,
                                            {
                                                postfix: '%',
                                                spaceAfterNumber: true,
                                                allowEmpty: false,
                                                withAbbreviation: false,
                                                precision: 2,
                                                forcePrecision: false,
                                                showApproximation: false,
                                            }
                                        )} currently when staking!`}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='grid grid-cols-1 gap-x-14 h-[64px] mb-5'>
                            <ReceiveBlock
                                reward={stakeReward}
                                unit={outputAsset?.symbol}
                                tokenPrice={tokenPrice}
                                precision={precision}
                            />
                        </div>
                        {isStakeableLst ? (
                            <LstStakeNowButton
                                className='h-8 px-10 !w-fit'
                                asset={outputAsset}
                                provider={provider}
                                variant={BUTTON_VARIANT_BLUE}
                                label='Stake'
                                inputAssetSlug={inputAsset?.slug}
                            />
                        ) : externalStakeLink ? (
                            <Link
                                href={externalStakeLink}
                                blank={true}
                                onClick={() => {
                                    onStake('stake', externalStakeLink)
                                }}
                            >
                                <ButtonWithBg
                                    className='h-8 px-10 !w-fit'
                                    variant={BUTTON_VARIANT_BLUE}
                                >
                                    Stake ↗
                                </ButtonWithBg>
                            </Link>
                        ) : (
                            <div className='flex justify-center items-center h-8 px-8 !w-fit bg-contrast-3 text-contrast-0 font-bold text-xs rounded-sm'>
                                Coming Soon
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
