import { formatOutputNumber } from 'utils/formatter'

export const RewardRateRange = ({ data }) => {
    const isEqual = data?.worstRate === data?.bestRate
    return (
        <div className='font-bold min-w-[120px] max-w-[120px] flex flex-col gap-1'>
            <div className='text-xs text-contrast-3'>Reward Rate</div>
            <div className='text-sm text-contrast-4 flex'>
                {isEqual ? (
                    formatOutputNumber(data?.worstRate, {
                        precision: 2,
                        forcePrecision: false,
                        postfix: '%',
                    })
                ) : (
                    <span>
                        {formatOutputNumber(data?.worstRate, {
                            precision: 2,
                            forcePrecision: false,
                            postfix: '%',
                        })}{' '}
                        -{' '}
                        {formatOutputNumber(data?.bestRate, {
                            precision: 2,
                            forcePrecision: false,
                            postfix: '%',
                        })}
                    </span>
                )}
            </div>
        </div>
    )
}
