import classNames from 'classnames'
import { Link } from 'components/ui/links/link'
import Image from 'next/image'
import { useState } from 'react'
import { THEME, useTheme } from 'state'

export const VspProTag = () => {
    const [isHovering, setIsHovering] = useState(false)
    const theme = useTheme()
    const vspProFileName =
        theme === THEME.DARK
            ? 'vsp-pro-dark-extended'
            : 'vsp-pro-light-extended'

    return (
        <Link href={'/vsp'}>
            <div className='w-[72px] h-[24px]'>
                <Image
                    src={`/static/svg/${vspProFileName}.svg`}
                    alt={`Verified`}
                    fill
                    sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                    onMouseOver={() => setIsHovering(true)}
                    onMouseOut={() => setIsHovering(false)}
                />
                <div
                    className={classNames(
                        'absolute top-full -left-1/2 w-[135px] h-0 transition-all duration-300',
                        {
                            ['!h-[77px]']: isHovering,
                        }
                    )}
                >
                    <Image
                        src='/static/svg/vsp-pro-audited.svg'
                        alt={`Audited`}
                        fill
                    />
                </div>
            </div>
        </Link>
    )
}
