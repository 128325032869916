import { useEffect, useRef } from 'react'
import dynamic from 'next/dynamic'
import { useMediaPredicate } from 'react-media-hook'
import { useTheme, useUiState, setUiState, THEME } from 'state'
import { SocialLinks } from './socialLinks'

import styles from './mobileMenu.module.scss'

const BgVideo = dynamic(
    () => import('components/ui').then(mod => mod.BgVideo),
    {
        ssr: false,
    }
)

const MobileMenuNav = dynamic(
    () => import('./mobileMenuNav').then(mod => mod.MobileMenuNav),
    {
        ssr: false,
    }
)

export function MobileMenu() {
    const theme = useTheme()
    const closeMenu = useUiState('closeMenu')
    const { unmountMenu } = setUiState()
    const menuRef = useRef(null)
    const withDesktopMenu = useMediaPredicate('(min-width: 1280px)')

    useEffect(() => {
        if (withDesktopMenu) {
            unmountMenu()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withDesktopMenu])

    return (
        <div
            className={styles.menu}
            data-theme={theme}
            ref={menuRef}
            onAnimationEnd={() => {
                closeMenu && unmountMenu()
            }}
        >
            <BgVideo
                title='Staking Rewards'
                vimeoId={theme === THEME.DARK ? '834212860' : '834212800'}
                className={styles.video}
            />

            <div className={styles.wrap}>
                <div className='container'>
                    <div className={styles.mobileNav}>
                        <MobileMenuNav />
                    </div>
                    <div className={styles.footer}>
                        <SocialLinks />
                    </div>
                </div>
            </div>
        </div>
    )
}
