import { useEffect, useRef } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import dynamic from 'next/dynamic'
import {
    BITCOIN_AND_OTHERS_KEY,
    BNB_ECOSYSTEM_KEY,
    COSMOS_ECOSYSTEM_KEY,
    ETHEREUM_ECOSYSTEM_KEY,
    LIQUID_RESTAKING_KEY,
    LIQUID_STAKING_KEY,
    NATIVE_RESTAKING_KEY,
    POLKADOT_ECOSYSTEM_KEY,
    POS_ASSET_CATEGORY,
    RewardOptionType,
    STABLECOIN_KEY,
    STABLECOINS_ASSET_CATEGORY,
    TYPE_ASSET,
} from 'utils/constants'
import { FaqSection } from 'components/faqSection'
import { PerformanceSection } from 'components/performanceChart'
import { Metrics } from './profile/metrics'
import { RewardOptionsSection } from './profile/rewardOptionsSection'
import { ValidatorsSection } from './profile/validatorsSection'
import { JournalSection } from './profile/journalSection'
import { getAllPerformanceChartSeriesConfig } from './profile/utils'
import { CalculatorSection } from './profile/calculatorSection'
import { UnderMaintenanceSection } from './profile/underMaintenanceSection'
import { AssetInformation } from './profile/assetInformation'
import { RewardOptionTableState } from 'state/rewardOptionTableState'
import { useFloatingBanner } from 'contexts/floatingBannerContext'

const HeroSection = dynamic(
    () => import('./profile/heroSection').then(module => module.HeroSection),
    {
        ssr: false,
    }
)

export const AssetProfilePage = ({
    asset = null,
    query = null,
    hasJournalPosts = false,
    rewardOptionCategories = [],
    soloStakingRewardOption = null,
}) => {
    const isTablet = useMediaPredicate('(min-width: 768px)')

    const isRunValidatorFilter =
        RewardOptionTableState.selectedFilter.value ===
        RewardOptionType.RUN_VALIDATOR

    const faqRef = useRef(null)
    const calculatorRef = useRef(null)

    const tagKeys = asset?.tags?.map(tag => tag.tagKey)

    const tagKeyEcosystem = tagKeys?.find(
        ecosystem =>
            ecosystem === ETHEREUM_ECOSYSTEM_KEY ||
            ecosystem === COSMOS_ECOSYSTEM_KEY ||
            ecosystem === POLKADOT_ECOSYSTEM_KEY ||
            ecosystem === BNB_ECOSYSTEM_KEY
    )

    const tagKeyTag = tagKeys?.find(
        tag =>
            tag === LIQUID_STAKING_KEY ||
            tag === STABLECOIN_KEY ||
            tag === BITCOIN_AND_OTHERS_KEY ||
            tag === LIQUID_RESTAKING_KEY ||
            tag === NATIVE_RESTAKING_KEY ||
            tag === POS_ASSET_CATEGORY.key ||
            tag === STABLECOINS_ASSET_CATEGORY.key
    )

    const { setFloatingBannerProperties } = useFloatingBanner()

    useEffect(() => {
        setFloatingBannerProperties({
            asset: asset.slug,
            tag: tagKeyTag ? tagKeyTag : null,
            ecosystem: tagKeyEcosystem ? tagKeyEcosystem : null,
        })
    }, [asset])

    return (
        <div className='pt-[var(--p-page-top)] px-[var(--p-page-sides)] pb-0 mb-24'>
            <div className='container'>
                <HeroSection
                    asset={asset}
                    hasRewardOptionsTable={rewardOptionCategories?.length > 0}
                />
                <div className='flex flex-col w-full gap-y-12'>
                    {asset?.underMaintenance ? (
                        <UnderMaintenanceSection asset={asset} />
                    ) : (
                        <AssetInformation
                            faqRef={faqRef?.current}
                            calculatorRef={calculatorRef?.current}
                            asset={asset}
                        />
                    )}
                    <PerformanceSection
                        id={asset?.id}
                        name={asset?.name}
                        symbol={asset?.symbol}
                        type={TYPE_ASSET}
                        allSeriesConfig={getAllPerformanceChartSeriesConfig(
                            asset?.symbol,
                            asset?.tags
                        )}
                        defaultMetricKeys={['staked_tokens', 'price']}
                        slug={asset?.slug}
                        isTablet={isTablet}
                        maxSelectedMetricsCount={3}
                    />
                    {rewardOptionCategories?.length > 0 && (
                        <RewardOptionsSection
                            asset={asset}
                            query={query}
                            rewardOptionCategories={rewardOptionCategories}
                        />
                    )}
                    {Boolean(soloStakingRewardOption) &&
                        isRunValidatorFilter && (
                            <ValidatorsSection
                                slug={asset.slug}
                                symbol={asset.symbol}
                                soloStakingRewardOption={
                                    soloStakingRewardOption
                                }
                            />
                        )}
                    <Metrics asset={asset} />
                    <div ref={calculatorRef}>
                        <CalculatorSection asset={asset} scenariosExpandable />
                    </div>
                    <div ref={faqRef}>
                        <FaqSection item={asset} type={TYPE_ASSET} />
                    </div>
                    {hasJournalPosts && (
                        <JournalSection
                            slug={asset.slug}
                            assetName={asset.name}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
