import classNames from 'classnames'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { getFormattedOperatorData } from 'components/rewardOptions/archive/v2/operatorRow'
import { LogoImage } from 'components/ui'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { RewardOptionType } from 'utils/constants'

export const OperatorRowMobile = ({
    operator,
    tokenPrice,
    className,
    sortByKey,
}) => {
    const { queryParams } = useTableArchiveContext()
    const timeframe = queryParams?.timeframe?.key

    const formattedData = getFormattedOperatorData(
        operator,
        tokenPrice,
        timeframe
    )

    const operatorProvider = operator?.providers?.find(
        p => p?.type?.label === RewardOptionType.PROOF_OF_STAKE
    )

    return (
        <div
            className={classNames(
                'flex items-center justify-between px-4 py-3 rounded-lg border-t border-solid border-contrast-2/20 bg-contrast-0',
                className
            )}
        >
            <LogoImage src={operatorProvider?.logoUrl} size={27} />
            <div className='ml-2 font-bold leading-3 flex flex-col justify-center'>
                <div className='text-[11px] text-contrast-5'>
                    {operatorProvider?.name}
                </div>
            </div>
            <MetricBox
                title='Staked Tokens'
                value={formattedData.aum}
                change={formattedData.stakedTokensChangePercent}
                isSmall
                className={classNames('flex-1 text-right')}
            />
        </div>
    )
}
