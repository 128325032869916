import classNames from 'classnames'
import { getFormattedData } from 'components/rewardOptions/archive/v2/getFormattedData'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import {
    BUTTON_VARIANT_STAKE_NOW,
    ButtonWithBg,
} from 'components/ui/buttons/buttonWithBg'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { getObjectFromJsonString } from 'utils/actions'

import { TYPE_ASSET } from 'utils/constants'
import { GTMEvent, logEvent } from 'utils/GTM'

export const CustodialRewardOptionSubRow = ({
    item,
    tokenPrice,
    className,
    profileItem,
    rewardOption,
    assetRewardCalculatorContext,
    provider,
}) => {
    const { queryParams } = useTableArchiveContext()
    const timeframe = queryParams?.timeframe?.key

    const formattedData = getFormattedData(
        item,
        TYPE_ASSET,
        timeframe,
        undefined,
        assetRewardCalculatorContext
    )

    const stakeLink = getObjectFromJsonString(provider?.links)?.stakelink

    const lockupIcon =
        formattedData?.lockupTimeInSeconds === 0 ? (
            <span className='icon icon-lock-0 !bg-contrast-2' />
        ) : (
            <span className='icon icon-lock-1 !bg-contrast-3' />
        )

    return (
        <div
            className={classNames(
                'flex items-center justify-between px-4 py-3 border-t border-solid border-shade-light/20 bg-wb',
                className
            )}
        >
            <div className='w-3/12'>
                <MetricBox title='Tier' value={item?.label} isSmall />
            </div>
            <div className='w-2/12 min-w-[89px]'>
                <MetricBox
                    title='Reward Rate'
                    value={formattedData.rewardRate}
                    className='rounded-lg bg-gradient-sr-light/10 px-3 py-2 w-[89px]'
                    isSmall
                />
            </div>
            <div className='w-2/12'>
                <MetricBox
                    title={formattedData?.rewardsAfterTitle}
                    value={formattedData?.rewardsAfterValue}
                    isSmall
                />
            </div>
            <div className='w-2/12'>
                <MetricBox
                    title='Duration'
                    value={
                        <div className='flex items-center gap-1'>
                            {lockupIcon}
                            {formattedData?.lockupTimeInSeconds === 0
                                ? 'Flexible'
                                : formattedData?.lockupTime}
                        </div>
                    }
                    isSmall
                />
            </div>
            <div className='w-2/12'>
                <MetricBox
                    title='Min/Max Deposit'
                    value={'Unlimited'} // TODO
                    isSmall
                />
            </div>
            <div className='px-4'>
                <ButtonWithBg
                    label='Sign Up'
                    item={item}
                    variant={BUTTON_VARIANT_STAKE_NOW}
                    className='!h-8 hover:bg-contrast-5/90 transition-all duration-200 ease-in-out'
                    onClick={() => {
                        logEvent(GTMEvent.CustodialSignup, {
                            provider: provider?.slug,
                        })
                        window.open(stakeLink, '_blank')
                    }}
                >
                    Sign Up <span className='ml-1'>↗</span>
                </ButtonWithBg>
            </div>
        </div>
    )
}
