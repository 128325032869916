import classNames from 'classnames'
import { MetricBox } from './metricBox'
import { CopyAddress } from 'components/ui'
import { compressAddress } from 'utils/stakingAssistant/utils'
import { ActiveMarker } from 'components/ui/activeMarker'

export const ValidatorRowMobile = ({
    formattedData,
    validator,
    className,
    onClick = () => {},
}) => {
    const address = validator?.address
    return (
        <div
            className={classNames(
                'flex items-center justify-between px-4 py-3 rounded-lg border-t border-solid border-contrast-2/20 bg-contrast-0',
                className
            )}
            onClick={onClick}
        >
            <div className='flex items-center'>
                <ActiveMarker />
                <MetricBox
                    title={
                        <div className='w-[90px]'>
                            <CopyAddress
                                address={address}
                                initialText='Address'
                                textClassName='!text-[10px] whitespace-nowrap'
                            />
                        </div>
                    }
                    value={compressAddress(address, 4, 4)}
                    isSmall
                    className='!px-0'
                />
            </div>
            <MetricBox
                title='Reward Rate'
                value={formattedData?.rewardRate}
                className='rounded-lg bg-gradient-sr-light/10 px-3 py-2'
                isSmall
            />
        </div>
    )
}
