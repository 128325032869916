import classNames from 'classnames'
import { getPegDeviationColor } from 'components/performanceChart/utils'
import { getRating } from 'components/providers/profile/utils'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { getAnimationClassNames } from 'components/rewardOptions/archive/v2/getFormattedData'
import { CopyAddress, Link, LogoImage, TooltipOnHover } from 'components/ui'
import Image from 'next/image'
import { LIQUID_RESTAKING_KEY } from 'utils/constants'
import { compressAddress } from 'utils/stakingAssistant/utils'
import { TOOLTIPS } from 'components/rewardOptions/constants'
import { MAX_TOOLTIP_TEXT_LENGTH, truncateString } from 'utils/formatter'
import { TOOLTIP_TEXT } from 'utils/stakingAssistant'

export const ExpandedLST = ({
    formattedData,
    isExpanded,
    asset,
    sortByKey,
}) => {
    const hasRestaking = formattedData?.outputAsset?.tags
        ?.map(t => t.tagKey)
        .includes(LIQUID_RESTAKING_KEY)
    const tag = (
        <div
            className={classNames(
                'rounded-full py-2 px-3 font-bold text-[8px] whitespace-nowrap text-white leading-[8px] w-auto',
                {
                    'bg-cyan': hasRestaking,
                    'bg-magenta': !hasRestaking,
                }
            )}
        >
            {hasRestaking ? 'Liquid Restaking' : 'Liquid Staking'}
        </div>
    )
    const tokenModel =
        formattedData?.outputAssetExchangeRatio === 1
            ? 'Rebasing'
            : 'Compounding'

    const operatorCount = asset?.operators?.length
    // const network = formattedData.item?.chains?.[0]
    const network = null // Ignore network for now as we dont have the logos
    return (
        <>
            <div className='flex w-full justify-around'>
                <MetricBox
                    title={
                        <div className='w-full flex justify-center'>
                            <Image
                                src={'/static/svg/vsp-tag-extended.svg'}
                                alt={`Verified Provider`}
                                width={42}
                                height={14}
                            />
                        </div>
                    }
                    value={
                        <div className='flex justify-center text-contrast-3'>{`${operatorCount} Operators`}</div>
                    }
                    valueClassName='!text-contrast-2'
                    isSmall
                    className='w-3/12 block xl:hidden'
                />
                <div className='w-3/12 flex items-center'>
                    <MetricBox
                        value={tag}
                        className={getAnimationClassNames(0, isExpanded)}
                        valueContainerClassName='!mt-0'
                        isSmall
                    />
                </div>
                <div
                    className={classNames(
                        getAnimationClassNames(1, isExpanded),
                        'w-3/12'
                    )}
                >
                    <TooltipOnHover text={network?.label}>
                        <MetricBox
                            title='Network'
                            isSmall
                            value={
                                network && (
                                    <Link
                                        href={`/asset/${network?.key}`}
                                        blank
                                        className='flex'
                                    >
                                        <LogoImage
                                            slug={network?.key}
                                            src={network?.logoUrl}
                                            alt={`${network?.name} logo`}
                                            size={16}
                                        />
                                    </Link>
                                )
                            }
                        />
                    </TooltipOnHover>
                </div>
                <MetricBox
                    title={
                        <div>
                            <Image
                                src={'/static/svg/vsp-tag-extended.svg'}
                                alt={`Verified Provider`}
                                width={36}
                                height={12}
                            />
                        </div>
                    }
                    value={`${getRating(
                        formattedData.providerVspScore
                    )} VSP Score`}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(2, isExpanded),
                        'w-3/12'
                    )}
                />
                <div
                    className={classNames(
                        getAnimationClassNames(3, isExpanded),
                        'w-3/12'
                    )}
                >
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIPS.stakers.text,
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: TOOLTIPS.stakers.title,
                            allText: TOOLTIPS.stakers.text,
                        }}
                    >
                        <MetricBox
                            title='Stakers'
                            value={formattedData.stakers}
                            change={formattedData.stakersChangePercent}
                            isSmall
                            className={classNames({
                                ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                    sortByKey === 'staking_wallets',
                            })}
                        />
                    </TooltipOnHover>
                </div>
            </div>
            <div className='flex w-full justify-around'>
                <div
                    className={classNames(
                        getAnimationClassNames(4, isExpanded),
                        'w-3/12'
                    )}
                >
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIPS.fee.text,
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: TOOLTIPS.fee.title,
                            allText: TOOLTIPS.fee.text,
                        }}
                        className='w-full'
                    >
                        <MetricBox
                            title='Fee'
                            value={formattedData.fee}
                            className={classNames({
                                ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                    sortByKey === 'commission',
                            })}
                            titleClassName='text-center'
                            valueClassName='flex justify-center'
                            isSmall
                        />
                    </TooltipOnHover>
                </div>
                <div
                    className={classNames(
                        getAnimationClassNames(5, isExpanded),
                        'w-3/12'
                    )}
                >
                    <TooltipOnHover text={'Peg Accuracy'}>
                        <MetricBox
                            title='Peg Accuracy'
                            value={formattedData.outputAssetPegDeviation}
                            isSmall
                            className={classNames({
                                ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                    sortByKey === 'peg_deviation',
                            })}
                            valueClassName={`text-${getPegDeviationColor(
                                Number(
                                    formattedData.outputAssetPegDeviation?.replace(
                                        '%',
                                        ''
                                    )
                                )
                            )}`}
                        />
                    </TooltipOnHover>
                </div>
                <MetricBox
                    title='Reward Freq.'
                    value={formattedData.rewardFrequency}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(6, isExpanded),
                        'w-3/12'
                    )}
                />
                <div
                    className={classNames(
                        getAnimationClassNames(7, isExpanded),
                        'w-3/12'
                    )}
                >
                    <TooltipOnHover
                        text={truncateString(
                            TOOLTIP_TEXT.TokenModel,
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={{
                            title: 'Token Model',
                            allText: TOOLTIP_TEXT.TokenModel,
                        }}
                        className='w-full'
                    >
                        <MetricBox
                            title='Token Model'
                            value={tokenModel}
                            isSmall
                        />
                    </TooltipOnHover>
                </div>
                <MetricBox
                    title={
                        <div className='w-[90px]'>
                            <CopyAddress
                                address={
                                    formattedData?.outputAssetLinks?.contract
                                }
                                textClassName='!text-[10px] whitespace-nowrap'
                            />
                        </div>
                    }
                    value={compressAddress(
                        formattedData?.outputAssetLinks?.contract,
                        4,
                        4
                    )}
                    isSmall
                    className={classNames(
                        getAnimationClassNames(8, isExpanded),
                        'w-3/12'
                    )}
                />
                {/* <MetricBox
                title='Explorer Link'
                value={
                    <Link
                        href={formattedData.outputAssetLinks?.explorer}
                        isExternal
                        blank
                        className='text-primary'
                    >
                        View on Explorer
                    </Link>
                }
                isSmall
                className={getAnimationClassNames(0, isExpanded)}
            /> */}
            </div>
        </>
    )
}
