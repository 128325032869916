import { getLatestNetFlowMetricPerDays } from 'utils/converter'
import { SKELETON_BG_CONTRAST2, Skeleton } from 'components/ui'
import { BarChart } from './barChart'

import styles from './netStakingFlowGroup.module.scss'

export const NetStakingFlowGroup = ({
    netStakingFlowItem = null,
    large = false,
    isLoading = false,
    width = '100%',
    height = '100%',
}) => {
    const latestMetricsPerDay = getLatestNetFlowMetricPerDays(
        netStakingFlowItem?.metrics ?? []
    )?.slice(0, 7)
    return (
        <div className={styles.netStakingFlowGroup}>
            {large && (
                <div className={styles.label}>Net Staking Flow Last 7d</div>
            )}
            <div
                style={{
                    width,
                    height,
                }}
            >
                {isLoading ? (
                    <Skeleton
                        width={'100%'}
                        height={'100%'}
                        variant={SKELETON_BG_CONTRAST2}
                        borderRadius={large ? '8px' : '4px'}
                    />
                ) : (
                    <BarChart
                        maxBarSize={large ? 24 : 12}
                        withLabels={large}
                        metrics={latestMetricsPerDay}
                    />
                )}
            </div>

            {!large && (
                <div className={styles.label}>Net Staking Flow Last 7d</div>
            )}
        </div>
    )
}
