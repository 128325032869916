import { Button } from 'components/rewardOptions/archive/v2/button'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import {
    getFormattedData,
    getFormattedDataForCustodial,
} from 'components/rewardOptions/archive/v2/getFormattedData'
import {
    ExpandableVerifiedTag,
    Flag,
    Link,
    LogoImage,
    TooltipOnHover,
} from 'components/ui'
import { ActiveMarker } from 'components/ui/activeMarker'
import {
    OPERATOR_KEY,
    RewardOptionType,
    TYPE_ASSET,
    TYPE_PROVIDER,
} from 'utils/constants'
import classNames from 'classnames'
import { ValidatorRowMobile } from './validatorRowMobile'
import { useEffect, useState } from 'react'
import { ExpandButton } from './expandButton'
import {
    BUTTON_VARIANT_STAKE_NOW,
    ButtonWithBg,
} from 'components/ui/buttons/buttonWithBg'
import { hasStakeWithButton } from 'components/rewardOptions/utils'
import { StakeWithButton } from 'components/ui/StakeWithButton'
import { ItemCountTag } from 'components/ui/itemCountTag'
import { fetchAdditionalRewardOptions } from 'data'
import { Dropdown } from 'components/rewardOptions/archive/v2/dropdown'
import {
    formatOutputNumber,
    getPrecisionBySignificantFigures,
} from 'utils/formatter'
import {
    getMetricDefaultValueByKey,
    getMetricValueByKey,
    getObjectFromJsonString,
} from 'utils/actions'
import { OperatorRowMobile } from 'components/rewardOptions/archive/v2/operatorRowMobile'
import { THEME, useTheme } from 'state'
import { useAssetRewardOptionsCalculatorContext } from 'contexts/assetRewardOptionsCalculatorContext'
import { CustodialRewardOptionSubRowMobile } from 'components/rewardOptions/archive/v2/custodialRewardOptionSubRowMobile'
import { checkIsDelegatable, checkIsStakeableLst } from '../utils'
import { SRStakeNowButton } from '../srStakeNowButton'
import { GTMEvent, logEvent } from 'utils/GTM'
import { ExpandedNativeRestaking } from './rowContent/expandedNativeRestaking'
import { useRouter } from 'next/router'
import { getMetricObject } from 'utils/getMetricObject'

/**
 * Renders a card for a reward option in the archive.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.item - The reward option item.
 * @param {number} props.index - The index of the reward option. (For showing number in the left side)
 * @param {string} props.type - The type of the reward option. (PoS, LST, smart contract, etc.)
 * @param {Object} props.profileItem - The current profile item in the url. (Either asset or provider)
 * @param {string} props.profileType - The profile type. (Either TYPE_PROVIDER or TYPE_ASSET)
 * @param {string} props.timeframe - The timeframe for the metrics. (e.g. '30d')
 * @returns {JSX.Element} The rendered RewardOptionRow component.
 */
export const RewardOptionCard = ({
    item,
    index,
    type,
    profileItem,
    profileType,
    timeframe,
    sortByKey,
    sponsoredROTrackingId,
}) => {
    const router = useRouter()
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)
    const assetRewardCalculatorContext =
        useAssetRewardOptionsCalculatorContext()
    const { calculateResult } = assetRewardCalculatorContext
    const rewardOption =
        profileType === TYPE_PROVIDER
            ? item?.inputAssets?.[0]
            : item?.type?.key === OPERATOR_KEY
              ? item?.providers?.find(
                    p => p?.type?.key === RewardOptionType.PROOF_OF_STAKE
                )
              : item?.providers?.[0]

    const isPoS = type === RewardOptionType.PROOF_OF_STAKE
    const isLST = type === RewardOptionType.LIQUID_STAKING
    const isSolo = type === RewardOptionType.RUN_VALIDATOR
    const isSmartContract = type === RewardOptionType.SMART_CONTRACT
    const isCustodial = type === RewardOptionType.CUSTODIAL
    const isBabylon = type === RewardOptionType.BABYLON_STAKING
    const isNativeRestaking = type === RewardOptionType.NATIVE_RESTAKING

    const formattedData = isCustodial
        ? getFormattedDataForCustodial(
              item,
              profileItem,
              assetRewardCalculatorContext
          )
        : getFormattedData(
              item,
              profileItem,
              timeframe,
              type,
              assetRewardCalculatorContext
          )

    const [isLoading, setIsLoading] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [additionalRewardOptions, setAdditionalRewardOptions] = useState([])
    const [additionalRewardOption, setAdditionalRewardOption] = useState(null)

    const [selectedOption, setSelectedOption] = useState(null)

    useEffect(() => {
        if (hasLoaded || (!isLST && !isSmartContract) || !isExpanded) {
            return
        }
        const fetchData = async () => {
            setIsLoading(true)
            const result = await fetchAdditionalRewardOptions([
                formattedData?.outputAsset?.slug,
            ])
            setAdditionalRewardOptions(result?.rewardOptions ?? [])
            setIsLoading(false)
            setHasLoaded(true)
        }
        fetchData()
    }, [
        isLST,
        isSmartContract,
        formattedData?.outputAsset?.slug,
        isExpanded,
        hasLoaded,
    ])

    const isVerified = rewardOption?.isVerified

    const linkToRewardoptionProfile =
        profileType === TYPE_ASSET
            ? isCustodial
                ? `/provider/${item?.slug}`
                : rewardOption?.isClaimed
                  ? `/provider/${rewardOption?.slug}`
                  : 'https://stakingrewards.typeform.com/claim-profile'
            : `/asset/${rewardOption?.slug}`

    const lstToken = formattedData?.outputAsset

    const validators = item?.validators

    const tokenPrice = getMetricDefaultValueByKey(profileItem, 'price')

    const additionalRewardRate = getMetricDefaultValueByKey(
        additionalRewardOption,
        'reward_rate'
    )

    const finalRewardRate =
        Number(formattedData?.rewardRate?.replace('%', '')) +
        additionalRewardRate

    const finalRewardValue = calculateResult(
        finalRewardRate,
        formattedData?.price
    )

    const operators = item?.operators

    const hasAdditionalRO =
        (isLST || isSmartContract) && additionalRewardOptions.length > 0

    const stakeLink = getObjectFromJsonString(item?.links)?.stakelink

    const isPrivateNode =
        getMetricValueByKey(item, 'is_not_delegation_node') === 1

    let hasStakeNowButton = false
    const inputAsset =
        profileType === TYPE_ASSET ? profileItem : item?.inputAssets?.[0]
    const provider =
        profileType === TYPE_ASSET ? item?.providers?.[0] : profileItem

    if (isLST) {
        hasStakeNowButton = checkIsStakeableLst(
            formattedData?.outputAsset,
            inputAsset,
            item
        )
    } else if (isPoS) {
        hasStakeNowButton = checkIsDelegatable(inputAsset, provider, item)
    }

    const pointsPerBlockMetric = getMetricObject(
        formattedData.item,
        'points_per_block'
    )

    return (
        <>
            <div className='relative'>
                {sponsoredROTrackingId && (
                    <div className='h-[42px] w-[136px] top-[10] mt-[30px] bg-primary mb-4 rounded-lg absolute hover:shadow-md transition-all duration-300 cursor-pointer  p-6'>
                        <div className='text-white  absolute top-[10px] left-[40px] text-[10px]'>
                            <p className='font-semibold text-[10px]'>
                                Sponsored
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <div
                className={classNames(
                    `rounded-lg hover:shadow-md transition-all duration-500 ease-in-out w-full min-w-[285px]`,
                    {
                        'bg-white': theme === THEME.LIGHT,
                        'bg-contrast-1': theme === THEME.DARK,
                        'mt-[50px]': sponsoredROTrackingId,
                    }
                )}
            >
                <div className='flex h-[75px] items-center justify-between p-6'>
                    <div className='flex items-center'>
                        {isVerified && !sponsoredROTrackingId && (
                            <div className='absolute -left-[34px] z-10'>
                                <ExpandableVerifiedTag size={20} />
                            </div>
                        )}
                        {!sponsoredROTrackingId && (
                            <div className='h-full flex items-center justify-center text-base text-contrast-4 font-bold flex-shrink-0 mr-1'>
                                {index}
                            </div>
                        )}
                        <div className='w-[160px] rounded-l-lg p-2'>
                            <Link
                                href={linkToRewardoptionProfile}
                                className='flex items-center'
                            >
                                <ActiveMarker />
                                <LogoImage
                                    src={
                                        isCustodial
                                            ? item?.logoUrl
                                            : rewardOption?.logoUrl
                                    }
                                    size={27}
                                />
                                <div className='ml-2 font-bold leading-3 flex flex-col justify-center'>
                                    <div className='text-[14px] text-contrast-5'>
                                        {isCustodial
                                            ? item?.name
                                            : rewardOption?.name}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {isCustodial && (
                        <Flag
                            country={formattedData?.country}
                            width={21}
                            height={15}
                        />
                    )}
                    {/* <span className='icon icon-calculator !w-5 !h-5' /> */}
                </div>
                <div className='border-t border-contrast-2/20 border-solid px-6 pt-4'>
                    <div className='flex gap-2'>
                        {!isBabylon && !isNativeRestaking && (
                            <MetricBox
                                title='Reward Rate'
                                value={formattedData.rewardRate}
                                className='rounded-lg bg-gradient-sr-light/10 h-full p-4 w-full'
                                titleClassName='text-xs -mb-1'
                                valueClassName='text-xs'
                            />
                        )}
                        {(isLST || isPoS || isBabylon) && (
                            <MetricBox
                                title='Staked Tokens'
                                value={
                                    isBabylon
                                        ? formattedData.stakedTokens + ' BTC'
                                        : formattedData.aum
                                }
                                change={formattedData.stakedTokensChangePercent}
                                className={classNames('w-full',{
                                    ['p-2 rounded-lg w-full']:
                                        sortByKey === 'staked_tokens',
                                })}
                                titleClassName='text-xs -mb-1'
                                valueClassName='text-xs'
                                changeClassName='text-xs'
                            />
                        )}
                        {isBabylon && (
                            <MetricBox
                                title='Points per block per 0.05 BTC'
                                value={formatOutputNumber(
                                    pointsPerBlockMetric?.defaultValue,
                                    {
                                        precision: 4,
                                    }
                                )}
                                titleClassName='text-xs -mb-1'
                                valueClassName='text-xs'
                                changeClassName='text-xs'
                                className={classNames('w-full', {
                                    ['p-2 rounded-lg w-full']:
                                        sortByKey === 'points_per_block',
                                })}
                            />
                        )}
                        {isSolo && (
                            <MetricBox
                                title='Hosting Fees'
                                value={formattedData.hostingFee}
                                className={classNames('w-1/2', {
                                    ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                        sortByKey === 'hosting_fee',
                                })}
                                titleClassName='text-xs -mb-1'
                                valueClassName='text-xs'
                                changeClassName='text-xs'
                            />
                        )}
                        {isNativeRestaking && (
                            <div className='grid grid-cols-2 gap-x-2 gap-y-4 w-full'>
                                <MetricBox
                                    title='Reward Rate'
                                    value={formattedData.rewardRate}
                                    className='rounded-lg bg-gradient-sr-light/10 w-full h-full p-4'
                                    titleClassName='text-xs -mb-1'
                                    valueClassName='text-xs'
                                />
                                <MetricBox
                                    title={formattedData?.rewardsAfterTitle}
                                    value={formattedData?.rewardsAfterValue}
                                    change={formattedData?.rewardsAfterChange}
                                />
                                <MetricBox
                                    title='AuM'
                                    value={formattedData.aum}
                                    change={
                                        formattedData.stakedTokensChangePercent
                                    }
                                />
                                <MetricBox
                                    title='Services'
                                    value={
                                        <span>
                                            {`${formattedData?.supportedChains}/${formattedData?.totalSupportedChains}`}
                                        </span>
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {isLST && (
                        <div className='flex mt-2'>
                            <MetricBox
                                title='Network'
                                // TODO add value
                                className='w-1/2'
                                titleClassName='text-xs -mb-1'
                                valueClassName='text-xs'
                                changeClassName='text-xs'
                            />
                            <MetricBox
                                title='LST'
                                value={
                                    <Link
                                        href={`/asset/${lstToken?.slug}`}
                                        blank
                                    >
                                        <LogoImage
                                            slug={lstToken?.slug}
                                            src={lstToken?.logoUrl}
                                            alt={`${lstToken?.name} logo`}
                                            size={16}
                                        />
                                    </Link>
                                }
                                className='w-1/2'
                            />
                        </div>
                    )}
                    {isBabylon && (
                        <div className='flex mt-2'>
                            <MetricBox
                                title='Fee'
                                value={formattedData.fee}
                                className='p-2 rounded-lg'
                            />
                        </div>
                    )}
                </div>
                {validators?.length > 1 && (
                    <div
                        className={classNames(
                            `bg-contrast-1 flex flex-col transition-all duration-300 p-0 gap-0 overflow-y-auto`,
                            {
                                'px-6 py-4 gap-3 !max-h-[240px]': isExpanded,
                            }
                        )}
                    >
                        {validators?.map((validator, i) => {
                            return (
                                <ValidatorRowMobile
                                    key={i}
                                    formattedData={formattedData}
                                    validator={validator}
                                    onClick={() =>
                                        selectedOption === validator
                                            ? setSelectedOption(null)
                                            : setSelectedOption(validator)
                                    }
                                    className={classNames(
                                        `opacity-0 h-0 transition-all duration-300 ease-in-out delay-[${
                                            100 + i * 50
                                        }ms] -translate-y-10`,
                                        {
                                            ['opacity-100 !h-[71px] translate-y-0']:
                                                isExpanded,
                                            ['!p-0 -z-10 delay-0 !border-0']:
                                                !isExpanded,
                                            'bg-white':
                                                selectedOption === validator,
                                        }
                                    )}
                                />
                            )
                        })}
                    </div>
                )}
                {hasAdditionalRO && (
                    <>
                        <div
                            className={classNames(
                                'flex flex-col transition-all h-0 duration-300 ease-in-out opacity-0 border-t border-shade-light/20 border-solid',
                                {
                                    ['opacity-100 h-full']: isExpanded,
                                    ['!p-0 -z-10 delay-0 !border-0']:
                                        !isExpanded,
                                }
                            )}
                        >
                            <div className='bg-gradient-sr-light/10 py-4 px-10'>
                                <div className='text-xs text-contrast-6 font-bold'>
                                    Additional Reward Options
                                </div>
                                <Link
                                    href={`/asset/${formattedData.outputAsset?.slug}`}
                                    className='text-primary text-[11px]'
                                    blank
                                >
                                    Learn More{' '}
                                    <span className='icon icon-arrow-right !bg-primary !w-[9px] !h-[8px]' />
                                </Link>
                            </div>
                            <div className='flex flex-col justify-between flex-1 px-4 gap-3 mb-3'>
                                <div className='flex-1'>
                                    {isExpanded && (
                                        <Dropdown
                                            data={additionalRewardOptions}
                                            formattedData={formattedData}
                                            additionalRewardOption={
                                                additionalRewardOption
                                            }
                                            setAdditionalRewardOption={
                                                setAdditionalRewardOption
                                            }
                                            isMobile
                                        />
                                    )}
                                </div>
                                <div className='flex justify-between p-3 bg-contrast-0 items-center font-bold text-xs text-contrast-3 rounded-lg h-[45px]'>
                                    {formattedData?.rewardsAfterTitle}
                                    <span className='ml-2 text-contrast-4 text-lg leading-[18px]'>
                                        {formatOutputNumber(finalRewardValue, {
                                            precision: Math.max(
                                                2,
                                                getPrecisionBySignificantFigures(
                                                    finalRewardValue,
                                                    3
                                                )
                                            ),
                                            prefix: '$',
                                            allowEmpty: false,
                                            withAbbreviation: false,
                                            forcePrecision: false,
                                            showApproximation: false,
                                        })}
                                    </span>
                                </div>
                                <div className='flex justify-between p-3 bg-gradient-sr-light/10 items-center font-bold text-xs text-contrast-3 rounded-lg h-[45px]'>
                                    Final Reward Rate
                                    <span className='ml-2 text-contrast-4 text-lg leading-[18px]'>
                                        {formatOutputNumber(finalRewardRate, {
                                            precision: 2,
                                            forcePrecision: false,
                                            postfix: '%',
                                        })}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {operators?.length > 0 && (
                            <div
                                className={classNames(
                                    `bg-contrast-1 flex flex-col transition-all duration-300 p-0 gap-0 overflow-y-auto !max-h-[240px]`,
                                    {
                                        'px-6 py-4 gap-3': isExpanded,
                                    }
                                )}
                            >
                                {operators?.map((operator, i) => {
                                    return (
                                        <OperatorRowMobile
                                            key={i}
                                            operator={operator}
                                            tokenPrice={tokenPrice}
                                            sortByKey={sortByKey}
                                            className={classNames(
                                                `opacity-0 h-0 transition-all duration-300 ease-in-out delay-[${
                                                    100 + i * 50
                                                }ms] -translate-y-10`,
                                                {
                                                    ['opacity-100 !h-[71px] translate-y-0']:
                                                        isExpanded,
                                                    ['!p-0 -z-10 delay-0 !border-0']:
                                                        !isExpanded,
                                                    ['rounded-b-lg']:
                                                        i ===
                                                        operators.length - 1, // add rounded bottom corners to the last validator
                                                }
                                            )}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </>
                )}
                {isCustodial && (
                    <div
                        className={classNames(
                            `bg-contrast-1 flex flex-col transition-all duration-300 p-0 gap-0 overflow-y-auto !max-h-[240px] h-0`,
                            {
                                'px-6 py-4 gap-3 h-auto': isExpanded,
                            }
                        )}
                    >
                        {item?.rewardOptions?.map((ro, i) => {
                            return (
                                <CustodialRewardOptionSubRowMobile
                                    key={i}
                                    item={ro}
                                    provider={item}
                                    timeframe={timeframe}
                                    assetRewardCalculatorContext={
                                        assetRewardCalculatorContext
                                    }
                                    className={classNames(
                                        `opacity-0 transition-all duration-300 ease-in-out delay-[${
                                            100 + i * 50
                                        }ms] -translate-y-10`,
                                        {
                                            ['opacity-100 translate-y-0']:
                                                isExpanded,
                                            ['!p-0 -z-10 delay-0 !border-0']:
                                                !isExpanded,
                                        }
                                    )}
                                />
                            )
                        })}
                    </div>
                )}

                {isNativeRestaking && (
                    <div className='flex flex-col'>
                        <StakeWithButton
                            item={item}
                            profileItem={profileItem}
                            validator={item?.validators?.[0]}
                            className='w-full'
                            isNewDesign
                        />
                        <div
                            className={classNames(
                                `bg-contrast-1 flex flex-col transition-all duration-300 p-0 gap-0 overflow-y-auto`,
                                {
                                    'px-4 lg:px-6 py-4 gap-3 !max-h-[240px]':
                                        isExpanded,
                                }
                            )}
                        >
                            <ExpandedNativeRestaking
                                formattedData={formattedData}
                                profileItem={profileItem}
                                timeframe={timeframe}
                                type={type}
                                assetRewardCalculatorContext={
                                    assetRewardCalculatorContext
                                }
                                selectedOption={selectedOption}
                                onSelectItem={item => setSelectedOption(item)}
                                isExpanded={isExpanded}
                                isCard
                            />
                        </div>
                    </div>
                )}

                <div className='px-6 py-4'>
                    {hasStakeNowButton ? (
                        <SRStakeNowButton
                            inputAsset={inputAsset}
                            outputAsset={formattedData?.outputAsset}
                            provider={provider}
                            rewardOption={item}
                            variant={BUTTON_VARIANT_STAKE_NOW}
                            className='!h-[35px] w-full hover:bg-shade-darkest/90 transition-all duration-200 ease-in-out'
                        />
                    ) : hasStakeWithButton(profileItem, item?.validators) ? (
                        isPrivateNode ? (
                            <TooltipOnHover
                                className='w-full'
                                textClassName='!text-center'
                                text='Private node: Delegation not possible'
                            >
                                <StakeWithButton
                                    item={item}
                                    profileItem={profileItem}
                                    validator={item?.validators?.[0]}
                                    className='w-full'
                                    isNewDesign
                                    disabled
                                />
                            </TooltipOnHover>
                        ) : (
                            <StakeWithButton
                                item={item}
                                profileItem={profileItem}
                                validator={item?.validators?.[0]}
                                className='w-full'
                                isNewDesign
                            />
                        )
                    ) : validators?.length > 1 ? (
                        isExpanded ? (
                            selectedOption ? (
                                <StakeWithButton
                                    item={rewardOption}
                                    profileItem={profileItem}
                                    validator={selectedOption}
                                    isNewDesign
                                    className='w-full'
                                />
                            ) : (
                                <Button disabled>
                                    <span>Select validator to Stake</span>
                                </Button>
                            )
                        ) : (
                            <Button onClick={() => setIsExpanded(!isExpanded)}>
                                <span>Stake Options</span>{' '}
                                <ItemCountTag count={validators?.length} />
                            </Button>
                        )
                    ) : null}
                    {(validators?.length > 1 || hasAdditionalRO) &&
                        !sponsoredROTrackingId && (
                            <button
                                className='w-full h-[35px] rounded-lg bg-contrast-1 flex items-center justify-center border-0 hover:bg-contrast-0 mt-2 transition-all duration-300'
                                onClick={() => {
                                    setIsExpanded(!isExpanded)
                                    logEvent(GTMEvent.CustodialSignup, {
                                        provider: provider?.slug,
                                    })
                                    window.open(stakeLink, '_blank')
                                }}
                            >
                                <ExpandButton isExpanded={isExpanded} />
                            </button>
                        )}
                    {isCustodial && (
                        <>
                            {isExpanded && (
                                <Button
                                    onClick={() => {
                                        if (sponsoredROTrackingId) {
                                            logEvent(GTMEvent.AdRoTable, {
                                                provider:
                                                    item.providers[0].slug,
                                                adName: item.providers[0].name,
                                                url: stakeLink,
                                                id: sponsoredROTrackingId,
                                            })
                                        }
                                        window.open(stakeLink, '_blank')
                                    }}
                                >
                                    <span>Sign Up</span>
                                </Button>
                            )}
                            <button
                                className='w-full h-[35px] rounded-lg bg-contrast-1 flex items-center justify-center border-0 hover:bg-contrast-0 mt-2 transition-all duration-300'
                                onClick={() => {
                                    setIsExpanded(!isExpanded)
                                }}
                            >
                                <ExpandButton isExpanded={isExpanded} />
                            </button>
                        </>
                    )}
                    {isBabylon && (
                        <Link
                            href={`/stake-app?input=bitcoin&type=babylon-staking&provider=${rewardOption?.slug}`}
                            blank={true}
                        >
                            <ButtonWithBg
                                className='!h-[35px] w-full hover:bg-shade-darkest/90 transition-all duration-200 ease-in-out'
                                variant={BUTTON_VARIANT_STAKE_NOW}
                            >
                                Stake Now
                            </ButtonWithBg>
                        </Link>
                    )}
                    {isNativeRestaking && (
                        <>
                            <Button
                                disabled={!selectedOption}
                                onClick={() => {
                                    const searchParams = new URLSearchParams({
                                        input: 'lava',
                                        type: 'dual-staking',
                                        providers: selectedOption?.slug,
                                        'secondary-providers':
                                            formattedData?.provider?.slug,
                                    })?.toString()

                                    router.push(`/stake-app?${searchParams}`)
                                }}
                            >
                                <span>
                                    {selectedOption
                                        ? 'Stake Now'
                                        : 'Select Validator'}
                                </span>
                            </Button>
                            <button
                                className='w-full h-[35px] rounded-lg bg-contrast-1 flex items-center justify-center border-0 hover:bg-contrast-0 mt-2 transition-all duration-300'
                                onClick={() => {
                                    setIsExpanded(!isExpanded)
                                }}
                            >
                                <ExpandButton isExpanded={isExpanded} />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
