export const getDynamicWalletIcon = (wallet = null) => {
    let spriteId = wallet?.connector?.wallet?.brand?.spriteId
    if (!spriteId) {
        const allWallets = Object.values(
            wallet?.connector?.walletBook?.wallets ?? {}
        )
        const walletName = wallet?.connector?.name
        spriteId = walletName
            ? allWallets?.find(w => w?.name === walletName)?.brand?.spriteId
            : null
    }
    return spriteId
        ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${spriteId}`
        : null
}
