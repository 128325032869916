import { createContext, useCallback, useContext, useState } from 'react'
import { REFRESH_INTERVAL_ASSET_PRICE_MS, useProfileMetrics } from 'data'
import { calculateIfValid, getMetricValueByKey } from 'utils/actions'
import { TYPE_ASSET } from 'utils/constants'
import {
    DECIMALS_LIMIT_TOKEN,
    DECIMALS_LIMIT_USD,
    formatInputNumber,
    isInvalidNumber,
} from 'utils/formatter'
import { getStakingAmountUsd } from 'components/calculator/utils'
import { DEFAULT_STAKING_AMOUNT_USD } from 'components/rewardOptions/smartContract/smartContractCardContext'

const AssetRewardOptionsCalculatorContext = createContext()

export const useAssetRewardOptionsCalculatorContext = () => {
    return useContext(AssetRewardOptionsCalculatorContext)
}

export const AssetRewardOptionsCalculatorContextProvider = ({
    asset = null,
    children,
}) => {
    const [amount, setAmount] = useState({
        actual: '',
        rounded: '',
    })
    const [usd, setUsd] = useState(true)

    const [stakingTimeInDays, setStakingTimeInDays] = useState(365)

    const { data, isLoading } = useProfileMetrics(
        TYPE_ASSET,
        asset?.slug,
        [
            'price',
            'reward_rate',
            'marketcap',
            'staking_marketcap',
            'real_reward_rate',
            'inflation_rate',
            'exchange_ratio',
            'market_ratio',
            'unstaking_time',
        ],
        REFRESH_INTERVAL_ASSET_PRICE_MS
    )

    const tokenPrice = getMetricValueByKey(data, 'price')

    const onSetAmount = useCallback(
        (newAmount = '', round = true) => {
            setAmount({
                actual: newAmount,
                rounded: !isInvalidNumber(newAmount)
                    ? round
                        ? formatInputNumber(
                              newAmount,
                              usd ? DECIMALS_LIMIT_USD : DECIMALS_LIMIT_TOKEN
                          )
                        : newAmount
                    : '',
            })
        },
        [usd]
    )

    const onSetUsd = useCallback(
        (newIsUsd = true) => {
            setUsd(newIsUsd)

            if (amount?.actual !== '') {
                const floatAmount = isInvalidNumber(amount?.actual)
                    ? 0
                    : parseFloat(amount?.actual)
                const newAmount = newIsUsd
                    ? floatAmount * tokenPrice
                    : tokenPrice !== 0
                    ? floatAmount / tokenPrice
                    : floatAmount
                onSetAmount(newAmount, true)
            }
        },
        [onSetAmount, amount?.actual, tokenPrice]
    )

    const calculateResult = useCallback(
        (rewardRate, price) => {
            if (isInvalidNumber(amount?.actual)) {
                return calculateIfValid(
                    ({ rewardRate, stakingTimeInDays, stakingAmountInUsd }) =>
                        (rewardRate / 100) *
                        (stakingTimeInDays / 365) *
                        stakingAmountInUsd,
                    {
                        rewardRate,
                        stakingTimeInDays,
                        stakingAmountInUsd: DEFAULT_STAKING_AMOUNT_USD,
                    }
                )
            }
            return calculateIfValid(
                ({ rewardRate, stakingTimeInDays, stakingAmountInUsd }) =>
                    (rewardRate / 100) *
                    (stakingTimeInDays / 365) *
                    stakingAmountInUsd,
                {
                    rewardRate,
                    stakingTimeInDays,
                    stakingAmountInUsd: getStakingAmountUsd(
                        amount?.actual,
                        usd,
                        price ?? tokenPrice
                    ),
                }
            )
        },
        [stakingTimeInDays, amount?.actual, usd, tokenPrice]
    )

    return (
        <AssetRewardOptionsCalculatorContext.Provider
            value={{
                asset: { ...asset, metrics: data?.metrics ?? [] },
                amount,
                setAmount: onSetAmount,
                usd,
                setUsd: onSetUsd,
                stakingTimeInDays,
                setStakingTimeInDays,
                isLoading,
                calculateResult,
            }}
        >
            {children}
        </AssetRewardOptionsCalculatorContext.Provider>
    )
}
