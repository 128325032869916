import classNames from 'classnames'

import styles from './expandButton.module.scss'

export const ExpandButton = ({
    isExpanded = false,
    isHidden = false,
    onClick = () => {},
    className,
}) => {
    return (
        <div
            className={classNames(
                styles.toggleButton,
                {
                    [styles.hidden]: isHidden,
                },
                className
            )}
            onClick={() => {
                if (!isHidden) {
                    onClick()
                }
            }}
        >
            <div
                className={classNames(styles.toggle, {
                    [styles.expanded]: isExpanded,
                })}
            >
                <span className={styles.bar1} />
                <span className={styles.bar2} />
            </div>
        </div>
    )
}
