import { useEffect, useState } from 'react'
import { getExchangeQuote } from 'state/stakingPortfolio/swing'
import { getQuoteInformation } from './utils'
import { useCombinedWalletsContext } from 'contexts/combinedWalletsContext'

export const useExchangeQuote = (
    inputValue = '',
    inputAssetSymbol = '',
    outputAssetSymbol = '',
    onGetQuote = () => {},
    isQuoteUpdated = false
) => {
    const [isLoadingQuote, setIsLoadingQuote] = useState(false)

    const [exchangeQuote, setExchangeQuote] = useState(null)

    const { isLoggedIn } = useCombinedWalletsContext()

    useEffect(() => {
        const getQuote = async () => {
            const value = inputValue
            if (
                !isNaN(value) &&
                value !== '' &&
                value > 0.0000001 &&
                isLoggedIn
            ) {
                setIsLoadingQuote(true)
                const quote = await getExchangeQuote(
                    inputAssetSymbol,
                    outputAssetSymbol,
                    Number(value)
                )
                setIsLoadingQuote(false)
                setExchangeQuote(quote)
            } else {
                setExchangeQuote(null)
            }
        }
        if (inputAssetSymbol && outputAssetSymbol && !isQuoteUpdated) {
            getQuote()
            onGetQuote()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputAssetSymbol, inputValue, isQuoteUpdated, outputAssetSymbol])

    const quotes =
        exchangeQuote?.routes
            ?.filter(route => ['deposit', 'swap'].includes(route?.quote?.type))
            ?.map(getQuoteInformation)
            ?.toSorted((a, b) => b?.receive?.token - a?.receive?.token) ?? []

    return {
        quotes,
        bestQuote: quotes?.[0] ?? null,
        isLoading: isLoadingQuote,
    }
}
