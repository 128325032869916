import { getObjectFromJsonString } from 'utils/actions'
import { BUTTON_VARIANT_FG, ButtonWithBg, Link } from 'components/ui'
import { checkIsStakeableLst } from 'components/rewardOptions/archive/utils'
import { LstStakeNowButton } from 'components/rewardOptions/archive/lstStakeNowButton'

export const StakingButtonInLstArchive = ({ asset = null }) => {
    const provider = asset?.rewardOptionsWithAssetAsOutput?.[0]?.providers?.[0]
    const inputAsset =
        asset?.rewardOptionsWithAssetAsOutput?.[0]?.inputAssets?.[0]
    const isStakeableLst = checkIsStakeableLst(
        asset,
        inputAsset,
        asset?.rewardOptionsWithAssetAsOutput?.[0]
    )

    if (isStakeableLst) {
        return (
            <LstStakeNowButton
                className={'h-8 px-3 !w-full'}
                asset={asset}
                provider={provider}
                variant={BUTTON_VARIANT_FG}
                label='Get Now'
                inputAssetSlug={inputAsset?.slug}
            />
        )
    }

    const links = getObjectFromJsonString(asset?.links)
    const externalStakeLink = links?.stakelink
    const isReferral = provider?.isReferral

    if (externalStakeLink && isReferral) {
        return (
            <Link href={externalStakeLink} blank={true}>
                <ButtonWithBg
                    className='h-8 px-3 !w-full'
                    variant={BUTTON_VARIANT_FG}
                >
                    Get Now ↗
                </ButtonWithBg>
            </Link>
        )
    }
}
