import { useRef } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { useClickOutside } from 'hooks'
import { useTableFiltersContext } from 'contexts/tableFiltersContext'
import { NO_DATA_INDICATOR } from 'utils/formatter'
import { Search } from 'components/forms'
import { Modal } from 'components/modal'
import { Portal } from 'components/ui'

import styles from './filter.module.scss'

export const Filter = ({
    label = '',
    icon = '',
    selectedName = '',
    height = '320px',
    withSearch = false,
    forceMobileView = false,
    children,
}) => {
    const isTablet = useMediaPredicate('(min-width: 1120px)')

    const { openFilter, setOpenFilter, params, setParams } =
        useTableFiltersContext()

    if (!isTablet || forceMobileView) {
        return (
            <MobileFilter
                label={label}
                icon={icon}
                selectedName={selectedName}
                height={height}
                withSearch={withSearch}
                forceMobileView={forceMobileView}
            >
                {children}
            </MobileFilter>
        )
    }

    return (
        <div
            className={classNames(styles.filter, {
                [styles.open]: openFilter === label,
            })}
            style={{
                '--height': height,
            }}
            onClick={() => {
                setOpenFilter(label)
            }}
            onMouseEnter={() => {
                setOpenFilter(label)
            }}
            onMouseLeave={() => {
                setOpenFilter(null)
            }}
        >
            <div className={styles.target}>
                {openFilter === label && withSearch ? (
                    <Search
                        searchTerms={params?.search}
                        setSearchTerms={search => {
                            setParams({
                                ...params,
                                search,
                                asset: null,
                            })
                        }}
                    />
                ) : (
                    <>
                        <div
                            className={classNames(
                                `icon icon-${icon}`,
                                styles.icon
                            )}
                        />
                        <div className={styles.selectedWrap}>
                            <p className={styles.label}>{label}</p>
                            <div className={styles.selected}>
                                {selectedName || NO_DATA_INDICATOR}
                            </div>
                        </div>
                    </>
                )}
                <div
                    className={classNames(
                        `icon icon-og icon-chevron-arrow-down`,
                        styles.arrow
                    )}
                />
            </div>
            <div className={styles.options} style={{ '--height': height }}>
                {children}
            </div>
        </div>
    )
}

export const MobileFilter = ({
    label = '',
    icon = '',
    selectedName = '',
    height = '320px',
    withSearch = false,
    forceMobileView,
    children,
}) => {
    const withFilterLabel = useMediaPredicate('(min-width: 360px)')

    const { openFilter, setOpenFilter, params, setParams } =
        useTableFiltersContext()

    const modalRef = useRef(null)
    useClickOutside(modalRef, () => {
        setOpenFilter(null)
    })

    return (
        <>
            <div
                className={classNames(styles.filter, styles.mobile, {
                    [styles.filterButton]: forceMobileView,
                })}
                style={{
                    '--height': height,
                }}
            >
                <div
                    className={classNames(styles.target, styles.small)}
                    onClick={() => {
                        setOpenFilter(label)
                    }}
                >
                    <div
                        className={classNames(`icon icon-${icon}`, styles.icon)}
                    />
                    {withFilterLabel && (
                        <div className={styles.selectedWrap}>
                            <p className={styles.label}>{label}</p>
                        </div>
                    )}
                </div>
            </div>
            {openFilter === label && (
                <Portal>
                    <Modal
                        className={styles.modal}
                        isOpen
                        onClose={() => {
                            setOpenFilter(null)
                        }}
                    >
                        <div
                            ref={modalRef}
                            className={classNames(
                                styles.filter,
                                styles.open,
                                styles.mobile
                            )}
                            style={{
                                '--height': `calc(${height} + ${
                                    withSearch ? '60px' : 0
                                })`,
                            }}
                        >
                            <div className={styles.target}>
                                <div
                                    className={classNames(
                                        `icon icon-${icon}`,
                                        styles.icon
                                    )}
                                />
                                <div className={styles.selectedWrap}>
                                    <p className={styles.label}>{label}</p>
                                    <div className={styles.selected}>
                                        {selectedName || NO_DATA_INDICATOR}
                                    </div>
                                </div>
                            </div>
                            {withSearch && (
                                <div className={styles.searchWrap}>
                                    <Search
                                        searchTerms={params?.search}
                                        setSearchTerms={search => {
                                            setParams({
                                                ...params,
                                                search,
                                                asset: null,
                                            })
                                        }}
                                    />
                                </div>
                            )}
                            <div
                                className={styles.options}
                                style={{ '--height': height }}
                            >
                                {children}
                            </div>
                        </div>
                    </Modal>
                </Portal>
            )}
        </>
    )
}
