import { AssetSelectDropdown } from 'components/assetConverter/assetSelectDropdown'
import { NumericInput } from 'components/forms'
import { LogoImage } from 'components/ui/logoImage'
import { useAssetsForRates } from 'data'
import { useEffect, useState } from 'react'
import { getPrecisionBySignificantFigures } from 'utils/formatter'

const DEFAULT_SOURCE_VALUE = 10000

export const AssetConverter = ({ asset }) => {
    const [rates, setRates] = useState()
    const [selectedTargetAsset, setSelectedTargetAsset] = useState('USD')
    const [sourceValue, setSourceValue] = useState()
    const [targetValue, setTargetValue] = useState()
    const { data: assetsForRates } = useAssetsForRates(asset?.slug)

    useEffect(() => {
        if (!assetsForRates) return
        const usd = assetsForRates?.assets?.find(d => d.slug === asset?.slug)
            ?.metrics?.[0]?.defaultValue
        const rates = assetsForRates?.assets?.map(
            d =>
                ({
                    symbol:
                        d.symbol === asset?.symbol
                            ? 'USD'
                            : d.symbol === 'EURS'
                            ? 'EUR'
                            : d.symbol,
                    rate:
                        d.slug === asset?.slug
                            ? d.metrics?.[0].defaultValue
                            : (1 / d.metrics?.[0].defaultValue) * usd,
                    slug: d.slug,
                    logoUrl:
                        d.symbol === asset?.symbol
                            ? '/static/svg/dollar-dark.svg'
                            : d.symbol === 'EURS'
                            ? '/static/svg/euro-dark.svg'
                            : d.logoUrl,
                    name:
                        d.symbol === asset?.symbol
                            ? 'US Dollar'
                            : d.symbol === 'EURS'
                            ? 'Euro'
                            : d.name,
                } || [])
        )
        setRates([
            rates?.find(r => r.symbol === 'USD'),
            rates?.find(r => r.symbol === 'EUR'),
            rates?.find(r => r.symbol === 'ETH'),
            rates?.find(r => r.symbol === 'BTC'),
            ...(rates?.filter(
                r => !['USD', 'EUR', 'ETH', 'BTC'].includes(r.symbol)
            ) || []),
        ])
        const usdAsset = rates.find(r => r.symbol === 'USD')
        setSelectedTargetAsset(usdAsset)
        setTargetValue(DEFAULT_SOURCE_VALUE * usdAsset?.rate)
    }, [assetsForRates]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex bg-contrast-1 rounded-lg w-full'>
                <div className='border-r border-solid border-contrast-2/40'>
                    <div className='p-4 flex gap-1 items-center justify-between min-w-[180px]'>
                        <div className='flex gap-2'>
                            <LogoImage
                                src={asset?.logoUrl}
                                alt={asset?.name}
                                slug={asset?.slug}
                                size={28}
                            />
                            <div className='text-xs font-bold'>
                                <div className='text-contrast-4'>
                                    {asset?.name}
                                </div>
                                <div className='text-contrast-3'>
                                    {asset?.symbol}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-2 flex items-center w-full h-[64px]'>
                    <NumericInput
                        inputClassName='!bg-transparent h-full w-full !py-0 !text-left !text-xl'
                        placeholder={DEFAULT_SOURCE_VALUE}
                        type='number'
                        value={sourceValue}
                        onValueChange={value => {
                            setSourceValue(value)
                            setTargetValue(
                                (value || DEFAULT_SOURCE_VALUE) *
                                    selectedTargetAsset?.rate
                            )
                        }}
                        decimalsLimit={getPrecisionBySignificantFigures(
                            sourceValue
                        )}
                    />
                </div>
            </div>
            <div className='flex w-full justify-center'>
                <span className='icon icon-arrow-right !bg-contrast-3 !w-[8px] -rotate-90' />
                <span className='icon icon-arrow-right !bg-contrast-3 !w-[8px] rotate-90' />
            </div>
            <div className='flex bg-contrast-1 rounded-lg w-full'>
                <AssetSelectDropdown
                    baseAsset={asset}
                    defaultAsset={selectedTargetAsset}
                    assetShortcuts={rates}
                    onChange={value => {
                        setSelectedTargetAsset(value)
                        const source = sourceValue || DEFAULT_SOURCE_VALUE
                        setTargetValue(source * value?.rate)
                    }}
                />
                <div className='px-2 flex items-center w-full h-[64px]'>
                    <NumericInput
                        inputClassName='!bg-transparent h-full w-full !py-0 !text-left !text-xl'
                        placeholder='0'
                        type='number'
                        maxValue={Number.MAX_SAFE_INTEGER}
                        value={targetValue}
                        onValueChange={value => {
                            setTargetValue(value)
                            const calculatedSourceValue =
                                value / selectedTargetAsset?.rate
                            if (
                                calculatedSourceValue !==
                                    DEFAULT_SOURCE_VALUE &&
                                calculatedSourceValue !== 0
                            ) {
                                setSourceValue(
                                    value / selectedTargetAsset?.rate
                                )
                            }
                        }}
                        decimalsLimit={getPrecisionBySignificantFigures(
                            targetValue
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
