import { useChains, useManager } from '@cosmos-kit/react'
import {
    getCosmosChainName,
    SUPPORTED_COSMOS_CHAINS,
} from 'components/staking/cosmos/utils'
import { LogoImage } from 'components/ui'
import { SDK, useCombinedWalletsContext } from 'contexts/combinedWalletsContext'
import React, { useMemo, useState } from 'react'
import { Tooltip } from 'react-tooltip'

export const ChainSelector = () => {
    const {
        primaryWallet,
        isLoggedIn,
        cosmosChain,
        setCosmosChain,
        setPrimaryWallet,
    } = useCombinedWalletsContext()
    const { chainRecords, getChainLogo } = useManager()
    const chains = useChains(SUPPORTED_COSMOS_CHAINS)
    const chain = chains[getCosmosChainName(cosmosChain)]
    const [isDropdownOpen, setIsDropdownOpen] = useState()

    const chainOptions = useMemo(
        () =>
            chainRecords
                .filter(c => c?.name !== 'neutron')
                .map(chainRecord => {
                    return {
                        chainName: chainRecord?.name,
                        label: chainRecord?.chain?.pretty_name ?? '',
                        value: chainRecord?.name,
                        icon: getChainLogo(chainRecord.name),
                    }
                }),
        [chainRecords, getChainLogo]
    )

    if (!isLoggedIn || primaryWallet?.sdk === SDK.Dynamic) {
        return null
    }
    return (
        <>
            <div
                className='flex items-center justify-center rounded-lg bg-contrast-0/50 h-[40px] px-4 font-bold text-sm gap-2 min-w-[120px] text-contrast-5 cursor-pointer hover:shadow-sm'
                data-tooltip-id='chain-selection-dropdown'
            >
                <LogoImage
                    src={getChainLogo?.(chain?.chain?.chain_name) || ''}
                    slug={chain?.chain?.chain_name}
                    size={18}
                    className='pointer-events-none'
                />{' '}
                <div className='text-sm pointer-events-none'>
                    {chain?.chain?.pretty_name}
                </div>
                <span className='icon icon-og icon-chevron-angle-down scale-150 !text-contrast-5 pointer-events-none' />
            </div>
            <Tooltip
                id='chain-selection-dropdown'
                noArrow
                isOpen={isDropdownOpen}
                clickable
                setIsOpen={setIsDropdownOpen}
                openOnClick
                closeEvents={{ click: true, blur: true }}
                globalCloseEvents={{ clickOutsideAnchor: true, scroll: true }}
                place='bottom'
                opacity={1}
                className='!bg-contrast-0/50 !text-xs !text-contrast-6 !max-w-[320px] !p-2 !rounded-lg z-10 !backdrop-blur'
                content={
                    <div className='flex flex-col gap-1'>
                        {chainOptions?.map((chainOption, i) => {
                            return (
                                <div
                                    key={i}
                                    className='flex items-center gap-4 w-full px-4 py-3 rounded-lg border-solid hover:border-primary border border-transparent duration-300 cursor-pointer transition-all'
                                    onClick={async e => {
                                        e.preventDefault()
                                        window.localStorage.setItem(
                                            'selected_cosmos_chain',
                                            chainOption?.value
                                        )
                                        setCosmosChain(chainOption?.value)
                                        setPrimaryWallet(
                                            {
                                                ...chain?.wallet,
                                                address: chain?.address,
                                                icon: chain?.wallet?.logo,
                                            },
                                            SDK.CosmosKit
                                        )
                                        setIsDropdownOpen(false)
                                    }}
                                >
                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-primary/10 transition-all duration-300 delay-100 opacity-0 hover:opacity-100 z-10'></div>
                                    <LogoImage
                                        src={chainOption.icon}
                                        size={24}
                                    />{' '}
                                    <div className='text-sm'>
                                        {chainOption.label}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            />
        </>
    )
}
