import { useEffect, useState } from 'react'
import { fetchSelectedAsset } from 'data'
import { AssetRewardCalculatorContextProvider } from 'contexts/assetRewardCalculatorContext'
import { GetLstCalculator } from './getLstCalculator'
import { ExitCalculator } from './exitCalculator'

export const LstCalculator = ({
    asset = null,
    rewardOptionId = null,
    contentBetween,
}) => {
    const [inputAsset, setInputAsset] = useState(null)
    useEffect(() => {
        async function fetchAndSetEthAsset() {
            const data = await fetchSelectedAsset('', {
                rewardOptionsWithAssetAsInput: {
                    ids: [rewardOptionId],
                },
            })
            setInputAsset(data?.assets?.[0] ?? null)
        }

        if (rewardOptionId) {
            fetchAndSetEthAsset()
        }
    }, [rewardOptionId])

    return (
        <div className='flex flex-col gap-y-9'>
            <AssetRewardCalculatorContextProvider
                asset={inputAsset}
                outputAsset={asset}
            >
                <GetLstCalculator />
            </AssetRewardCalculatorContextProvider>
            {contentBetween}
            <AssetRewardCalculatorContextProvider
                asset={asset}
                outputAsset={inputAsset}
            >
                <ExitCalculator />
            </AssetRewardCalculatorContextProvider>
        </div>
    )
}
