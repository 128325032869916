import { AssetRewardCalculatorInputsForTable } from 'components/assets/profile/info/assetRewardCalculatorInputs'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { Flag, TooltipOnHover } from 'components/ui'

export const MetricsCustodial = ({ formattedData }) => {
    const country = formattedData.country
    return (
        <div className='flex flex-1 justify-start'>
            <div className=''>
                <TooltipOnHover
                    text={<AssetRewardCalculatorInputsForTable />}
                    textClassName='!w-[300px]'
                >
                    <MetricBox
                        title={formattedData?.rewardsAfterTitle}
                        value={formattedData?.rewardsAfterValue}
                    />
                </TooltipOnHover>
            </div>
            <MetricBox
                title='Country'
                value={<Flag country={country} width={21} height={15} />}
                className='h-full'
            />
        </div>
    )
}
