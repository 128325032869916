import classNames from 'classnames'
import Image from 'next/image'
import { useWindowSize } from 'hooks'
import { useTheme } from 'state'
import { BUTTON_VARIANT_FG, ButtonWithBg, Link } from 'components/ui'

import styles from './summitBanner.module.scss'

const YouTubeVideoLink = ({ href = '#', label = 'Video' }) => {
    return (
        <div className={styles.youTubeVideoLink}>
            <Link className={styles.link} href={href} blank>
                <Image
                    src={`/static/img/youtube-play-button.png`}
                    width={16}
                    height={16}
                    alt={label}
                    placeholder='empty'
                />
                <span className={styles.label}>{label}</span>
            </Link>
        </div>
    )
}

// NB: this component depends on the browser's viewport width.
// Import dynamically without SSR
export const SummitBanner = () => {
    const theme = useTheme()
    const { width } = useWindowSize()
    return (
        <div className={styles.summitBanner} data-theme={theme}>
            <div className={classNames('container', styles.hfull)}>
                <div className={styles.content}>
                    <div className={styles.info}>
                        <div className={styles.headerWrap}>
                            <h2>The Staking Summit</h2>
                            <p className='text-description'>
                                The annual Staking Summit is the biggest
                                in-person, staking-focused conference in the
                                world. Hundreds of Validators, Protocols, and
                                VCs come together to discuss where Staking goes
                                next.
                            </p>
                        </div>
                        <div className={styles.buttonWrap}>
                            <Link href={'/summit'}>
                                <ButtonWithBg
                                    variant={BUTTON_VARIANT_FG}
                                    iconName={'icon-arrow-right'}
                                >
                                    Get involved with the Staking Summit 2024
                                </ButtonWithBg>
                            </Link>
                        </div>
                        <div className={styles.links}>
                            <YouTubeVideoLink
                                href={
                                    'https://youtu.be/v1QbHhEGlp4?feature=shared'
                                }
                                label='Watch 2023 Aftermovie'
                            />
                            <YouTubeVideoLink
                                href={
                                    'https://youtu.be/xLisIt69_BY?feature=shared'
                                }
                                label='Watch 2023 Summit Recap'
                            />
                        </div>
                    </div>
                    <div
                        className={styles.photos}
                        // Scale the overlapping photos without breaking the ratios
                        style={{ zoom: width > 825 ? 1 : width / 825 }}
                    >
                        <div className={styles.social}>
                            <Image
                                src={`/static/img/OpenGraph-summit24.png`}
                                alt='Summit-social'
                                width={590}
                                height={332}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
