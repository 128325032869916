import dynamic from 'next/dynamic'
import { CalculatorContext } from './calculatorContext'
import { AssetsBox, ProvidersBox } from './selectorBox'
import { Collapse } from './collapse'

// Inputs with the units (symbols) rely on the window object
// to calculate the container width dynamically.
// Load client-side to avoid the problem of undefined window.
const AmountBox = dynamic(() => import('./amountBox'), {
    ssr: false,
})
const ChartBox = dynamic(() => import('./chartBox'), {
    ssr: false,
})
// The metrics are calculated asynchronously within useEffect with Promise.all,
// that does not resolve until HTML arrives and which causes hydration errors
// (on some reason, only in the production build).
// Loading dynamically helped to resolve the issues.
const StakingScenariosBox = dynamic(() => import('./stakingScenariosBox'), {
    ssr: false,
})
const OptimalStakingFrequency = dynamic(
    () => import('./optimalStakingFrequency'),
    { ssr: false }
)

import styles from './calculator.module.scss'

const Scenarios = () => (
    <div className={styles.scenarios}>
        <StakingScenariosBox />
        <OptimalStakingFrequency />
    </div>
)

export const Calculator = ({
    scenariosExpandable = false,
    noRedirect = false,
    assetSlug = null,
}) => {
    return (
        <CalculatorContext noRedirect={noRedirect} defaultAssetSlug={assetSlug}>
            <div className={styles.calculator}>
                <AssetsBox className={styles.step1} isLocked={!!assetSlug} />
                <ProvidersBox className={styles.step2} />
                <AmountBox className={styles.step3} />
                <ChartBox className={styles.chart} />
            </div>
            {scenariosExpandable ? (
                <Collapse label={'Staking Scenarios'}>
                    <Scenarios />
                </Collapse>
            ) : (
                <Scenarios />
            )}
        </CalculatorContext>
    )
}
