import { useContext, createContext, useState, useEffect } from 'react'
import { useStakingModalContext } from './stakingModalContext'
import { formatToken } from 'utils/formatter'
import { useRouter } from 'next/router'
import { LAVA_STAKING_TYPE_OPTIONS } from 'components/staking/lava/constants'

export const LavaStakingContext = createContext({})

export const LAVA_STAKING_UPDATE = {
    PROVIDER: 'provider',
    VALIDATOR: 'validator',
}

export const LavaStakingContextProvider = ({ children }) => {
    const router = useRouter()

    const [stakingType, setStakingType] = useState('')
    const [stakingAmount, setStakingAmount] = useState(0)
    const [providerPairs, setProviderPairs] = useState([])
    const [selectedProvider, setSelectedProvider] = useState(null)
    const [selectedValidator, setSelectedValidator] = useState(null)
    const { inputAsset } = useStakingModalContext()
    const [updateIndex, setUpdateIndex] = useState([null, null])

    const defaultStakingType = router?.query?.type ?? ''

    useEffect(() => {
        setStakingType(() =>
            LAVA_STAKING_TYPE_OPTIONS.find(
                ({ key }) => key === defaultStakingType
            )
        )
    }, [])

    const createLavaUrlQuery = list => {
        const providersQueryString = list
            ?.map(({ validator, amount = 0 }) => {
                return `${validator?.providers?.[0]?.slug};${formatToken(amount)}`
            })
            .join(',')

        const secondaryProvidersQueryString = list
            .map(
                ({ provider, amount = 0 }) =>
                    `${provider?.providers?.[0]?.slug};${formatToken(amount)}`
            )
            .join(',')

        const url = `/stake-app?input=${inputAsset?.slug}&type=dual-staking&providers=${providersQueryString}&secondary-providers=${secondaryProvidersQueryString}`
        router.replace(url)
    }

    const value = {
        stakingAmount,
        stakingType,
        setStakingType,

        setStakingAmount,
        providerPairs,
        setProviderPairs,

        selectedProvider,
        setSelectedProvider,
        selectedValidator,
        setSelectedValidator,

        updateIndex,
        setUpdateIndex,

        addProviderPair: ({ provider, validator, amount = 0 }) => {
            const numberOfPairs = providerPairs.length + 1
            const newList = [
                ...providerPairs,
                { provider, validator, amount, index: providerPairs.length },
            ].map(pair => {
                return {
                    ...pair,
                    // update the total amount to stake for each provider pair
                    amount: stakingAmount / numberOfPairs,
                }
            })

            setProviderPairs(newList)
            createLavaUrlQuery(newList)
            // clear selections
            setSelectedProvider(null)
            setSelectedValidator(null)
        },
        removeProviderPairByIndex: index => {
            const newList = providerPairs.filter((_, i) => i !== index)
            setProviderPairs(() => [...newList])
            createLavaUrlQuery(newList)
        },
        updateProviderPairAmount: (index, amount) => {
            const newList = [...providerPairs]
            newList[index].amount = amount
            setProviderPairs(newList)
            const totalAmount = newList.reduce(
                (acc, { amount }) => acc + (parseFloat(amount) || 0),
                0
            )
            setStakingAmount(totalAmount)
            createLavaUrlQuery(newList)
        },
        updateLavaStakingAmount: amount => {
            setStakingAmount(amount)
            const numberOfPairs = providerPairs.length
            const newList = providerPairs.map(pair => {
                return {
                    ...pair,
                    // update the total amount to stake for each provider pair
                    amount: parseFloat(amount ?? 0) / numberOfPairs,
                }
            })
            setProviderPairs(newList)
        },
        updateProviderPair: (index, type, value) => {
            const newList = [...providerPairs]
            newList[index][type] = value
            setProviderPairs(newList)
            createLavaUrlQuery(newList)
            setSelectedProvider(null)
            setSelectedValidator(null)
        },
        setDefaultPairList: list => {
            const totalAmount = list.reduce(
                (acc, { amount }) => acc + (parseFloat(amount) || 0),
                0
            )
            setProviderPairs(list)
            setStakingAmount(totalAmount)
        },
    }

    return (
        <LavaStakingContext.Provider value={value}>
            {children}
        </LavaStakingContext.Provider>
    )
}

export const useLavaStakingContext = () => {
    return useContext(LavaStakingContext)
}
