import { useState } from 'react'
import dynamic from 'next/dynamic'
import DOMPurify from 'isomorphic-dompurify'
import { calculatorTextQuery, fetchCmsContent } from 'data'
import { useEffectOnceOnMount } from 'hooks'
import { Title, TitleDescription } from 'components/text'
import { YoutubeLink } from 'components/ui'
import { Calculator } from 'components/calculator'
import { MetricGroup } from 'components/metrics'
import {
    CalculatorGlobalMetrics,
    GLOBAL_METRIC_GROUPS,
    START_TIME_SECONDS,
    VIDEO_ID,
} from './constants'

// The height adapts to the window size through useMediaPredicate().
// Import dynamically to set the right window size and prevent hydration.
const YoutubeVideo = dynamic(
    () => import('components/ui').then(module => module.YoutubeVideo),
    {
        ssr: false,
    }
)

import styles from './calculatorPage.module.scss'

export function CalculatorPage() {
    const [cmsText, setCmsText] = useState('')

    useEffectOnceOnMount(() => {
        async function fetchAndSetCmsText() {
            const htmlContent = await fetchCmsContent(calculatorTextQuery)
            setCmsText(DOMPurify.sanitize(htmlContent.page?.content ?? ''))
        }
        fetchAndSetCmsText()
    }, [])

    return (
        <div className={styles.calcPage}>
            <div className='container'>
                <div className={styles.header}>
                    <Title className={styles.title}>
                        The Staking
                        <br />
                        Calculator
                    </Title>
                    <TitleDescription className={styles.description}>
                        Calculate your current rewards and run scenarios to
                        estimate future rewards.
                    </TitleDescription>
                    <div className={styles.stats}>
                        <MetricGroup
                            item={{
                                metrics: [
                                    {
                                        metricKey:
                                            CalculatorGlobalMetrics.Assets,
                                        defaultValue: 50,
                                    },
                                ],
                            }}
                            metricGroup={GLOBAL_METRIC_GROUPS.find(
                                g => g.key === CalculatorGlobalMetrics.Assets
                            )}
                            highlighted
                            large
                            isLoading={false}
                        />
                        <MetricGroup
                            item={{
                                metrics: [
                                    {
                                        metricKey:
                                            CalculatorGlobalMetrics.Providers,
                                        defaultValue: 240,
                                    },
                                ],
                            }}
                            metricGroup={GLOBAL_METRIC_GROUPS.find(
                                g => g.key === CalculatorGlobalMetrics.Providers
                            )}
                            highlighted
                            large
                            isLoading={false}
                        />
                    </div>
                    <div className={styles.linkWrap}>
                        <YoutubeLink
                            href={`https://youtube.com/watch?v=${VIDEO_ID}&t=${START_TIME_SECONDS}s`}
                            label={'Learn how to use the calculator'}
                        />
                    </div>
                </div>

                <Calculator scenariosExpandable={false} noRedirect={false} />

                <div className={styles.videoWrap}>
                    <YoutubeVideo
                        src={`https://www.youtube-nocookie.com/embed/${VIDEO_ID}?start=${START_TIME_SECONDS}`}
                        header={'How to Use the Staking Calculator.'}
                    />
                </div>

                <div
                    className='cms'
                    dangerouslySetInnerHTML={{ __html: cmsText }}
                />
            </div>
        </div>
    )
}
