/* global BigInt */
import { fetchEtherscan } from './etherscanFetcher'
// import { rethABI } from './ABI/rethABI'
// import { stethABI } from './ABI/stethABI'
import Web3 from 'web3'
import {
    ETHx_ADDRESS,
    rETH2_ADDRESS,
    rETH_ADDRESS,
    sETH2_ADDRESS,
    stETH_ADDRESS,
    WETH_ADDRESS,
} from 'utils/stakingAssistant/constants'

const web3 = new Web3(
    new Web3.providers.HttpProvider(
        `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`
    )
)

export const ERC20_ABI = [
    {
        constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: 'balance',
                type: 'uint256',
            },
        ],
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [{ name: '', type: 'string' }],
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            { name: '_to', type: 'address' },
            { name: '_value', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [],
        type: 'function',
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, name: 'from', type: 'address' },
            { indexed: true, name: 'to', type: 'address' },
            { indexed: false, name: 'value', type: 'uint256' },
        ],
        name: 'Transfer',
        type: 'event',
    },
]

export const getEthBalance = async address => {
    const balanceInWei = await web3.eth.getBalance(address)
    const balanceInEth = web3.utils.fromWei(balanceInWei, 'ether')
    return Number(balanceInEth)
}

export const getERC20TokenBalance = async (address, tokenAddress) => {
    const seth2Contract = new web3.eth.Contract(ERC20_ABI, tokenAddress)
    const balance = await seth2Contract.methods.balanceOf(address).call()
    const balanceInEther = web3.utils.fromWei(balance, 'ether')
    return Number(balanceInEther)
}

export function trimTrailingZeroes(num) {
    if (!num) {
        return undefined
    }
    let str = num.toString()
    // Remove trailing zeroes after a decimal point
    str = str.replace(/(\.\d*?)0+$/, '$1')
    // Remove trailing decimal point, if it exists
    str = str.replace(/\.$/, '')
    return str
}

export function getProviderInfo(provider) {
    if (!provider) {
        return {}
    }
    return {
        logoUrl: provider.logoUrl,
        vspScore: provider.vsp,
        networkControl: provider.staking_share,
        nodeOperators: provider.operator_count,
        commission: provider.commission?.toFixed(2) + '%',
        aumChange: provider.assets_under_management,
    }
}

export async function getTokenAggregatedBalances(tokenAddress, walletAddress) {
    try {
        const response = await fetchEtherscan({
            module: 'account',
            action: 'tokentx',
            contractaddress: tokenAddress,
            address: walletAddress,
            startblock: 0,
            endblock: 'latest',
            sort: 'asc',
        })

        let balance = BigInt(0)
        const aggregatedBalances = []
        // etherscan uses lowercase addresses
        const lowercaseAddress = walletAddress.toLowerCase()

        if (!Array.isArray(response.data.result)) {
            throw new Error(response.data?.message)
        }

        const transactions = response.data.result || []
        transactions.forEach(tx => {
            const value = BigInt(tx.value)
            if (tx.from === lowercaseAddress) {
                balance -= value
            } else {
                balance += value
            }
            aggregatedBalances.push({
                balance: getEthFromWei(balance),
                timestamp: Number(tx.timeStamp),
                block: tx.blockNumber,
            })
        })

        return aggregatedBalances
    } catch (e) {
        console.error(`Error on aggregating balances`, e)
        return []
    }
}

export function getEthFromWei(wei) {
    if (!wei) {
        return 0
    }
    // for some reason 0 returns "0."
    return wei === BigInt(0)
        ? 0
        : Number(web3.utils.fromWei(BigInt(Number(wei)), 'ether'))
}

export function getWeiFromEth(eth) {
    const value = eth < 0 ? Number(eth) : eth.toString()
    return BigInt(web3.utils.toWei(value, 'ether'))
}

export async function getRETH2AggregatedBalances(address) {
    try {
        async function getStakewiseData(address) {
            const response = await fetch(
                `
                https://api.stakewise.io/staker-rewards/${address}/?from_date=0&network=mainnet`
            )
            const data = await response.json()

            const result = data?.reverse().map(entry => {
                return {
                    value: entry.reward,
                    timeStamp: new Date(entry.date).getTime() / 1000,
                    date: entry.date,
                    type: 'reward',
                }
            })

            return result
        }

        const stakewiseData = await getStakewiseData(address)

        const response = await fetchEtherscan({
            module: 'account',
            action: 'tokentx',
            contractaddress: rETH2_ADDRESS,
            address,
            startblock: 0,
            endblock: 'latest',
            sort: 'asc',
        })

        const etherscanData = response.data.result
        const mergedTransactions = [...stakewiseData, ...etherscanData].sort(
            (a, b) => a.timeStamp - b.timeStamp
        )

        const aggregatedBalances = []
        let balance = BigInt(0)

        mergedTransactions.forEach(tx => {
            const value = BigInt(tx.value || 0)
            if (tx.from === address.toLowerCase()) {
                balance -= value
            } else {
                balance += value
            }
            aggregatedBalances.push({
                balance: getEthFromWei(balance),
                timestamp: Number(tx.timeStamp),
                date: new Date(tx.timeStamp * 1000).toISOString(),
                block: tx.blockNumber,
                type: tx.type || 'transfer',
                change: getEthFromWei(value),
            })
        })

        return aggregatedBalances
    } catch (e) {
        console.error('Error at getRETH2AggregatedBalances', e)
        return []
    }
}

export async function getLidoData(address) {
    try {
        const response = await fetch(
            `https://stake.lido.fi/api/rewards?address=${address}&currency=usd&onlyRewards=false&archiveRate=true`
        )
        const data = await response.json()
        if (!Array.isArray(data?.events)) {
            console.error('Error at getLidoData', data)
        }
        const convertedData = data?.events?.reverse().map(item => {
            return {
                block: item.block,
                timestamp: Number(item.blockTime),
                date: new Date(item.blockTime * 1000).toISOString(),
                balance: getEthFromWei(item.balance),
                type: item.type,
                change: getEthFromWei(item.change),
            }
        })
        return convertedData
    } catch (e) {
        console.error('Error at getLidoData', e)
        return []
    }
}

export const SORT_OPTION = {
    Risk: 'Risk',
    Reward: 'Reward',
    Decentralization: 'Decentralization',
}

/**
 * Calculates rETH rewards from the transaction list and the rETH rates
 * Gets the difference between the rates, and multiplies it with the balance
 * @param {*} transactions
 * @param {*} rethRates
 * @returns array with rewards for each day
 */
export function calculateRethRewards(transactions = [], rethRates = []) {
    let rewards = []
    transactions.forEach((entry, i) => {
        const block = Number(entry.block) - 6647 // 6647 is the number between blocks with rewards, and we need the one before the deposit, to get the rate
        const nextTransactionBlock =
            Number(transactions[i + 1]?.block) || Infinity
        const filteredRates = rethRates.filter(
            r => r.block.number > block && r.block.number < nextTransactionBlock
        )
        rewards.push(entry)
        const balance = entry.balance
        const currentRewards = filteredRates.map((r, i) => {
            // Skip the first one, because we need the previous one to calculate the difference
            if (i == 0) {
                return
            }
            const dif =
                getEthFromWei(r.rate) -
                getEthFromWei(filteredRates[i - 1]?.rate || 0)
            const reward = dif * entry.balance
            return {
                balance, // balance + reward ???
                change: reward,
                timestamp: r.block.timestamp,
                type: 'reward',
            }
        })
        rewards = [...rewards, ...currentRewards.filter(Boolean)]
    })
    return rewards
}

export function calculateWstethRewards(transactions = [], wstethRates = []) {
    let changes = []
    const rates = wstethRates
        .map(r => {
            const date = new Date(r.date)
            date.setHours(12, 30, 0, 0)
            return {
                ...r,
                timestamp: new Date(r.date).getTime() / 1000,
            }
        })
        .reverse()
    transactions.forEach((entry, i) => {
        const timestamp = entry.timestamp - 24 * 60 * 60
        const nextTimestamp = transactions[i + 1]?.timestamp || Infinity
        const filteredRates = rates.filter(
            r => r.timestamp > timestamp && r.timestamp < nextTimestamp
        )
        changes.push(entry)
        const balance = entry.balance
        const rewards = filteredRates.map((r, i) => {
            // Skip the first one, because we need the previous one to calculate the difference
            if (i == 0) {
                return
            }
            const dif =
                getEthFromWei(r.stEthPerWstETH) -
                getEthFromWei(filteredRates[i - 1]?.stEthPerWstETH || 0)
            const reward = dif * entry.balance
            return {
                balance: balance + reward,
                change: reward,
                timestamp: r.timestamp,
                type: 'reward',
            }
        })
        changes = [...changes, ...rewards.filter(Boolean)]
    })
    return changes
}

export function calculateEthxRewards(transactions = [], ethxRates = []) {
    let changes = []
    const rates = ethxRates
        .map(r => {
            const date = new Date(r.date)
            date.setHours(12, 30, 0, 0)
            return {
                ...r,
                timestamp: new Date(r.date).getTime() / 1000,
            }
        })
        .reverse()
    transactions.forEach((entry, i) => {
        const timestamp = entry.timestamp - 24 * 60 * 60
        const nextTimestamp = transactions[i + 1]?.timestamp || Infinity
        const filteredRates = rates.filter(
            r => r.timestamp > timestamp && r.timestamp < nextTimestamp
        )
        changes.push(entry)
        const balance = entry.balance
        const rewards = filteredRates.map((r, i) => {
            // Skip the first one, because we need the previous one to calculate the difference
            if (i == 0) {
                return
            }
            const dif =
                r.ethx_to_eth_conversion -
                (filteredRates[i - 1]?.ethx_to_eth_conversion || 0)
            const reward = dif * entry.balance
            return {
                balance: balance,
                change: reward,
                timestamp: r.timestamp,
                type: 'reward',
            }
        })
        changes = [...changes, ...rewards.filter(Boolean)]
    })
    return changes
}

export const getTotalTokenEarnings = (lstBalanceHistory = [], rate = 1) => {
    return (
        lstBalanceHistory
            .filter(entry => entry.type === 'reward')
            .reduce((sum, entry) => sum + entry.change, 0) * rate
    )
}

function padAddressToTopic(address) {
    // Remove '0x' prefix if present
    if (address.startsWith('0x')) {
        address = address.slice(2)
    }

    // Pad with 24 zeros at the front
    return '0x' + '0'.repeat(24) + address
}

const WRAP_EVENT_SIGNATURE =
    '0xe1fffcc4923d04b559f4d29a8bfc6cda04eb5b0d3c460751c2402c5c5cc9109c'
const UNWRAP_EVENT_SIGNATURE =
    '0x7fcf532c15f0a6db0bd6d0e038bea71d30d808c7d98cb3bf7268a95bf5081b65'

export async function getWethbalances(address) {
    try {
        const [response, wrapResponse, unwrapResponse] = await Promise.all([
            fetchEtherscan({
                module: 'account',
                action: 'tokentx',
                contractaddress: WETH_ADDRESS,
                address: address,
                startblock: 0,
                endblock: 'latest',
                sort: 'asc',
            }),
            fetchEtherscan({
                module: 'logs',
                action: 'getLogs',
                fromBlock: '0',
                toBlock: 'latest',
                address: WETH_ADDRESS, // WETH contract address on mainnet
                topic0: WRAP_EVENT_SIGNATURE, // Wrap event signature
                topic1: padAddressToTopic(address),
            }),
            fetchEtherscan({
                module: 'logs',
                action: 'getLogs',
                fromBlock: '0',
                toBlock: 'latest',
                address: WETH_ADDRESS, // WETH contract address on mainnet
                topic0: UNWRAP_EVENT_SIGNATURE, // Unwrap event signature
                topic1: padAddressToTopic(address),
            }),
        ])

        if (!Array.isArray(response.data.result)) {
            throw new Error(response.data)
        }
        if (!Array.isArray(wrapResponse.data.result)) {
            throw new Error(wrapResponse.data)
        }
        if (!Array.isArray(unwrapResponse.data.result)) {
            throw new Error(unwrapResponse.data)
        }

        function decodeDepositEvent(log) {
            // Non-indexed parameters are in the data field
            const nonIndexedData = web3.eth.abi.decodeParameter(
                'uint256',
                log.data
            )

            return {
                value: BigInt(nonIndexedData.toString()),
                block: Number(log.blockNumber),
                timeStamp: Number(log.timeStamp),
                date: new Date(Number(log.timeStamp) * 1000).toISOString(),
                topics: log.topics,
            }
        }

        const wrapLogs = wrapResponse.data.result
        const decodedWrapLogs =
            wrapLogs
                .map(log => decodeDepositEvent(log))
                .map(log => {
                    return {
                        ...log,
                        blockNumber: log?.block,
                        isWrapEvent: true,
                    }
                }) || []

        const unwrapLogs = unwrapResponse.data.result
        const decodedUnwrapLogs =
            unwrapLogs
                .map(log => decodeDepositEvent(log))
                .map(log => {
                    return {
                        ...log,
                        blockNumber: log?.block,
                        isUnwrapEvent: true,
                    }
                }) || []

        let balance = BigInt(0)
        const aggregatedBalances = []
        // etherscan uses lowercase addresses
        const lowercaseAddress = address.toLowerCase()

        const transactions = (response.data.result || [])
            .concat(decodedWrapLogs)
            .concat(decodedUnwrapLogs)
            .sort((a, b) => a.blockNumber - b.blockNumber)
        transactions.forEach(tx => {
            const value = BigInt(tx.value)
            const isOutgoing =
                tx.from?.toLowerCase() === lowercaseAddress || tx.isUnwrapEvent
            if (isOutgoing) {
                balance -= value
            } else {
                balance += value
            }
            aggregatedBalances.push({
                balance: getEthFromWei(balance),
                change: getEthFromWei(isOutgoing ? -value : value),
                timestamp: Number(tx.timeStamp),
                block: tx.blockNumber,
            })
        })

        return aggregatedBalances
    } catch (e) {
        console.error('Error at getWethbalances', JSON.stringify(e))
        return []
    }
}

export async function getEthBalances(address) {
    try {
        const [txResponse, internalTxResponse] = await Promise.all([
            fetchEtherscan({
                module: 'account',
                action: 'txlist',
                address,
                startblock: 0,
                endblock: 'latest',
                sort: 'asc',
            }),
            fetchEtherscan({
                module: 'account',
                action: 'txlistinternal',
                address,
                startblock: 0,
                endblock: 'latest',
                sort: 'asc',
                txtype: 'all',
            }),
        ])

        // if (txResponse.data.status !== '1') {
        //     throw new Error(txResponse.data?.message)
        // }
        // if (internalTxResponse.data.status !== '1') {
        //     throw new Error(internalTxResponse.data?.message)
        // }

        const transactions = txResponse.data.result
        const internalTransactions = internalTxResponse.data.result
        const changes = {}
        const eth = []

        for (const tx of transactions) {
            const blockNumber = Number(tx.blockNumber)
            const ethValue = web3.utils.fromWei(tx.value, 'ether')
            const isOutgoing = tx.from.toLowerCase() === address.toLowerCase()
            const gasFee = web3.utils.fromWei(tx.gasUsed * tx.gasPrice, 'ether')

            const change =
                tx.from === tx.to ||
                (tx.txreceipt_status !== '1' && tx.txreceipt_status !== '')
                    ? 0
                    : Number(ethValue) * (isOutgoing ? -1 : 1)
            const gasPrice = Number(gasFee) * (isOutgoing ? -1 : 0)

            if (!changes[blockNumber]) {
                changes[blockNumber] = { ethBalanceChange: 0 }
            }

            changes[blockNumber].ethBalanceChange += change + gasPrice
            changes[blockNumber].timestamp = +tx.timeStamp

            eth.push({
                block: blockNumber,
                change: change + gasPrice,
                timestamp: Number(tx.timeStamp),
            })
        }

        for (const tx of internalTransactions) {
            const blockNumber = Number(tx.blockNumber)
            const ethValue = web3.utils.fromWei(tx.value, 'ether')
            const isOutgoing = tx.from.toLowerCase() === address.toLowerCase()

            if (!changes[blockNumber]) {
                changes[blockNumber] = { ethBalanceChange: 0 }
            }

            if (tx.isError === '0') {
                changes[blockNumber].ethBalanceChange +=
                    Number(ethValue) * (isOutgoing ? 0 : 1)

                const change = Number(ethValue) * (isOutgoing ? 0 : 1)
                eth.push({
                    block: blockNumber,
                    change: change,
                    timestamp: Number(tx.timeStamp),
                })
            }
            changes[blockNumber].timestamp = +tx.timeStamp
        }

        let totalBalance = 0
        const result = eth
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((entry, i) => {
                if (i === 0) {
                    totalBalance += entry.change
                    return {
                        ...entry,
                        balance: entry.change,
                    }
                }
                totalBalance += entry.change
                return {
                    date: new Date(entry.timestamp * 1000).toISOString(),
                    ...entry,
                    balance: totalBalance,
                }
            })
        return result
    } catch (e) {
        console.error('Error at getEthBalances', e)
        return []
    }
}

export function formatDateForChart(value) {
    const date = new Date(value)
    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]

    const day = date.getDate() // extract the day
    const monthIndex = date.getMonth() // extract the month index (0-11)
    const year = date.getFullYear().toString() // extract the full year (e.g., 2023)

    return `${monthNames[monthIndex]}. ${day}, ${year}`
}

export function formatNumberForChart(num) {
    const number = Number(num)
    if (number === 0) return '0'

    // Determine the order of magnitude of the absolute value of the number
    const magnitude = Math.max(Math.floor(Math.log10(Math.abs(number))), -5)

    // Determine the number of decimal places to show
    const decimalPlaces =
        magnitude >= 0 ? Math.max(0, 2 - magnitude) : Math.abs(magnitude) + 2

    return number.toFixed(decimalPlaces)
}

export const TOOLTIP_TEXT = {
    ETH: "ETH Holdings reflect your wallet's ETH and WETH balances from on-chain sources, showcasing your exposure to Ethereum's market and the potential ETH available for staking, aiding in network validation and possibly earning rewards.",
    StakedPercentage:
        'Staked Percentage is the ratio of your LSTs to your total ETH Holdings, expressed as a percentage. This metric illustrates the portion of your Ethereum assets currently committed to staking, highlighting your active participation in network validation processes.',
    TotalEarnings:
        'Total Earnings represent the aggregate dollar value of rewards accrued from your LSTs. This metric reflects the financial return on your staked assets, helping you gauge the profitability of your participation in staking activities.',
    MissedEarnings:
        'Missed Earnings provide a estimate of the earnings foregone by not staking your average ETH holdings. Calculated as the time-weighted average of your ETH holdings multiplied by a standard APR of the LSTs. This metric offers a rough gauge of potential earnings, circumventing the complexities of transaction fees and staking durations, thus presenting a straightforward insight into the possible financial benefits of staking.',
    PotentialEarnings:
        'Potential Earnings depict the theoretical maximum earnings attainable by staking your ETH at the LST with the highest available APR. This metric is calculated by multiplying the sum of your LST holdings by the highest APR for LST, providing a glimpse into the earning potential when staking under the most favorable conditions.',
    LifetimeEarningsstETH:
        'Accrued stEth rewards represent the lifetime rewards accumulated in your wallet, denoted in USD. This metric is derived from the historical cumulative sum of inflow and outflow versus the actual balance of your wallet. Due to the unique rebasing mechanism of stEth, the balance increases without any transaction activity, reflecting the ongoing rewards accrued over time.',
    LifetimeEarningssETH2:
        'Accrued sETH2 rewards represent the lifetime rewards accumulated in your wallet, denoted in USD. This metric tallies the total reward tokens accrued, providing a measure of the returns generated from your participation in staking, contributing to network validation and security.',
    LifetimeEarningsrETH:
        'Accrued rEth rewards represent the lifetime rewards accumulated in your wallet, denoted in USD. Due to the unique value accrual mechanism of rEth, the value of rETH increases without any transaction activity, reflecting the ongoing rewards accrued over time. This metric provides insight into the returns generated from your holdings, aiding in assessing the financial benefits of your participation in the network.',
    ExpectedEarnings:
        "Expected Earnings is calculated by multiplying the latest balance of the LST by their current reward rate. This metric provides an estimation of the potential earnings you could receive from staking, offering a glimpse into the financial benefits of participating in the protocol. It's a useful indicator for stakers to gauge the potential return on their staked assets based on the prevailing reward rates.",
    VspScore:
        'VSP Score evaluates a liquid staking protocol based on on-chain performance, business operations, operator set, and security setup. It analyzes aspects like Uptime/Block Sign Percentage, Slashing Events, and Client Diversity to gauge protocol reliability and operator legitimacy. This score also scrutinizes operator set performance and security measures like audits and key management, aiding investors in selecting a suitable staking service. Learn more about the methodology and Get Listed.',
    NetworkControl:
        'Network Control refers to the proportion of influence the protocol exerts over the entire Ethereum staking ecosystem. This metric, expressed as a percentage, encapsulates the share of network governance and validation capabilities the protocol holds, contributing to the overall security and consensus mechanisms of the Ethereum network. A higher percentage indicates a more significant level of control and influence within the staking ecosystem, potentially impacting network decisions and the direction of protocol developments.',
    NodeOperators:
        'Node Operators constitute the operational layer of Ethereum validators, encompassing professional entities dedicated to running validator nodes. By facilitating consensus and ensuring network security, Node Operators play a crucial role in maintaining the integrity and functionality of the Ethereum blockchain, making them a pivotal component of the staking ecosystem.',
    Commission:
        'Commission refers to the portion of staking rewards taken by the LST protocols, which is directed towards their DAOs. This commission is a way of compensating the LST protocols for facilitating staking activities and ensuring the continuous development and maintenance of the protocol, thereby supporting a sustainable staking ecosystem.',
    AuM: "Assets under Management (AuM) represents the total monetary value of staked ETH tokens within the LST protocol, expressed in USD terms. This metric provides a snapshot of the protocol's financial scale and engagement it has garnered from the staking community. A higher AuM indicates a more significant influence within the Ethereum staking ecosystem.",
    RewardAPR:
        'Reward APR denotes the current annualized reward rate for this LST. The rate, expressed as a percentage, reflects the potential annual returns stakers can earn for their participation in network consensus.',
    TokenModel:
        "Token Model explains the two distinct token mechanisms employed within each LST. The 'Value Accrual' model implies that staking rewards enhance the worth of the LST, making it more valuable over time. Conversely, the 'Rebasing' model ensures that the price of LSTs remains constant, while the balance in your wallet increases. The choice between these models is vital, especially for tax considerations, as they may impact how earnings and holdings are reported and taxed.",
    Withdrawals:
        "Withdrawals indicate the duration required to unstake your LSTs from the protocol. The time frame may vary among different protocols, and it's essential to note that some protocols have not yet activated the withdrawal feature, temporarily hindering the retrieval of staked assets. Despite this, LSTs can still be traded on secondary markets, providing a level of liquidity even if withdrawals are not currently enabled. This metric is crucial for stakers to gauge the liquidity and accessibility of their staked assets within a given protocol.",
    TradingVolume24h:
        "24h Trading Volume represents the total dollar amount of trading activity for the LST across all markets within the last 24 hours. This metric provides a snapshot of the token's recent trading liquidity and market activity, offering insight into the current demand and trading interest surrounding the LST.",
    Inflow24h:
        "Inflow 24h represents the amount of ETH that has been staked with the LST protocol over the last 24 hours. This metric provides insight into the recent level of engagement and trust from the staking community. It can be a useful indicator of the protocol's momentum and the staking community's response to prevailing network conditions and reward rates.",
    RealizedLifetimeEarnings:
        "Realized Lifetime Earnings refer to the total amount of staking rewards that a user has accumulated over time by staking ETH with their wallet. This figure represents the aggregate earnings from participating in the network's staking process, offering a historical view of the rewards gained from their staking activities.",
    MissedLifetimeEarnings:
        'Missed Lifetime Earnings represent the total amount of potential staking rewards that a user has not earned due to non-participation with their wallet. This metric provides insight into the unrealized earnings, highlighting opportunities for optimizing staking strategies to maximize future rewards.',
    OptimalLifetimeEarnings:
        "Optimal Lifetime Earnings indicate the hypothetical maximum amount of staking rewards that a user could have earned if they had staked all of their ETH holdings consistently. This metric provides a benchmark for the ideal earnings potential, assuming perfect participation and optimal staking conditions throughout the user's involvement in the network's staking process.",
    ExpectedExpectedEarnings:
        "Expected Earnings refer to the projected amount of staking rewards that a user is anticipated to earn over the next year, assuming he continues to stake the same amount of ETH. This forecast is based on current staking conditions and performance, offering a forward-looking estimate of potential earnings from continued participation in the network's staking process.",
    PotentialExpectedEarnings:
        'Potential Expected Earnings denote the possible amount of staking rewards that a user could earn additionally in the upcoming year if he optimizes his staking strategy. This estimate considers ideal conditions, such as maximum participation and efficiency, providing a target for what could be achieved under the most favorable staking circumstances.',
    OptimalExpectedEarnings:
        'Optimal Expected Earnings represent the estimated maximum amount of staking rewards that a user can anticipate earning in the next year under optimal staking conditions. This figure assumes full and efficient utilization of the user’s ETH holdings for staking, reflecting the highest potential earnings achievable with perfect strategy execution and consistent network conditions.',
}
