import {
    formatOutputNumber,
    getPrecisionBySignificantFigures,
} from 'utils/formatter'

export const getChainIdByAssetSlug = (slug = '') => {
    const slugToChainIdMap = {
        cosmos: 'cosmoshub',
        axelar: 'axelar-dojo-1',
        osmosis: 'osmosis-1',
        celestia: 'celestia',
        dydx: 'dydx',
        lava: 'lava',
    }
    return slugToChainIdMap[slug] || ''
}

export const formatUsd = (value = null) => {
    return formatOutputNumber(value, {
        precision: Math.max(2, getPrecisionBySignificantFigures(value, 3)),
        prefix: '$',
        withAbbreviation: false,
    })
}

export const formatToken = (value = null, symbol = '') => {
    return formatOutputNumber(value, {
        postfix: symbol,
        spaceAfterNumber: true,
        precision: Math.max(2, getPrecisionBySignificantFigures(value, 3)),
        withAbbreviation: false,
    })
}

export const formatRewardRate = (value = null) => {
    return formatOutputNumber(value, {
        postfix: '%',
        precision: 2,
        withAbbreviation: false,
    })
}
