/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { setUiState } from 'state'
import { ScrollTrigger } from 'utils/gsap'
import { useRouter } from 'next/router'

export function Route({ children }) {
    const { setScrollDir, setScrolled, closeAll } = setUiState()
    const router = useRouter()

    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    useEffect(() => {
        setScrolled(false)
        // document.body.scrollTop = 0

        ScrollTrigger.create({
            start: '80px',
            end: '+=99999',
            onToggle: self => setScrolled(self.isActive),
            onUpdate: self => setScrollDir(self.direction),
            // markers: true,
        })

        ScrollTrigger.refresh()

        return () => {
            // gsap.killTweensOf('*')
            // ScrollTrigger.getAll().forEach(trigger => trigger.kill())
            closeAll()
        }
    }, [router])

    return (
        <main
            style={{
                opacity: isLoaded ? 1 : 0,
                transition: 'opacity 0.8s linear',
            }}
        >
            {children}
        </main>
    )
}
