import { useState } from 'react'
import styles from './slider2.module.scss'
import classNames from 'classnames'

export const Slider = ({
    minValue = 0,
    maxValue = 100,
    defaultValue = 0,
    onChange = () => {},
    onMouseUp = () => {},
}) => {
    const [value, setValue] = useState(defaultValue)

    const handleChange = event => {
        setValue(event.target.value)
        onChange(event.target.value)
    }

    return (
        <div className='flex w-full'>
            <input
                type='range'
                min={minValue}
                max={maxValue}
                value={value}
                className={classNames(
                    styles.slider,
                    'bg-contrast-2/20 h-[17px] !rounded-lg w-full !p-0 !my-0'
                )}
                onChange={handleChange}
                onMouseUp={onMouseUp}
            />
        </div>
    )
}
