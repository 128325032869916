import { useEffect } from 'react'
import { usePaginatedOtherRewardOptionAssetsForProfile } from 'data'

export const useOtherRewardOptionAssets = (
    slug = '',
    page = 0,
    pageSizeNumber = 10
) => {
    const { data, size, setSize, isValidating } =
        usePaginatedOtherRewardOptionAssetsForProfile(
            slug,
            pageSizeNumber + 1,
            true
        )

    useEffect(() => {
        // NB: SWR's size (page number) starts from 1
        if (page + 1 !== size) {
            setSize(page + 1)
        }
    }, [page, setSize, size])

    const lastBatch = data?.[(data?.length ?? 0) - 1]
    const hasMore = (lastBatch?.rewardOptions?.length ?? 0) > pageSizeNumber
    const noDataFound = !Boolean(
        data?.map(batch => batch?.rewardOptions ?? [])?.flat()?.length
    )
    const isLoading = isValidating

    // Since we fetched pageSizeNumber + 1 items (for hasMore check), remove the last item
    const batches = data?.map(
        batch => batch?.rewardOptions?.slice(0, pageSizeNumber) ?? []
    )

    return {
        data: batches,
        isLoading,
        noDataFound,
        isEnd: !hasMore,
    }
}
