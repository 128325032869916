import { useState } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { getMetricValueByKey } from 'utils/actions'
import {
    capitalize,
    NO_DATA_INDICATOR,
    formatPercentage,
} from 'utils/formatter'
import {
    TYPE_ASSET,
    TYPE_PROVIDER,
    TYPE_VALIDATOR,
    PROVIDER_CATEGORIES,
    SOLO_STAKING_KEY,
    SMART_CONTRACT_KEY,
} from 'utils/constants'
import { useCalculatorContext } from 'components/calculator'
import {
    DETAILS_TYPE_STATUS_ACTIVE,
    DETAILS_TYPE_STATUS_INACTIVE,
    DETAILS_TYPE_TEXT,
    ItemWithLogo,
    GroupSize,
} from 'components/ui'
import { InfiniteItemScroll } from './infiniteItemScroll'
import { getItemsFromPaginatedData } from './utils'

import styles from './itemGroup.module.scss'

export const RewardOptionGroup = ({
    item = null,
    validator = null,
    selected = false,
    showCategory = false,
}) => {
    const provider = item?.providers?.[0]

    const categoryName =
        PROVIDER_CATEGORIES?.find(({ key }) => key === item?.type?.key)?.name ??
        item?.type?.key ??
        NO_DATA_INDICATOR

    let logoUrl
    let name
    let details
    let detailsType
    let slug
    let verified

    const rewardRateString = formatPercentage(
        getMetricValueByKey(
            validator !== null ? validator : item,
            'reward_rate'
        )
    )

    if (!provider) {
        const asset = item?.inputAssets?.[0]
        const assetName = asset?.name ?? NO_DATA_INDICATOR
        const article = /^[aeiou]/i.test(String(assetName)) ? 'an' : 'a'
        const actionString =
            item?.type?.key === SOLO_STAKING_KEY
                ? `Run ${article} ${assetName} Validator`
                : `Stake on ${assetName}`

        logoUrl = asset?.logoUrl
        name = actionString
        details = selected
            ? `${rewardRateString} APR`
            : showCategory
            ? categoryName
            : ''
        detailsType = DETAILS_TYPE_TEXT
        slug = asset?.slug
        verified = false
    } else {
        const categoryOrStatus = showCategory
            ? categoryName
            : provider?.isActive
            ? 'Active'
            : 'Inactive'

        logoUrl = provider?.logoUrl
        name =
            item?.type?.key === SMART_CONTRACT_KEY
                ? item?.label
                : provider?.name
        details = selected ? `${rewardRateString} APR` : categoryOrStatus
        detailsType =
            selected || showCategory
                ? DETAILS_TYPE_TEXT
                : provider?.isActive
                ? DETAILS_TYPE_STATUS_ACTIVE
                : DETAILS_TYPE_STATUS_INACTIVE
        slug = provider?.slug
        // The "verified" icon for unselected items is shown outside of the container
        verified = selected ? provider?.isVerified : false
    }

    return (
        <ItemWithLogo
            name={name}
            iconUrl={logoUrl}
            details={details}
            detailsType={detailsType}
            slug={slug}
            alt={name}
            iconSize={selected ? 36 : 28}
            sizeType={selected ? GroupSize.Medium : GroupSize.Small}
            isVerified={verified}
            withDetails={true}
        />
    )
}

const ValidatorGroup = ({ item = null }) => {
    return (
        <div className={classNames(styles.details)}>
            <span className={styles.address}>
                {item?.address ?? NO_DATA_INDICATOR}
            </span>
            <span
                className={classNames(
                    styles.status,
                    item?.status?.label === 'active'
                        ? styles.active
                        : styles.inactive
                )}
            >
                {capitalize(item?.status?.label) || 'Inactive'}
            </span>
        </div>
    )
}

export const RewardRateGroup = ({ item = null }) => {
    return (
        <div className={styles.rewardDetails}>
            <span className={styles.percentage}>
                {formatPercentage(getMetricValueByKey(item, 'reward_rate'))}
            </span>
            <span className={styles.text}>Reward rate</span>
        </div>
    )
}

const AssetItem = ({ item = null, onSelect = () => {} }) => {
    const { setAsset } = useCalculatorContext()
    return (
        <div
            className={styles.itemWrap}
            onClick={() => {
                setAsset(item)
                onSelect()
            }}
        >
            <div className={classNames(styles.item)}>
                <div className={classNames(styles.row)}>
                    <div className={classNames(styles.itemSelector)}>
                        <ItemWithLogo
                            name={item?.name}
                            iconUrl={item?.logoUrl}
                            details={item?.symbol}
                            detailsType={DETAILS_TYPE_TEXT}
                            slug={item?.slug}
                            alt={`${item?.name} ${item?.symbol}`}
                            iconSize={28}
                            sizeType={GroupSize.Small}
                            isVerified={false}
                            withDetails={true}
                        />
                        <RewardRateGroup item={item} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const RewardOptionItem = ({
    item = null,
    onSelect = () => {},
    hasExpansion = false,
    showCategory = false,
}) => {
    const { setOption } = useCalculatorContext()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div
            className={styles.itemWrap}
            onClick={() => {
                if (!hasExpansion) {
                    setOption(item)
                    onSelect()
                } else {
                    setIsOpen(!isOpen)
                }
            }}
        >
            <div
                className={classNames(styles.item, {
                    [styles.open]: isOpen,
                })}
            >
                <div className={classNames(styles.row)}>
                    <div className={classNames(styles.itemSelector)}>
                        <RewardOptionGroup
                            item={item}
                            showCategory={showCategory}
                            selected={false}
                            // Do not use validator information
                            // for the unselected collapsed row
                            validator={null}
                        />
                        <RewardRateGroup item={item} />
                    </div>

                    <ExpandButton
                        onClick={() => setIsOpen(!isOpen)}
                        disabled={!hasExpansion}
                    />
                </div>
                <div className={classNames(styles.more)}>
                    {isOpen && (
                        <InfiniteItemScroll
                            entity={TYPE_VALIDATOR}
                            optionId={item?.id}
                            renderItemGroup={validatorItem => {
                                return (
                                    <ValidatorItem
                                        item={validatorItem}
                                        rewardOption={item}
                                        onSelect={onSelect}
                                    />
                                )
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export const ValidatorItem = ({
    item = null,
    rewardOption = null,
    onSelect = () => {},
}) => {
    const { setOption } = useCalculatorContext()
    return (
        <div
            className={styles.validator}
            onClick={() => {
                setOption(rewardOption, item)
                onSelect()
            }}
        >
            <ValidatorGroup item={item} />
            <RewardRateGroup item={item} />
        </div>
    )
}

const ExpandButton = ({ onClick = () => {}, disabled = false }) => {
    return (
        <button
            className={styles.moreToggle}
            onClick={onClick}
            disabled={disabled}
        >
            <div className={classNames(styles.toggle)}>
                <span className={styles.bar1} />
                <span className={styles.bar2} />
            </div>
        </button>
    )
}

export const ItemGroup = ({
    item = null,
    entity = TYPE_ASSET,
    onSelect = () => {},
    showCategory = false,
}) => {
    if (entity === TYPE_ASSET) {
        return <AssetItem item={item} onSelect={onSelect} />
    }

    if (entity === TYPE_PROVIDER) {
        const provider = item?.providers?.[0]
        const validators = getItemsFromPaginatedData(item, TYPE_VALIDATOR, true)
        const hasExpansion = (validators?.length ?? 0) > 1
        return (
            <div className={styles.verifiedWrap}>
                <div
                    className={classNames(styles.vppIcon, {
                        [styles.hidden]: !provider?.isVerified,
                    })}
                >
                    <Image
                        src={'/static/svg/vsp-tag.svg'}
                        alt={`Verified Provider`}
                        width={18}
                        height={18}
                        priority
                    />
                </div>
                <div className={styles.childrenWrap}>
                    <RewardOptionItem
                        item={item}
                        onSelect={onSelect}
                        hasExpansion={hasExpansion}
                        showCategory={showCategory}
                    />
                </div>
            </div>
        )
    }

    return null
}
