import classNames from 'classnames'
import { getMetricValueByKey, getTagName } from 'utils/actions'
import { UNDER_MAINTENANCE, TRENDING, ASSET_CATEGORIES } from 'utils/constants'
import { GTMEvent, logEvent } from 'utils/GTM'
import { formatOutputNumber } from 'utils/formatter'
import { BUTTON_VARIANT_BLUE, ButtonWithBg, Link, Tag } from 'components/ui'
import { TOOLTIPS } from '../constants'
import { useIsTrending } from './hooks'

import styles from './tagsList.module.scss'

export const TagsList = ({ asset = null, isUntracked = false }) => {
    const pageViewsTrend12h = getMetricValueByKey(asset, 'page_views_trend')

    const isTrending = useIsTrending(pageViewsTrend12h)

    return (
        <div className={classNames(styles.tagsList, 'flex')}>
            {!asset?.isActive && (
                <>
                    <Link
                        href='https://integration.stakingrewards.com/'
                        blank
                        onClick={() => {
                            logEvent(GTMEvent.GetAssetIntegratedButton)
                        }}
                    >
                        <ButtonWithBg
                            variant={BUTTON_VARIANT_BLUE}
                            className='text-xs w-auto h-8'
                        >
                            Get this asset integrated!
                        </ButtonWithBg>
                    </Link>
                    <Tag label={'Untracked listing'} />
                </>
            )}
            {asset?.underMaintenance && !isUntracked && (
                <Tag
                    label={UNDER_MAINTENANCE}
                    tooltipTextObj={TOOLTIPS.underMaintenance(asset?.name)}
                />
            )}
            {isTrending && (
                <Tag
                    label={TRENDING}
                    tooltipTextObj={TOOLTIPS.trending(
                        formatOutputNumber(pageViewsTrend12h, {
                            precision: 2,
                            forcePrecision: false,
                            allowEmpty: false,
                            withAbbreviation: true,
                            showApproximation: true,
                            postfix: '%',
                            spaceAfterNumber: false,
                            showPlus: false,
                        })
                    )}
                />
            )}
            {asset?.tags?.map((tag, idx) => {
                const link = ASSET_CATEGORIES.find(
                    category => category.key === tag?.tagKey
                )
                    ? `/assets/${tag?.tagKey}`
                    : null
                return (
                    <Tag
                        key={`tag-${tag?.tagKey}-${idx}`}
                        className={classNames({
                            ['hover:opacity-80']: link,
                        })}
                        label={getTagName(tag?.tagKey)}
                        link={link}
                    />
                )
            })}
        </div>
    )
}
