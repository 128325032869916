import { createContext, useContext, useEffect, useState } from 'react'
import { useClientWidthMediaPredicate } from 'hooks'
import { TableView } from 'components/ui'
import { getPageSizeOption } from 'utils/actions'
import { PageSizes } from 'utils/constants'

const TableArchiveContext = createContext({})

export const TableArchiveContextProvider = ({
    params = {},
    pageSize = PageSizes.Twenty,
    withPerformanceMetric = false,
    isEth = false,
    minRowWidthPx = 1024,
    children,
}) => {
    // Rendered the right view client-side only (depending on the window size)
    const showTableConfig = useClientWidthMediaPredicate(minRowWidthPx)

    const [selectedTableView, setSelectedTableView] = useState(
        showTableConfig ? TableView.List : TableView.Grid
    )

    useEffect(() => {
        setSelectedTableView(showTableConfig ? TableView.List : TableView.Grid)
    }, [showTableConfig])

    const [hasMore, setHasMore] = useState(false)
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        pageSize: getPageSizeOption(pageSize),
    })

    return (
        <TableArchiveContext.Provider
            value={{
                selectedTableView: showTableConfig
                    ? selectedTableView
                    : TableView.Grid,
                setSelectedTableView,
                showTableConfig,
                queryParams: params,
                paginationParams,
                setPaginationParams,
                hasMore,
                setHasMore,
                withPerformanceMetric,
                isEth,
                pageSize,
            }}
        >
            {children}
        </TableArchiveContext.Provider>
    )
}

export const useTableArchiveContext = () => {
    return useContext(TableArchiveContext)
}
