import { useEffect, useState } from 'react'
import { fetchPopularAssets, fetchSimilarAssets } from 'data'
import { getMetricPrecision, getMetricValueByKey } from 'utils/actions'
import { formatOutputNumber } from 'utils/formatter'
import { MAIN_ASSET_METRIC_GROUPS } from '../constants'
import { AssetCard } from '../archive/assetCard'

const getBestRewardAsset = list => {
    const result = list?.reduce((acc, asset) => {
        const accRewardRate = getMetricValueByKey(acc, 'reward_rate') || 0
        const assetRewardRate = getMetricValueByKey(asset, 'reward_rate') || 0
        return accRewardRate > assetRewardRate ? acc : asset
    }, null)
    return result
}

const MostPopularAssetsRow = ({}) => {
    const [popularAssets, setPopularAssets] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const fetchedPopularAssets = await fetchPopularAssets(
                'page_views_30d',
                ['page_views_30d', 'price', 'reward_rate']
            )
            setPopularAssets(fetchedPopularAssets?.assets || [])
        }
        fetchData()
    }, [])
    const assetWithMaxRewardRate = getBestRewardAsset(popularAssets || [])

    const maxValue = getMetricValueByKey(assetWithMaxRewardRate, 'reward_rate')
    const format = {
        ...(MAIN_ASSET_METRIC_GROUPS.find(m => m.key === 'reward_rate') || {}),
        precision: getMetricPrecision('reward_rate', maxValue),
    }

    return (
        <div>
            <h3 className='text-contrast-4 text-xl font-bold mb-3'>
                Earn up to {formatOutputNumber(maxValue, format)} with the most
                popular staking assets
            </h3>
            <div className='text-contrast-3 text-base mb-6'>
                The Top 4 most popular staking assets on Staking Rewards
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6 mb-12'>
                {(popularAssets || []).filter(Boolean).map((item, i) => (
                    <AssetCard
                        key={item?.slug}
                        index={i + 1}
                        item={item}
                        sortByKey={'price'}
                        minimal={true}
                    />
                ))}
            </div>
        </div>
    )
}

const SimilarAssetsRow = ({
    asset = null,
    similarByMetricKey = null,
    similarByMetricLabel = '',
}) => {
    const [similarAssets, setSimilarAssets] = useState([])

    useEffect(() => {
        if (!asset) return
        const fetchData = async () => {
            const fetchedSimilarAssets = await fetchSimilarAssets(
                asset,
                similarByMetricKey,
                ['reward_rate', similarByMetricKey]
            )
            setSimilarAssets(
                [
                    fetchedSimilarAssets?.twoBefore?.[1],
                    fetchedSimilarAssets?.twoBefore?.[0],
                    ...(fetchedSimilarAssets?.twoAfter || []),
                ].filter(Boolean)
            )
        }
        fetchData()
    }, [asset, similarByMetricKey])

    const assetWithMaxRewardRate = getBestRewardAsset(similarAssets)

    const maxValue = getMetricValueByKey(assetWithMaxRewardRate, 'reward_rate')

    const format = {
        ...(MAIN_ASSET_METRIC_GROUPS.find(m => m.key === 'reward_rate') || {}),
        precision: getMetricPrecision('reward_rate', maxValue),
    }

    if (!similarAssets?.length) return null

    return (
        <div className='flex flex-col'>
            <h3 className='text-contrast-4 text-xl font-bold mb-3'>
                Earn up to {formatOutputNumber(maxValue, format)} on assets with
                a similar {similarByMetricLabel}
            </h3>
            <div className='text-contrast-3 text-base mb-6'>
                Of all the fully integrated assets on Staking Rewards, these are
                the closest to {asset?.name} in {similarByMetricLabel}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6 mb-12'>
                {similarAssets?.filter(Boolean).map((item, i) => (
                    <AssetCard
                        key={item?.slug}
                        index={i + 1}
                        item={item}
                        sortByKey={similarByMetricKey}
                        minimal={true}
                    />
                ))}
            </div>
        </div>
    )
}

export const SimilarAssetsSection = ({ asset = null, withPopular = true }) => {
    return (
        <div className='flex flex-col gap-y-6'>
            <SimilarAssetsRow
                asset={asset}
                similarByMetricKey='marketcap'
                similarByMetricLabel='Market Cap'
            />
            <SimilarAssetsRow
                asset={asset}
                similarByMetricKey='daily_trading_volume'
                similarByMetricLabel='Daily Volume'
            />
            {withPopular && <MostPopularAssetsRow />}
        </div>
    )
}
