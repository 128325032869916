import classNames from 'classnames'
import Image from 'next/image'
import { NO_DATA_INDICATOR, formatOutputNumber } from 'utils/formatter'
import { getMetricPrecision, getObjectFromJsonString } from 'utils/actions'
import { convertTime } from 'utils/converter'
import { BUTTON_VARIANT_FG, ButtonWithBg, Link } from 'components/ui'

import styles from './validatorsSection.module.scss'
import { HeaderWithLink } from 'components/text/headerWithLink'

const OWN_SOLO_STAKING_METRICS = [
    {
        key: 'reward_rate',
        label: 'Reward Rate',
        format: value =>
            formatOutputNumber(value, {
                precision: getMetricPrecision('reward_rate', value),
                forcePrecision: false,
                allowEmpty: false,
                withAbbreviation: false,
                showApproximation: true,
                postfix: '%',
                spaceAfterNumber: false,
                showPlus: false,
            }),
        defaultTooltip: `The reward rate is calculated by tracking the total rewards distributed over a standard measurement period across all network participants. This amount is then extrapolated to represent an annual figure, adjusting for the network's unique time measurement method to ensure accuracy.`,
    },
    {
        key: 'minimum',
        label: 'Minimum',
        format: (value, symbol) =>
            formatOutputNumber(value, {
                precision: getMetricPrecision('minimum', value),
                forcePrecision: false,
                allowEmpty: false,
                withAbbreviation: true,
                showApproximation: true,
                postfix: symbol ?? '',
                spaceAfterNumber: true,
                showPlus: false,
            }),
        defaultTooltip: `The minimum required to run a validator and receive rewards can change over time and may be influenced by the network's active set size and overall participation rate, with adjustments reflecting shifts in network dynamics and participation trends, requiring validators to stay informed on current conditions.`,
    },
    {
        key: 'lockup_time',
        label: 'Lockup Time',
        format: value => {
            const secondsToDays = convertTime(value, 'seconds', 'days')
            return formatOutputNumber(secondsToDays, {
                precision: getMetricPrecision('lockup_time', secondsToDays),
                forcePrecision: false,
                allowEmpty: false,
                withAbbreviation: false,
                showApproximation: true,
                postfix: `day${secondsToDays !== 1 ? 's' : ''}`,
                spaceAfterNumber: true,
                showPlus: false,
            })
        },
        defaultTooltip: `When initiating a stake, there can be a starting period before the stake becomes active, followed by a waiting period upon withdrawal. The length of these periods can vary, reflecting the network's unique processing times. As a result, the actual time your assets are locked may differ based on operational procedures and current network status.`,
    },
]

export const ValidatorsSection = ({
    slug = '',
    symbol = '',
    soloStakingRewardOption = null,
}) => {
    const ownSoloStakingMetrics = OWN_SOLO_STAKING_METRICS.filter(metric =>
        soloStakingRewardOption?.metrics
            ?.map(({ metricKey }) => metricKey)
            ?.includes(metric?.key)
    )

    const linksObject = getObjectFromJsonString(
        soloStakingRewardOption?.inputAssets?.[0]?.links
    )

    const hardwareRequirementsLink =
        linksObject?.hardwareRequirements || linksObject?.website

    return (
        <div id='section-run-a-validator' className={styles.validatorsSection}>
            <div className={styles.ownValidatorRow}>
                <div className={styles.column}>
                    <HeaderWithLink
                        title={`Run Your Own ${symbol} Validator`}
                        href={`/asset/${slug}#section-run-a-validator`}
                    />
                    <div
                        className={classNames(styles.text, styles.sectionText)}
                    >
                        Running a Validator is a great way to support the
                        network and contribute to the security of the network.
                        It requires a local set up in your home. Running a
                        Validator is a great way to foster decentralization. You
                        can run a validator either at home on your own server,
                        or set it up remotely in the cloud.
                    </div>
                    {Boolean(ownSoloStakingMetrics?.length) && (
                        <div className={styles.metrics}>
                            {ownSoloStakingMetrics?.map((metricShape, idx) => {
                                const metric =
                                    soloStakingRewardOption?.metrics?.find(
                                        metric =>
                                            metric?.metricKey ===
                                            metricShape?.key
                                    )
                                const tooltip =
                                    metric?.tooltip ??
                                    metricShape?.defaultTooltip
                                return (
                                    <div
                                        key={`own-solo-metric-${metric?.metricKey}-${idx}`}
                                        className={styles.metric}
                                    >
                                        <div className={styles.metricGroup}>
                                            <div className={styles.name}>
                                                {slug === 'ethereum-2-0' &&
                                                metricShape?.key ===
                                                    'lockup_time'
                                                    ? 'Withdrawal Queue'
                                                    : metricShape.label}
                                            </div>
                                            <div className={styles.value}>
                                                {metricShape?.format(
                                                    metric?.defaultValue,
                                                    symbol
                                                ) ??
                                                    `${metric?.defaultValue}` ??
                                                    NO_DATA_INDICATOR}
                                            </div>
                                        </div>
                                        <p className={styles.text}>{tooltip}</p>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
                {hardwareRequirementsLink && (
                    <div className={styles.column}>
                        <div className={styles.disks}>
                            <div className='w-[445px] h-[342px]'>
                                <Image
                                    src='/static/svg/disks.svg'
                                    alt={'Validators'}
                                    fill
                                    sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                                />
                            </div>
                            <Link href={hardwareRequirementsLink} blank>
                                <ButtonWithBg
                                    className={styles.hardwareBtn}
                                    variant={BUTTON_VARIANT_FG}
                                    iconName='icon-share'
                                >
                                    Hardware Requirements
                                </ButtonWithBg>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
