import { createContext, useCallback, useContext, useState } from 'react'
import { REFRESH_INTERVAL_ASSET_PRICE_MS, useProfileMetrics } from 'data'
import { getMetricValueByKey } from 'utils/actions'
import { TYPE_ASSET } from 'utils/constants'
import {
    DECIMALS_LIMIT_TOKEN,
    DECIMALS_LIMIT_USD,
    formatInputNumber,
    isInvalidNumber,
} from 'utils/formatter'

const AssetRewardCalculatorContext = createContext()

export const useAssetRewardCalculatorContext = () => {
    return useContext(AssetRewardCalculatorContext)
}

export const AssetRewardCalculatorContextProvider = ({
    asset = null,
    outputAsset = null,
    children,
}) => {
    const [amount, setAmount] = useState({
        actual: '',
        rounded: '',
    })
    const [usd, setUsd] = useState(true)

    const [stakingTimeInDays, setStakingTimeInDays] = useState(365)

    const { data, isLoading } = useProfileMetrics(
        TYPE_ASSET,
        asset?.slug,
        [
            'price',
            'reward_rate',
            'marketcap',
            'staking_marketcap',
            'real_reward_rate',
            'inflation_rate',
            'exchange_ratio',
            'market_ratio',
            'unstaking_time',
        ],
        REFRESH_INTERVAL_ASSET_PRICE_MS
    )

    const { data: outputData, isLoading: isLoadingOutput } = useProfileMetrics(
        TYPE_ASSET,
        outputAsset?.slug,
        [
            'price',
            'reward_rate',
            'marketcap',
            'staking_marketcap',
            'real_reward_rate',
            'inflation_rate',
            'exchange_ratio',
            'market_ratio',
            'unstaking_time',
        ],
        REFRESH_INTERVAL_ASSET_PRICE_MS
    )

    const tokenPrice = getMetricValueByKey(data, 'price')

    const onSetAmount = useCallback(
        (newAmount = '', round = true) => {
            setAmount({
                actual: newAmount,
                rounded: !isInvalidNumber(newAmount)
                    ? round
                        ? formatInputNumber(
                              newAmount,
                              usd ? DECIMALS_LIMIT_USD : DECIMALS_LIMIT_TOKEN
                          )
                        : newAmount
                    : '',
            })
        },
        [usd]
    )

    const onSetUsd = useCallback(
        (newIsUsd = true) => {
            setUsd(newIsUsd)

            if (amount?.actual !== '') {
                const floatAmount = isInvalidNumber(amount?.actual)
                    ? 0
                    : parseFloat(amount?.actual)
                const newAmount = newIsUsd
                    ? floatAmount * tokenPrice
                    : tokenPrice !== 0
                    ? floatAmount / tokenPrice
                    : floatAmount
                onSetAmount(newAmount, true)
            }
        },
        [onSetAmount, amount?.actual, tokenPrice]
    )

    return (
        <AssetRewardCalculatorContext.Provider
            value={{
                asset: { ...asset, metrics: data?.metrics ?? [] },
                outputAsset: outputAsset
                    ? { ...outputAsset, metrics: outputData?.metrics ?? [] }
                    : null,
                amount,
                setAmount: onSetAmount,
                usd,
                setUsd: onSetUsd,
                stakingTimeInDays,
                setStakingTimeInDays,
                isLoading: isLoading || isLoadingOutput,
            }}
        >
            {children}
        </AssetRewardCalculatorContext.Provider>
    )
}
