import classNames from 'classnames'
import { isProProvider } from 'components/providers/archive/utils'
import { Button } from 'components/rewardOptions/archive/v2/button'
import { Dropdown } from 'components/rewardOptions/archive/v2/dropdown'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { OperatorRow } from 'components/rewardOptions/archive/v2/operatorRow'
import { ExpandedLST } from 'components/rewardOptions/archive/v2/rowContent/expandedLST'
import { ExpandedPoS } from 'components/rewardOptions/archive/v2/rowContent/expandedPoS'
import { ExpandedNativeRestaking } from 'components/rewardOptions/archive/v2/rowContent/expandedNativeRestaking'
import { MetricsLST } from 'components/rewardOptions/archive/v2/rowContent/metricsLST'
import { MetricsPoS } from 'components/rewardOptions/archive/v2/rowContent/metricsPoS'
import {
    getFormattedData,
    getFormattedDataForCustodial,
} from 'components/rewardOptions/archive/v2/getFormattedData'
import { ValidatorRow } from 'components/rewardOptions/archive/v2/validatorRow'
import { hasStakeWithButton } from 'components/rewardOptions/utils'
import {
    ExpandableVerifiedTag,
    Link,
    LogoImage,
    ToggleButton,
    TooltipOnHover,
} from 'components/ui'
import { StakeWithButton } from 'components/ui/StakeWithButton'
import { ActiveMarker } from 'components/ui/activeMarker'
import { ExpandableProTag } from 'components/ui/expandableProTag'
import { ItemCountTag } from 'components/ui/itemCountTag'
import { useEffect, useState } from 'react'
import {
    getMetricDefaultValueByKey,
    getMetricValueByKey,
    getObjectFromJsonString,
} from 'utils/actions'
import {
    ALL_KEY,
    EXTERNAL_ARROW,
    OPERATOR_KEY,
    RewardOptionType,
    TYPE_ASSET,
    TYPE_PROVIDER,
} from 'utils/constants'
import { formatOutputNumber } from 'utils/formatter'
import { BUTTON_VARIANT_STAKE_NOW } from 'components/ui/buttons/buttonWithBg'
import { MetricsSolo } from 'components/rewardOptions/archive/v2/rowContent/metricsSolo'
import { MetricsSmartContract } from 'components/rewardOptions/archive/v2/rowContent/metricsSmartContract'
import { ExpandedSmartContract } from 'components/rewardOptions/archive/v2/rowContent/expandedSmartContract'
import { SmartContractCardContextProvider } from 'components/rewardOptions/smartContract/smartContractCardContext'
import { MetricsCustodial } from 'components/rewardOptions/archive/v2/rowContent/metricsCustodial'
import { ExpandedCustodial } from 'components/rewardOptions/archive/v2/rowContent/expandedCustodial'
import {
    checkIsDelegatable,
    checkIsStakeableLst,
    getStatusAndName,
} from 'components/rewardOptions/archive/utils'
import { fetchAdditionalRewardOptions } from 'data'
import { THEME, useTheme } from 'state'
import { useAssetRewardOptionsCalculatorContext } from 'contexts/assetRewardOptionsCalculatorContext'
import { CustodialRewardOptionSubRow } from 'components/rewardOptions/archive/v2/custodialRewardOptionSubRow'
import { SRStakeNowButton } from '../srStakeNowButton'
import { GTMEvent, logEvent } from 'utils/GTM'
import { MetricsBabylon } from 'components/rewardOptions/archive/v2/rowContent/metricsBabylon'
import { MetricsNativeRestaking } from './rowContent/metricsNativeRestaking'
import { ExpandedDrop } from './rowContent/expandedDrop'
import Image from 'next/image'
/**
 * Renders a row for a reward option in the archive.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.item - The reward option item.
 * @param {number} props.index - The index of the reward option. (For showing number in the left side)
 * @param {string} props.type - The type of the reward option. (PoS, LST, smart contract, etc.)
 * @param {Object} props.profileItem - The current profile item in the url. (Either asset or provider)
 * @param {string} props.profileType - The profile type. (Either TYPE_PROVIDER or TYPE_ASSET)
 * @param {string} props.timeframe - The timeframe for the metrics. (e.g. '30d')
 * @returns {JSX.Element} The rendered RewardOptionRow component.
 */
export const RewardOptionRow = ({
    item,
    index,
    type,
    profileItem,
    profileType,
    timeframe,
    sortByKey,
    sponsoredROTrackingId,
}) => {
    const theme = useTheme()
    const isPoS = type === RewardOptionType.PROOF_OF_STAKE
    const isLST = type === RewardOptionType.LIQUID_STAKING
    const isSolo = type === RewardOptionType.RUN_VALIDATOR
    const isSmartContract = type === RewardOptionType.SMART_CONTRACT
    const isCustodial = type === RewardOptionType.CUSTODIAL
    const isBabylon = type === RewardOptionType.BABYLON_STAKING
    const isNativeRestaking = type === RewardOptionType.NATIVE_RESTAKING

    const isExpandable =
        isPoS ||
        isLST ||
        isSmartContract ||
        isCustodial ||
        type === ALL_KEY ||
        isNativeRestaking

    const assetRewardCalculatorContext =
        useAssetRewardOptionsCalculatorContext()

    const formattedData = isCustodial
        ? getFormattedDataForCustodial(
              item,
              profileItem,
              assetRewardCalculatorContext
          )
        : getFormattedData(
              item,
              profileItem,
              timeframe,
              type,
              assetRewardCalculatorContext
          )

    const { calculateResult } = assetRewardCalculatorContext
    const rewardOption =
        profileType === TYPE_PROVIDER
            ? item?.inputAssets?.[0]
            : item?.type?.key === OPERATOR_KEY
              ? item?.providers?.find(
                    p => p?.type?.key === RewardOptionType.PROOF_OF_STAKE
                )
              : item?.providers?.[0]

    const isVerified = rewardOption?.isVerified
    const isPro = isProProvider(rewardOption)
    const isDrop = rewardOption?.slug === 'drop'

    const [isExpanded, setIsExpanded] = useState(
        (!sponsoredROTrackingId &&
            index === 1 &&
            (isCustodial || isLST || isNativeRestaking)) ||
            isDrop
    )
    const [additionalRewardOption, setAdditionalRewardOption] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [additionalRewardOptions, setAdditionalRewardOptions] = useState([])

    useEffect(() => {
        if (
            hasLoaded ||
            (!isLST && !isSmartContract) ||
            !isExpanded ||
            !formattedData?.outputAsset?.slug
        ) {
            return
        }
        const fetchData = async () => {
            setIsLoading(true)
            const result = await fetchAdditionalRewardOptions([
                formattedData?.outputAsset?.slug,
            ])
            setAdditionalRewardOptions(result?.rewardOptions ?? [])
            setIsLoading(false)
            setHasLoaded(true)
        }
        fetchData()
    }, [
        isLST,
        isSmartContract,
        formattedData?.outputAsset?.slug,
        isExpanded,
        hasLoaded,
    ])

    const isPrivateNode =
        getMetricValueByKey(item, 'is_not_delegation_node') === 1
    const validators = isPrivateNode ? [] : item?.validators
    const operators = item?.operators

    const expandedViewMap = {
        all: (
            <ExpandedPoS
                formattedData={formattedData}
                validators={validators}
                isExpanded={isExpanded}
                sortByKey={sortByKey}
            />
        ),
        [RewardOptionType.PROOF_OF_STAKE]: (
            <ExpandedPoS
                formattedData={formattedData}
                validators={validators}
                isExpanded={isExpanded}
                sortByKey={sortByKey}
            />
        ),
        [RewardOptionType.LIQUID_STAKING]: (
            <ExpandedLST
                formattedData={formattedData}
                isExpanded={isExpanded}
                asset={item}
                sortByKey={sortByKey}
            />
        ),
        [RewardOptionType.SMART_CONTRACT]: (
            <ExpandedSmartContract
                formattedData={formattedData}
                isExpanded={isExpanded}
                asset={item}
                sortByKey={sortByKey}
            />
        ),
        [RewardOptionType.CUSTODIAL]: (
            <ExpandedCustodial
                formattedData={formattedData}
                isExpanded={isExpanded}
                bestRewardOption={item}
            />
        ),
        [RewardOptionType.NATIVE_RESTAKING]: (
            <ExpandedPoS
                formattedData={formattedData}
                validators={validators}
                isExpanded={isExpanded}
                sortByKey={sortByKey}
            />
        ),
    }
    const expandedView = expandedViewMap[type]

    const linkToRewardoptionProfile =
        profileType === TYPE_ASSET
            ? isCustodial
                ? `/provider/${item?.slug}`
                : rewardOption?.isClaimed
                  ? `/provider/${rewardOption?.slug}`
                  : 'https://stakingrewards.typeform.com/claim-profile'
            : `/asset/${rewardOption?.slug}`

    const tokenPrice = getMetricDefaultValueByKey(profileItem, 'price')

    const additionalRewardRate = getMetricDefaultValueByKey(
        additionalRewardOption,
        'reward_rate'
    )
    const finalRewardRate =
        Number(formattedData?.rewardRate?.replace('%', '')) +
        additionalRewardRate

    const finalRewardValue = calculateResult(
        finalRewardRate,
        formattedData?.price
    )

    let hasStakeNowButton = false
    const inputAsset =
        profileType === TYPE_ASSET ? profileItem : item?.inputAssets?.[0]
    const provider =
        profileType === TYPE_ASSET ? item?.providers?.[0] : profileItem

    if (isLST) {
        hasStakeNowButton = checkIsStakeableLst(
            formattedData?.outputAsset,
            inputAsset,
            item
        )
    } else if (isPoS) {
        hasStakeNowButton = checkIsDelegatable(inputAsset, provider, item)
    }

    const status = getStatusAndName(rewardOption, item)

    const hasButton =
        hasStakeNowButton ||
        hasStakeWithButton(profileItem, item?.validators) ||
        validators?.length > 1 ||
        isCustodial ||
        isLST ||
        isBabylon

    const renderStakeButton = () => {
        if (!hasButton) {
            return null
        }
        if (isDrop) {
            return (
                <Link
                    className='flex my-auto'
                    href='/stake-app?input=cosmos&output=drop-atom&type=liquid-staking'
                >
                    <button className='flex items-center gap-2 h-[42px] bg-black rounded-lg'>
                        <span className='text-sm font-semibold text-white '>
                            Stake now
                        </span>
                        <div className='icon icon-brand !bg-white'></div>
                    </button>
                </Link>
            )
        }
        if (isBabylon) {
            return (
                <Link
                    className='flex my-auto'
                    href={`/stake-app?input=bitcoin&type=babylon-staking&provider=${rewardOption?.slug}`}
                >
                    <button className='flex items-center gap-2 h-[42px] bg-black rounded-lg'>
                        <span className='text-sm font-semibold text-white '>
                            Stake with
                        </span>
                        <div className='icon icon-brand !bg-white'></div>
                    </button>
                </Link>
            )
        }
        if (hasStakeNowButton) {
            return (
                <SRStakeNowButton
                    inputAsset={inputAsset}
                    outputAsset={formattedData?.outputAsset}
                    provider={provider}
                    rewardOption={item}
                    variant={BUTTON_VARIANT_STAKE_NOW}
                    className='!h-8 hover:bg-shade-darkest/90 transition-all duration-200 ease-in-out'
                />
            )
        } else if (hasStakeWithButton(profileItem, item?.validators)) {
            const isPrivateNode =
                getMetricValueByKey(item, 'is_not_delegation_node') === 1
            if (isPrivateNode) {
                return (
                    <TooltipOnHover
                        textClassName='!text-center'
                        text='Private node: Delegation not possible'
                    >
                        <StakeWithButton
                            item={item}
                            profileItem={profileItem}
                            validator={item?.validators?.[0]}
                            isNewDesign
                            disabled
                        />
                    </TooltipOnHover>
                )
            } else {
                return (
                    <StakeWithButton
                        item={item}
                        profileItem={profileItem}
                        validator={item?.validators?.[0]}
                        isNewDesign
                    />
                )
            }
        } else if (isLST) {
            const links = getObjectFromJsonString(
                formattedData?.provider?.links
            )
            if (formattedData?.provider?.isReferral && links?.stakelink) {
                return (
                    <Button
                        onClick={() => {
                            if (sponsoredROTrackingId) {
                                logEvent(GTMEvent.AdRoTable, {
                                    provider: item.providers[0].slug,
                                    adName: item.providers[0].name,
                                    url: `/asset/${formattedData?.outputAsset?.slug}`,
                                    id: sponsoredROTrackingId,
                                })
                            }
                            window.open(
                                `/asset/${formattedData?.outputAsset?.slug}`
                            )
                        }}
                    >
                        Stake Now {EXTERNAL_ARROW}
                    </Button>
                )
            } else {
                return (
                    <Button
                        onClick={() => {
                            if (sponsoredROTrackingId) {
                                logEvent(GTMEvent.AdRoTable, {
                                    provider: item.providers[0].slug,
                                    adName: item.providers[0].name,
                                    url: `/asset/${formattedData?.outputAsset?.slug}`,
                                    id: sponsoredROTrackingId,
                                })
                            }
                            window.open(
                                `/asset/${formattedData?.outputAsset?.slug}`
                            )
                        }}
                    >
                        Go To LST Profile
                    </Button>
                )
            }
        } else if (isPoS && validators?.length > 1) {
            return (
                <Button onClick={() => setIsExpanded(!isExpanded)}>
                    <span>Stake Options</span>{' '}
                    <ItemCountTag count={validators?.length} />
                </Button>
            )
        } else if (isCustodial) {
            return (
                <Button onClick={() => setIsExpanded(!isExpanded)}>
                    <span>Sign up options</span>{' '}
                    <ItemCountTag count={item?.rewardOptions?.length} />
                </Button>
            )
        }
    }

    const stakeLink = getObjectFromJsonString(
        additionalRewardOption?.links ?? {}
    )?.stakelink

    const mainContent = (
        <div
            className={classNames(
                'rounded-lg hover:shadow-md transition-all duration-500 ease-in-out',
                {
                    'shadow-md': isExpanded,
                    'bg-white': theme === THEME.LIGHT,
                    'bg-contrast-1': theme === THEME.DARK,
                }
            )}
        >
            <div className='h-[76px] w-full flex items-center relative justify-between'>
                {isVerified && !sponsoredROTrackingId && (
                    <div className='absolute -left-[10px] z-10'>
                        {isPro ? (
                            <ExpandableProTag size={20} />
                        ) : (
                            <ExpandableVerifiedTag size={20} />
                        )}
                    </div>
                )}
                {sponsoredROTrackingId ? (
                    <div className='h-[76px] w-10 bg-primary rounded-lg relative hover:shadow-md transition-all duration-300 cursor-pointer'>
                        <div className='text-white -rotate-90 absolute top-[31px] -left-[12px] text-[10px]'>
                            Sponsored
                        </div>
                        <div
                            className={classNames(
                                'absolute rounded-lg h-full left-[23px] top-0 flex items-center',
                                {
                                    'bg-white': theme === THEME.LIGHT,
                                    'bg-contrast-1': theme === THEME.DARK,
                                }
                            )}
                            style={{ width: 'calc(100% - 23px)' }}
                        ></div>
                    </div>
                ) : (
                    <div className='w-[42px] h-full flex items-center justify-center text-base text-contrast-4 font-bold border-r border-solid border-contrast-2/20 flex-shrink-0'>
                        {index}
                    </div>
                )}
                <div className='xl:w-[160px] rounded-l-lg p-4'>
                    <Link
                        href={linkToRewardoptionProfile}
                        className='flex items-center'
                    >
                        {isPoS && <ActiveMarker status={status} />}
                        <LogoImage
                            src={
                                isCustodial
                                    ? item?.logoUrl
                                    : rewardOption?.logoUrl
                            }
                            slug={isCustodial ? item?.slug : rewardOption?.slug}
                            size={27}
                        />
                        <div className='ml-2 font-bold leading-3 hidden xl:flex flex-col justify-center'>
                            <div className='text-[14px] text-contrast-5 leading-[14px]'>
                                {isCustodial ? item?.name : rewardOption?.name}
                            </div>
                        </div>
                    </Link>
                </div>
                {!isSmartContract && !isBabylon && (
                    <div className='flex h-full min-w-[101px] flex-1 max-w-[120px] mr-4'>
                        <MetricBox
                            title='Reward Rate'
                            value={formattedData.rewardRate}
                            className='rounded-lg bg-gradient-sr-light/10 h-full min-w-[101px] flex-1 max-w-[120px]'
                        />
                        <div
                            className={classNames(
                                'flex absolute rounded-full w-6 h-6 items-center justify-center -right-[12px] top-[26px]',
                                {
                                    'bg-white': theme === THEME.LIGHT,
                                    'bg-contrast-1': theme === THEME.DARK,
                                }
                            )}
                        >
                            <div className='flex w-full h-full bg-gradient-sr-light/10 rounded-full items-center justify-center'>
                                <span
                                    className={classNames(
                                        'icon icon-arrow-right !w-[10px] !h-[8px]',
                                        {
                                            '!bg-black': theme === THEME.LIGHT,
                                            '!bg-white': theme === THEME.DARK,
                                        }
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {(isPoS || type === ALL_KEY) && (
                    <MetricsPoS
                        formattedData={formattedData}
                        sortByKey={sortByKey}
                    />
                )}
                {isLST && (
                    <MetricsLST
                        formattedData={formattedData}
                        asset={item}
                        sortByKey={sortByKey}
                    />
                )}
                {isSolo && (
                    <MetricsSolo
                        formattedData={formattedData}
                        sortByKey={sortByKey}
                    />
                )}
                {isSmartContract && (
                    <MetricsSmartContract
                        formattedData={formattedData}
                        sortByKey={sortByKey}
                    />
                )}
                {isCustodial && (
                    <MetricsCustodial formattedData={formattedData} />
                )}
                {isBabylon && (
                    <MetricsBabylon
                        formattedData={formattedData}
                        sortByKey={sortByKey}
                    />
                )}
                {isNativeRestaking && (
                    <MetricsNativeRestaking
                        formattedData={formattedData}
                        sortByKey={sortByKey}
                    />
                )}
                {/* Placeholder for non assigned type */}
                {!Object.values(RewardOptionType).includes(type) &&
                    type !== ALL_KEY && <div className='flex flex-1'></div>}
                <div
                    className={classNames(
                        'flex h-full items-center w-[230px] justify-end',
                        {
                            '!w-8': isSmartContract,
                        }
                    )}
                >
                    {/* <span className='icon icon-calculator !w-5 !h-5' /> */}
                    {hasButton && (
                        <div
                            className={classNames(
                                `${sponsoredROTrackingId ? 'px-12' : 'px-4'}`
                            )}
                        >
                            {renderStakeButton()}
                        </div>
                    )}
                    {isExpandable && !sponsoredROTrackingId && (
                        <div className='w-8 h-full flex items-center justify-center border-l border-shade-light/20 border-solid'>
                            <ToggleButton
                                onClick={() => {
                                    setIsExpanded(!isExpanded)
                                    // TODO clarify
                                    // if (asset) {
                                    //     logEvent(GTMEvent.ExpandTableClick, {
                                    //         asset: asset?.slug,
                                    //     })
                                    // }
                                }}
                                // hidden={!withExpansion}
                                expanded={isExpanded}
                            />
                        </div>
                    )}
                </div>
            </div>
            {isExpandable && (
                <div
                    className={classNames(
                        'w-full h-0 opacity-0 border-t border-solid border-shade-light/20 flex justify-around items-center transition-all duration-300 ease-in-out',
                        {
                            '!h-[76px] opacity-100':
                                isExpanded && !isLST && !isCustodial,
                            '!border-0': !isExpanded,
                            'flex-col xl:flex-row': isLST,
                            '!h-[152px] xl:!h-[76px] opacity-100':
                                isExpanded && isLST,
                        }
                    )}
                >
                    {expandedView}
                </div>
            )}
            {isExpandable && (
                <div
                    className={classNames(
                        'transition-all duration-300 ease-in-out'
                    )}
                >
                    {validators?.length > 1 &&
                        validators?.map((validator, i) => {
                            return (
                                <ValidatorRow
                                    key={i}
                                    validator={validator}
                                    tokenPrice={tokenPrice}
                                    profileItem={profileItem}
                                    rewardOption={item}
                                    className={classNames(
                                        `opacity-0 h-0 transition-all duration-300 ease-in-out delay-[${
                                            100 + i * 50
                                        }ms] -translate-y-10`,
                                        {
                                            ['opacity-100 !h-[71px] translate-y-0']:
                                                isExpanded,
                                            ['!p-0 -z-10 delay-0 !border-0']:
                                                !isExpanded,
                                            ['rounded-b-lg']:
                                                i === validators.length - 1, // add rounded bottom corners to the last validator
                                        }
                                    )}
                                    sortByKey={sortByKey}
                                />
                            )
                        })}
                    {(isLST || isSmartContract) && (
                        <>
                            <div
                                className={classNames(
                                    'flex transition-all duration-300 ease-in-out h-0 opacity-0 border-t border-shade-light/20 border-solid pb-3',

                                    isExpanded &&
                                        additionalRewardOptions.length > 0
                                        ? ['drop'].includes(rewardOption?.slug)
                                            ? 'h-[82px]'
                                            : 'h-[69px]'
                                        : '',
                                    {
                                        ['opacity-100 ']:
                                            isExpanded &&
                                            additionalRewardOptions.length > 0,
                                        ['!p-0 -z-10 delay-0 !border-0']:
                                            !isExpanded,
                                    }
                                )}
                            >
                                <div className='flex justify-between items-stretch flex-1 px-4 gap-2 mt-3'>
                                    <div className='flex-1'>
                                        {isExpanded && (
                                            <Dropdown
                                                data={additionalRewardOptions}
                                                formattedData={formattedData}
                                                additionalRewardOption={
                                                    additionalRewardOption
                                                }
                                                setAdditionalRewardOption={
                                                    setAdditionalRewardOption
                                                }
                                                isLoading={isLoading}
                                                showChain={['drop'].includes(
                                                    rewardOption?.slug
                                                )}
                                            />
                                        )}
                                    </div>
                                    <div className='flex flex-col justify-center xl:justify-start xl:flex-row p-3 bg-ultra-light items-center font-bold text-xs text-contrast-3 rounded-lg dark:bg-blackCard'>
                                        <span className='text-gray text-sm leading-none font-normal tracking-[0.3px]'>
                                            Final Reward Rate
                                        </span>
                                        <span className='ml-2 text-bw text-base leading-none dark:text-white'>
                                            {formatOutputNumber(
                                                finalRewardRate,
                                                {
                                                    precision: 2,
                                                    forcePrecision: false,
                                                    postfix: '%',
                                                }
                                            )}
                                        </span>
                                    </div>
                                    {stakeLink && (
                                        <Link
                                            blank
                                            rel='noreferrer noopener'
                                            href={stakeLink ?? '#'}
                                            className='py-3 px-4 flex gap-1 items-center !border !border-solid rounded-lg border-[#303030] dark:border-[#E0E0E0] hover:opacity-80 duration-200'
                                        >
                                            <span className='font-semibold text-sm leading-none'>
                                                Deposit on DeFi
                                            </span>
                                            {additionalRewardOption
                                                ?.providers?.[0]?.logoUrl && (
                                                <Image
                                                    src={
                                                        additionalRewardOption
                                                            ?.providers?.[0]
                                                            ?.logoUrl
                                                    }
                                                    alt={`${additionalRewardOption?.providers?.[0]?.name} logo`}
                                                    width={16}
                                                    height={16}
                                                    className='rounded-full'
                                                />
                                            )}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {operators?.length > 0 && (
                        <div
                            className={classNames(
                                'overflow-y-auto rounded-b-lg shadow-md h-full max-h-0 transition-all duration-300 operator-container',
                                {
                                    '!max-h-[240px]': isExpanded,
                                }
                            )}
                        >
                            {operators?.map((operator, i) => {
                                return (
                                    <OperatorRow
                                        key={i}
                                        operator={operator}
                                        tokenPrice={tokenPrice}
                                        className={classNames(
                                            `opacity-0 h-0 transition-all duration-300 ease-in-out delay-[${
                                                100 + i * 50
                                            }ms] -translate-y-10`,
                                            {
                                                ['opacity-100 !h-[71px] translate-y-0']:
                                                    isExpanded,
                                                ['!p-0 -z-10 delay-0 !border-0']:
                                                    !isExpanded,
                                                ['rounded-b-lg']:
                                                    i === operators.length - 1, // add rounded bottom corners to the last validator
                                            }
                                        )}
                                        sortByKey={sortByKey}
                                    />
                                )
                            })}
                        </div>
                    )}
                    {isCustodial && (
                        <div
                            className={classNames(
                                'overflow-y-auto rounded-b-lg shadow-md h-full max-h-0 transition-all duration-300 operator-container',
                                {
                                    '!max-h-[240px]': isExpanded,
                                }
                            )}
                        >
                            {item?.rewardOptions?.map((ro, i) => {
                                return (
                                    <CustodialRewardOptionSubRow
                                        key={ro?.id || i}
                                        item={ro}
                                        provider={item}
                                        profileItem={profileItem}
                                        assetRewardCalculatorContext={
                                            assetRewardCalculatorContext
                                        }
                                        className={classNames(
                                            `opacity-0 h-0 transition-all duration-300 ease-in-out delay-[${
                                                100 + i * 50
                                            }ms] -translate-y-10`,
                                            {
                                                ['opacity-100 !h-[71px] translate-y-0']:
                                                    isExpanded,
                                                ['!p-0 -z-10 delay-0 !border-0']:
                                                    !isExpanded,
                                                ['rounded-b-lg']:
                                                    i ===
                                                    item?.rewardOptions
                                                        ?.length -
                                                        1, // add rounded bottom corners to the last item
                                            }
                                        )}
                                    />
                                )
                            })}
                        </div>
                    )}
                    {isNativeRestaking && (
                        <div
                            className={classNames(
                                'h-0 transition-all duration-300 ease-in-out overflow-hidden',
                                {
                                    'h-auto': isExpanded,
                                }
                            )}
                        >
                            <ExpandedNativeRestaking
                                formattedData={formattedData}
                                profileItem={profileItem}
                                timeframe={timeframe}
                                type={type}
                                assetRewardCalculatorContext={
                                    assetRewardCalculatorContext
                                }
                                isExpanded={isExpanded}
                            />
                        </div>
                    )}
                    {isDrop && rewardOption?.slug === 'drop' && (
                        <div
                            className={classNames(
                                'h-0 transition-all duration-300 ease-in-out overflow-hidden',
                                {
                                    'h-auto': isExpanded,
                                }
                            )}
                        >
                            <ExpandedDrop
                                operators={operators}
                                isExpanded={isExpanded}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )

    return isSmartContract ? (
        <SmartContractCardContextProvider
            rewardOption={item}
            tokenPrice={tokenPrice}
        >
            {mainContent}
        </SmartContractCardContextProvider>
    ) : (
        mainContent
    )
}
