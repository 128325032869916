import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setIsTableExpanded } from 'state'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { CUSTODIAL_PROVIDER_KEY, PageSizeChoices } from 'utils/constants'
import { DropdownSelect } from 'components/forms'
import {
    BUTTON_VARIANT_FG,
    ButtonWithBg,
    TableViewSelector,
} from 'components/ui'
import styles from './paginatedTableArchive.module.scss'

// const CtaHomePage = dynamic(
//     () =>
//         import('components/stakingAssistant/ctas/ctaHomePage').then(
//             module => module.CtaHomePage
//         ),
//     {
//         ssr: false,
//     }
// )

export const PaginatedTableArchive = ({
    children,
    withoutViewControl,
    title,
}) => {
    const dispatch = useDispatch()

    const {
        showTableConfig,
        selectedTableView,
        setSelectedTableView,
        paginationParams,
        setPaginationParams,
        hasMore,
        isEth,
        queryParams,
    } = useTableArchiveContext()

    const categoryKey = queryParams?.category?.key
    const isCustodial = categoryKey === CUSTODIAL_PROVIDER_KEY

    // Reset global expansion toggle on unmount
    useEffect(() => {
        return () => {
            dispatch(setIsTableExpanded(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const page = Number(paginationParams?.page ?? 0)

    return (
        <div className={styles.tableWithPagination}>
            {showTableConfig && (
                <div className={styles.paginationConfig}>
                    {withoutViewControl ? (
                        title
                    ) : (
                        <TableViewSelector
                            selectedTableView={selectedTableView}
                            onSelect={view => {
                                setSelectedTableView(view)
                            }}
                        />
                    )}
                    <div className={styles.pageSizeDropdownWrap}>
                        <p className={styles.label}>Items per Page</p>
                        <div className={styles.pageSizeDropdown}>
                            <DropdownSelect
                                choices={PageSizeChoices}
                                selected={paginationParams?.pageSize}
                                onSelect={newPageSize => {
                                    setPaginationParams({
                                        page: 0,
                                        pageSize: newPageSize,
                                    })
                                }}
                                small
                            />
                        </div>
                    </div>
                </div>
            )}
            {children}
            <div className='mt-6 flex flex-col gap-y-5'>
                <div className='flex flex-col md:flex-row gap-y-5 items-center justify-center w-full'>
                    {!isCustodial && hasMore && (
                        <ButtonWithBg
                            className='w-max px-10'
                            onClick={() => {
                                setPaginationParams({
                                    ...paginationParams,
                                    page: page + 1,
                                })
                            }}
                            variant={BUTTON_VARIANT_FG}
                        >
                            Load More
                        </ButtonWithBg>
                    )}
                </div>
                {/* {isEth && <CtaHomePage />} */}
            </div>
        </div>
    )
}
