import { THEME, useTheme } from 'state'
import styles from './brandIcon.module.scss'
import classNames from 'classnames'

export function BrandIcon({
    className = '',
    old = false,
}) {
    const theme = useTheme()

    return (
        <div className={`${styles.brandIcon} ${className}`} data-theme={theme}>
            {theme === THEME.DARK && <div className={styles.rainbow} />}
            <span
                className={classNames(
                    'max-w-[32px] max-h-[18px] sm:max-w-[54px] sm:max-h-[54px] icon',
                    old ? 'icon-brand-old' : 'icon-brand'
                )}
            />
        </div>
    )
}
