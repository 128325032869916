import { CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY } from 'utils/constants'

export const STAKED_TOKENS_METRIC_KEY = 'staked_tokens'
export const STAKING_WALLETS_METRIC_KEY = 'staking_wallets'

export const TOOLTIPS = {
    rewardRateRO: {
        title: 'Reward Rate',
        text: 'Use the Reward Rate to gauge your potential returns over the next year. This metric provides a theoretical annual percentage rate (APR) based on the current market metrics. Comparing Reward Rates between different reward options can help you optimize your investment strategy. However, please note that this is a theoretical rate, and actual returns can vary due to market fluctuations and changes in reward values.',
    },
    fee: {
        title: 'Fee',
        text: "The Fee metric represents the commission charged by the provider on your earnings. It's an important factor to consider when projecting your net returns. The Reward Rate displayed already accounts for this fee, meaning it reflects what you'll earn after the fee deduction. Be cautious of providers offering 0 or unusually low fees, as it could be a strategy to attract more volume and they may drastically increase fees once they have gained enough traction. Remember, higher fees don't always mean lower net returns, as they might be associated with higher-performing reward options.",
    },
    hostingFee: {
        title: 'Hosting Fee',
        text: '',
    },
    staked: {
        title: 'Staked Tokens',
        text: "Staked Tokens represent the total number of tokens currently staked or locked with this reward option. This metric can give you an insight into the level of trust other users have in this provider and its overall market traction. If you're seeking to promote decentralization, you could use this data to diversify your investment away from heavily staked providers. However, large quantities of staked tokens could also indicate a well-established and trusted provider. Keep in mind that the popularity of a provider doesn't guarantee their performance.",
    },
    networkControl: {
        title: 'Network Control',
        text: "Network Control shows what percentage of all staked tokens are delegated to this reward option. It offers a broader perspective of the provider's influence and popularity in the overall network. A high Network Control may indicate a trusted and established provider, but it may also limit decentralization. If you aim to promote decentralization, consider delegating your tokens to providers with lower Network Control. Remember, a high Network Control doesn't necessarily guarantee superior returns or stability.",
    },
    selfStaked: {
        title: 'Self staked',
        text: "Self Staked Tokens represents the amount a provider has directly staked on-chain. This metric can give you an idea of how much 'skin in the game' the provider has, indicating their level of commitment and confidence in their own service. However, it doesn't account for potential indirect stakes the provider may have, such as delegations from other wallets they control, or delegations they've made to other providers for diversification. Therefore, while a high value in Self Staked Tokens can show significant investment by the provider, a low value doesn't necessarily indicate a lack of investment or commitment, as they could be using other strategies.",
    },
    stakers: {
        title: 'Stakers',
        text: "Stakers represents the number of unique wallet addresses that are staking or delegating with this reward option. Similar to Staked Tokens and Network Control, it can provide insights into the provider's popularity and trust level. A high number of Stakers indicates a wider user base, which can be a positive signal. However, if you notice a high Staked Tokens value coupled with a low number of Stakers, this could suggest that a large portion of the stake is controlled by a few, potentially professional entities, like a centralized entity, a 'whale', or another protocol. This could signify a more professional operation, but it could also limit decentralization.",
    },
    netStakingFlow7d: {
        title: 'Net Staking Flow 7d',
        text: "Net Staking Flow 7d indicates the net change in staked tokens over the past 7 days, converted into USD using the current asset price. It's calculated by subtracting the number of unstaked tokens from the number of staked tokens. A positive value signifies an increase in staked tokens, suggesting growing interest in the reward option. Conversely, a negative value might indicate a decrease in confidence or interest. Since this metric is expressed in USD, it allows for easy comparison between different assets. This metric is especially useful for spotting short-term trends. Significant changes could be a signal to restake with a different provider, delegate to this provider, or completely unstake, depending on the magnitude and direction of the change. However, while it's a valuable tool for detecting shifts, it should be used in conjunction with longer-term metrics and other indicators for a comprehensive investment strategy.",
    },
    performance: {
        title: 'Performance',
        text: `Performance is a measure of the validator's uptime and reliability.`,
    },
}

export const REWARD_OPTIONS_METRIC_GROUPS = [
    {
        key: 'reward_rate',
        label: 'Reward Rate',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.rewardRateRO,
        withPercentage: false,
        width: '70px',
        showPlus: false,
        withColor: false,
    },
    {
        key: 'commission',
        label: 'Fee',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.fee,
        withPercentage: false,
        width: '60px',
        showPlus: false,
        withColor: false,
    },
    {
        key: 'hosting_fee',
        label: 'Hosting Fee',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.hostingFee,
        withPercentage: false,
        width: '90px',
        showPlus: false,
        withColor: false,
    },
    {
        key: 'performance',
        label: 'Performance',
        tooltipTextObj: TOOLTIPS.performance,
        withPercentage: false,
        width: '100px',
        showPlus: false,
        postfix: '%',
        withColor: false,
    },
    {
        key: 'staking_wallets',
        label: 'Stakers',
        postfix: '',
        tooltipTextObj: TOOLTIPS.stakers,
        withPercentage: true,
        width: '100px',
        showPlus: false,
        withColor: true,
    },
    {
        key: STAKED_TOKENS_METRIC_KEY,
        label: 'Staked Tokens',
        postfix: '',
        tooltipTextObj: TOOLTIPS.staked,
        withPercentage: true,
        width: '180px',
        showPlus: false,
        withColor: true,
    },
    {
        key: CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
        label: 'Net Staking Flow 7d',
        prefix: '$',
        tooltipTextObj: TOOLTIPS.netStakingFlow7d,
        withPercentage: false,
        width: '120px',
        showPlus: true,
        withColor: false,
    },
    {
        key: 'staking_share',
        label: 'Network Control',
        postfix: '%',
        tooltipTextObj: TOOLTIPS.networkControl,
        withPercentage: false,
        width: '100px',
        showPlus: false,
        withColor: false,
    },
    {
        key: 'self_staked_tokens',
        label: 'Self-Staked',
        postfix: '',
        tooltipTextObj: TOOLTIPS.selfStaked,
        withPercentage: true,
        width: '120px',
        showPlus: false,
        withColor: true,
    },
]

export const REWARD_OPTIONS_METRIC_GROUPS_AVS = [
    {
        key: 'assets_under_management',
        label: 'Restaked ETH ($)',
        prefix: '$',
        tooltipTextObj: null,
        withPercentage: true,
        width: '140px',
        showPlus: false,
        withColor: true,
    },
]

export const REWARD_OPTIONS_SORT_BY_CHOICES = REWARD_OPTIONS_METRIC_GROUPS.map(
    group => ({
        key: group.key,
        name: group.label,
        withPercentage: group.withPercentage,
    })
)

// For Asset RO
export const DEFAULT_REWARD_OPTIONS_SORT_BY =
    REWARD_OPTIONS_SORT_BY_CHOICES.find(
        choice => choice.key === STAKED_TOKENS_METRIC_KEY
    )

// For Provider RO
export const DEFAULT_REWARD_OPTIONS_FOR_PROVIDER_SORT_BY =
    REWARD_OPTIONS_SORT_BY_CHOICES.find(
        choice => choice.key === STAKING_WALLETS_METRIC_KEY
    )
