import { useEffect, useState } from 'react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { TYPE_ASSET } from 'utils/constants'
import { getSelectedChartSeriesConfig, seriesMapToChartData } from './utils'
import { usePerformanceChartMetrics } from './hooks'

import styles from './performanceOverTime.module.scss'
import { TabLoading } from 'components/ui/containerWithTabs'

// Recharts relies on the window object unavailable for SSR
const PerformanceChart = dynamic(
    () => import('./performanceChart').then(module => module.PerformanceChart),
    { ssr: false }
)

const CalendarModal = dynamic(
    () => import('components/calendar').then(module => module.CalendarModal),
    {
        ssr: false,
    }
)

const MetricsSelector = ({
    selectedMetrics = [],
    setSelectedMetrics = () => {},
    seriesConfig = [],
    isTablet = false,
    maxMetricsCount = 2,
}) => {
    return (
        <div className='flex flex-row items-center flex-wrap gap-x-5'>
            {seriesConfig?.map((metric, idx) => {
                const selected = selectedMetrics
                    ?.map(m => m?.key)
                    ?.includes(metric.key)
                const metricsCount = selectedMetrics?.length ?? 0
                const addingDisabled = metricsCount >= maxMetricsCount
                const removalDisabled = metricsCount <= 1
                return (
                    <div
                        key={`metric-${metric.key}-${idx}`}
                        className={styles.radioSelector}
                    >
                        <label
                            className={classNames(styles.checkbox, {
                                [styles.selected]: selected,
                                [styles.disabled]:
                                    (selected && isTablet && removalDisabled) ||
                                    (!selected && isTablet && addingDisabled),
                            })}
                        >
                            <input
                                type='checkbox'
                                checked={selected}
                                onChange={() => {
                                    if (!isTablet && !selected) {
                                        setSelectedMetrics([metric])
                                        return
                                    }

                                    if (
                                        isTablet &&
                                        selected &&
                                        !removalDisabled
                                    ) {
                                        setSelectedMetrics(
                                            prevMetrics =>
                                                prevMetrics?.filter(
                                                    m => m?.key !== metric?.key
                                                ) ?? []
                                        )
                                        return
                                    }

                                    if (
                                        isTablet &&
                                        !selected &&
                                        !addingDisabled
                                    ) {
                                        setSelectedMetrics(prevMetrics => [
                                            ...prevMetrics,
                                            metric,
                                        ])
                                    }
                                }}
                            />
                            <span
                                className={styles.checkmark}
                                style={{
                                    backgroundColor: selected
                                        ? metric?.color
                                        : 'var(--c-contrast-1)',
                                    border: `2px solid ${
                                        selected
                                            ? metric?.color
                                            : 'var(--c-contrast-3)'
                                    }`,
                                }}
                            />
                            <span className={styles.label}>{metric.name}</span>
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

export const PerformanceOverTime = ({
    id = null,
    type = TYPE_ASSET,
    seriesConfig = [],
    defaultMetricKeys = [],
    maxSelectedMetricsCount = 2,
    isTablet = false,
    initialDayCount = 30,
}) => {
    const [isDateModalOpen, setIsDateModalOpen] = useState(false)

    const [dateInterval, setDateInterval] = useState({
        startDate: new Date(
            new Date().getTime() - initialDayCount * 24 * 60 * 60 * 1000
        ),
        endDate: new Date(),
    })

    const { data: seriesMap, isLoading } = usePerformanceChartMetrics(
        id,
        type,
        dateInterval,
        seriesConfig
    )

    const [selectedMetrics, setSelectedMetrics] = useState(
        getSelectedChartSeriesConfig(seriesConfig, defaultMetricKeys, !isTablet)
    )

    // If a window is resized to a small one and several metrics are selected,
    // use only one of them.
    useEffect(() => {
        if (!isTablet) {
            setSelectedMetrics(
                selectedMetrics?.[0] ? [selectedMetrics?.[0]] : []
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTablet])

    const maxMetricsCount = Math.min(
        isTablet ? maxSelectedMetricsCount : 1,
        seriesConfig.length
    )

    const isDatepickerDisabled = isLoading

    if (isLoading) {
        return (
            <TabLoading
                messages={['Loading performance over time data...']}
                small={true}
            />
        )
    }

    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-row flex-wrap items-center justify-between gap-x-4 gap-y-2 mb-2'>
                <div className='flex flex-row items-center flex-wrap gap-x-4 gap-y-2'>
                    {maxMetricsCount > 1 && (
                        <div className='text-xs font-bold text-contrast-3'>
                            {`Compare up to ${maxMetricsCount} at a time:`}
                        </div>
                    )}
                    <div className='-top-[5px]'>
                        <MetricsSelector
                            selectedMetrics={selectedMetrics}
                            setSelectedMetrics={setSelectedMetrics}
                            seriesConfig={seriesConfig}
                            isTablet={isTablet}
                            maxMetricsCount={maxMetricsCount}
                        />
                    </div>
                </div>
                <div
                    onClick={() => {
                        if (isDatepickerDisabled) {
                            return
                        }
                        setIsDateModalOpen(true)
                    }}
                    className={classNames(
                        'flex rounded-full border border-solid border-contrast-2 text-contrast-4 font-bold text-[10px] px-4 py-1 select-none cursor-pointer hover:bg-contrast-2/20 transition-all ease-in-out',
                        {
                            'opacity-50 cursor-not-allowed hover:bg-transparent':
                                isDatepickerDisabled,
                        }
                    )}
                >
                    {`${dateInterval.startDate?.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })} - ${dateInterval.endDate?.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })}`}

                    <span className='icon icon-chevron-down !bg-contrast-3 !w-[5px] ml-2' />
                </div>
            </div>
            <CalendarModal
                isOpen={isDateModalOpen}
                startDate={dateInterval.startDate}
                endDate={dateInterval.endDate}
                onChange={(newStartDate, newEndDate) => {
                    setDateInterval({
                        startDate: newStartDate,
                        endDate: newEndDate,
                    })
                }}
                onClose={() => {
                    setIsDateModalOpen(false)
                }}
                minDate={new Date(2022, 0, 1)}
                maxDate={new Date()}
            />
            <PerformanceChart
                chartData={seriesMapToChartData(
                    seriesMap,
                    dateInterval.startDate,
                    dateInterval.endDate
                )}
                selectedMetrics={selectedMetrics}
            />
        </div>
    )
}
