import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { ALL_KEY } from 'utils/constants'
import { MetricGroup } from 'components/metrics'
import {
    MOMENTUM_COLUMN,
    REWARD_COLUMN,
    RISK_COLUMN,
    GENERAL_COLUMN,
    ROI_365D_COLUMN,
    getMoreAssetMetricGroups,
} from '../constants'
import { getAssetSortByOptionsPerCategory } from './utils'
import { NetStakingFlowGroup } from './netStakingFlowGroup'

import styles from './assetExpandedMetricsRow.module.scss'
import { isMetricWithExpansion } from 'utils/actions'

export const AssetExpandedMetricsRow = ({
    netStakingFlowItem = null,
    item = null,
    index = '',
    isLoading = false,
}) => {
    const { queryParams } = useTableArchiveContext()

    const sortByKey = queryParams?.sortBy?.key
    const timeframeKey = queryParams?.timeframe?.key

    return (
        <div className={styles.assetExpandedMetricsRow}>
            <div className={styles.column} style={{ '--grid-area': 'chart' }}>
                <NetStakingFlowGroup
                    netStakingFlowItem={netStakingFlowItem}
                    large
                    isLoading={isLoading}
                    width={'300px'}
                    height={'150px'}
                />
            </div>
            {[GENERAL_COLUMN, RISK_COLUMN, REWARD_COLUMN, MOMENTUM_COLUMN].map(
                column => (
                    <div
                        key={`col-${column}`}
                        className={styles.column}
                        style={{ '--grid-area': column.toLowerCase() }}
                    >
                        <div className={styles.name}>{column}</div>
                        {getMoreAssetMetricGroups(item?.slug)
                            .filter(
                                metricGroup => metricGroup.column === column
                            )
                            .map(metricGroup => (
                                <MetricGroup
                                    key={`${item?.id}-${
                                        metricGroup?.key ?? ''
                                    }-${index}`}
                                    metricGroup={metricGroup}
                                    item={item}
                                    timeframeKey={timeframeKey}
                                    isLoading={isLoading}
                                    highlighted={metricGroup?.key === sortByKey}
                                    hasExpansion={isMetricWithExpansion(metricGroup?.key)}
                                    isSortable={getAssetSortByOptionsPerCategory(
                                        ALL_KEY
                                    )
                                        .map(category => category.key)
                                        .includes(metricGroup?.key)}
                                />
                            ))}
                    </div>
                )
            )}
            <div className={styles.column} style={{ '--grid-area': 'roi365d' }}>
                <div className={styles.name}>{ROI_365D_COLUMN}</div>
                <div className={styles.roi}>
                    {getMoreAssetMetricGroups(item?.slug)
                        .filter(
                            metricGroup =>
                                metricGroup.column === ROI_365D_COLUMN
                        )
                        .map(metricGroup => (
                            <div
                                key={`${item?.id}-${metricGroup?.key ?? ''}`}
                                style={{ gridArea: metricGroup?.key }}
                            >
                                <MetricGroup
                                    metricGroup={metricGroup}
                                    item={item}
                                    timeframeKey={timeframeKey}
                                    isLoading={isLoading}
                                    highlighted={metricGroup?.key === sortByKey}
                                    hasExpansion={isMetricWithExpansion(metricGroup?.key)}
                                    isSortable={getAssetSortByOptionsPerCategory(
                                        ALL_KEY
                                    )
                                        .map(category => category.key)
                                        .includes(metricGroup?.key)}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}
