import classNames from 'classnames'
import { getObjectFromJsonString } from 'utils/actions'
import { formatOutputNumber } from 'utils/formatter'
import { getMetricObject } from 'utils/getMetricObject'

export const TextPerformanceChart = ({
    darker = false,
    name = '',
    symbol = '',
    data = null,
    parsedStakedTokensAbsolutes = null,
}) => {
    const asset = data?.assets?.[0]

    const price = getMetricObject(asset, 'price')

    const inflationRate = getMetricObject(asset, 'inflation_rate')

    const parsedPricePercentages =
        asset && getObjectFromJsonString(price?.changePercentages)

    const isParsedStakedTokensAbsolutes30dNegative =
        parsedStakedTokensAbsolutes?.['30d'] < 0


    const parsedInflationRateAbsolutes =
        asset && getObjectFromJsonString(inflationRate?.changeAbsolutes)

    const isInflationRateAbsolutes30dNegative =
        parsedInflationRateAbsolutes?.['30d'] < 0

    const isPricePercentage30dNegative =
        Number(parsedPricePercentages?.['30d']) < 0

    const formattedStakedTokensAbsolutes30d = formatOutputNumber(
        parsedStakedTokensAbsolutes?.['30d']
    )

    const formattedValueNetwork = formatOutputNumber(
        parsedStakedTokensAbsolutes?.['30d'] * price?.defaultValue
    )

    const text = `Over the past 30 days, there has been a net ${isParsedStakedTokensAbsolutes30dNegative ? 'decrease' : 'increase'} of ${formattedStakedTokensAbsolutes30d} ${symbol} staked on the ${name} network, worth $${formattedValueNetwork} at the current market rate. The value of ${symbol} has ${isPricePercentage30dNegative ? 'decreased' : 'increased'} over the same time period, with one ${symbol} currently priced at $${price?.defaultValue}. The inflation rate on the ${name} network is currently ${inflationRate?.defaultValue.toFixed(2)}%, which represents a ${parsedInflationRateAbsolutes?.['30d'].toFixed(2)}% ${isInflationRateAbsolutes30dNegative ? 'decrease' : 'increase'} over the same time frame.`

    return (
        <div
            className={classNames(
                'w-full bg-contrast-1 h-full pt-[48px] pb-[48px] pl-[24px] pr-[24px] rounded-b-lg border border-solid border-contrast-2/40 border-r border-l border-b s:border-t-1 border-t-0',
                { 'bg-opacity-50': !darker }
            )}
        >
            <h3 className='mb-[16px] text-lg font-bold'>{`${name} Staking is trending ${isParsedStakedTokensAbsolutes30dNegative ? 'downwards' : 'upwards'} this month`}</h3>

            <p className='text-[--c-mid] font-normal text-base'>{text}</p>
        </div>
    )
}
