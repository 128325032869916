import { useCustodialRewardOptionsData } from 'components/rewardOptions/archive/v2/hooks'
import { RewardOptionCard } from 'components/rewardOptions/archive/v2/rewardOptionCard'
import { RewardOptionRow } from 'components/rewardOptions/archive/v2/rewardOptionRow'
import { Skeleton, TableView } from 'components/ui'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { useEffect } from 'react'

export const CustodialRewardOptionRows = ({
    profileItem,
    profileType,
    totalCount,
}) => {
    const {
        selectedTableView,
        queryParams,
        setHasMore,
        paginationParams,
        pageSize,
    } = useTableArchiveContext()

    const sortByKey = queryParams?.sortBy?.key
    const timeframe = queryParams?.timeframe?.key
    const rewardOptionType = queryParams?.category?.key
    const isGridView = selectedTableView === TableView.Grid

    const { data, isLoading, noDataFound, isEnd } =
        useCustodialRewardOptionsData(profileItem)

    useEffect(() => {
        setHasMore(!isEnd)
    }, [isEnd, setHasMore])

    const page = Number(paginationParams?.page ?? 0)

    const rowsLeft = totalCount - (page + 1) * pageSize
    const loadingRowsCount = rowsLeft < 0 ? pageSize + rowsLeft : pageSize

    return (
        <div className='flex flex-col gap-4'>
            {data?.map((batch, batchIndex) => {
                const startIndex = batchIndex * pageSize
                return (
                    <div
                        key={`page-${batchIndex}`}
                        className='flex flex-col gap-4'
                    >
                        {batch?.map((item, index) => {
                            if (isGridView) {
                                return (
                                    <RewardOptionCard
                                        key={startIndex + index}
                                        index={startIndex + index + 1}
                                        item={item}
                                        type={rewardOptionType}
                                        profileItem={profileItem}
                                        profileType={profileType}
                                        timeframe={timeframe}
                                        sortByKey={sortByKey}
                                    />
                                )
                            }

                            return (
                                <RewardOptionRow
                                    key={startIndex + index}
                                    index={startIndex + index + 1}
                                    item={item}
                                    type={rewardOptionType}
                                    profileItem={profileItem}
                                    profileType={profileType}
                                    timeframe={timeframe}
                                    sortByKey={sortByKey}
                                />
                            )
                        })}
                    </div>
                )
            })}
            {isLoading &&
                Array.from({ length: loadingRowsCount }, (v, i) => i).map(
                    (_, idx) => (
                        <Skeleton
                            key={`item-${idx}`}
                            width='100%'
                            height={isGridView ? '300px' : '76px'}
                            borderRadius={8}
                        />
                    )
                )}
        </div>
    )
}
