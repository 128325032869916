import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { REFRESH_INTERVAL_ASSET_PRICE_MS, useProfileMetrics } from 'data'
import { LAVA_SLUG, TYPE_ASSET } from 'utils/constants'

import styles from './rewardOptionsSection.module.scss'
import { HeaderWithLink } from 'components/text/headerWithLink'

// Available types of the table are selected based on the window size.
const RewardOptionsTableArchiveSection = dynamic(
    () =>
        import('components/rewardOptions').then(
            module => module.RewardOptionsTableArchiveSection
        ),
    {
        ssr: false,
    }
)

export const RewardOptionsSection = ({
    asset = null,
    query = null,
    rewardOptionCategories = [],
    isAVS = false,
}) => {
    const { data: assetWithPrice } = useProfileMetrics(
        TYPE_ASSET,
        asset?.slug,
        ['price'],
        REFRESH_INTERVAL_ASSET_PRICE_MS
    )

    const isLava = asset?.slug === LAVA_SLUG

    const title = isAVS
        ? `Restake to ${asset?.name} with a Verified Provider`
        : `Find your ideal ${asset?.symbol}  Staking Provider in ${isLava ? '3' : '2'} Steps`

    const description = isAVS
        ? `Find the best provider to restake your ETH or LSTs with ${asset?.name}. Explore all operators and the breakdown of their restaked assets.`
        : `Find the best place to stake your ${asset?.symbol}. Sort providers by their reward rate, network control & more.`

    return (
        <div
            id={`section-stake-${asset?.symbol}`}
            className={styles.rewardOptionsSection}
        >
            <div className={classNames(styles.tableTopWrap, 'mb-2')}>
                <HeaderWithLink
                    title={title}
                    href={`/asset/${asset?.slug}#section-stake-${asset?.symbol}`}
                />
                {isAVS && <p className='text-description'>{description}</p>}
            </div>
            <RewardOptionsTableArchiveSection
                item={{
                    ...asset,
                    metrics: [
                        ...(asset?.metrics ?? []),
                        ...(assetWithPrice?.metrics ?? []),
                    ],
                }}
                type={TYPE_ASSET}
                existingRewardOptionCategories={rewardOptionCategories}
                query={query}
                isAVS={isAVS}
            />
        </div>
    )
}
