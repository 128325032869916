import { useState, createContext, useContext } from 'react'
import {
    useChainAndRewardAssets,
    useSmartContractRewardFormulaWithMetrics,
} from './hooks'
import { convertTime } from 'utils/converter'
import { getObjectFromJsonString } from 'utils/actions'

const SmartContractCardContext = createContext()

export const DEFAULT_STAKING_AMOUNT_USD = 10000

export const SmartContractCardContextProvider = ({
    rewardOption = null,
    tokenPrice = null,
    children,
}) => {
    const [stakingAmountUsd, setStakingAmountUsd] = useState('')

    const [stakingTimeInDays, setStakingTimeInDays] = useState(
        convertTime(1, 'years', 'days')
    )
    const {
        rewardFormulaWithMetrics,
        isLoading: isLoadingRewardFormulaWithMetrics,
    } = useSmartContractRewardFormulaWithMetrics(rewardOption)

    const rewardAssetsSlugs = Object.keys(
        getObjectFromJsonString(
            rewardOption?.metrics?.find(m => m?.metricKey === 'reward_rate')
                ?.variation
        )?.reward_distribution ?? {}
    )
    const {
        chainAsset,
        rewardAssets,
        isLoading: isLoadingRelatedAssets,
    } = useChainAndRewardAssets(
        rewardOption?.id,
        rewardOption?.chains?.[0]?.key, // TODO: support multiple chains when designed
        rewardAssetsSlugs
    )

    return (
        <SmartContractCardContext.Provider
            value={{
                rewardOption,
                stakingAmountUsd,
                setStakingAmountUsd,
                stakingTimeInDays,
                setStakingTimeInDays,
                rewardFormulaWithMetrics,
                isLoadingRewardFormulaWithMetrics,
                chainAsset,
                rewardAssets,
                isLoadingRelatedAssets,
                tokenPrice,
            }}
        >
            {children}
        </SmartContractCardContext.Provider>
    )
}

export const useSmartContractCardContext = () =>
    useContext(SmartContractCardContext)
