import { events, cosmos } from '@stakingrewards/staking-sdk'
import { getMetricValueByKey } from 'utils/actions'
import { TransactionStatus } from '../constants'
import { useQuery } from '@tanstack/react-query'

export const getCosmosTransactionFee = (transaction = null) => {
    if (!transaction) {
        return null
    }

    const estimatedFee = transaction?.estimatedFee?.walletFees?.DEFAULT ?? null
    const totalAmountInUUnits = Number(estimatedFee?.amount ?? 0)

    return estimatedFee?.denom === 'uatom'
        ? totalAmountInUUnits / 1000000 // convert to uUnits (e.g. uatom) to unit (e.g. atom)
        : totalAmountInUUnits
}

export const useGetAllCosmosBalances = (chainName, walletAddress = '') => {
    return useQuery({
        enabled: walletAddress?.length > 0,
        queryKey: ['getAllCosmosBalances', walletAddress, chainName],
        queryFn: async () => {
            const balances = await cosmos.CosmosClient.getAllBalances(
                chainName,
                walletAddress
            )
            return balances
        },
    })
}

export const getWeightedAverageRewardRate = (rewardOptions = []) => {
    if (rewardOptions?.length === 1)
        return getMetricValueByKey(rewardOptions?.[0], 'reward_rate') ?? 0

    const totalAmount = (rewardOptions ?? []).reduce(
        (acc, option) => acc + (option.amount ?? 0),
        0
    )

    if (Number(totalAmount) === 0) return 0

    return rewardOptions.reduce((acc, option) => {
        const rewardRate = getMetricValueByKey(option, 'reward_rate') ?? 0
        const amount = option.amount ?? 0
        return acc + (rewardRate * amount) / totalAmount
    }, 0)
}

// NB: might not be universal, find a better way
export const checkIfWrongWallet = (slug = '', walletAddress = '') => {
    if (!walletAddress || !slug) {
        return true
    }

    const prefixesMap = {
        cosmos: 'cosmos',
        axelar: 'axl',
        osmosis: 'osmo',
        celestia: 'celestia',
        dydx: 'dydx',
        lava: 'lava',
    }

    if (slug === 'ethereum-2-0') {
        const isCosmosEcosystemWallet = !!Object.values(prefixesMap).find(p =>
            String(walletAddress).startsWith(p)
        )
        return isCosmosEcosystemWallet
    }

    const prefix = prefixesMap[slug]
    return prefix ? !String(walletAddress).startsWith(prefix) : true
}

export const SUPPORTED_COSMOS_CHAINS = [
    'cosmoshub',
    // 'cosmoshubtestnet',
    'celestia',
    'dydx',
    'lava',
    'neutron',
    // 'neutrontestnet',
]

export const getCosmosChainName = (currentChainName = '') => {
    if (SUPPORTED_COSMOS_CHAINS.includes(currentChainName)) {
        return currentChainName
    }

    return 'cosmoshub'
}

export const getCosmosTransactionInformation = (status = '') => {
    const { TransactionEvents } = events

    const TransactionEventStepMap = {
        [TransactionEvents.PENDING]: {
            step1: {
                status: TransactionStatus.Pending,
                message: TransactionEvents.PENDING,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: { status: TransactionStatus.Pending, message: 'Staked' },
        },
        [TransactionEvents.WAITING_FOR_SIGNATURE]: {
            step1: {
                status: TransactionStatus.Loading,
                message: TransactionEvents.WAITING_FOR_SIGNATURE,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: { status: TransactionStatus.Pending, message: 'Staked' },
        },
        [TransactionEvents.BROADCASTING]: {
            step1: {
                status: TransactionStatus.Success,
                message: 'Transaction signed',
            },
            step2: {
                status: TransactionStatus.Loading,
                message: TransactionEvents.BROADCASTING,
            },
            step3: { status: TransactionStatus.Pending, message: 'Staked' },
        },
        [TransactionEvents.SUCCESS]: {
            step1: {
                status: TransactionStatus.Success,
                message: 'Transaction signed',
            },
            step2: {
                status: TransactionStatus.Success,
                message: 'Transaction confirmed',
            },
            step3: { status: TransactionStatus.Success, message: 'Staked' },
        },
    }

    return (
        TransactionEventStepMap?.[status] ?? {
            step1: {
                status: TransactionStatus.Pending,
                message: TransactionEvents.PENDING,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: {
                status: TransactionStatus.Failed,
                message: TransactionEvents.FAILED,
            },
        }
    )
}

export const getLavaRedelegationTransactionInformation = (status = '') => {
    const { TransactionEvents } = events

    const TransactionEventStepMap = {
        [TransactionEvents.PENDING]: {
            step1: {
                status: TransactionStatus.Pending,
                message: TransactionEvents.PENDING,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: {
                status: TransactionStatus.Pending,
                message: 'Redelegated',
            },
        },
        [TransactionEvents.WAITING_FOR_SIGNATURE]: {
            step1: {
                status: TransactionStatus.Loading,
                message: TransactionEvents.WAITING_FOR_SIGNATURE,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: {
                status: TransactionStatus.Pending,
                message: 'Redelegated',
            },
        },
        [TransactionEvents.BROADCASTING]: {
            step1: {
                status: TransactionStatus.Success,
                message: 'Transaction signed',
            },
            step2: {
                status: TransactionStatus.Loading,
                message: TransactionEvents.BROADCASTING,
            },
            step3: {
                status: TransactionStatus.Pending,
                message: 'Redelegated',
            },
        },
        [TransactionEvents.SUCCESS]: {
            step1: {
                status: TransactionStatus.Success,
                message: 'Transaction signed',
            },
            step2: {
                status: TransactionStatus.Success,
                message: 'Transaction confirmed',
            },
            step3: {
                status: TransactionStatus.Success,
                message: 'Redelegated',
            },
        },
    }

    return (
        TransactionEventStepMap?.[status] ?? {
            step1: {
                status: TransactionStatus.Pending,
                message: TransactionEvents.PENDING,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: {
                status: TransactionStatus.Failed,
                message: TransactionEvents.FAILED,
            },
        }
    )
}

export const getLavaUndelegationTransactionInformation = (status = '') => {
    const { TransactionEvents } = events

    const TransactionEventStepMap = {
        [TransactionEvents.PENDING]: {
            step1: {
                status: TransactionStatus.Pending,
                message: TransactionEvents.PENDING,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: {
                status: TransactionStatus.Pending,
                message: 'Undelegated',
            },
        },
        [TransactionEvents.WAITING_FOR_SIGNATURE]: {
            step1: {
                status: TransactionStatus.Loading,
                message: TransactionEvents.WAITING_FOR_SIGNATURE,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: {
                status: TransactionStatus.Pending,
                message: 'Undelegated',
            },
        },
        [TransactionEvents.BROADCASTING]: {
            step1: {
                status: TransactionStatus.Success,
                message: 'Transaction signed',
            },
            step2: {
                status: TransactionStatus.Loading,
                message: TransactionEvents.BROADCASTING,
            },
            step3: {
                status: TransactionStatus.Pending,
                message: 'Undelegated',
            },
        },
        [TransactionEvents.SUCCESS]: {
            step1: {
                status: TransactionStatus.Success,
                message: 'Transaction signed',
            },
            step2: {
                status: TransactionStatus.Success,
                message: 'Transaction confirmed',
            },
            step3: {
                status: TransactionStatus.Success,
                message: 'Undelegated',
            },
        },
    }

    return (
        TransactionEventStepMap?.[status] ?? {
            step1: {
                status: TransactionStatus.Pending,
                message: TransactionEvents.PENDING,
            },
            step2: {
                status: TransactionStatus.Pending,
                message: 'Process transaction',
            },
            step3: {
                status: TransactionStatus.Failed,
                message: TransactionEvents.FAILED,
            },
        }
    )
}
