import { memo, useMemo } from 'react'
import { SlotId, isTestAd, useAdSlot } from 'components/ads'
import styles from './nativeTextAd.module.scss'

const NativeTextAdComponent = ({ slug, id }) => {
    const divId = useMemo(
        () => id || `native-text-ad${isTestAd ? '-test' : ''}`,
        [id]
    )

    const targeting = {
        test: isTestAd,
        slug,
        component: 'default',
        type: 'asset',
    }

    useAdSlot({
        adId: divId,
        slotId: SlotId.NativeText,
        size: 'fluid',
        slug,
        targeting,
    })

    return (
        <div className={styles.container}>
            <div className={styles.ad}>
                <div id={divId}></div>
            </div>
        </div>
    )
}

export const NativeTextAd = memo(NativeTextAdComponent)
