import classNames from 'classnames'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import {
    Loader,
    LogoImage,
    ButtonWithBg,
    BUTTON_VARIANT_FG,
} from 'components/ui'
import { useInfiniteQuery } from '@tanstack/react-query'
import { fetchLavaValidators } from 'data/queries'
import { useRouter } from 'next/router'
import { getFormattedData } from '../getFormattedData'
import { formatRewardRate } from 'components/staking/utils'

export const ExpandedNativeRestaking = ({
    formattedData = null,
    className = '',
    isExpanded = false,
    profileItem = null,
    type = null,
    assetRewardCalculatorContext = null,
    timeframe = null,
    onSelectItem = () => {},
    selectedOption = null,
}) => {
    const PAGE_SIZE = 20
    const router = useRouter()

    const { isLoading, data, fetchNextPage, isFetching } = useInfiniteQuery({
        queryKey: ['fetchExpandedLavaValidators'],
        enabled: isExpanded,
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            const nextPage = lastPage?.length ? allPages?.length + 1 : undefined
            return nextPage
        },
        queryFn: async ({ pageParam = 0 }) => {
            const offset = pageParam * PAGE_SIZE
            const data = await fetchLavaValidators({
                offset, // offset for next page
                limit: PAGE_SIZE,
                metrics: [
                    'price',
                    'staking_share',
                    'performance',
                    'assets_under_management',
                    'staked_tokens',
                    'commission',
                ],
            })
            return data?.rewardOptions
        },
    })

    const validators = data?.pages?.flat() ?? []

    if (!isExpanded) return null
    if (isLoading) return <Loader />
    return (
        <div className='flex flex-col w-full duration-200 transition-all'>
            <div className='hidden lg:block bg-white dark:bg-contrast-1 lg:px-[42px] rounded-b-[8px] lg:py-4'>
                <span className='text-sm text-shade-darkest font-bold dark:text-white'>
                    Step #3: Select Validator to Pair
                </span>
            </div>
            <div
                className={classNames(
                    'flex flex-col  overflow-y-auto lg:rounded-b-lg lg:shadow-md h-full lg:gap-0 lg:!max-h-[240px] transition-all duration-300 operator-container'
                )}
            >
                <div className='flex flex-col gap-3 lg:gap-0 '>
                    {validators?.map((validator, index) => {
                        const validatorData = validator?.providers?.[0]
                        if (!validatorData) return null

                        const isSelected =
                            selectedOption?.id === validatorData?.id

                        const searchParams = new URLSearchParams({
                            input: 'lava',
                            type: 'dual-staking',
                            providers: validatorData?.slug,
                            'secondary-providers':
                                formattedData?.provider?.slug,
                        })?.toString()

                        const formattedValidatorData = getFormattedData(
                            validator,
                            profileItem,
                            timeframe,
                            type,
                            assetRewardCalculatorContext
                        )

                        const totalRewardRate = formatRewardRate(
                            formattedData?.rewardRateUnformatted +
                                formattedValidatorData?.rewardRateUnformatted
                        )

                        return (
                            <div
                                key={index}
                                onClick={() => onSelectItem?.(validatorData)}
                                className={classNames(
                                    'grid grid-cols-2 gap-4 rounded-lg lg:rounded-none lg:flex lg:items-center lg:justify-between px-3 py-2 lg:px-[42px] lg:py-3 border-t border-solid border-shade-light/20 duration-200',
                                    className,
                                    isSelected ? 'bg-white' : 'bg-contrast-0'
                                )}
                            >
                                <div className='flex items-center gap-2 w-auto lg:gap-4'>
                                    <div
                                        className={classNames(
                                            '!w-4 !h-4 lg:!w-[42px] lg:!h-[12px]',
                                            {
                                                'icon icon-vsp lg:icon-vsp-extended icon-og':
                                                    validatorData?.isVerified,
                                            }
                                        )}
                                    ></div>
                                    <div className='w-[6px] h-[6px] min-w-[6px] min-h-[6px] rounded-full activeMarker'></div>
                                    <div className='flex items-center gap-3 w-[145px]'>
                                        <LogoImage
                                            src={validatorData?.logoUrl}
                                            slug={validatorData?.slug}
                                            size={24}
                                        />
                                        <div className='flex flex-col'>
                                            <span className='font-bold text-[11px] text-[#303030] dark:text-white'>
                                                {validatorData?.name}
                                            </span>
                                            <span className='font-bold text-text text-[11px]'>
                                                Validator
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='hidden lg:flex w-2/12 min-w-[110px] justify-center'>
                                    <MetricBox
                                        title='Reward Rate'
                                        value={
                                            formattedValidatorData?.rewardRate
                                        }
                                        isSmall
                                    />
                                </div>
                                <div className='flex w-2/12 min-w-[89px] justify-center'>
                                    <MetricBox
                                        title='AuM'
                                        value={formattedValidatorData?.aum}
                                        changeClassName='text-green'
                                        change={
                                            formattedData.stakedTokensChangePercent
                                        }
                                        isSmall
                                    />
                                </div>
                                <div className='hidden xl:flex w-1/12'>
                                    <MetricBox
                                        title='Fee'
                                        value={formattedValidatorData?.fee}
                                        isSmall
                                    />
                                </div>
                                {formattedValidatorData?.performance && (
                                    <div className='flex w-2/12'>
                                        <MetricBox
                                            title='Performance'
                                            value={
                                                formattedValidatorData.performance
                                            }
                                            isSmall
                                        />
                                    </div>
                                )}
                                <div className='hidden lg:flex w-2/12'>
                                    <MetricBox
                                        title='Network Control'
                                        value={
                                            formattedValidatorData?.networkControl
                                        }
                                        isSmall
                                    />
                                </div>
                                <div className='flex w-2/12 min-w-[145px] justify-center'>
                                    <MetricBox
                                        title='Final Reward Rate'
                                        value={totalRewardRate}
                                        className='rounded-lg bg-gradient-sr-light/10 px-3 py-2'
                                        isSmall
                                    />
                                </div>
                                <button
                                    onClick={() => {
                                        router.push(
                                            `/stake-app?${searchParams}`
                                        )
                                    }}
                                    className='hidden max-h-[40px] lg:grid grid-cols-[1fr_18px] items-center gap-2 min-w-[125px] bg-shade-darker hover:bg-shade-darker/80 rounded-lg py-2 px-3 duration-200'
                                >
                                    <span className='text-white text-sm font-semibold'>
                                        Stake with
                                    </span>
                                    <div className='icon icon-wallet !bg-white'></div>
                                </button>
                            </div>
                        )
                    })}
                </div>
                <div className='flex justify-center py-2'>
                    <ButtonWithBg
                        className='mx-auto w-full !max-w-[200px]'
                        disabled={isFetching}
                        onClick={fetchNextPage}
                        variant={BUTTON_VARIANT_FG}
                    >
                        {isFetching ? 'Loading...' : 'Load More'}
                    </ButtonWithBg>
                </div>
            </div>
        </div>
    )
}
