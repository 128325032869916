import classNames from 'classnames'

export const CARD_BACKGROUND_VARIANT = {
    default: 'default',
    error: 'error',
}

const BACKGROUND_CLASS = {
    default: 'bg-[#F7F7F7] dark:bg-shade-darkest',
    error: 'bg-[#CE414E]/10',
}

const TEXT_CLASS = {
    default: 'text-[#4A4A4A] dark:text-contrast-3',
    error: 'text-[#CE414E]',
}

const ICON_CLASS = {
    default: 'dark:!bg-contrast-3',
    error: '!bg-[#CE414E]',
}

export const Card = ({
    customBackground = '',
    iconClass = '',
    children = null,
    variant = CARD_BACKGROUND_VARIANT.default,
    className = '',
    borderRadius = '',
}) => {
    const backgroundClass = customBackground
        ? customBackground
        : BACKGROUND_CLASS?.[variant] ?? BACKGROUND_CLASS.default
    const textClass = TEXT_CLASS?.[variant] ?? TEXT_CLASS.default
    const iconExtraClass = ICON_CLASS?.[variant] ?? ''

    return (
        <div
            className={classNames(
                'grid items-center gap-2 p-6',
                backgroundClass,
                borderRadius ? borderRadius : 'rounded-lg',
                iconClass?.length > 0
                    ? 'grid-cols-[18px_1fr]'
                    : 'grid-cols-[1fr]',
                className
            )}
        >
            {iconClass?.length > 0 && (
                <div
                    className={classNames(
                        'icon w-[18px] h-[18px]',
                        iconClass,
                        iconExtraClass
                    )}
                />
            )}
            <span
                className={classNames(
                    'text-base font-medium',
                    textClass
                )}
            >
                {children}
            </span>
        </div>
    )
}

Card.displayName = 'Card'
