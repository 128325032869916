import { StakingModalContext } from 'contexts/stakingModalContext'
import { createContext, useContext, useEffect } from 'react'
import { WalletState } from 'state/stakingPortfolio/walletState'
import { LoginType } from 'utils/stakingAssistant/constants'

export const CustomAddressContext = createContext({})

// This is used to read address from window object on client side and put it into the state
export const CustomAddressContextProvider = ({ children }) => {
    useEffect(() => {
        if (!window.localStorage.getItem('address')) {
            return
        }
        WalletState.currentWallet.value = {
            address: window.localStorage.getItem('address'),
            icon: null,
        }
        WalletState.connectionType.value = LoginType.ADDRESS
    }, [])

    return (
        <CustomAddressContext.Provider value={{}}>
            {children}
        </CustomAddressContext.Provider>
    )
}

export const useStakingModalContext = () => {
    return useContext(StakingModalContext)
}
