import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { fetchAssetsBySearchQuery } from 'data'
import { TYPE_ASSET } from 'utils/constants'
import { GroupSize, ItemWithLogo, ToggleButton } from 'components/ui'

import styles from './assetFilter.module.scss'

export const AssetFilter = ({
    searchTerms = '',
    onSelect = () => {},
    withSeparator = false,
    selectedAsset = null,
}) => {
    const [assetResults, setAssetResults] = useState(null)

    useEffect(() => {
        fetchAssetsBySearchQuery(searchTerms)
            .then(data => {
                const fetchedAssets = data?.assets ?? []
                const assets =
                    !selectedAsset ||
                    fetchedAssets.find(
                        asset => asset?.slug === selectedAsset?.slug
                    )
                        ? fetchedAssets
                        : [selectedAsset, ...fetchedAssets]
                setAssetResults(assets.slice(0, 9))
            })
            .catch(() => {
                setAssetResults(selectedAsset ? [selectedAsset] : [])
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerms])

    if (!assetResults?.length) {
        return null
    }

    return (
        <div
            className={classNames(styles.assetFilter, {
                [styles.withSeparator]: withSeparator,
            })}
        >
            <p className={styles.label}>Assets</p>
            <div className={styles.stackedItems}>
                {assetResults?.map((item, idx) => {
                    const isSelected = selectedAsset?.slug === item?.slug
                    return (
                        <div
                            key={`list-${TYPE_ASSET}-${item?.slug}-${idx}`}
                            className={classNames(styles.asset, {
                                [styles.selected]: isSelected,
                            })}
                            title={item?.name}
                            onClick={() => {
                                onSelect(isSelected ? null : item)
                            }}
                        >
                            <ItemWithLogo
                                iconUrl={item?.logoUrl || item?.logo_url}
                                name={''}
                                details={item?.symbol}
                                alt={item?.symbol}
                                slug={item?.slug}
                                iconSize={16}
                                sizeType={GroupSize.Small}
                                withDetails={true}
                            />
                            <div className={styles.removeBtn}>
                                {isSelected && <ToggleButton expanded={true} />}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
