import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { fetchAssetsBySlugs } from 'data'
import { calculateIfValid } from 'utils/actions'
import { formatOutputNumber, isInvalidNumber } from 'utils/formatter'
import { useAssetRewardCalculatorContext } from 'contexts/assetRewardCalculatorContext'
import { Modal } from 'components/modal'
import { Loader, LogoImage, TooltipOnHover } from 'components/ui'
import { RewardRatePart } from './rewardRatePart'

const ASSETS_PREVIEW_COUNT = 2

export const useRewardDistributionAssets = (
    rewardBreakdown = {},
    limit = 3
) => {
    const [rewardAssets, setRewardAssets] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchData = async (rewardAssetsSlugs = []) => {
            setIsLoading(true)
            try {
                const result = await fetchAssetsBySlugs(
                    [...rewardAssetsSlugs].filter(Boolean)
                )
                const sortedAssets =
                    result?.assets?.toSorted((a, b) => {
                        return (
                            rewardBreakdown?.[b.slug] -
                            rewardBreakdown?.[a.slug]
                        )
                    }) ?? []

                setRewardAssets(sortedAssets)
            } catch (e) {
                setError(e)
            } finally {
                setIsLoading(false)
            }
        }

        const rewardAssetsSlugs = Object.keys(rewardBreakdown)
            .toSorted(
                (a, b) => rewardBreakdown?.[b.slug] - rewardBreakdown?.[a.slug]
            )
            ?.slice(0, limit)
        if (rewardAssetsSlugs?.length) {
            fetchData(rewardAssetsSlugs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { rewardAssets, isLoading, error }
}

const RewardDistributionRow = ({
    asset = null,
    percent = 0,
    wider = false,
}) => {
    const isSmallScreen = useMediaPredicate('(max-width: 359px)')
    const formattedPercent = formatOutputNumber(percent, {
        prefix: Number(percent?.toFixed(2)) === 0 ? '>' : '',
        postfix: '%',
        precision: 2,
    })
    return (
        <TooltipOnHover
            text={`${formattedPercent} of your rewards get paid out in ${asset?.symbol}`}
            className='w-full'
        >
            <div className='flex flex-row justify-between items-center py-1'>
                <div className='flex flex-row items-center'>
                    <LogoImage
                        src={asset?.logoUrl}
                        alt={asset?.slug}
                        slug={asset?.slug}
                        size={24}
                    />
                    <div
                        className={classNames(
                            'ml-2 font-bold text-sm flex items-center',
                            !isSmallScreen && wider ? 'w-20' : 'w-16'
                        )}
                    >
                        {asset?.symbol}
                    </div>
                </div>
                <div
                    className={classNames(
                        'flex items-center h-full w-full',
                        wider
                            ? 'min-w-[64px] sm:min-w-[128px]'
                            : 'min-w-[64px]',
                        { '!hidden': isSmallScreen }
                    )}
                >
                    <div className='w-full bg-contrast-0 rounded-full h-1.5 -top-[1px]'>
                        <div
                            className='bg-primary h-1.5 rounded-full'
                            style={{
                                width: `${percent}%`,
                            }}
                        />
                    </div>
                </div>
                <div className='ml-4 text-xs flex items-center'>
                    {formattedPercent}
                </div>
            </div>
        </TooltipOnHover>
    )
}

const RewardDistributionRows = ({
    rewardBreakdown = {},
    limit = 3,
    onShowMore = () => {},
    isModal = false,
}) => {
    const { rewardAssets, isLoading } = useRewardDistributionAssets(
        rewardBreakdown,
        limit + 1
    )
    const totalReward = Object.values(rewardBreakdown).reduce(
        (acc, curr) => acc + curr,
        0
    )
    const hasMore = rewardAssets?.length > limit

    if (isLoading) {
        return <Loader centered />
    }

    return (
        <div className='flex flex-col gap-2'>
            {rewardAssets?.slice(0, limit)?.map((a, idx) => {
                const percent = calculateIfValid(
                    ({ rewardFraction, totalReward }) => {
                        return (rewardFraction / totalReward) * 100
                    },
                    {
                        rewardFraction: rewardBreakdown?.[a?.slug],
                        totalReward,
                    }
                )

                if (isInvalidNumber(percent)) {
                    return
                }

                return (
                    <RewardDistributionRow
                        key={`${a.slug}-${idx}`}
                        asset={a}
                        percent={percent}
                        wider={isModal}
                    />
                )
            })}
            {hasMore && (
                <div
                    className={
                        'p-1 text-center bg-contrast-0/80 cursor-pointer rounded-lg hover:opacity-80 text-primary text-xs'
                    }
                    onClick={() => {
                        onShowMore?.()
                    }}
                >
                    Show all
                </div>
            )}
        </div>
    )
}

const RewardDistributionModal = ({
    rewardBreakdown = {},
    onClose = () => {},
}) => {
    const { asset } = useAssetRewardCalculatorContext()

    return (
        <Modal className={`!pl-8 !pr-12 !pb-6 !pt-8`} isOpen onClose={onClose}>
            <div
                className={
                    'pt-3 flex flex-col gap-4 rounded-b-lg sm:min-w-[250px] min-h-[400px]'
                }
            >
                <h3 className='text-[18px] font-bold'>{`${asset?.symbol} Reward Rate Breakdown`}</h3>
                <p className='text-[14px] mb-3'>
                    See how rewards are paid out for {asset?.symbol}.
                </p>
                <RewardDistributionRows
                    rewardBreakdown={rewardBreakdown}
                    limit={Object.values(rewardBreakdown).length}
                    isModal={true}
                />
            </div>
        </Modal>
    )
}

export const RewardDistribution = ({ rewardBreakdown = {} }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div className='flex flex-col rounded-lg border border-solid border-contrast-2/40 w-full sm:min-w-[250px] mb-4 sm:mb-0'>
            <div className='bg-contrast-0 p-4 rounded-t-lg'>
                <RewardRatePart withLstRates={false} />
            </div>
            <div
                className={
                    'border-t border-solid border-contrast-2/40 p-4 flex flex-col gap-4 rounded-b-lg'
                }
            >
                {isExpanded && (
                    <RewardDistributionModal
                        rewardBreakdown={rewardBreakdown}
                        onClose={() => {
                            setIsExpanded(false)
                        }}
                    />
                )}
                <div className='flex flex-col gap-4'>
                    <RewardDistributionRows
                        rewardBreakdown={rewardBreakdown}
                        limit={ASSETS_PREVIEW_COUNT}
                        onShowMore={() => {
                            setIsExpanded(true)
                        }}
                        isModal={false}
                    />
                </div>
            </div>
        </div>
    )
}
