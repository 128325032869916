import { CUSTODIAL_PROVIDER_KEY, LIQUID_STAKING_KEY } from 'utils/constants'

export const SWING_STATUS_MESSAGES = {
    allowance: {
        PENDING: 'Creating a transaction for the allowance step',
        SUCCESS: 'Allowance step successfully completed',
        FAILED: 'Allowance step failed',
        IDLE: 'Allowance',
    },
    approve: {
        PENDING: 'Creating a transaction for the approve step',
        SUCCESS: 'Approve step successfully completed',
        FAILED: 'Approve step failed',
        IDLE: 'Approval',
    },
    send: {
        PENDING: 'Sending...',
        SUCCESS: 'Transaction signed',
        CONFIRMING: 'Confirming transaction',
        ACTION_REQUIRED: 'Confirm transaction in the wallet',
        CHAIN_SWITCH_REQUIRED: 'Chain switch required',
        WALLET_CONNECTION_REQUIRED: 'Wallet connection required',
        FAILED: 'Transaction not signed',
        IDLE: 'Transaction pending',
    },
    bridge: {
        PENDING: 'Transaction pending',
        SUCCESS: 'Transaction completed',
        CONFIRMING: 'Processing transaction',
        FAILED: 'Transaction failed',
        IDLE: 'Processing transaction',
    },
}

export const ETHEREUM_STAKING_TYPE_OPTIONS = [
    {
        key: LIQUID_STAKING_KEY,
        label: 'Liquid Staking',
        isDisabled: false,
        iconClassName: 'icon-drop',
        tagLabel: 'Extra yield',
        description:
            'Liquid staking is the process of depositing your tokens to a liquid staking protocol. In exchange, you receive liquid staking tokens (LSTs), which are receipt tokens that represent your staked amount.',
    },
    {
        key: CUSTODIAL_PROVIDER_KEY,
        label: 'Custodial Staking',
        isDisabled: true,
        iconClassName: 'icon-vault',
        tagLabel: 'Most convenient',
        description:
            'Custodial staking is where you delegate your tokens to a third-party custodian to handle the staking process on your behalf. This service is usually offered by centralized exchanges (CEXs) or web3 wallet providers.',
    },
]
