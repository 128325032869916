import { useEffect, useMemo, useState } from 'react'
import { fetchAssetsBySlugs, fetchFormulaMetrics } from 'data'
import { calculateIfValid } from 'utils/actions'
import { evaluateFormula, getMetricsFromFormula } from 'components/calculator'
import {
    DEFAULT_STAKING_AMOUNT_USD,
    useSmartContractCardContext,
} from './smartContractCardContext'

export const useChainAndRewardAssets = (
    rewardOptionId = '',
    chainAssetSlug = '',
    rewardAssetsSlugs = []
) => {
    const [chainAsset, setChainAsset] = useState(null)
    const [rewardAssets, setRewardAssets] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const result = await fetchAssetsBySlugs(
                    [chainAssetSlug, ...rewardAssetsSlugs].filter(Boolean)
                )
                setChainAsset(
                    result?.assets?.find(a => a?.slug === chainAssetSlug)
                )
                setRewardAssets(
                    result?.assets?.filter(a =>
                        rewardAssetsSlugs?.includes(a?.slug)
                    )
                )
            } catch (e) {
                setError(e)
            } finally {
                setIsLoading(false)
            }
        }

        if (chainAssetSlug || rewardAssetsSlugs?.length) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewardOptionId])

    return { chainAsset, rewardAssets, isLoading, error }
}

export function useSmartContractRewardFormulaWithMetrics(option = null) {
    const [isLoading, setIsLoading] = useState(true)
    const [rewardFormulaWithMetrics, setRewardFormulaWithMetrics] = useState({
        formula: null,
        metrics: [],
    })

    useEffect(() => {
        async function updateRewardOptionFormulaData() {
            setIsLoading(true)
            let formula
            let metrics
            if (option?.rewardFormula) {
                // Set metrics from option?.rewardFormula
                const allMetricKeys = getMetricsFromFormula(
                    option?.rewardFormula
                )
                const metricKeys = allMetricKeys?.filter(
                    key =>
                        !['user_price', 'user_input', 'user_days'].includes(key)
                )
                formula = option?.rewardFormula ?? null
                if (metricKeys?.length > 0) {
                    const response = await fetchFormulaMetrics(
                        option?.id,
                        metricKeys
                    )
                    metrics = response?.rewardOptions?.[0]?.metrics ?? []
                } else {
                    metrics = []
                }
            } else {
                formula = null
                metrics = []
            }
            setRewardFormulaWithMetrics({
                formula,
                metrics,
            })
            setIsLoading(false)
        }

        setRewardFormulaWithMetrics({
            formula: null,
            metrics: [],
        })

        if (option) {
            updateRewardOptionFormulaData(option)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option?.id])

    return { rewardFormulaWithMetrics, isLoading }
}

export const useRewardRate = stakingTimeInDays => {
    const {
        rewardFormulaWithMetrics,
        isLoadingRewardFormulaWithMetrics,
        stakingAmountUsd,
        tokenPrice,
    } = useSmartContractCardContext()

    return useMemo(() => {
        if (
            isLoadingRewardFormulaWithMetrics ||
            !rewardFormulaWithMetrics?.formula
        ) {
            return null
        }

        const metricsScope = rewardFormulaWithMetrics?.metrics?.reduce(
            (acc, { metricKey, defaultValue }) => {
                acc[metricKey] = defaultValue
                return acc
            },
            {}
        )

        return (
            evaluateFormula(rewardFormulaWithMetrics?.formula, {
                ...metricsScope,
                user_days: stakingTimeInDays,
                // user_input is in tokens
                user_input: calculateIfValid(
                    ({ stakingAmountUsd, tokenPrice }) =>
                        stakingAmountUsd / tokenPrice,
                    {
                        stakingAmountUsd:
                            stakingAmountUsd || DEFAULT_STAKING_AMOUNT_USD,
                        tokenPrice,
                    }
                ),
                user_price: tokenPrice,
            }) * 100
        )
    }, [
        isLoadingRewardFormulaWithMetrics,
        rewardFormulaWithMetrics?.formula,
        rewardFormulaWithMetrics?.metrics,
        stakingTimeInDays,
        stakingAmountUsd,
        tokenPrice,
    ])
}
