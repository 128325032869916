import { useCalculatorContext } from 'components/calculator/calculatorContext'
import Chart from 'components/calculator/chart'
import { DEFAULT_STAKING_TIME_OBJECT } from 'components/calculator/constants'
import {
    getChartYAxisDomain,
    getSeriesToShow,
    getTimeObjectOrDefault,
    toChartData,
} from 'components/calculator/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { stakingResults } from 'state/assetProfile'
import { SOLO_STAKING_KEY } from 'utils/constants'
import { convertTime, timeObjectToDays } from 'utils/converter'
import { timeObjectToString } from 'utils/formatter'

export const CalculatorChart = () => {
    const {
        asset,
        stakingTime,
        setStakingTime,
        expectedPrice,
        setExpectedPrice,
        initialTokenPrice,
        expectedTokenPrice,
        stakingAmountTokens,
        usd,
        option,
        validator,
        rewardFormulaWithMetrics,
        isReady,
    } = useCalculatorContext()

    const [graphs, setGraphs] = useState(undefined)

    const stakingTimeOrDefault = useMemo(
        () => getTimeObjectOrDefault(stakingTime, DEFAULT_STAKING_TIME_OBJECT),
        [stakingTime]
    )

    const stakingTimeInDays = useMemo(
        () => timeObjectToDays(stakingTimeOrDefault),
        [stakingTimeOrDefault]
    )

    const graphTimeSpanInYears = useMemo(() => {
        return stakingTimeInDays < convertTime(3, 'years', 'days') ? 3 : 10
    }, [stakingTimeInDays])

    const workerRef = useRef(null)
    useEffect(() => {
        workerRef.current = new Worker(
            new URL('./calculations/graphWorker.js', import.meta.url),
            { type: 'module' }
        )
        workerRef.current.onmessage = event => {
            setGraphs(event?.data)
            const nonCompoundedValues = event?.data?.totalUsdValues
            stakingResults.value = {
                '1d': nonCompoundedValues[1],
                '7d': nonCompoundedValues[7],
                '30d': nonCompoundedValues[30],
                '90d': nonCompoundedValues[90],
                '365d': nonCompoundedValues[365],
            }
        }
        return () => {
            workerRef.current?.terminate()
        }
    }, [])

    useEffect(() => {
        async function sendMessageToWorker() {
            workerRef.current?.postMessage({
                rewardFormulaWithMetrics,
                stakingAmountTokens,
                usd,
                initialTokenPrice,
                validator,
                option,
                expectedTokenPrice,
                stakingTimeInDays,
                graphTimeSpanInYears,
            })
        }

        if (isReady) {
            sendMessageToWorker()
        }
    }, [
        stakingAmountTokens,
        usd,
        initialTokenPrice,
        validator,
        option,
        expectedTokenPrice,
        stakingTimeInDays,
        graphTimeSpanInYears,
        rewardFormulaWithMetrics,
        isReady,
    ])

    const seriesToShow = useMemo(
        () =>
            getSeriesToShow(
                option?.type?.key === SOLO_STAKING_KEY,
                expectedPrice !== '',
                usd
            ),
        [expectedPrice, option?.type?.key, usd]
    )

    return (
        <div className='p-6 pl-2 h-full'>
            {graphs && option && (
                <Chart
                    data={toChartData(graphs)}
                    domainY={getChartYAxisDomain(graphs, seriesToShow)}
                    asset={asset}
                    yearsSpan={graphTimeSpanInYears}
                    usd={usd}
                    stakingTimeReference={{
                        x: stakingTimeInDays,
                        label: timeObjectToString(
                            stakingTimeOrDefault,
                            false,
                            false
                        ),
                    }}
                    seriesToShow={seriesToShow}
                    legendConfig={{
                        top: 0,
                        right: 0,
                        className: 'flex gap-4 -top-[68px]',
                    }}
                />
            )}
        </div>
    )
}
