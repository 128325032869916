import {
    NATIVE_RESTAKING_KEY,
    LIQUID_STAKING_KEY,
    POS_PROVIDER_KEY,
} from 'utils/constants'

export const LAVA_STAKING_TYPE_OPTIONS = [
    {
        key: NATIVE_RESTAKING_KEY,
        label: 'Native Restaking',
        isDisabled: false,
        iconClassName: 'icon-dual-staking',
        tagLabel: 'Extra yield',
        description:
            'Native Restaking staking involves staking tokens in two ways: providers stake to run RPC nodes and handle data requests, while validators stake to secure the blockchain and validate transactions.',
    },
    {
        key: POS_PROVIDER_KEY,
        label: 'Native Staking',
        isDisabled: false,
        iconClassName: 'icon-hand',
        tagLabel: 'Full Rewards',
        description:
            'Enables you to earn a percentage of block rewards, by staking. Delegate your tokens with confidence by choosing a trustworthy staking provider from our list of Verified Staking Providers.',
    },
    {
        key: LIQUID_STAKING_KEY,
        label: 'Liquid Staking',
        isDisabled: true,
        iconClassName: 'icon-drop',
        description:
            'Liquid staking is the process of depositing your tokens to a liquid staking protocol. In exchange, you receive liquid staking tokens (LSTs), which are receipt tokens that represent your staked amount.',
    },
]
