import { signal } from '@preact/signals-react'

const targetAsset = signal()

const DEFAULT_STAKING_TIME = 365
const stakingTime = signal(DEFAULT_STAKING_TIME)

const expectedRewardRate = signal()

const stakingResults = signal({
    '1d': 0,
    '7d': 0,
    '30d': 0,
    '90d': 0,
    '365d': 0,
})

export { targetAsset, stakingTime, stakingResults, expectedRewardRate }
