import { useState } from 'react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { TYPE_ASSET } from 'utils/constants'
import { TabLoading } from 'components/ui/containerWithTabs'
import { seriesMapToPegDeviationData } from './utils'
import { usePerformanceChartMetrics } from './hooks'

// Recharts relies on the window object unavailable for SSR
const PegDeviationChart = dynamic(
    () =>
        import('./pegDeviationChart').then(module => module.PegDeviationChart),
    { ssr: false }
)

const CalendarModal = dynamic(
    () => import('components/calendar').then(module => module.CalendarModal),
    {
        ssr: false,
    }
)

export const PegDeviationOverTime = ({
    id = null,
    type = TYPE_ASSET,
    seriesConfig = [],
    initialDayCount = 30,
}) => {
    const [isDateModalOpen, setIsDateModalOpen] = useState(false)

    const [dateInterval, setDateInterval] = useState({
        startDate: new Date(
            new Date().getTime() - initialDayCount * 24 * 60 * 60 * 1000
        ),
        endDate: new Date(),
    })

    const { data: seriesMap, isLoading } = usePerformanceChartMetrics(
        id,
        type,
        dateInterval,
        seriesConfig
    )

    const isDatepickerDisabled = isLoading

    if (isLoading) {
        return (
            <TabLoading
                messages={['Loading peg deviation data...']}
                small={true}
            />
        )
    }

    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-row items-center justify-end gap-x-4 gap-y-2 mb-2'>
                <div
                    onClick={() => {
                        if (isDatepickerDisabled) {
                            return
                        }
                        setIsDateModalOpen(true)
                    }}
                    className={classNames(
                        'flex rounded-full border border-solid border-contrast-2 text-contrast-4 font-bold text-[10px] px-4 py-1 select-none cursor-pointer hover:bg-contrast-2/20 transition-all ease-in-out',
                        {
                            'opacity-50 cursor-not-allowed hover:bg-transparent':
                                isDatepickerDisabled,
                        }
                    )}
                >
                    {`${dateInterval.startDate?.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })} - ${dateInterval.endDate?.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })}`}

                    <span className='icon icon-chevron-down !bg-contrast-3 !w-[5px] ml-2' />
                </div>
            </div>
            <CalendarModal
                isOpen={isDateModalOpen}
                startDate={dateInterval.startDate}
                endDate={dateInterval.endDate}
                onChange={(newStartDate, newEndDate) => {
                    setDateInterval({
                        startDate: newStartDate,
                        endDate: newEndDate,
                    })
                }}
                onClose={() => {
                    setIsDateModalOpen(false)
                }}
                minDate={new Date(2022, 0, 1)}
                maxDate={new Date()}
            />
            <PegDeviationChart
                chartData={seriesMapToPegDeviationData(
                    seriesMap,
                    dateInterval.startDate,
                    dateInterval.endDate
                )}
                metric={seriesConfig?.[0]}
            />
        </div>
    )
}
