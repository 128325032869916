import {
    calculateIfValid,
    getDaysBetweenDates,
    getMetricValueByKey,
} from 'utils/actions'
import {
    formatOutputNumber,
    getPrecisionBySignificantFigures,
    isInvalidNumber,
} from 'utils/formatter'

export const seriesMapToChartData = (
    seriesMap = {},
    startDate = new Date(),
    endDate = new Date()
) => {
    // Map existing in the data dates and values
    let seriesDateMap = {}
    for (const [seriesName, seriesData] of Object.entries(seriesMap)) {
        seriesData?.forEach(data => {
            if (data?.createdAt) {
                const date = String(data.createdAt).split('T')[0]
                if (
                    !seriesDateMap[date]?.[seriesName] ||
                    new Date(data.createdAt) >
                        new Date(seriesDateMap[date][`${seriesName}Time`])
                ) {
                    if (!seriesDateMap[date]) {
                        seriesDateMap[date] = {}
                    }
                    seriesDateMap[date][seriesName] = getMetricValueByKey(
                        {
                            metrics: [
                                {
                                    metricKey: seriesName,
                                    defaultValue: data.defaultValue,
                                },
                            ],
                        },
                        seriesName
                    )
                    seriesDateMap[date][`${seriesName}Time`] = data.createdAt
                }
            }
        })
    }

    let resultMap = {}

    const numberOfDays = getDaysBetweenDates(startDate, endDate) + 1

    let currentDate = new Date(endDate)
    for (let i = 0; i < numberOfDays; i++) {
        const date = currentDate.toISOString().split('T')[0]
        const formattedDate = currentDate.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
        })
        const entry = { day: i + 1, date: formattedDate }
        Object.keys(seriesMap).forEach(seriesName => {
            entry[seriesName] = seriesDateMap[date]?.[seriesName] ?? null
        })
        resultMap[date] = entry
        currentDate.setDate(currentDate.getDate() - 1)
    }

    const chartData = Object.values(resultMap)

    // Add normalizedValues to plot on the same y axis
    let minMaxValuesMap = {}
    Object.keys(seriesMap).forEach(mKey => {
        if (mKey) {
            const tsValues =
                chartData
                    ?.map(dataPoint => dataPoint?.[mKey])
                    ?.filter(dataPoint => dataPoint != null) ?? []
            const minValue = Math.min(...tsValues)
            const maxValue = Math.max(...tsValues)
            minMaxValuesMap[mKey] =
                minValue === maxValue
                    ? { min: minValue * 0.9, max: maxValue * 1.1 }
                    : { min: minValue, max: maxValue }
        }
    })
    const chartDataWithNormalized =
        chartData?.map(dataPoint => {
            const normalizedValues = Object.keys(minMaxValuesMap).map(mKey => {
                const metricValue = dataPoint?.[mKey]
                const minValue = minMaxValuesMap[mKey]?.min
                const maxValue = minMaxValuesMap[mKey]?.max
                const normalized = calculateIfValid(
                    ({ metricValue, minValue, maxValue }) =>
                        (metricValue - minValue) / (maxValue - minValue),
                    {
                        metricValue,
                        minValue,
                        maxValue,
                    }
                )
                return { [`normalized_${mKey}`]: normalized }
            })
            const normalizedValuesObject = normalizedValues.reduce(
                (acc, obj) => ({ ...acc, ...obj }),
                {}
            )
            return { ...dataPoint, ...normalizedValuesObject }
        }) ?? []

    return chartDataWithNormalized
}

export const getSelectedChartSeriesConfig = (
    metricsConfig = [],
    defaultMetricKeys = [],
    isMobile = false
) => {
    // If it's mobile, only use the first metric key. Otherwise, use all of them.
    const activeMetricKeys = isMobile
        ? [defaultMetricKeys[0]]
        : defaultMetricKeys

    const selectedMetricsConfig = activeMetricKeys
        .map(metricKey => metricsConfig?.find(m => m?.key === metricKey))
        .filter(Boolean)

    // If there are any remaining spots in our array (based on the length of activeMetricKeys),
    // fill them with configurations from metricsConfig that aren't already in selectedMetricsConfig.
    const remainingSlots =
        activeMetricKeys.length - selectedMetricsConfig.length
    if (remainingSlots > 0) {
        const additionalMetrics = metricsConfig
            ?.filter(m => !selectedMetricsConfig.includes(m))
            .slice(0, remainingSlots)

        return [...selectedMetricsConfig, ...additionalMetrics]
    }

    return selectedMetricsConfig
}

export const toNetStakingFlowChartData = metrics => {
    const metricsNumber = metrics?.length ?? 0
    return metrics
        ?.sort((a, b) => b?.daysDiff - a?.daysDiff)
        ?.map((m, idx) => {
            const value =
                getMetricValueByKey(
                    { metrics: [m?.metric] },
                    'net_staking_flow',
                    '24h'
                ) ?? 0
            const precision = getPrecisionBySignificantFigures(value, 3)
            return {
                daysDiff: metricsNumber - idx - 1,
                label: formatOutputNumber(value, {
                    allowEmpty: false,
                    withAbbreviation: true,
                    precision,
                    forcePrecision: false,
                    showApproximation: false,
                    prefix: '',
                    spaceBeforeNumber: false,
                    showPlus: true,
                }),
                value: value,
                fill: value > 0 ? 'var(--c-green)' : 'var(--c-red)',
            }
        })
}

export const getPegDeviationColor = metricValue => {
    if (metricValue < 98) {
        return 'red'
    } else if (metricValue < 99.5) {
        return 'orange'
    } else if (metricValue < 102) {
        return 'green'
    } else if (metricValue < 104) {
        return 'orange'
    }
    return 'red'
}

const addThresholdPoints = (prevValue, currValue) => {
    // Ensure prevValue is always less than or equal to currValue
    let prev = prevValue
    let curr = currValue
    if (prevValue > currValue) {
        prev = Math.min(prevValue, currValue)
        curr = Math.max(prevValue, currValue)
    }

    const thresholds = [98, 99.5, 102, 104]
    let result = []

    // Only add threshold values that are between prevValue and currValue
    for (const threshold of thresholds) {
        if (prev < threshold && threshold < curr) {
            result.push(threshold)
        }
    }

    return result
}

const fillWithLinearInterpolation = data => {
    let result = data.map(dp => ({ ...dp, isInterpolated: false }))

    const getY = dpObj => {
        return dpObj?.peg_deviation
    }

    const getX = dpObj => {
        return dpObj?.day
    }

    const interpolate = (startIndex, endIndex) => {
        const startValue = getY(result[startIndex])
        const endValue = getY(result[endIndex])
        const step =
            (endValue - startValue) /
            (getX(result[endIndex]) - getX(result[startIndex]))

        for (let i = startIndex + 1; i < endIndex; i++) {
            result[i] = {
                ...result[i],
                peg_deviation:
                    startValue +
                    step * (getX(result[i]) - getX(result[startIndex])),
                isInterpolated: true,
            }
        }
    }
    let startIndex = null
    for (let i = 0; i < result.length; i++) {
        if (getY(result[i]) !== null) {
            if (startIndex !== null) {
                interpolate(startIndex, i)
            }
            startIndex = i
        }
    }
    return result
}

export const seriesMapToPegDeviationData = (
    seriesMap = {},
    startDate = new Date(),
    endDate = new Date()
) => {
    const seriesName = 'peg_deviation'
    // Map existing in the data dates and values
    let seriesDateMap = {}
    seriesMap[seriesName]?.forEach(data => {
        if (data?.createdAt) {
            const date = String(data.createdAt).split('T')[0]
            if (
                !seriesDateMap[date]?.[seriesName] ||
                new Date(data.createdAt) >
                    new Date(seriesDateMap[date][`${seriesName}Time`])
            ) {
                if (!seriesDateMap[date]) {
                    seriesDateMap[date] = {}
                }
                seriesDateMap[date][seriesName] = getMetricValueByKey(
                    {
                        metrics: [
                            {
                                metricKey: seriesName,
                                defaultValue: data.defaultValue,
                            },
                        ],
                    },
                    seriesName
                )
                seriesDateMap[date][`${seriesName}Time`] = data.createdAt
            }
        }
    })

    let resultMap = {}
    const numberOfDays = getDaysBetweenDates(startDate, endDate) + 1
    let currentDate = new Date(endDate)
    for (let i = 0; i < numberOfDays; i++) {
        const date = currentDate.toISOString().split('T')[0]
        const formattedDate = currentDate.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
        })
        const entry = { day: numberOfDays - i, date: formattedDate }
        Object.keys(seriesMap).forEach(seriesName => {
            entry[seriesName] = seriesDateMap[date]?.[seriesName] ?? null
        })
        resultMap[date] = entry
        currentDate.setDate(currentDate.getDate() - 1)
    }

    const chartData = fillWithLinearInterpolation(
        Object.values(resultMap).reverse()
    )

    const chartDataWithThresholds =
        chartData?.map((dataPoint, idx) => {
            const metricValue = dataPoint?.[seriesName]
            const prevValue = idx < 1 ? null : chartData[idx - 1]?.[seriesName]
            const thresholdValues = isInvalidNumber(prevValue)
                ? []
                : addThresholdPoints(prevValue, metricValue)
            return { ...dataPoint, thresholdValues }
        }) ?? []

    return chartDataWithThresholds
}

const flattenWithThresholds = (chartData = []) => {
    const fullList = []
    for (let i = 0; i < chartData.length; i++) {
        if (i > 0) {
            const dp = chartData[i]
            if (dp.thresholdValues.length > 0) {
                const prevDay = chartData[i - 1].day
                const prevValue = chartData[i - 1].peg_deviation
                const currValue = chartData[i].peg_deviation
                const thresholds =
                    prevValue <= currValue
                        ? dp.thresholdValues
                        : [...dp.thresholdValues].reverse()
                thresholds.forEach(threshold => {
                    const xIncrement = Math.abs(
                        (threshold - prevValue) / (currValue - prevValue)
                    )
                    fullList.push({
                        day: prevDay + xIncrement,
                        date: null,
                        peg_deviation: threshold,
                        color: getPegDeviationColor(threshold),
                        isInterpolated: true,
                    })
                })
            }
        }
        fullList.push({
            day: chartData[i].day,
            date: chartData[i].date,
            peg_deviation: chartData[i].peg_deviation,
            color: getPegDeviationColor(chartData[i].peg_deviation),
            isInterpolated: chartData[i].isInterpolated,
        })
    }
    return fullList
}

export const preparePegDeviationData = (chartData = []) => {
    const dataWithThresholds = flattenWithThresholds(chartData)

    let coloredDataset = [...dataWithThresholds]
    for (let i = 1; i < dataWithThresholds.length; i++) {
        const prevDataPoint = dataWithThresholds[i - 1]
        const currentDataPoint = dataWithThresholds[i]

        const colorForComparison =
            prevDataPoint.peg_deviation < currentDataPoint.peg_deviation
                ? prevDataPoint.color
                : currentDataPoint.color

        coloredDataset[i - 1][colorForComparison] = prevDataPoint.peg_deviation
        coloredDataset[i][colorForComparison] = currentDataPoint.peg_deviation
    }

    return coloredDataset
}
