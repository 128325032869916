import classNames from 'classnames'
import { AssetRewardCalculatorInputsForTable } from 'components/assets/profile/info/assetRewardCalculatorInputs'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { TooltipOnHover } from 'components/ui'

export const MetricsSolo = ({ formattedData, sortByKey }) => {
    return (
        <div className='flex flex-1 justify-start'>
            <div className=''>
                <TooltipOnHover
                    text={<AssetRewardCalculatorInputsForTable />}
                    textClassName='!w-[300px]'
                >
                    <MetricBox
                        title={formattedData?.rewardsAfterTitle}
                        value={formattedData?.rewardsAfterValue}
                    />
                </TooltipOnHover>
            </div>
            <div className=''>
                <MetricBox
                    title='Hosting Fees'
                    value={formattedData?.hostingFee}
                    className={classNames({
                        ['bg-contrast-1 -m-2 p-2 rounded-lg']:
                            sortByKey === 'hosting_fee',
                    })}
                />
            </div>
        </div>
    )
}
