import classNames from 'classnames'
import { AssetRewardCalculatorInputsForTable } from 'components/assets/profile/info/assetRewardCalculatorInputs'
import { MetricBox } from 'components/rewardOptions/archive/v2/metricBox'
import { useSmartContractCardContext } from 'components/rewardOptions/smartContract/smartContractCardContext'
import { Link, LogoImage, TooltipOnHover } from 'components/ui'
import { useTimeElapsed } from 'hooks'
import { THEME, useTheme } from 'state'
import { calculateIfValid, getObjectFromJsonString } from 'utils/actions'
import {
    MAX_TOOLTIP_TEXT_LENGTH,
    formatOutputNumber,
    getPrecisionBySignificantFigures,
    truncateString,
} from 'utils/formatter'

export const MetricsSmartContract = ({ formattedData, sortByKey }) => {
    const theme = useTheme()
    const { rewardOption, rewardAssets } = useSmartContractCardContext()
    const stakedTokensUpdatedAt = useTimeElapsed(
        formattedData.stakedTokensCreatedAt
    )

    const inputAssetsBreakdown = getObjectFromJsonString(
        rewardOption?.inputAssetsBreakdown
    )
    const sortedInputAssetsKeyValuePairs = Object.entries(
        inputAssetsBreakdown ?? {}
    )
        .map(([key, value]) => ({ key, value }))
        .sort((a, b) => b.value - a.value)

    const rewardRateMetric = rewardOption?.metrics?.find(
        metric => metric?.metricKey === 'reward_rate'
    )
    const rewardRateVariation = Object.entries(
        getObjectFromJsonString(rewardRateMetric?.variation)
            ?.reward_distribution ?? {}
    )
        .map(([slug, value]) => {
            const asset = rewardAssets?.find(
                a => String(a.slug).toLowerCase() === slug
            )
            return {
                slug: asset?.slug,
                logoUrl: asset?.logoUrl,
                symbol: asset?.symbol,
                rewardRate: calculateIfValid(({ fraction }) => fraction * 100, {
                    fraction: value,
                }),
            }
        })
        .sort((a, b) => b.rewardRate - a.rewardRate)

    const stakeIcons = (
        <div className='flex'>
            {rewardOption?.inputAssets?.map((asset, index) => {
                return (
                    <Link
                        key={index}
                        href={`/asset/${asset?.slug}`}
                        blank
                        className={classNames('flex justify-center', {
                            '-ml-1': index > 0,
                        })}
                    >
                        <LogoImage
                            slug={asset?.slug}
                            src={asset?.logoUrl}
                            alt={`${asset?.name} logo`}
                            size={20}
                        />
                    </Link>
                )
            })}
        </div>
    )

    const outputAsset = rewardOption?.outputAssets?.[0]
    const receiptToken = outputAsset ? (
        <div className='flex'>
            <Link
                href={`/asset/${outputAsset?.slug}`}
                blank
                className={classNames('flex justify-center')}
            >
                <LogoImage
                    slug={outputAsset?.slug}
                    src={outputAsset?.logoUrl}
                    alt={`${outputAsset?.name} logo`}
                    size={20}
                />
            </Link>
        </div>
    ) : null

    const rewardRateMetricBox = (
        <div className='h-full'>
            <MetricBox
                title='Reward Rate'
                value={formattedData.rewardRate}
                className='rounded-lg bg-gradient-sr-light/10 h-full min-w-[101px] flex-1 max-w-[120px]'
            />
            <div
                className={classNames(
                    'flex absolute rounded-full w-6 h-6 items-center justify-center -right-[12px] top-[26px]',
                    {
                        'bg-white': theme === THEME.LIGHT,
                        'bg-contrast-1': theme === THEME.DARK,
                    }
                )}
            >
                <div className='flex w-full h-full bg-gradient-sr-light/10 rounded-full items-center justify-center'>
                    <span
                        className={classNames(
                            'icon icon-arrow-right !w-[10px] !h-[8px]',
                            {
                                '!bg-black': theme === THEME.LIGHT,
                                '!bg-white': theme === THEME.DARK,
                            }
                        )}
                    />
                </div>
            </div>
        </div>
    )
    return (
        <>
            <div className='flex h-full min-w-[101px] flex-1 max-w-[120px] mr-4'>
                {rewardRateVariation?.length > 0 ? (
                    <TooltipOnHover
                        text={
                            <RewardBreakdownTooltip
                                title='Rewards'
                                data={rewardRateVariation}
                                formattedData={formattedData}
                            />
                        }
                        textClassName='!w-[280px]'
                    >
                        {rewardRateMetricBox}
                    </TooltipOnHover>
                ) : (
                    rewardRateMetricBox
                )}
            </div>
            <div className='flex flex-1 justify-around'>
                <div className=''>
                    <TooltipOnHover
                        text={<AssetRewardCalculatorInputsForTable />}
                        textClassName='!w-[300px]'
                    >
                        <MetricBox
                            title={formattedData?.rewardsAfterTitle}
                            value={formattedData?.rewardsAfterValue}
                        />
                    </TooltipOnHover>
                </div>
                <div className='text-xs text-contrast-6 font-bold w-[112px] flex items-center'>
                    {formattedData?.item?.label}
                </div>
                <div className='flex'>
                    {sortedInputAssetsKeyValuePairs?.length > 0 ? (
                        <TooltipOnHover
                            text={
                                <AssetBreakdownTooltip
                                    title='Deposit Assets'
                                    data={sortedInputAssetsKeyValuePairs}
                                    rewardOption={rewardOption}
                                />
                            }
                            textClassName='!w-[248px]'
                        >
                            <MetricBox title='Stake' value={stakeIcons} />
                        </TooltipOnHover>
                    ) : (
                        <MetricBox title='Stake' value={stakeIcons} />
                    )}
                </div>
                <div className='flex items-center'>
                    <TooltipOnHover
                        text={
                            <MetricBox
                                title='Staked Tokens'
                                value={formattedData.stakedTokens}
                                change={formattedData.stakedTokensChangePercent}
                            />
                        }
                        updatedTimeStatus={stakedTokensUpdatedAt}
                    >
                        <MetricBox
                            title='TVL'
                            value={formattedData.aum}
                            change={formattedData.stakedTokensChangePercent}
                            className={classNames({
                                ['bg-contrast-0 -m-2 p-2 rounded-lg']:
                                    sortByKey === 'staked_tokens',
                            })}
                        />
                    </TooltipOnHover>
                </div>
                <div className='flex'>
                    <TooltipOnHover
                        text={truncateString(
                            outputAsset?.description,
                            MAX_TOOLTIP_TEXT_LENGTH
                        )}
                        overlay={
                            String(outputAsset?.description).length >
                            MAX_TOOLTIP_TEXT_LENGTH
                                ? {
                                      title:
                                          outputAsset?.symbol ||
                                          'Receipt Token',
                                      allText: outputAsset?.description,
                                  }
                                : null
                        }
                    >
                        <MetricBox title='Receipt Token' value={receiptToken} />
                    </TooltipOnHover>
                </div>
                {/* <div className='flex'>
                    <MetricBox
                        title='Earn up to'
                        value={''} // TODO: Add value
                    />
                </div> */}
            </div>
        </>
    )
}

const AssetBreakdownTooltip = ({ title, data, rewardOption }) => {
    const isFlexible = data?.filter(item => item?.value === 1)?.length > 1
    return (
        <div>
            <div className='text-[10px] font-bold text-contrast-6 mb-4 mt-2'>
                {title}
                {isFlexible && (
                    <div className='text-[10px] text-contrast-3'>
                        *Any of these assets
                    </div>
                )}
            </div>
            {data?.map((item, index) => {
                const inputAsset = rewardOption?.inputAssets?.find(
                    a => a.slug === item?.key
                )
                return (
                    <div
                        key={index}
                        className='flex items-center mb-2 justify-between'
                    >
                        <div className='flex items-center gap-1 w-[60px]'>
                            <LogoImage
                                slug={inputAsset?.slug}
                                src={inputAsset?.logoUrl}
                                alt={`${inputAsset?.slug} logo`}
                                size={24}
                            />
                            <p className='text-xs font-bold text-contrast-6'>
                                {inputAsset?.symbol}
                            </p>
                        </div>
                        {!isFlexible && (
                            <>
                                <div className='w-[80px] bg-contrast-0 rounded-full h-1.5 -top-[1px]'>
                                    <div
                                        className='bg-primary h-1.5 rounded-full'
                                        style={{
                                            width: `${item?.rewardRate * 100}%`,
                                        }}
                                    />
                                </div>
                                <div className='text-primary font-bold text-[10px]'>
                                    {item?.value * 100}%
                                </div>
                            </>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

const RewardBreakdownTooltip = ({ title, formattedData, data }) => {
    return (
        <div>
            <div className='flex justify-between items-center'>
                <div className='text-[10px] font-bold text-contrast-6 mb-4 mt-2'>
                    {title}
                </div>
                <div className='font-bold text-[10px] text-contrast-2'>
                    {formattedData?.rewardRate} p.a.
                </div>
            </div>
            {data?.map((item, index) => {
                return (
                    <div
                        key={index}
                        className='flex items-center mb-2 justify-between'
                    >
                        <div className='flex items-center gap-1 w-[60px]'>
                            <LogoImage
                                slug={item?.slug}
                                src={item?.logoUrl}
                                alt={`${item?.name} logo`}
                                size={24}
                            />
                            <p className='text-xs font-bold text-contrast-6'>
                                {item?.symbol}
                            </p>
                        </div>
                        <div className='w-[80px] bg-contrast-0 rounded-full h-1.5 -top-[1px]'>
                            <div
                                className='bg-primary h-1.5 rounded-full'
                                style={{
                                    width: `${(item?.rewardRate / formattedData?.rewardRateUnformatted) * 100}%`,
                                }}
                            />
                        </div>
                        <div className='text-primary font-bold text-[10px] w-[70px]'>
                            {`${formatOutputNumber(item?.rewardRate, {
                                postfix: '%',
                                precision: Math.max(
                                    getPrecisionBySignificantFigures(
                                        item?.rewardRate,
                                        3
                                    ),
                                    2
                                ),
                                allowEmpty: false,
                                withAbbreviation: false,
                                forcePrecision: false,
                                showApproximation: false,
                            })} p.a.`}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
