import { useState } from 'react'
import {
    REFRESH_INTERVAL_ASSET_PRICE_MS,
    REFRESH_INTERVAL_MS,
    useProfileMetricsWithMinMax,
} from 'data'
import { getMetricValueByKey, isMetricWithExpansion } from 'utils/actions'
import {
    ALL_KEY,
    DEFAULT_ASSET_TIMEFRAME,
    POS_ASSET_CATEGORY,
    TIMEFRAME_CHOICES,
    TYPE_ASSET,
} from 'utils/constants'
import { DropdownSelect } from 'components/forms'
import { LogoImage } from 'components/ui'
import { ErrorBoundary } from 'components/utils'
import { ContainerWithTabs, TabLoading } from 'components/ui/containerWithTabs'
import {
    ProfileMetric,
    ProfileMetricWithoutExpansion,
} from 'components/metrics/profileMetric2'
import {
    GENERAL_COLUMN,
    MOMENTUM_COLUMN,
    REWARD_COLUMN,
    RISK_COLUMN,
} from '../constants'
import { getAssetSortByOptionsPerCategory } from '../archive/utils'
import { getAssetMetricGroupsPerTab } from './utils'
import { HeaderWithLink } from 'components/text/headerWithLink'
import { TextAnalyseStakingData } from './lst/textAnalyzeStakingData'
import { useRouter } from 'next/router'

export const Metrics = ({ asset = null }) => {
    const [timeframe, setTimeframe] = useState(DEFAULT_ASSET_TIMEFRAME)
    const router = useRouter()
    const currentPath = router.pathname
    const isAssetPage = currentPath.startsWith('/asset')

    const [selectedTab, setSelectedTab] = useState()
    const isPoS = asset?.tags?.find(t => t.tagKey === POS_ASSET_CATEGORY.key)

    return (
        <div id='section-analyze-staking-data'>
            <div className='flex flex-row items-center justify-between gap-x-6 gap-y-2 flex-wrap mb-4'>
                <div className='flex flex-col'>
                    <div className='flex flex-row items-center gap-x-2 mb-2'>
                        <LogoImage
                            src={asset?.logoUrl}
                            alt={asset?.name}
                            size={20}
                        />
                        <p className='font-bold text-contrast-3 text-sm'>
                            {asset?.name}
                        </p>
                    </div>
                    <HeaderWithLink
                        title={`Analyze ${asset?.symbol} Staking Data`}
                        href={`/asset/${asset?.slug}#section-analyze-staking-data`}
                    />
                    <p className='text-description'>{`Compare the market position of ${asset?.symbol} against other staking assets.`}</p>
                </div>
                {selectedTab?.key !== MOMENTUM_COLUMN.toLowerCase() && (
                    <DropdownSelect
                        className='z-10 w-[124px]'
                        choices={TIMEFRAME_CHOICES}
                        selected={timeframe}
                        onSelect={setTimeframe}
                        small
                        renderSelected={selected => (
                            <div className='flex flex-row w-full justify-between items-center gap-x-2 py-1 cursor-pointer'>
                                <span className='icon icon-clock min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] !bg-contrast-4' />
                                <div className='flex flex-col w-full space-y-1 text-left'>
                                    <p className='text-contrast-3 font-bold text-xs leading-none whitespace-nowrap'>
                                        Change %
                                    </p>
                                    <div className='text-contrast-4 font-bold text-xs leading-none overflow-auto whitespace-normal truncate'>
                                        {selected?.name}
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                )}
            </div>
            <ErrorBoundary
                fallback={
                    <p>
                        Something went wrong while loading performance
                        profile...
                    </p>
                }
            >
                <ContainerWithTabs
                    className='md:overflow-visible'
                    tabs={[
                        GENERAL_COLUMN,
                        RISK_COLUMN,
                        REWARD_COLUMN,
                        MOMENTUM_COLUMN,
                    ].map(tab => {
                        const metricGroups = getAssetMetricGroupsPerTab(
                            tab,
                            asset?.slug
                        )
                        const hasPrice = metricGroups.find(metricGroup =>
                            [
                                'price',
                                'price_roi_365d',
                                'total_roi_365d',
                            ].includes(metricGroup.key)
                        )
                        const TabComponent = hasPrice
                            ? PerformanceProfileTabWithPrice
                            : PerformanceProfileTab

                        return {
                            key: tab.toLowerCase(),
                            name: tab,
                            event: '',
                            content: (
                                <TabComponent
                                    slug={asset?.slug}
                                    timeframeKey={timeframe?.key}
                                    metricGroups={getAssetMetricGroupsPerTab(
                                        tab,
                                        asset?.slug
                                    )}
                                    asset={asset}
                                    title={tab}
                                />
                            ),
                        }
                    })}
                    isLoading={false}
                    onChange={setSelectedTab}
                    messages={['Loading performance metrics...']}
                    large={true}
                    height='100%'
                    isPoS={isPoS}
                />
            </ErrorBoundary>
            {isPoS && isAssetPage && <TextAnalyseStakingData asset={asset} />}
        </div>
    )
}

const PerformanceProfileTabWithPrice = ({
    slug = '',
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    metricGroups = [],
    asset = null,
    title = '',
}) => {
    const { data: priceMetricData, isLoading: isLoadingPrice } =
        useProfileMetricsWithMinMax(
            TYPE_ASSET,
            slug,
            ['price'],
            REFRESH_INTERVAL_ASSET_PRICE_MS
        )

    const metricsToFetch = [
        ...new Set(
            metricGroups
                .map(metricGroup =>
                    // if customMetricKeys is defined, use it instead of the key
                    metricGroup.customMetricKeys?.length
                        ? metricGroup.customMetricKeys
                        : metricGroup.key
                )
                .flat()
                .filter(Boolean)
        ),
    ].filter(key => key !== 'price')

    const { data: metricsData, isLoading: isLoadingProfileMetrics } =
        useProfileMetricsWithMinMax(
            TYPE_ASSET,
            slug,
            metricsToFetch,
            REFRESH_INTERVAL_MS
        )

    const allMetricsData = { ...priceMetricData, ...metricsData }
    const filteredMetricGroups =
        metricGroups?.filter(metricGroup => {
            if (metricGroup.customMetricKeys?.length) {
                return metricGroup.customMetricKeys.every(
                    metricKey =>
                        !!getMetricValueByKey(
                            allMetricsData?.[`current_${metricKey}`]?.[0],
                            metricKey
                        )
                )
            }

            return !!getMetricValueByKey(
                allMetricsData?.[`current_${metricGroup?.key}`]?.[0],
                metricGroup?.key,
                metricGroup?.timeframeKey
            )
        }) ?? []

    return (
        <Tab
            isLoading={isLoadingPrice || isLoadingProfileMetrics}
            metricGroups={filteredMetricGroups}
            asset={asset}
            timeframeKey={timeframeKey}
            metricsData={allMetricsData}
            title={title}
        />
    )
}

const PerformanceProfileTab = ({
    slug = '',
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    metricGroups = [],
    asset = null,
    title = '',
}) => {
    const metricsToFetch = [
        ...new Set(
            metricGroups
                .map(metricGroup =>
                    // if customMetricKeys is defined, use it instead of the key
                    metricGroup.customMetricKeys?.length
                        ? metricGroup.customMetricKeys
                        : metricGroup.key
                )
                .flat()
                .filter(Boolean)
        ),
    ]

    const { data: metricsData, isLoading: isLoadingProfileMetrics } =
        useProfileMetricsWithMinMax(
            TYPE_ASSET,
            slug,
            metricsToFetch,
            REFRESH_INTERVAL_MS
        )

    const filteredMetricGroups =
        metricGroups?.filter(metricGroup => {
            if (metricGroup.customMetricKeys?.length) {
                return metricGroup.customMetricKeys.every(
                    metricKey =>
                        !!getMetricValueByKey(
                            metricsData?.[`current_${metricKey}`]?.[0],
                            metricKey
                        )
                )
            }

            return !!getMetricValueByKey(
                metricsData?.[`current_${metricGroup?.key}`]?.[0],
                metricGroup?.key,
                metricGroup?.timeframeKey
            )
        }) ?? []

    return (
        <Tab
            isLoading={isLoadingProfileMetrics}
            metricGroups={filteredMetricGroups}
            asset={asset}
            timeframeKey={timeframeKey}
            metricsData={metricsData}
            title={title}
        />
    )
}

const Tab = ({
    isLoading = false,
    metricGroups = [],
    asset = null,
    metricsData = null,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    title = '',
}) => {
    if (isLoading) {
        return (
            <TabLoading
                messages={['Loading performance metrics...']}
                small={true}
            />
        )
    }

    if (!metricGroups?.length) {
        return (
            <p className='p-5'>{`No ${String(
                title
            ).toLowerCase()} metrics available for this asset.`}</p>
        )
    }
    return (
        <div className='flex flex-row flex-wrap gap-9 p-5'>
            {metricGroups.map((metricGroup, idx) => {
                const withExpansion = isMetricWithExpansion(metricGroup?.key)
                if (!withExpansion) {
                    const mergedMetrics = (
                        metricGroup.customMetricKeys?.length
                            ? metricGroup.customMetricKeys
                                  .map(
                                      key =>
                                          metricsData?.[`current_${key}`]?.[0]
                                              ?.metrics
                                  )
                                  .flat()
                            : [
                                  metricsData?.[
                                      `current_${metricGroup?.key}`
                                  ]?.[0]?.metrics,
                              ]
                    ).filter(Boolean)
                    return (
                        <ProfileMetricWithoutExpansion
                            className='w-[210px]'
                            key={`metric-${metricGroup?.key}-${idx}`}
                            metricGroup={metricGroup}
                            item={{
                                ...asset,
                                metrics: [...asset.metrics, ...mergedMetrics],
                            }}
                            timeframeKey={timeframeKey}
                        />
                    )
                }
                return (
                    <ProfileMetric
                        className='w-[210px]'
                        key={`metric-${metricGroup?.key}-${idx}`}
                        metricGroup={metricGroup}
                        item={asset}
                        timeframeKey={timeframeKey}
                        minMarketValue={getMetricValueByKey(
                            metricsData?.[`min_${metricGroup?.key}`]?.[0],
                            metricGroup?.key,
                            metricGroup?.timeframeKey
                        )}
                        maxMarketValue={getMetricValueByKey(
                            metricsData?.[`max_${metricGroup?.key}`]?.[0],
                            metricGroup?.key,
                            metricGroup?.timeframeKey
                        )}
                        metricData={
                            metricsData?.[`current_${metricGroup?.key}`]?.[0]
                        }
                        currentMetricKey={metricGroup?.key}
                        isSortableInArchive={getAssetSortByOptionsPerCategory(
                            ALL_KEY
                        )
                            .map(category => category.key)
                            .includes(metricGroup?.key)}
                    />
                )
            })}
        </div>
    )
}
