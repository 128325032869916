import classNames from 'classnames'
import { TableFiltersContextProvider } from 'contexts/tableFiltersContext'
import { DEFAULT_ASSET_TIMEFRAME, RewardOptionType } from 'utils/constants'
import { SortingFilter } from './sortingFilter'
import { TimeFrameFilter } from './timeFrameFilter'

import styles from './filtersRow.module.scss'
import { useCallback, useEffect } from 'react'
import { Link } from 'components/ui'
import Image from 'next/image'
import { VerifiedToggleButton } from 'components/ui/verifiedToggleButton2'
import { TypesFilter } from './typesFilter'
import { THEME, useTheme } from 'state'
import { RewardOptionTableState } from 'state/rewardOptionTableState'
import { GTMEvent, logEvent } from 'utils/GTM'
import { useRouter } from 'next/router'

const LEARN_MORE_LINK =
    '/journal/infographics/5-ways-to-stake-your-crypto-assets'

const RUN_VALIDATOR_FILTER = {
    key: RewardOptionType.RUN_VALIDATOR,
    title: 'Run A Validator',
    bulletPoints: 'Full control, aids decentralization for tech savvy',
    learnMoreLink: `${LEARN_MORE_LINK}#h2-0`,
    withVsp: true,
}

/**
 * Renders the FiltersRow component.
 *
 * @component
 * @param {Object} params - The parameters for the component.
 * @param {Object} setParams - The function to set the parameters.
 * @param {Function} getDefaultSortBy - The function to get the default sort by value.
 * @param {string} defaultTimeframe - The default timeframe value.
 * @param {Object} otherDefaultParams - The other default parameters.
 * @param {Array} categories - The categories array.
 * @param {Array} sortByChoices - The sort by choices array.
 * @param {boolean} withAssetFilter - Whether to include the asset filter or not.
 * @param {boolean} useNewFilters - Whether to use the new filters or not.
 * @returns {JSX.Element} The rendered FiltersRow component.
 */
export function FiltersRow({
    params = {},
    setParams = () => {},
    getDefaultSortBy = () => {},
    defaultTimeframe = DEFAULT_ASSET_TIMEFRAME,
    otherDefaultParams = {},
    categories = [],
    sortByChoices = [],
    withAssetFilter = false,
    useNewFilters = false,
}) {
    const router = useRouter()
    const slug = router?.query?.slug
    const theme = useTheme()
    // const [hideFilters, setHideFilters] = useState(false)
    const setCategoryFilter = category => {
        setParams({
            ...params,
            category: category,
        })
    }

    const filterMap = {
        pos: {
            key: RewardOptionType.PROOF_OF_STAKE,
            title: 'Native Staking',
            bulletPoints: 'Transparent, Simple, Protocol-Level Staking',
            learnMoreLink: `${LEARN_MORE_LINK}#h2-1`,
            withVsp: true,
        },
        'liquid-staking': {
            key: RewardOptionType.LIQUID_STAKING,
            title: slug === 'cosmos' ? 'Liquid Staking' : 'Liquid (Re)Staking',
            bulletPoints: 'Flexible exits, entry to DeFi',
            learnMoreLink: `${LEARN_MORE_LINK}#h2-2`,
            withVsp: true,
        },
        'solo-staking': RUN_VALIDATOR_FILTER,
        'smart-contract': {
            key: RewardOptionType.DEFI,
            title: 'DeFi',
            bulletPoints:
                'Simple to Advanced Yield Strategies for crypto natives',
            learnMoreLink: `${LEARN_MORE_LINK}#h2-3`,
            withVsp: false,
        },
        hosting: RUN_VALIDATOR_FILTER,
        custodial: {
            key: RewardOptionType.CUSTODIAL,
            title: 'Custodial Staking',
            bulletPoints: 'Quick and easy for beginners',
            learnMoreLink: `${LEARN_MORE_LINK}#h2-4`,
            withVsp: false,
        },
        'run-validator': RUN_VALIDATOR_FILTER,
        'babylon-staking': {
            logo: '/static/svg/babylon-white.svg',
            key: RewardOptionType.BABYLON_STAKING,
            title: 'Babylon Staking',
            bulletPoints: 'Non-Custodial Timelock for BTC',
            learnMoreLink: `${LEARN_MORE_LINK}#h2-4`,
            withVsp: true,
        },
        'dual-staking': {
            key: RewardOptionType.NATIVE_RESTAKING,
            title: 'Native Restaking',
            bulletPoints:
                'Secure, efficient staking for network and data operations.',
            learnMoreLink: `${LEARN_MORE_LINK}#h2-4`,
            withVsp: false,
        },
    }

    useEffect(() => {
        RewardOptionTableState.selectedFilter.value =
            filterMap[params?.category?.key]?.key
    }, [params?.category?.key])

    const onToggleVerified = useCallback(
        value => {
            setParams({
                ...params,
                verifiedFirst: value,
            })
        },
        [params, setParams]
    )

    const withVsp = !['custodial', 'smart-contract'].includes(
        RewardOptionTableState.selectedFilter.value
    )

    const filters = categories
        ?.filter(c => c.key !== 'all')
        // replace hosting and solo with run-validator filter and keep only one
        .reduce((acc, category) => {
            const filter = filterMap[category?.key]
            if (!filter) return acc
            if (!acc.find(c => c.key === filter?.key)) {
                return [
                    ...acc,
                    {
                        ...category,
                        ...filter,
                        key: filter?.key,
                    },
                ]
            } else {
                return acc
            }
        }, [])

    const babylonFilter = filters.find(
        filter => filter.key === RewardOptionType.BABYLON_STAKING
    )
    const otherFilters = filters.filter(
        filter => filter.key !== RewardOptionType.BABYLON_STAKING
    )

    // If the Babylon staking filter is found, place it at the beginning of the list
    const updatedFilters = babylonFilter
        ? [babylonFilter, ...otherFilters]
        : filters

    return (
        <TableFiltersContextProvider
            params={params}
            setParams={setParams}
            getDefaultSortBy={getDefaultSortBy}
            defaultTimeframe={defaultTimeframe}
            otherDefaultParams={otherDefaultParams}
            categories={categories}
            sortByChoices={sortByChoices}
        >
            <>
                {useNewFilters ? (
                    <div
                        className={classNames(
                            'w-full bg-contrast-0 p-6 rounded-lg mb-2',
                            {
                                'bg-white': theme === THEME.LIGHT,
                                'bg-contrast-1': theme === THEME.DARK,
                            },
                            styles.newFilter
                        )}
                    >
                        <div className='flex justify-between'>
                            <div className='mb-6'>
                                <h3 className='text-2xl font-bold text-contrast-4'>
                                    Step #1: Choose your Staking Type
                                </h3>
                            </div>
                            {/* <div className='flex items-center'>
                            <span className='text-[10px] text-contrast-4'>
                                Hide filters
                            </span>
                            <Switch
                                isSmall
                                onClick={() => setHideFilters(!hideFilters)}
                                isLeft={!hideFilters}
                            />
                        </div> */}
                        </div>

                        <div className='flex w-full gap-4 flex-wrap'>
                            {updatedFilters?.map((filter, index) => {
                                return (
                                    <FilterCard
                                        logo={filter.logo}
                                        key={index}
                                        title={filter?.title}
                                        bulletPoints={filter?.bulletPoints}
                                        learnMoreLink={filter?.learnMoreLink}
                                        onClick={() => {
                                            logEvent(
                                                GTMEvent.RewardOptionFilterChanged,
                                                {
                                                    ro_type: filter?.key,
                                                }
                                            )
                                            RewardOptionTableState.selectedFilter.value =
                                                filter?.key
                                            setCategoryFilter(filter)
                                        }}
                                        isSelected={
                                            RewardOptionTableState
                                                .selectedFilter.value ===
                                            filter?.key
                                        }
                                        withVsp={filter?.withVsp}
                                    />
                                )
                            })}
                            {/* <StakingAdvisor /> */}
                        </div>
                        <div
                            className={classNames(
                                'flex w-full mt-4 gap-4',
                                withVsp ? 'flex-col md:flex-row' : 'flex-row'
                            )}
                        >
                            {withVsp && (
                                <div className='flex items-center w-full'>
                                    <VerifiedToggleButton
                                        onChange={onToggleVerified}
                                    />
                                </div>
                            )}
                            <div
                                className={classNames(
                                    'flex gap-4 justify-center md:justify-end',
                                    { ['w-full']: !withVsp }
                                )}
                            >
                                {/* <div className={styles.filterWrap}>
                                    <TypesFilter
                                        withAssetFilter={withAssetFilter}
                                    />
                                </div> */}
                                <div
                                    className={classNames(
                                        'flex items-center justify-center'
                                    )}
                                >
                                    <SortingFilter
                                        useNewFilters={useNewFilters}
                                    />
                                </div>
                                <div
                                    className={classNames(
                                        'flex items-center justify-center'
                                    )}
                                >
                                    <TimeFrameFilter
                                        useNewFilters={useNewFilters}
                                    />
                                </div>
                            </div>
                        </div>
                        {withVsp && (
                            <div className='flex mt-4'>
                                <Link
                                    className='text-primary text-[11px]'
                                    href={'/vsp'}
                                >
                                    Learn more about the Verified Staking
                                    Provider (VSP) Program{' '}
                                    <span className='icon icon-arrow-right !bg-primary !w-[9px] !h-[8px]' />
                                </Link>
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        className={classNames(
                            styles.filtersRow,
                            'flex-1 w-full'
                        )}
                    >
                        <div className={styles.fullRow} />
                        <div className={styles.row}>
                            <div className={styles.filterWrap}>
                                <TypesFilter
                                    withAssetFilter={withAssetFilter}
                                />
                            </div>
                            <div className={styles.filterWrap}>
                                <SortingFilter />
                            </div>
                            <div
                                className={classNames(
                                    styles.filterWrap,
                                    styles.last
                                )}
                            >
                                <TimeFrameFilter />
                            </div>
                        </div>
                    </div>
                )}
            </>
        </TableFiltersContextProvider>
    )
}

const FilterCard = ({
    logo = false,
    title,
    bulletPoints = '',
    learnMoreLink,
    isSelected,
    onClick,
    withVsp = false,
}) => {
    return (
        <div
            className={classNames(
                'bg-contrast-0 rounded-lg p-4 flex-1 border-transparent border-2 border-solid hover:border-primary transition-all duration-300 cursor-pointer',
                {
                    ['!border-primary']: isSelected,
                }
            )}
            onClick={onClick}
        >
            <div className='h-full'>
                <div
                    className={classNames(
                        'text-contrast-6 flex gap-[8px] font-bold text-xs lg:text-base mb-8 lg:mb-4',
                        {
                            'text-primary': isSelected,
                        }
                    )}
                >
                    {logo && (
                        <Image
                            src={logo}
                            alt={title}
                            priority
                            width={16}
                            height={16}
                        />
                    )}

                    {title}
                </div>
                <p className='mb-10 hidden lg:flex text-contrast-5 text-xs leading-5'>
                    {bulletPoints}
                </p>
                <div className='absolute bottom-0 flex flex-row justify-between w-full whitespace-nowrap'>
                    <Link
                        className='text-primary text-[11px]'
                        href={learnMoreLink}
                        blank
                    >
                        Learn More
                    </Link>
                    {withVsp && (
                        <Image
                            src={'/static/svg/vsp-tag-extended.svg'}
                            alt={`Verified Provider`}
                            width={42}
                            height={14}
                            className='hidden lg:block right-0'
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
