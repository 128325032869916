import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setIsTouchedExpansion, setShowTableControl } from 'state'
import { useIsVisible } from 'hooks'

export const TableArchiveWithControl = ({
    forceHideControl = false,
    children,
}) => {
    const elRef = useRef(null)
    const isInView = useIsVisible(elRef)

    // Table control is a part of global page control
    const dispatch = useDispatch()
    useEffect(() => {
        // Show the expand/collapse button only when the table is visible
        dispatch(setShowTableControl(isInView && !forceHideControl))
        return () => {
            dispatch(setShowTableControl(false))
            dispatch(setIsTouchedExpansion(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView, forceHideControl])

    return <div ref={elRef}>{children}</div>
}
