import classNames from 'classnames'
import { ButtonWithBg } from 'components/ui'
import { BUTTON_VARIANT_STAKE_NOW } from 'components/ui/buttons/buttonWithBg'

// Button used for Reward Option table
export const Button = (
    { children, onClick = () => {}, ...props },
    className
) => {
    return (
        <ButtonWithBg
            className={classNames(
                'text-xs h-8 min-w-24 hover:bg-contrast-5/90 transition-all duration-200 ease-in-out',
                className
            )}
            variant={BUTTON_VARIANT_STAKE_NOW}
            onClick={onClick}
            {...props}
        >
            {children}
        </ButtonWithBg>
    )
}
