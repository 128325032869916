import { useEffect, useState } from 'react'
import {
    fetchApprovedHistoricalMetrics,
    fetchPerformanceChartMetrics,
} from 'data'
import { TYPE_ASSET } from 'utils/constants'

export const useApprovedHistoricalMetricKeys = (
    id = null,
    type = TYPE_ASSET,
    metricKeys = []
) => {
    const [approvedHistoricalMetricKeys, setApprovedHistoricalMetricKeys] =
        useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        fetchApprovedHistoricalMetrics(id, type, metricKeys)
            .then(data => {
                const metrics = data?.[`${type}s`]?.[0]?.metrics ?? []
                const filteredMetricKeys = metrics
                    .filter(m => m?.approvedHistorical)
                    .map(m => m?.metricKey)
                    .filter(Boolean)
                setApprovedHistoricalMetricKeys(filteredMetricKeys)
            })
            .catch(() => {
                setApprovedHistoricalMetricKeys([])
            })
            .finally(() => {
                setIsLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, JSON.stringify(metricKeys)])

    return { data: approvedHistoricalMetricKeys, isLoading }
}

export const usePerformanceChartMetrics = (
    id = null,
    type = TYPE_ASSET,
    dateInterval = { startDate: new Date(), endDate: new Date() },
    seriesConfig = []
) => {
    const [seriesMap, setSeriesMap] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        const metricKeys = seriesConfig?.map(mObj => mObj.key) ?? []
        fetchPerformanceChartMetrics(
            id,
            type,
            metricKeys,
            dateInterval?.startDate,
            dateInterval?.endDate
        )
            .then(fetchedAsset => {
                let newSeriesMap = {}
                metricKeys.forEach(key => {
                    const metrics = fetchedAsset?.metrics ?? []
                    const filteredMetrics = metrics.filter(
                        m => m?.metricKey === key
                    )
                    if (filteredMetrics.length > 0) {
                        newSeriesMap[key] = filteredMetrics
                    }
                })
                setSeriesMap(newSeriesMap)
            })
            .catch(() => {
                setSeriesMap({})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [id, dateInterval, seriesConfig, type])

    return { data: seriesMap, isLoading }
}
