import { getMetricValueByKey, calculateIfValid } from 'utils/actions'
import {
    isInvalidNumber,
    formatOutputNumber,
    NO_DATA_INDICATOR,
    EMPTY_STAKING_TIME_OBJECT,
} from 'utils/formatter'
import { timeObjectToDays } from 'utils/converter'
import {
    TYPE_ASSET,
    TYPE_PROVIDER,
    TYPE_VALIDATOR,
    SOLO_STAKING_KEY,
} from 'utils/constants'
import {
    DEFAULT_STAKING_AMOUNT_USD,
    DEFAULT_STAKING_TIME_OBJECT,
} from './constants'

export const getNoDataMessage = (entity = TYPE_ASSET) => {
    return `No ${entity}s found`
}

export const getNumberOrDefault = (value = null, defaultValue = 0) => {
    return isInvalidNumber(value) ? defaultValue : value
}

export const getAssetSlugFromRewardOption = (rewardOption = null) => {
    return rewardOption?.inputAssets?.[0]?.slug
}

export const getStakingAmountTokens = (
    amount = '',
    usd = true,
    tokenPrice = null,
    option = null
) => {
    const isInvalidTokenPrice = isInvalidNumber(tokenPrice) || tokenPrice === 0

    if (usd) {
        const stakingAmountUsd = getNumberOrDefault(
            amount,
            getDefaultStakingAmountUsd(option, tokenPrice)
        )
        return isInvalidTokenPrice ? 0 : stakingAmountUsd / tokenPrice
    }

    return getNumberOrDefault(
        amount,
        isInvalidTokenPrice
            ? 0
            : getDefaultStakingAmountUsd(option, tokenPrice) / tokenPrice
    )
}

export const getStakingAmountUsd = (
    amount = '',
    usd = true,
    tokenPrice = null,
    option = null
) => {
    if (usd) {
        return getNumberOrDefault(
            amount,
            getDefaultStakingAmountUsd(option, tokenPrice)
        )
    }

    const validatedTokenPrice = getNumberOrDefault(tokenPrice, 0)
    const defaultAmountInTokens =
        validatedTokenPrice !== 0
            ? getDefaultStakingAmountUsd(option, tokenPrice) /
              validatedTokenPrice
            : 0
    return isInvalidNumber(amount)
        ? defaultAmountInTokens
        : amount * validatedTokenPrice
}

export const getAmountInCurrency = (
    amount = '',
    asset = null,
    inUsd = true,
    format = false,
    withSymbol = false,
    toUsd = true,
    tokenPrice = 0,
    option = null
) => {
    let formattedAmount = ''
    if (amount !== '') {
        let amountInCurrency = toUsd
            ? getStakingAmountUsd(amount, inUsd, tokenPrice, option)
            : getStakingAmountTokens(amount, inUsd, tokenPrice, option)

        formattedAmount = format
            ? formatOutputNumber(amountInCurrency, {
                  prefix: withSymbol && toUsd ? '$' : '',
                  postfix: withSymbol && !toUsd ? asset?.symbol ?? '' : '',
                  spaceAfterNumber: true,
                  allowEmpty: false,
                  withAbbreviation: false,
                  forcePrecision: false,
                  showApproximation: true,
              })
            : amountInCurrency
    }

    return formattedAmount
}

export const getDefaultStakingAmountUsd = (option = null, tokenPrice = 1) => {
    const minValueTokens = getMetricValueByKey(option, 'minimum')
    if (option?.type?.key === SOLO_STAKING_KEY && minValueTokens) {
        return minValueTokens * tokenPrice
    }
    return DEFAULT_STAKING_AMOUNT_USD
}

const isNumberInRange = (minValue = null, maxValue = null, value = null) => {
    return (
        !isInvalidNumber(minValue) &&
        !isInvalidNumber(maxValue) &&
        !isInvalidNumber(value) &&
        minValue <= value &&
        maxValue >= value
    )
}

export const hasValidMetricData = (metric = null) => {
    return (
        isNumberInRange(
            metric?.editableMin,
            metric?.editableMax,
            metric?.defaultValue
        ) &&
        !isInvalidNumber(metric?.defaultValue) &&
        !isInvalidNumber(metric?.userValue)
    )
}

export const getItemsFromPaginatedData = (
    batch = {},
    entity = TYPE_ASSET,
    validate = false
) => {
    if (entity === TYPE_ASSET) {
        return batch?.assets ?? []
    }

    if (entity === TYPE_PROVIDER) {
        return batch?.rewardOptions ?? []
    }

    if (entity === TYPE_VALIDATOR) {
        if (validate) {
            return (
                batch?.validators?.filter(validator =>
                    Boolean(getMetricValueByKey(validator, 'reward_rate'))
                ) ?? []
            )
        }
        return batch?.validators ?? []
    }

    return []
}

export const getRateObject = (fraction = null) => {
    const validatedFraction = getNumberOrDefault(fraction, NO_DATA_INDICATOR)
    return {
        fraction: validatedFraction,
        percentage: calculateIfValid(({ fraction }) => fraction * 100, {
            fraction: validatedFraction,
        }),
    }
}

export const getOptimalStakingFrequencyNetworkFeeKey = (slug = '') => {
    switch (slug) {
        case 'elrond':
            return 'redelegation_fee'
        case 'kusama':
            return 'average_staking_fee'
        case 'cosmos':
            return 'redelegation_fee_30d'
    }

    return null
}

export const toChartData = (data = null) => {
    const numberOfDataPoints = data?.['notStaking']?.length ?? 0
    const transformedData = Array.from(
        { length: numberOfDataPoints + 1 },
        (_, i) => i
    ).map(day => ({
        day,
        notStaking: data?.['notStaking']?.[day],
        totalUsdValues: data?.['totalUsdValues']?.[day],
        totalUsdValuesCompounded: data?.['totalUsdValuesCompounded']?.[day],
        sellTotal: data?.['sellTotal']?.[day],
    }))

    return transformedData
}

export const getSeriesToShow = (
    isSoloStaking = false,
    hasCustomExpectedPrice = false,
    usd = true
) => {
    let seriesList = ['totalUsdValues']

    if (!isSoloStaking) {
        seriesList.push('totalUsdValuesCompounded')
    }

    if (hasCustomExpectedPrice && usd) {
        seriesList.push('sellTotal')
        seriesList.push('notStaking')
    }

    return seriesList
}

export const getChartYAxisDomain = (data = null, seriesToShow = []) => {
    const allValues = [
        ...(seriesToShow.includes('notStaking') ? data?.notStaking : []),
        ...(seriesToShow.includes('totalUsdValues')
            ? data?.totalUsdValues
            : []),
        ...(seriesToShow.includes('sellTotal') ? data?.sellTotal : []),
        ...(seriesToShow.includes('totalUsdValuesCompounded')
            ? data?.totalUsdValuesCompounded
            : []),
    ].filter(value => !isInvalidNumber(value))

    const minYValue = Math.min(...allValues)
    const maxYValue = Math.max(...allValues)

    return [minYValue, maxYValue]
}

export const getTimeObjectOrDefault = (
    timeObject = EMPTY_STAKING_TIME_OBJECT,
    defaultTimeObject = DEFAULT_STAKING_TIME_OBJECT
) => {
    const totalDays = timeObjectToDays(timeObject)
    return totalDays === 0 ? defaultTimeObject : timeObject
}
