import Image from 'next/image'
import classNames from 'classnames'
import { useElementSize } from 'hooks'
import { getObjectFromJsonString } from 'utils/actions'
import { isoDateToEuropeanDate } from 'utils/converter'
import { isInvalidNumber } from 'utils/formatter'
import {
    DIRECTION_HORIZONTAL,
    HorizontalSeparator,
    Link,
    ScrollbarWithGradient,
    withLoadingSkeleton,
    withTooltip,
} from 'components/ui'
import { getValueTextColorClassNames } from 'components/metrics/utils'
import { getRating } from './utils'

import styles from './vspSection.module.scss'
import { VspProTag } from 'components/ui/vspProTag'

export const TOOLTIPS = {
    srRating: {
        title: 'Staking Rewards Rating',
        text: 'It is based on Staking Rewards’ assessment of the Staking Provider through a structured due diligence process. Providers with ratings of AAA, AA, or A meet the Verification Criteria and are deemed secure, reliable, and trustworthy. The rating considers the Staking Provider’s Security Setup, Reliability, and Business Operations.',
    },
    businessOperations: {
        title: 'Business Operations',
        text: "Business Operations assess the legitimacy, delegator protection, and sustainability of a provider's services in the dynamic crypto environment. Emphasis is placed on business practices and the accuracy of provided information. This section evaluates how the provider handles various situations, such as slashing protection, fund delegation, and market downturn strategies.",
    },
    securitySetup: {
        title: 'Security Setup',
        text: 'Trust is crucial for investors when selecting a staking service. Even if most delegations are non-custodial, Verified Staking Providers are expected to adhere to certain standards to ensure resilient and consistent services. Factors considered include key management, security standards, certifications for the staking service, and redundancy of the provider’s validator nodes.',
    },
    reliability: {
        title: 'Reliability',
        text: 'Reliability and performance are key metrics for validators. Staking Rewards focuses on historical average performance on validator operations, the setup and support of the maintenance team, the presence of an alerting system for prompt issue resolution, and the slashing history of the provider’s staking services when verifying providers.',
    },
    operatorSet: {
        title: 'Operator Set',
        text: 'Optimal performance for Liquid Staking Tokens relies on the diligence of the Operator Set. This carefully chosen assembly of validators requires diversification and unwavering reliability. The staking APR of liquid staking tokens hinges on their performance. Key evaluation factors encompass operator entry prerequisites, ensuring a blend of operator dominance and diversity, diligent monitoring of operators coupled with their performance, and a vigilant stance on addressing bad behavior through appropriate penalties.',
    },
    valueAddedScore: {
        title: 'Value-Added Rating',
        text: "The Value-Added Rating evaluates a staking provider's contribution to the ecosystem and community growth and development. It is separate from the verification process and serves as an additional reference for staking decisions. The rating assesses customer service, governance participation, ecosystem & community development.",
    },
}

const Metric = withLoadingSkeleton(
    ({
        label = '',
        value = null,
        isRating = false,
        width = '100%',
        withCircle = false,
        id = null,
    }) => {
        const formattedValue = isRating ? getRating(value) : value
        const textColorClassName = getValueTextColorClassNames(
            value,
            withCircle,
            'vsp_score'
        )

        const LabelWithTooltip = withTooltip(() => (
            <div className={styles.labelWrap}>
                <span className={styles.label}>{label}</span>
            </div>
        ))

        return (
            <div
                className={styles.metric}
                style={{ minWidth: width, maxWidth: width }}
            >
                <LabelWithTooltip
                    tooltipObject={TOOLTIPS[id]}
                    // Add some space to the first item, so that the tooltip is not cut off
                    tooltipClassName={classNames({
                        [styles.tooltip]: id === 'srRating',
                    })}
                />
                <p
                    className={classNames(
                        'text-xl font-bold',
                        textColorClassName
                    )}
                >
                    {formattedValue}
                </p>
            </div>
        )
    }
)

const VspRow = ({
    isLoading = false,
    verificationScores = {},
    roType = 'PoS',
    valueAddedScore = null,
}) => {
    return (
        <div className={styles.scoresRow}>
            {[
                {
                    id: 'srRating',
                    label: 'SR Rating',
                    value: verificationScores?.totalScore,
                    isRating: true,
                    width: '140px',
                    withCircle: true,
                },
                {
                    label: 'Reward Option Type',
                    value: roType === 'PoS' ? 'Proof-of-Stake' : roType,
                    isRating: false,
                    width: '150px',
                    withCircle: false,
                },
                {
                    id: 'businessOperations',
                    label: 'Business Operations',
                    value: verificationScores?.bizOpsScore,
                    isRating: true,
                    width: '140px',
                    withCircle: true,
                },
                {
                    id: 'securitySetup',
                    label: 'Security Setup',
                    value: verificationScores?.securitySetUpScore,
                    isRating: true,
                    width: '120px',
                    withCircle: true,
                },
                roType === 'Liquid (Re)Staking'
                    ? {
                          id: 'operatorSet',
                          label: 'Operator Set',
                          value: verificationScores?.operatorSetScore,
                          isRating: true,
                          width: '100px',
                          withCircle: true,
                      }
                    : {
                          id: 'reliability',
                          label: 'Reliability',
                          value: verificationScores?.reliabilityScore,
                          isRating: true,
                          width: '100px',
                          withCircle: true,
                      },
                {
                    id: 'valueAddedScore',
                    label: 'Value-Added Rating',
                    value: valueAddedScore,
                    isRating: true,
                    width: '130px',
                    withCircle: true,
                },
                {
                    label: 'Verified Since',
                    value: isoDateToEuropeanDate(
                        verificationScores?.verifiedSince
                    ),
                    isRating: false,
                    width: '140px',
                    withCircle: false,
                },
            ].map((metric, idx) => (
                <Metric
                    key={`vsp-score-${idx}`}
                    id={metric.id}
                    isLoading={isLoading}
                    width={metric.width || '140px'}
                    value={metric.value}
                    label={metric.label}
                    isRating={metric.isRating}
                    withCircle={metric.withCircle}
                />
            ))}
        </div>
    )
}

const RowSeparator = ({ scrollWidth = null }) => {
    return (
        <div
            style={{
                width: isInvalidNumber(scrollWidth)
                    ? '100%'
                    : `${scrollWidth}px`,
            }}
        >
            <HorizontalSeparator className={styles.separator} />
        </div>
    )
}

export const VspSection = ({
    provider = null,
    isLoading = false,
    isPro = false,
}) => {
    const [ref, { width }] = useElementSize()

    const vsp = provider?.metrics?.find(m => m.metricKey === 'vsp')

    const verificationScores = getObjectFromJsonString(
        vsp?.variation
    )?.verificationScore

    const isPosVerified = verificationScores?.pos?.verified
    const isLsVerified = verificationScores?.ls?.verified
    const hasTable = isPosVerified || isLsVerified

    const valueAddedServices = verificationScores?.valueAdded?.text ?? []

    const hasAnyData = hasTable || Boolean(valueAddedServices?.length)

    // Do not show an empty table
    if (!hasAnyData) {
        return null
    }

    const productSchema = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: provider.name,
        review: {
            '@type': 'Review',
            reviewRating: {
                '@type': 'Rating',
                ratingValue: vsp.defaultValue,
                bestRating: 100,
            },
        },
    }

    return (
        <>
            <div className={styles.vspSection}>
                <div className={styles.header}>
                    <h3 className={styles.title}>
                        <span className={styles.vppIcon}>
                            {isPro ? (
                                <VspProTag />
                            ) : (
                                <Image
                                    src={'/static/svg/vsp-tag-extended.svg'}
                                    alt={`Verified`}
                                    fill
                                    sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                                />
                            )}
                        </span>
                        Verified Staking Provider
                    </h3>
                    <Link
                        className={styles.link}
                        href={
                            'https://docs.stakingrewards.com/verified-provider-program/rating-methodology/sr-rating'
                        }
                        blank
                    >
                        <span className={styles.label}>
                            Learn more about the methodology
                        </span>
                        <span
                            className={classNames(
                                'icon icon-arrow-right',
                                styles.icon
                            )}
                        />
                    </Link>
                </div>

                {hasTable && (
                    <>
                        <HorizontalSeparator className={styles.separator} />
                        <div className={styles.scoresWrap}>
                            <ScrollbarWithGradient
                                direction={DIRECTION_HORIZONTAL}
                                maxMediaBreakPoint={'2000px'}
                                bgColor={false}
                            >
                                <div className={styles.scores} ref={ref}>
                                    {isPosVerified && (
                                        <VspRow
                                            isLoading={isLoading}
                                            verificationScores={
                                                verificationScores?.pos
                                            }
                                            roType='PoS'
                                            valueAddedScore={
                                                verificationScores?.valueAdded
                                                    ?.score
                                            }
                                        />
                                    )}

                                    {isPosVerified && isLsVerified && (
                                        <RowSeparator scrollWidth={width} />
                                    )}

                                    {isLsVerified && (
                                        <VspRow
                                            isLoading={isLoading}
                                            verificationScores={
                                                verificationScores?.ls
                                            }
                                            roType='Liquid Staking'
                                            valueAddedScore={
                                                verificationScores?.valueAdded
                                                    ?.score
                                            }
                                        />
                                    )}
                                </div>
                            </ScrollbarWithGradient>
                        </div>
                    </>
                )}

                {Boolean(valueAddedServices?.length) && (
                    <>
                        <HorizontalSeparator className={styles.separator} />
                        <div className={styles.servicesWrap}>
                            <h4 className={styles.title}>
                                Value-Added Services
                            </h4>
                            <div className={styles.services}>
                                {valueAddedServices
                                    .filter(Boolean)
                                    .map((service, idx) => (
                                        <div
                                            key={`va-service-${idx}`}
                                            className={styles.service}
                                        >
                                            <Link
                                                href={service.link}
                                                className={styles.link}
                                                blank={true}
                                            >
                                                {service.title}
                                            </Link>
                                            <p className={styles.description}>
                                                {service.description}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <HorizontalSeparator className={styles.separator} />
            <script type='application/ld+json'>
                {JSON.stringify(productSchema)}
            </script>
        </>
    )
}
