import { memo, useState } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { GTMEvent, logEvent } from 'utils/GTM'

import styles from './verifiedToggleButton2.module.scss'

const VERIFIED_FIRST = 'verifiedFirst'
const ALL_PROVIDERS = 'allProviders'

export const VerifiedToggleButtonComponent = ({
    initialVerifiedFirst = true,
    onChange = () => {},
    forRewardOptions = false,
}) => {
    const [selectedOption, setSelectedOption] = useState(initialVerifiedFirst)

    const handleToggle = e => {
        const value = e.target.value === VERIFIED_FIRST
        if (value) {
            logEvent(GTMEvent.VerifiedProvidersClicked)
        } else {
            logEvent(GTMEvent.AllProvidersClicked)
        }
        setSelectedOption(value)
        onChange(value)
    }

    return (
        <div className={styles.toggleContainer}>
            <div
                className={classNames(styles.slider, {
                    [styles.slideRight]: !selectedOption,
                })}
            ></div>
            <button
                className={classNames(styles.toggleBtn, {
                    [styles.active]: selectedOption,
                })}
                value={VERIFIED_FIRST}
                onClick={handleToggle}
            >
                <span className={styles.vppIcon}>
                    <Image
                        src={'/static/svg/vsp-tag-extended.svg'}
                        alt={`Verified`}
                        fill
                        sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                    />
                </span>
                Show Verified Providers First
            </button>
            <button
                className={classNames(styles.toggleBtn, {
                    [styles.active]: !selectedOption,
                })}
                value={ALL_PROVIDERS}
                onClick={handleToggle}
            >
                {`Show All Providers${forRewardOptions ? ' & Validators' : ''}`}
            </button>
        </div>
    )
}

export const VerifiedToggleButton = memo(VerifiedToggleButtonComponent)
