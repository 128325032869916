export const StakingModalViews = {
    Get: 'get',
    Stake: 'stake',
    Portfolio: 'portfolio',
}

export const StakeViewType = {
    Main: 'main',
    Stake: 'stake',
    StakingType: 'stakingType',
    ProviderPair: 'providerPair',
    Provider: 'provider',
    Validator: 'validator',
    Route: 'route',
    Transaction: 'transaction',
    InputAsset: 'inputAsset',
    TransactionFee: 'transactionFee',
}

export const TransactionStatus = {
    Pending: 'pending',
    Loading: 'loading',
    Failed: 'failed',
    Success: 'success',
}
