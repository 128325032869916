import { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { useClickOutside } from 'hooks'
import { TYPE_ASSET, TYPE_PROVIDER } from 'utils/constants'
import { useCalculatorContext } from 'components/calculator'
import { DropdownSelect, Search } from 'components/forms'
import { DETAILS_TYPE_TEXT, ItemWithLogo, GroupSize } from 'components/ui'
import { InfiniteItemScroll } from './infiniteItemScroll'
import { ItemGroup, RewardOptionGroup } from './itemGroup'

import styles from './itemSelect.module.scss'

export const ItemSelect = ({
    selectedItem = null,
    entity = TYPE_ASSET,
    categories = [],
    defaultCategory = null,
    isDisabled = false,
    onChange = () => {},
}) => {
    const [isActive, setIsActive] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory)

    const { asset, validator } = useCalculatorContext()

    const selectRef = useRef(null)

    const onSetIsActive = newIsActive => {
        setIsActive(newIsActive)
        if (!newIsActive) {
            setSearchQuery('')
        }
    }

    useClickOutside(selectRef, () => {
        onSetIsActive(false)
    })

    useEffect(() => {
        setSelectedCategory(defaultCategory)
    }, [defaultCategory])

    return (
        <div className={styles.itemSelect}>
            <div
                onClick={() => {
                    !isDisabled && onSetIsActive(true)
                }}
                className={classNames(styles.selected, {
                    [styles.disabled]: isDisabled,
                })}
            >
                {entity === TYPE_ASSET ? (
                    <ItemWithLogo
                        name={selectedItem?.name}
                        iconUrl={selectedItem?.logoUrl}
                        details={selectedItem?.symbol}
                        detailsType={DETAILS_TYPE_TEXT}
                        slug={selectedItem?.slug}
                        alt={`${selectedItem?.name} ${selectedItem?.symbol}`}
                        iconSize={36}
                        sizeType={GroupSize.Medium}
                        isVerified={false}
                        withDetails={true}
                    />
                ) : (
                    <RewardOptionGroup
                        item={selectedItem}
                        validator={validator}
                        selected
                        showCategory={false}
                    />
                )}
                {!isDisabled && (
                    <div className={styles.arrow}>
                        <span className='icon icon-og icon-chevron-arrow-down' />
                    </div>
                )}
            </div>
            {isActive && (
                <div
                    className={classNames(styles.selectBox, {
                        [styles.withVerified]: entity === TYPE_PROVIDER,
                    })}
                    ref={selectRef}
                >
                    <h3>{`Choose your ${entity}`}</h3>
                    <Search
                        searchTerms={searchQuery}
                        setSearchTerms={setSearchQuery}
                        doFocus={isActive}
                        onEscape={() => setIsActive(false)}
                    />
                    {Boolean(categories?.length) && !searchQuery && (
                        <DropdownSelect
                            choices={categories}
                            selected={selectedCategory}
                            onSelect={setSelectedCategory}
                        />
                    )}

                    <div className={styles.scrollWrap}>
                        <InfiniteItemScroll
                            searchTerms={searchQuery}
                            categoryKey={selectedCategory?.key}
                            entity={entity}
                            assetSlug={asset?.slug}
                            renderItemGroup={item => {
                                return (
                                    <ItemGroup
                                        item={item}
                                        entity={entity}
                                        onSelect={() => {
                                            onSetIsActive(false)
                                            onChange(item)
                                        }}
                                        showCategory={
                                            entity === TYPE_PROVIDER &&
                                            Boolean(searchQuery)
                                        }
                                    />
                                )
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
