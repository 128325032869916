import classNames from 'classnames'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { readTime, trimUnescapedHtml } from 'utils/actions'
import { ComponentWithLink } from 'components/ui'
import { getJournalPageUrl } from './utils'
import { JOURNAL_PAGES } from './constants'

import styles from './articlePost.module.scss'

export const ArticlePost = ({
    post = null,
    category = '',
    isDynamic = false,
    isLatest = false,
}) => {
    const categoryName =
        category || post?.categories?.data?.[0]?.attributes?.name
    const categoryPageKey = JOURNAL_PAGES.find(
        p => p.name === categoryName
    )?.key

    const router = useRouter()

    const authorName = post.authors.data.map(
        author => author.attributes.username
    )

    const articleSchema = {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        headline: post?.title,
        image: [post?.featuredImage?.data?.attributes?.url],
        datePublished: post?.date,
        dateModified: post?.modifiedDate,
        author: [
            {
                '@type': 'Person',
                name: authorName,
            },
        ],
    }

    return (
        <div
            className={classNames(styles.articlePost, {
                [styles.latest]: isLatest,
                [styles.dynamic]: isDynamic,
            })}
        >
            <ComponentWithLink
                link={
                    post && categoryPageKey
                        ? `/journal/${categoryPageKey}/${
                              post?.slug ? `${post?.slug}/` : ''
                          }`
                        : null
                }
                blank={false}
            >
                <div className={styles.postContent}>
                    <div className={styles.image}>
                        <Image
                            src={post?.featuredImage?.data?.attributes?.url}
                            alt='Post'
                            width={post?.featuredImage?.data?.attributes?.width}
                            height={
                                post?.featuredImage?.data?.attributes?.height
                            }
                            priority={isLatest}
                        />
                    </div>

                    <div
                        className={classNames(styles.text, {
                            [styles.large]: isLatest,
                        })}
                    >
                        <div className={styles.wrap}>
                            <p className={styles.date}>
                                {new Date(
                                    String(post?.date).split('T')[0]
                                ).toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                })}
                            </p>
                            <p className={styles.title}>
                                {trimUnescapedHtml(post?.title || 'Article')}
                            </p>
                        </div>
                        {isLatest && (
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{
                                    __html: post?.excerpt,
                                }}
                            />
                        )}
                        <p className={styles.info}>
                            <span
                                tabIndex={0}
                                role='link'
                                aria-label={categoryName}
                                className={styles.category}
                                onClick={e => {
                                    e.preventDefault()
                                    router.push(
                                        getJournalPageUrl(categoryPageKey)
                                    )
                                }}
                            >
                                {categoryName}
                            </span>
                            {' · '}
                            <span>{readTime(post?.content ?? '')}</span>
                        </p>
                    </div>
                </div>
            </ComponentWithLink>
            <script type='application/ld+json'>
                {JSON.stringify(articleSchema)}
            </script>
        </div>
    )
}
