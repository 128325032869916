import { Link, LogoImage } from 'components/ui'
import { NO_DATA_INDICATOR } from 'utils/formatter'

export const ProviderCountMetric = ({ providerCount, data }) => {
    return (
        <div className='font-bold min-w-[120px] max-w-[120px] flex flex-col gap-1'>
            <div className='text-xs text-contrast-3'>Providers</div>
            <div className='text-sm text-contrast-4 flex gap-2 items-center'>
                <span>{providerCount ?? NO_DATA_INDICATOR}</span>
                <div className='flex !w-full'>
                    {data?.providers?.map((provider, i) => {
                        return (
                            <Link
                                key={i}
                                href={`/provider/${provider?.slug}`}
                                className='-mr-2 hover:z-10 transition-all duration-300 hover:scale-110'
                            >
                                <LogoImage src={provider?.logoUrl} size={24} />
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
