import { getTimeAgo } from 'utils/converter'
import { STAKING_START_DATE } from 'utils/stakingAssistant/constants'

export const calculateLastReward = lstHistory => {
    const COINS = ['rETH', 'wstETH'] // ['stETH', 'rETH2', 'rETH', 'wstETH']
    const lastItems = COINS.map(coin => {
        return {
            ...lstHistory?.[coin]
                ?.filter(item => item.type === 'reward')
                .reduce(
                    (acc, item) => {
                        return item.timestamp > acc.timestamp ? item : acc
                    },
                    { timestamp: 0 }
                ),
            coin,
        }
    }).filter(item => item.timestamp > 0)

    const last = lastItems.reduce(
        (acc, item) => {
            return item.timestamp > acc.timestamp ? item : acc
        },
        { timestamp: 0 }
    )

    return {
        ...last,
        timeAgo: getTimeAgo(last.timestamp * 1000),
    }
}

export const getTotalTokenEarnings = (lstBalanceHistory = [], rate = 1) => {
    return (
        lstBalanceHistory
            .filter(entry => entry.type === 'reward')
            .reduce((sum, entry) => sum + entry.change, 0) * rate
    )
}

export function getMissedEarningsTotal(balanceHistory, rate) {
    if (!balanceHistory) {
        return { eth: 0, usd: 0 }
    }

    const filteredBalanceHistory = balanceHistory.filter(
        b => b.timestamp > STAKING_START_DATE.getTime() / 1000
    )

    const firstTimestamp = filteredBalanceHistory[0]?.timestamp
    const lastTimestamp = Math.floor(new Date().getTime() / 1000)

    // Note: we assume that `balanceHistory` is sorted by timestamp.

    let totalBalance = 0
    let previousTimestamp = firstTimestamp
    let previousBalance = filteredBalanceHistory[0]?.balance || 0

    // iterate over each entry, starting from the second one
    for (let i = 1; i < filteredBalanceHistory.length; i++) {
        const entry = filteredBalanceHistory[i]
        const dayDifference =
            (entry.timestamp - previousTimestamp) / (60 * 60 * 24) // calculate day difference
        totalBalance += previousBalance * dayDifference // accumulate total balance
        previousTimestamp = entry.timestamp
        previousBalance = entry.balance
    }

    // don't forget to account for the period from the last entry to today
    const dayDifferenceToToday =
        (lastTimestamp - previousTimestamp) / (60 * 60 * 24)
    totalBalance += previousBalance * dayDifferenceToToday

    // calculate the total number of days from the first entry to today
    const totalDays = (lastTimestamp - firstTimestamp) / (60 * 60 * 24)

    // calculate and return the average balance per day
    const averageBalance = totalBalance / totalDays

    const missedEarnings = averageBalance * (totalDays / 365)
    return {
        eth: missedEarnings,
        usd: missedEarnings * rate,
    }
}

export const compressAddress = (address, fromStart = 8, fromEnd = 10) => {
    if (!address) {
        return ''
    }
    if (address.length <= fromStart + fromEnd) {
        return address
    }
    return `${address.slice(0, fromStart)}...${address.slice(-fromEnd)}`
}

const addressRegex = /^0x[a-fA-F0-9]{40}$/

export function isAddress(address) {
    return addressRegex.test(address)
}
