import { NO_DATA_INDICATOR, isInvalidNumber } from 'utils/formatter'

export const getAllPerformanceChartSeriesConfig = () => {
    return [
        {
            key: 'assets_under_management',
            name: 'AuM',
            prefix: '$',
            color: 'var(--c-magenta)',
        },
        {
            key: 'staking_wallets',
            name: 'Stakers',
            postfix: '',
            color: 'var(--c-cyan)',
        },
    ]
}

export const getRating = (value = null) => {
    if (isInvalidNumber(value)) {
        return NO_DATA_INDICATOR
    }

    if (value >= 94) return 'AAA'
    if (value >= 83) return 'AA'
    if (value >= 70) return 'A'
    if (value >= 50) return 'B'
    if (value >= 25) return 'C'
    return 'D'
}
