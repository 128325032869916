import { LIQUID_STAKING_KEY, POS_PROVIDER_KEY } from 'utils/constants'

export const MAX_DELEGATORS_COUNT = 10

export const COSMOS_STAKING_TYPE_OPTIONS = [
    {
        key: POS_PROVIDER_KEY,
        label: 'Native Staking',
        isDisabled: false,
        iconClassName: 'icon-hand',
        tagLabel: 'Full Rewards',
        description:
            'Enables you to earn a percentage of block rewards, by staking. Delegate your tokens with confidence by choosing a trustworthy staking provider from our list of Verified Staking Providers.',
    },
    {
        key: LIQUID_STAKING_KEY,
        label: 'Liquid Staking',
        isDisabled: true,
        iconClassName: 'icon-drop',
        tagLabel: 'Extra yield',
        description:
            'Liquid staking is the process of depositing your tokens to a liquid staking protocol. In exchange, you receive liquid staking tokens (LSTs), which are receipt tokens that represent your staked amount.',
    },
]
