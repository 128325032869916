import { getBaseUrl } from 'utils/api'

// Fetch ETH and WETH balance history
export const fetchEthHistory = async address => {
    return fetchData(
        `/api/staking-assistant/eth-balance-history?address=${address}`,
        'ETH history'
    )
}

export const fetchLSTBalanceHistory = async address => {
    return fetchData(
        `/api/staking-assistant/lst-balance-history?address=${address}`,
        'LST history'
    )
}

const fetchData = async (link, description) => {
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), 300 * 1000) // 300s timeout

    try {
        const response = await fetch(`${getBaseUrl()}${link}`, {
            signal: controller.signal,
        })
        const data = await response.json()

        if (data && !data.error) {
            return data
        } else {
            console.error(
                `Error returned, while fetching ${
                    description || ''
                } @ ${link}:`,
                data.error
            )
        }
    } catch (error) {
        if (error?.name === 'AbortError') {
            console.error(
                `Fetching ${description || ''} @ ${link} aborted due to timeout`
            )
        } else {
            console.error(
                `Error was thrown, while fetching ${
                    description || ''
                } @ ${link}:`,
                error
            )
        }
    } finally {
        clearTimeout(id) // clear the timeout if the request completes in time
    }
}
