import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { createSteadyState } from 'steadystate'

const STAGE = process.env.NEXT_PUBLIC_VERCEL_ENV

const key =
    STAGE === 'production' ? 'stakingrewards.com' : 'staging.stakingrewards.com'

const persist = state => window.localStorage.setItem(key, JSON.stringify(state))

export const THEME = {
    LIGHT: 'default',
    DARK: 'dark',
}

const initialState = {
    lang: 'en',
    currency: 'eur',
    currencySymbol: '€',
    cookiesAccepted: false,
    initialized: null,
    stageAuthKey: null,
    stageGate: true,
    theme: THEME.LIGHT,
    lastSeenAnnouncementId: null,
}

const actions = {
    setCurrency: (state, { payload }) => {
        state.currency = payload
        if (payload === 'usd') {
            state.currencySymbol = '$'
        } else if (payload === 'gbp') {
            state.currencySymbol = '£'
        } else if (payload === 'eur') {
            state.currencySymbol = '€'
        }
    },
}

const storageState = createSteadyState({
    key: 'storage',
    initialState,
    actions,
})

export const { storage, useStorageState, setStorageState } = storageState

export function useWatchStorage() {
    const router = useRouter()
    const initialized = useStorageState('initialized')
    const currency = useStorageState('currency')
    const lang = useStorageState('lang')
    const cookiesAccepted = useStorageState('cookiesAccepted')
    const theme = useStorageState('theme')
    const lastSeenAnnouncementId = useStorageState('lastSeenAnnouncementId')

    const {
        setInitialized,
        setCurrency,
        setLang,
        setCookiesAccepted,
        setTheme,
        setLastSeenAnnouncementId,
    } = setStorageState()

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            const localDb = window.localStorage.getItem(key)
            const obj = localDb ? JSON.parse(localDb) : null

            if (obj && Object.keys(obj).length) {
                setCurrency(obj['currency'])
                setLang(obj['lang'])
                setCookiesAccepted(obj['cookiesAccepted'])
                setTheme(obj['theme'] || theme)
                setLastSeenAnnouncementId(
                    obj['lastSeenAnnouncementId'] || lastSeenAnnouncementId
                )
                return
            }
        }

        persist({
            currency,
            lang,
            cookiesAccepted,
            theme,
            lastSeenAnnouncementId,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currency,
        lang,
        cookiesAccepted,
        initialized,
        theme,
        lastSeenAnnouncementId,
    ])

    useEffect(() => {
        const isPortfolio = String(router.asPath).startsWith('/terminal')
        document.documentElement.setAttribute(
            'data-theme',
            isPortfolio ? THEME.DARK : theme
        )
    }, [router?.asPath, theme])
}

export function useTheme() {
    const theme = useStorageState('theme')

    const router = useRouter()
    const isPortfolio = String(router.asPath).startsWith('/terminal')

    return isPortfolio ? THEME.DARK : theme
}
