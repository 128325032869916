import classNames from 'classnames'

import styles from './buttonWithBg.module.scss'

export const BUTTON_VARIANT_BG = 'background'
export const BUTTON_VARIANT_FG = 'foreground'
export const BUTTON_VARIANT_BLUE = 'blue'
export const BUTTON_VARIANT_CONTRAST4 = 'contrast4'
export const BUTTON_VARIANT_CONTRAST5 = 'contrast5'
export const BUTTON_VARIANT_STAKE_NOW = 'stakeNow'
export const BUTTON_VARIANT_DARK = 'dark'

export const ButtonWithBg = ({
    className = '',
    onClick = () => {},
    variant = BUTTON_VARIANT_BG,
    iconName = null,
    disabled = false,
    children,
}) => {
    const withIcon = Boolean(iconName)
    return (
        <button
            className={classNames(
                styles.buttonWithBg,
                {
                    [styles.withIcon]: withIcon,
                    [styles.bg]: variant === BUTTON_VARIANT_BG,
                    [styles.fg]: variant === BUTTON_VARIANT_FG,
                    [styles.blue]: variant === BUTTON_VARIANT_BLUE,
                    [styles.contrast4]: variant === BUTTON_VARIANT_CONTRAST4,
                    [styles.contrast5]: variant === BUTTON_VARIANT_CONTRAST5,
                    [styles.stakeNow]: variant === BUTTON_VARIANT_STAKE_NOW,
                    [styles.dark]: variant === BUTTON_VARIANT_DARK,
                },
                className
            )}
            onClick={onClick}
            disabled={disabled}
        >
            <span>{children}</span>
            {withIcon && (
                <span className={classNames(`icon ${iconName}`, styles.icon)} />
            )}
        </button>
    )
}
