import { setUiState } from 'state'
import { useEffectOnceOnMount } from './useEffectOnceOnMount'

export function useScrollYParams() {
    const { setIsPageTop, setIsPageBottom } = setUiState()
    useEffectOnceOnMount(() => {
        function handleScroll() {
            const scrollableHeight =
                document.documentElement.scrollHeight - window.innerHeight
            const scrolledDistance = window.scrollY
            setIsPageTop(
                (scrolledDistance || document?.documentElement?.scrollTop) > 0
            )
            setIsPageBottom(scrolledDistance === scrollableHeight)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
}
